import { FaUserAlt, FaUsers, FaPalette, FaCopy, FaSlidersH, FaTable, FaBoxes } from 'react-icons/fa'
import { FaSliders } from 'react-icons/fa6'

export const configItems = [

    {
        "name": "Usuários",
        "legend": "Gerenciar usuários",
        "icon": <FaUserAlt />,
        "permission": ["users:read", 'users:create', 'permissionGroups:create', 1],
        "subItems": [
            {
                "path": "/users",
                "name": "Internos",
                "permission": ["users:read", "users:create", 1],
            },
            {
                "path": "/permissionGroups",
                "name": "Grupos de permissão",
                "permission": ['permissionGroups:create', 1],
            },
        ]
    },
    {
        "name": "Clientes",
        "legend": "Gerenciar clientes",
        "icon": <FaUsers />,
        "permission": ["customers:read", "customers:create", "usersClient:read", "usersClient:create", "companies:read", "companies:create", 1],
        "subItems": [
            {
                "path": "/clients",
                "name": "Clientes",
                "permission": ["customers:read", "customers:create", 1],
            },
            {
                "path": "/usersClients",
                "name": "Usuários",
                "permission": ["usersClient:read", "usersClient:create", 1],
            },
            {
                "path": "/companies",
                "name": "Empresas",
                "permission": ["companies:read", "companies:create", 1],
            },
        ]
    },
    {
        "name": "Template",
        "legend": "Configurar Template",
        "icon": <FaCopy />,
        "permission": [1],
        "subItems": [
            {
                "path": "/templateAccountsPlans",
                "name": "Plano de contas",
                "permission": [1],
            },
        ]
    },
    {
        "name": "Parâmetros Panorama",
        "legend": "Outros",
        "icon": <FaSlidersH />,
        "permission": ['lm_inflatorFinancial:create', 'lm_inflatorFinancial:read', 'lm_regimeTributary:create', 'lm_regimeTributary:read', 1],
        "subItems": [
            {
                "path": "/inflatorFinancial",
                "name": "Inflator Financeiro",
                "permission": ['lm_inflatorFinancial:create', 'lm_inflatorFinancial:read', 1],
            },
            {
                "path": "/taxRegime",
                "name": "Regime Tributário",
                "permission": ['lm_regimeTributary:create', 'lm_regimeTributary:read', 1],
            }
        ]
    },
    // {
    //     "name": "Parâmetros",
    //     "legend": "Outros",
    //     "icon": <FaSlidersH />,
    //     "permission": ['lm_paymentCondition:create', 'lm_paymentCondition:read', 'lm_seller:create', 'lm_seller:read', 'lm_commissionSale:create', 'lm_commissionSale:read', 3],
    //     "subItems": [
    //         {
    //             "path": "/paymentCondition",
    //             "name": "Condição de pagamento",
    //             "permission": ['lm_paymentCondition:create', 'lm_paymentCondition:read', 3],
    //         },
    //         {
    //             "path": "/seller",
    //             "name": "Vendedor",
    //             "permission": ['lm_seller:create', 'lm_seller:read', 3],
    //         },
    //         {
    //             "path": "/comissionSale",
    //             "name": "Comissão de Venda",
    //             "permission": ['lm_commissionSale:create', 'lm_commissionSale:read', 3],
    //         }
    //     ]
    // },
    {
        "name": "Parâmetros",
        "legend": "Outros",
        "icon": <FaSliders />,
        "permission": ['lm_paymentCondition:create', 'lm_paymentCondition:read', 'lm_seller:create', 'lm_seller:read', 'lm_commissionSale:create', 'lm_commissionSale:read', 1],
        "subItems": [
            {
                "path": "/paymentCondition",
                "name": "Condição de pagamento",
                "permission": ['lm_paymentCondition:create', 'lm_paymentCondition:read', 1],
            },
            {
                "path": "/seller",
                "name": "Vendedor",
                "permission": ['lm_seller:create', 'lm_seller:read', 1],
            },
            {
                "path": "/comissionSale",
                "name": "Comissão de Venda",
                "permission": ['lm_commissionSale:create', 'lm_commissionSale:read', 1],
            }
        ]
    },
    {
        "name": "Tabela ICMS",
        "legend": "Outros",
        "icon": <FaTable />,
        "permission": ['icmsTable:create', 'icmsTable:read', 1],
        "subItems": [
            {
                "path": "/icmsTable",
                "name": "ICMS",
                "permission": ['icmsTable:create', 'icmsTable:read', 1]
            }
        ]
    },
    {
        "name": "Produtos",
        "legend": "Outros",
        "icon": <FaBoxes />,
        "permission": ['lm_productsGroup:create', 'lm_productsGroup:read', 1],
        "subItems": [
            {
                "path": "/productsGroup",
                "name": "Grupos de Produtos",
                "permission": ['lm_productsGroup:create', 'lm_productsGroup:read', 1]
            }
        ]
    },
    {
        "name": "Temas",
        "legend": "Personalizar cores",
        "icon": <FaPalette />,
        "permission": [1, 2, 3],
        "subItems": [
            {
                "path": "/theme",
                "name": "Meu tema",
                "permission": [1, 2, 3],
            }
        ]
    },
]