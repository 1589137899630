import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { monthsList } from "./monthsList"
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';

export function TableDetails({ data }){

    const [tableHead, setTableHead] = useState([])
    const [newData, setNewData] = useState([])

    useEffect(() => {
        async function getData(){
            const arrayMonthsData = []

            data?.map(sub => {
                sub?.accounts.map(acc => {
                    acc?.values.map((value, index2) => {
                        if (arrayMonthsData.includes(`${monthsList.filter(item => item?.id == value?.month)[0]?.name}/${value?.year}`)){
                            return
                        }
                        arrayMonthsData.push(`${monthsList.filter(item => item?.id == value?.month)[0]?.name}/${value?.year}`, '%')
                    })
                })
            })
            arrayMonthsData.push('Acumulado', '%')

            const formatAccounts = data.map((sub, index) => {
                let valuesPerMonth = []
                let valuesPerYear = []
    
                const newAccounts = sub?.accounts.map((acc) => {
                    acc?.values.map((value, index2) => {
                        valuesPerMonth?.push(value)
                    })
                    const filterValues = acc?.values.filter(item => item?.value != 0)
                    const totalValue = filterValues.reduce((accumulator2, currentValue2) => accumulator2 + currentValue2?.value, 0)
                    valuesPerYear.push(totalValue)
                    return {
                        ...acc,
                        totalValueYear: totalValue
                    }
                })
    
                const formatValuesPerMonth = valuesPerMonth.reduce((acumulator, month) => {
                    if (!acumulator[month?.month]) {
                        acumulator[month?.month] = month?.value
                    } else {
                        acumulator[month?.month] = acumulator[month?.month] + month?.value
                    }
                    return acumulator
                }, {})
    
                const totalAllAccounts = valuesPerYear.reduce((accumulator2, currentValue2) => accumulator2 + currentValue2, 0)
    
                return {
                    ...sub,
                    accounts: newAccounts,
                    totalAllAccounts,
                    valuesPerMonth: formatValuesPerMonth
                }
    
            })

            setTableHead(arrayMonthsData)
            setNewData(formatAccounts)
        }
        getData()

    }, [])

    return (
        <div className='hidden sm:flex sm:flex-col h-[60vh] relative w-full'>
            <div className="flex flex-col pb-2 shadow-xl pr-0 overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 relative rounded-md">
                <table className='border-collapse' id='TableToExport'>
                    <thead className='m-0 p-0 bg-primaryDefaultLight text-white font-bold relative'>
                        <tr className='sticky top-0 z-20'>
                            <th className='sticky left-0 top-0 bg-primaryDefaultLight '>
                                <div className='min-w-[20rem] z-5 p-1 border-0 border-r-2 border-r-gray-300 text-sm'>
                                    Plano de contas
                                </div>
                            </th>
                            {
                                tableHead.map(month => {
                                    return (
                                        <th className='bg-primaryDefaultLight text-sm'>{month}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody className='pb-2 m-0'>
                        {
                            newData.map((sub, index) => {
                                return (
                                    <>
                                        <tr className={`bg-blue-100   dark:bg-thirdDefaultDark ${index + 1 == 1 ? '' : 'border-t border-t-gray-700'}`}>
                                            <td className={`m-0 p-0 z-10 sticky left-0 z-3`}>
                                                <div className='min-w-[10rem] py-0 px-1 border-0 border-r-2 border-r-gray-300 flex items-center dark:text-white text-sm'>{sub?.code} - {sub?.description}</div>
                                            </td>
                                            {
                                                tableHead?.map(field => {
                                                    if (field == 'Acumulado'){
                                                        return (
                                                            <td className={`m-0 p-0 sticky left-0 z-3`}>
                                                                <div className='min-w-[8rem] py-0 px-1 border-0 flex items-center text-xs font-semibold justify-center dark:text-white '>Total: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(sub?.totalAllAccounts)}</div>
                                                            </td>
                                                        )
                                                    }
                                                    return (
                                                        <td className={`m-0 p-0 sticky left-0 z-3`}>
                                                            <div className='min-w-[6rem] py-0 px-1 border-0 flex items-center'></div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        {
                                            sub?.accounts?.map((acc, index) => {
                                                let percentTotal = 0
                                                if (sub?.totalAllAccounts) {
                                                    percentTotal = (acc?.totalValueYear * 100) / sub?.totalAllAccounts
                                                }
                                                let formatPorcentTotal = percentTotal?.toFixed(2)?.toString()?.replace('.', ',')
                                                return (
                                                    <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                                        <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                            {
                                                                acc?.description.length > 32 ?
                                                                    <Tippy
                                                                        content={<span>{acc?.description}</span>}
                                                                        arrow={true}
                                                                        animation='perspective'
                                                                        placement='top'
                                                                        delay={100}>
                                                                        <div className="py-0 px-1 border-0 border-r-2 dark:text-white  border-r-gray-300 flex items-center text-sm cursor-help">
                                                                            {
                                                                                acc?.description.length > 32 ? `${acc?.code} - ${acc?.description.slice(0, 32)}...` : acc?.description
                                                                            }
                                                                        </div>
                                                                    </Tippy>
                                                                    :
                                                                    <div className='py-0 px-1 border-0 border-r-2 dark:text-white border-r-gray-300 flex items-center text-sm '>{acc?.code} - {acc?.description}</div>
                                                            }
                                                        </td>
                                                        {
                                                            acc?.values.map((val, index2) => {
                                                                let porcentValue = 0
                                                                let valueTotalMonth = !sub?.valuesPerMonth[index2 + 1] ? 0 : sub?.valuesPerMonth[index2 + 1]
                                                                if (sub?.valuesPerMonth[index2 + 1]) {
                                                                    porcentValue = (val?.value * 100) / valueTotalMonth
                                                                }
                                                                let formatPorcent = porcentValue?.toFixed(2)?.toString()?.replace('.', ',')
                                                                return (
                                                                    <>
                                                                        <td><div className='mt-1 text-black dark:text-white  px-1 py-0 h-5 items-center justify-center flex text-sm'>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val?.value)}</div></td>
                                                                        <td><div className='mt-1 text-black dark:text-white px-1 py-0 h-5 items-center justify-center flex text-sm'>{formatPorcent}%</div></td>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                        <td>
                                                            <div className='mt-1 text-black px-1 py-0 h-5 items-center justify-center flex text-sm  dark:text-white'>
                                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(acc?.totalValueYear)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='mt-1 text-black px-1 py-0 h-5 items-center justify-center flex text-sm dark:text-white'>
                                                                {formatPorcentTotal}%
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}