import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Tippy from "@tippyjs/react";
import { FaArrowDown, FaEdit, FaFilter } from "react-icons/fa";
import { FiSlash } from 'react-icons/fi';
import { useThemeContext } from '../../../contexts/themeContext';
import { useAuth } from '../../../contexts/useAuth';
import { api } from '../../../services/api/api';
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
import { TablePaginationDefault } from '../../../components/table/tablePagination.default';
import { Body } from '../../../components/container/Body';
import { DefaultLoader } from '../../../components/loaders/defaultLoader';
import { ScreenModal } from '../../../components/modals/notification/screenModal';
import { responseError } from '../../../utils/responsesFunctions/error.response';
import { InputAutoComplete } from '../../../components/input/input.autocomplete';
import { Input } from '../../../components/input/input';
import { LabelInput } from '../../../components/label/label.input';
import { validateFields } from '../../../utils/form.validator';

export function ICMSTableCreate(){
    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [addressStateList, setAddressStateList] = useState([])

    const [selectedUfOrigin, setSelectedUfOrigin] = useState('')
    const [selectedUfDestiny, setSelectedUfDestiny] = useState('')
    const [selectedIcms, setSelectedIcms] = useState('')
    const [selectedStartDate, setSelectedStartDate] = useState('')

    useEffect(() => {
        async function getState(){
            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()

                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))
                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getState()
    }, [])

    async function handleSubmit(){
        setLoading(true)

        const requiredFields = [
            { name: 'selectedUfOrigin', value: selectedUfOrigin, required: true, type: 'object' },
            { name: 'selectedUfDestiny', value: selectedUfDestiny, required: true, type: 'object' },
            { name: 'selectedIcms', value: selectedIcms, required: true, type: 'string' },
            { name: 'selectedStartDate', value: selectedStartDate, required: true, type: 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post('api/v1/lm/icmsTable', {
                ufOrigin: selectedUfOrigin?.sigla,
                ufDestiny: selectedUfDestiny?.sigla,
                icms: selectedIcms,
                startDate: selectedStartDate
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('ICMS cadastrado com sucesso')
            setShowNotificationModalSuccess(true)
            navigate('/icmsTable')
        } catch(error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Criação de ICMS'}></Title>
            </TitlePage>
            <Body>
                {
                    loading ?
                    <DefaultLoader/>
                    :
                    <div className='flex flex-col items-center lg:items-start justify-start w-full'>
                        <form className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
                            <LabelInput 
                                text={'UF Origem'} 
                                required={true}
                                showIconInfo={true}
                                messageIconInfo={'Definir o unidade federativa de origem para aplicar a porcentagem de ICMS. Caso selecionado para o mês que já exista cadastro (Origem -> Destino) vai substituir o anterior com nova porcentagem.'}
                            >
                                <InputAutoComplete
                                    preSelectedValue={selectedUfOrigin ? addressStateList.filter(e => { return e?.sigla == selectedUfOrigin?.sigla })[0]?.nome : ''}
                                    value={selectedUfOrigin?.nome}
                                    selectedLabel={'nome'} id='selectedUfOrigin'
                                    data={addressStateList} optionList={['nome']}
                                    onChange={e => setSelectedUfOrigin(e)}>
                                </InputAutoComplete>
                            </LabelInput>
                            <LabelInput text={'UF Destino'} required={true}>
                                <InputAutoComplete
                                    preSelectedValue={selectedUfDestiny ? addressStateList.filter(e => { return e?.sigla == selectedUfDestiny?.sigla })[0]?.nome : ''}
                                    value={selectedUfDestiny?.nome}
                                    selectedLabel={'nome'} id='selectedUfDestiny'
                                    data={addressStateList} optionList={['nome']}
                                    onChange={e => setSelectedUfDestiny(e)}>
                                </InputAutoComplete>
                            </LabelInput>
                            <LabelInput text={'ICMS %'} required={true}>
                                <Input
                                    type={'number'}
                                    width={80}
                                    value={selectedIcms ? selectedIcms : ''}
                                    id='selectedIcms'
                                    onChange={(e) => setSelectedIcms(e.target.value)}
                                />
                            </LabelInput>
                            <LabelInput text={'Data Inicial'} required={true}>
                                <Input
                                    type={'month'}
                                    width={80}
                                    value={selectedStartDate ? selectedStartDate : ''}
                                    id='selectedStartDate'
                                    onChange={(e) => {setSelectedStartDate(e.target.value)}}
                                />
                            </LabelInput>
                        </form>
                        <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 lg:gap-2 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                            <Button
                                onClick={() => navigate('/icmsTable')}
                                shadow={true}
                                approval={false}
                            >Cancelar
                            </Button>
                            <Button
                                shadow={true}
                                onClick={() => handleSubmit()}
                            >Cadastrar
                            </Button>
                        </div>
                    </div>
                }
            </Body>
        </Container>
    )
}