import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Tippy from "@tippyjs/react";
import { FaArrowDown, FaEdit, FaFilter } from "react-icons/fa";
import { FiSlash } from 'react-icons/fi';
import { useThemeContext } from '../../../contexts/themeContext';
import { useAuth } from '../../../contexts/useAuth';
import { api } from '../../../services/api/api';
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
import { TablePaginationDefault } from '../../../components/table/tablePagination.default';
import { Body } from '../../../components/container/Body';
import { DefaultLoader } from '../../../components/loaders/defaultLoader';
import { ScreenModal } from '../../../components/modals/notification/screenModal';
import { responseError } from '../../../utils/responsesFunctions/error.response';
import { InputAutoComplete } from '../../../components/input/input.autocomplete';
import { Input } from '../../../components/input/input';

export function ICMSTable(){
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({  })
    const [quantity, setQuantity] = useState(0)
    const [addressStateList, setAddressStateList] = useState([])

    useEffect(() => {
        async function getState(){
            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()

                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))
                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getState()
    }, [])

    return (
        <>
            <ScreenModal title={'Filtros'}>
                {
                    loading ?
                    <DefaultLoader />
                    :
                    <div className='flex flex-col pt-2 pb-5 px-0 gap-2 items-center h-full justify-center'>
                        <label className='flex flex-col gap-1 items-start justify-center'>
                            <a className='text-sm text-inputPlaceholderLight'>UF Origem *</a>
                            <InputAutoComplete
                                preSelectedValue={filterConstructor?.ufOrigin ? addressStateList.filter(e => { return e?.sigla == filterConstructor.ufOrigin })[0]?.nome : ''}
                                value={filterConstructor?.ufOrigin ? addressStateList.filter(e => { return e?.sigla == filterConstructor.ufOrigin })[0]?.nome : ''}
                                selectedLabel={'nome'} id='addressState'
                                height={96}
                                data={addressStateList} optionList={['nome']}
                                onChange={e => {setFilterConstructor({ ...filterConstructor, ufOrigin: e.sigla })}}>
                            </InputAutoComplete>
                        </label>
                        <label className='flex flex-col gap-1 items-start justify-center'>
                            <a className='text-sm text-inputPlaceholderLight'>UF Destino *</a>
                            <InputAutoComplete
                                preSelectedValue={filterConstructor?.ufDestiny ? addressStateList.filter(e => { return e?.sigla == filterConstructor.ufDestiny })[0]?.nome : ''}
                                value={filterConstructor?.ufDestiny ? addressStateList.filter(e => { return e?.sigla == filterConstructor.ufDestiny })[0]?.nome : ''}
                                selectedLabel={'nome'} id='addressState'
                                height={96}
                                data={addressStateList} optionList={['nome']}
                                onChange={e => {setFilterConstructor({ ...filterConstructor, ufDestiny: e.sigla })}}>
                            </InputAutoComplete>
                        </label>
                        <label className='flex flex-col gap-1 items-start justify-center'>
                            <a className='text-sm text-inputPlaceholderLight'>Data Inicial *</a>
                            <Input
                                type={'month'}
                                width={80}
                                value={filterConstructor?.startDate ? filterConstructor?.startDate : ''}
                                id='startDate'
                                onChange={(e) => setFilterConstructor({ ...filterConstructor, startDate: e?.target?.value })}
                            />
                        </label>
                        <div className='mt-5'>
                            <Button onClick={() => {
                                setFilter(filterConstructor)
                                setShowUniversalModal(false)
                            }}>Filtrar</Button>
                        </div>
                    </div>
                }
            </ScreenModal>
            <Container validPeriod_lm={true}>
                <TitlePage>
                    <div className='flex flex-row gap-2 items-center justify-between  pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Tabela ICMS'}></Title>
                            <div className='flex flex-row gap-2 md:w-48 justify-between items-center'>
                                <h3 className='text-sm font-light dark:text-titleGrayTextDark'>{quantity} ICMS</h3>
                            </div>
                        </div>
                        {
                            (userData[0]?.permissions?.indexOf('icmsTable:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                            <>
                                <div className='hidden sm:flex flex-row gap-5 items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/icmsTableCreate')}>
                                    <Button shadow={true} width={40} onClick={() => navigate('/icmsTableCreate')}>+ Novo ICMS</Button>
                                </div>
                                <div className='sm:hidden flex flex-col items-center justify-end' onClick={() => navigate('/icmsTableCreate')}>
                                    <Button shadow={true} onClick={() => navigate('/icmsTableCreate')}>Novo</Button>
                                </div>
                            </>
                        }
                    </div>
                </TitlePage>
                <Body>
                    <div className='w-full sm:mt-0 mt-2 items-center flex gap-4 justify-between'>
                        <div className='flex flex-row items-center gap-4'>
                            <Button onClick={() => { setShowUniversalModal(true) }}>
                                <FaFilter className='text-white' />
                                <p className='text-white'> Filtrar </p>
                            </Button>
                            {
                                filter && JSON.stringify(filter) != JSON.stringify({  }) ? 
                                <Tippy
                                    key={`clearFilter`}
                                    content={<span>Limpar filtro</span>}
                                    arrow={true}
                                    animation='perspective'
                                    placement='right'
                                    delay={100}>
                                    <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                        <FiSlash className='cursor-pointer' onClick={async () => {
                                            setLoading(true)
                                            setFilter({  })
                                            setFilterConstructor({})
                                            await new Promise(resolve => setTimeout(resolve, 500))
                                            setLoading(false)
                                        }} />
                                    </div>
                                </Tippy>
                                : <></>
                            }
                        </div>
                    </div>
                    <TablePaginationDefault
                        onClick={(e) => e.func ? {} : {}}
                        quantity={(e) => setQuantity(e)}
                        apiUrl={'/api/v1/lm/icmsTable'}
                        filters={filter}
                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />}]}
                        colSpan={9}
                        collumns={[
                            {
                                name: 'ufOrigin',
                                type: 'string',
                                description: 'UF Origem'
                            },
                            {
                                name: 'ufDestiny',
                                type: 'string',
                                description: 'UF Destino'
                            },
                            {
                                name: 'icms',
                                type: 'string',
                                description: 'ICMS',
                                func: (item) => {
                                    return `${item?.icms}%`
                                }
                            },
                            {
                                name: 'startDate',
                                type: 'date',
                                description: 'Data Inicial'
                            },
                        ]}
                    />
                </Body>
            </Container>
        </>
    )
}