// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// components
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { LabelInput } from '../../../components/label/label.input'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { TitlePage } from '../../../components/titlePages/title.page'
import { MyProfilePictureEdit } from "./myProfile.editPicture";
// services 
import { api } from '../../../services/api/api'
import { getMyProfile, getPermissionGroups, getTypeAccess } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
//utils
import { validateEmail } from '../../../utils/validators/email.validator'
import { removePontuaction } from '../../../utils/validators/removePontuaction'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { justNumber } from '../../../utils/validators/justNumber'
import { mCPF } from '../../../utils/validators/cpfMask'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { Title } from '../../../components/titlePages/title'

export function MyProfile() {

    const { userData, verifytoken } = useAuth()
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [updatedUser, setUpdatedUser] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [showNotification, setShowNotication] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [successData, setSuccessData] = useState(false)
    const [typeAccessList, setTypeAccessList] = useState([])
    const [permissionGroupsData, setPermissionsGroupsData] = useState([])
    const [thisUserData, setThisUserData] = useState([])

    //selectedVariables
    const [selectedTypeAccesss, setSelectedTypeAccess] = useState('')
    const [selectedPermissionGroup, setSelectedPermissionGroup] = useState('')
    const [selectedFullname, setSelectedFullname] = useState('')
    const [selectedUserEmail, setSelectedUserEmail] = useState('')
    const [selectedBirthDay, setSelectedBirthDay,] = useState('')
    const [selectedDDDPhone, setSelectedDDDPhone] = useState('')
    const [selectedUserGender, setSelectedUserGender] = useState('')
    const [selectedPhone, setSelectedPhone,] = useState('')
    const [selecteduserCpf, setSelectedUserCpf] = useState('')
    
    const [selectButtonAlter, setSelectButtonAlter] = useState(false)
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('')

    const [picPreview, setPicturePreview] = useState('')
    const [imageSrc, setImageSrc] = useState(null)
    const [profilePic, setProfilePic] = useState('')

    //temporary API
    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    useEffect(() => {
        setSelectButtonAlter(false)
        setLoading(true)    

        async function getData() {

            const userDataApi = await getMyProfile(userData[0].token)
            if (userDataApi.error) {
                setLoading(false)
                setShowNotication(userDataApi.data)
                return
            }

            const thiUserGender = sexTypes.filter((gender) => {
                if (gender.id === userDataApi.data[0].gender) {
                    return gender
                }
            })
            
            setThisUserData(userDataApi.data[0])
            setSelectedFullname(userDataApi.data[0]?.fullname)
            setSelectedDDDPhone(userDataApi.data[0]?.dddphone)
            setSelectedPhone(userDataApi.data[0]?.phone)
            setSelectedUserEmail(userDataApi.data[0]?.email)
            setSelectedTypeAccess(userDataApi.data[0]?.typeAccess_description)
            setSelectedPermissionGroup(userDataApi.data[0]?.permissionGroup_name)
            setSelectedBirthDay(userDataApi.data[0]?.birthday ? userDataApi.data[0]?.birthday : '')
            setSelectedUserGender(thiUserGender[0])
            setSelectedUserCpf(userDataApi.data[0]?.cpf)

            setLoading(false)
        }

        getData()

    }, [updatedUser])

    useEffect(() => {

        async function getPic(){

            try {
                
                const response = await api.get('/api/v1/myProfile/photo', {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: 'Bearer ' + userData[0].token 
                    }
                })
                setProfilePic(URL.createObjectURL(response.data))

            } catch (error) {
                setProfilePic('')
            }

        }

        getPic()

    }, [imageSrc])

    async function handleSubmit() {

        const fields =
        [
            { "name": 'selectedFullname', "value": selectedFullname, "required": true, "type": 'string' },
            { "name": 'selectedUserEmail', "value": selectedUserEmail, "required": true, "type": 'string' }
        ]

        if (selectedUserEmail){
            if (!validateEmail(selectedUserEmail)) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('E-mail inválido')
            }
        }

        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Campos obrigatórios não preenchidos!')
            return setShowNotificationModalSuccess(false)
        }

        if (selectButtonAlter) {

            if (!currentPassword) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha o campo senha')
            }

            if (!newPassword) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha o campo nova senha')
            }

            if (!newPasswordConfirm) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha o campo confirmar nova senha')
            }

            if (newPassword !== newPasswordConfirm) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('As senhas não correspondem')
            }

            try {

                await api.put(`api/v1/myProfile/alterPass`, {
                    password: currentPassword,
                    newPassword: newPassword
                }, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })

                setSelectButtonAlter(false)
                setCurrentPassword('')
                setNewPassword('')
                setNewPasswordConfirm('')

            } catch (error) {
                setSelectButtonAlter(false)
                setCurrentPassword('')
                setNewPassword('')
                setNewPasswordConfirm('')
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setSelectButtonAlter(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setSelectButtonAlter(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }
        
        setLoading(true)

        try {
            
            const userEdit = await api.put(`/api/v1/myProfile`, {
                fullname: selectedFullname,
                birthday: selectedBirthDay ? selectedBirthDay : undefined,
                email: selectedUserEmail,
                cpf: selecteduserCpf ? selecteduserCpf?.replace('-', '')?.replaceAll('.', '') : undefined,
                phone: selectedPhone?.toString()?.indexOf('-') > -1 ? selectedPhone?.replace('-', '') : selectedPhone?.toString()?.indexOf('-') < 0 ? parseInt(selectedPhone) : undefined,
                dddphone: selectedDDDPhone ? selectedDDDPhone : undefined,
                gender: selectedUserGender?.id?.length > 0 ? selectedUserGender.id : undefined
            },{
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            await verifytoken(userData[0].token)

            setShowModificationModal(true)
            setLoading(false)
            setShowNotificationModalText('Perfil atualizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    //picture functions
    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    function handlePicture() {

        const input = document.getElementById('myProfilePicture')
        input.click()
    }

    async function showPreview(e) {

        const file = e.target.files[0];
        let imageDataUrl = await readFile(file)
        setImageSrc(imageDataUrl);
        setPicturePreview(URL.createObjectURL(file))
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <Title text={'Meu Perfil'}/>
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            imageSrc ?
                                <Body>
                                    {/* <div className='absolute h-full z-[999]'> */}
                                        <MyProfilePictureEdit onChange={(e) => setImageSrc(e)} imgSrc={imageSrc} picPreview={picPreview} />                            
                                    {/* </div> */}
                                </Body>
                            :
                            <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                                <div className={`rounded-full w-24 h-24 border-2 border-gray-300 ${profilePic?.length > 0 ? 'bg-transparent' : 'bg-primaryDefaultLight cursor-pointer hover:brightness-125'}  flex flex-col items-center justify-center`}>
                                    {
                                        profilePic?.length > 0 ?
                                            <div className='flex flex-col items-center justify-center h-20 w-20 sm:h-20 sm:w-20 shadow-lg bg-transparent rounded-full overflow-hidden'>
                                                <img src={profilePic}></img>
                                            </div>
                                            :
                                            <a className='text-secondaryDefaultLight text-3xl'>{selectedFullname?.slice(0, 1)}</a>

                                    }
                                </div>
                                <a onClick={() => handlePicture()} className='mt-5 mb-5 hover:underline cursor-pointer text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Alterar foto de perfil</a>
                                <input id='myProfilePicture' type='file' onChange={(e) => showPreview(e)} hidden={true}></input>
                                <form className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Nome Completo *</a>
                                        <Input width={80} charLimit={255} id='selectedFullname' type='text' value={selectedFullname} onChange={(e) => setSelectedFullname(e.target.value)} autoFocus={true}></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                        <Input width={80} charLimit={255} id='selectedUserEmail' type='e-mail' value={selectedUserEmail} onChange={(e) => { validateEmail(e.target.value); setSelectedUserEmail(e.target.value) }}></Input>
                                    </label>
                                    <label className='relative flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Tipo de acesso *</a>
                                        <Input
                                            disabled={true}
                                            value={selectedTypeAccesss}
                                            id='selectedTypeAccesss'
                                            width={80}
                                        />
                                    </label>
                                    {
                                        selectedPermissionGroup &&
                                        <label className='relative flex flex-col gap-1 items-start justify-center'>
                                            <a className='text-sm text-inputPlaceholderLight'>Permissão *</a>
                                            <Input
                                                disabled={true}
                                                value={selectedPermissionGroup}
                                                id='selectedPermissionGroup'
                                                width={80}
                                            />
                                        </label>
                                    }
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>CPF</a>
                                        <Input 
                                            charLimit={14} 
                                            width={80}
                                            id='selecteduserCpf' 
                                            type='text' 
                                            value={selecteduserCpf ? mCPF(selecteduserCpf) : ''} 
                                            onChange={(e) => setSelectedUserCpf(mCPF(e.target.value))}
                                        />
                                    </label>
                                    <div className='flex flex-row gap-3'>
                                        <LabelInput text={'DDD'}>
                                            <Input 
                                                charLimit={2} 
                                                width={'50px'} 
                                                type={'text'} 
                                                onChange={(e) => setSelectedDDDPhone(justNumber(e.target.value))} 
                                                value={selectedDDDPhone ? selectedDDDPhone : ''}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Telefone'}>
                                            <Input 
                                                charLimit={10} 
                                                type={'text'} 
                                                width={'256px'}
                                                value={selectedPhone ? maskPhone(selectedPhone) : ''}
                                                onChange={(e) => setSelectedPhone(maskPhone(e.target.value))} 
                                            />
                                        </LabelInput>
                                    </div>

                                </form>

                                {
                                    selectButtonAlter ?
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
                                            <div className='flex flex-row gap-3'>
                                                <LabelInput text={'Senha atual *'}>
                                                    <Input width={80} type={'password'} value={currentPassword} name='currentPassword' onChange={(e) => { setCurrentPassword(e.target.value) }}></Input>
                                                </LabelInput>
                                            </div>
                                            <div className='flex flex-row gap-3 mt-6'>
                                                <a onClick={() => {
                                                    setSelectButtonAlter(false)
                                                    setCurrentPassword('')
                                                    setNewPassword('')
                                                    setNewPasswordConfirm('')
                                                }}
                                                    className="cursor-pointer text-center m-auto items-center justify hover:underline text-sm">Cancelar alteração</a>
                                            </div>
                                            <div className='flex flex-row gap-3'>
                                                <LabelInput text={'Nova senha *'}>
                                                    <Input width={80} type={'password'} value={newPassword} name='newPassword' onChange={(e) => { setNewPassword(e.target.value) }}></Input>
                                                </LabelInput>
                                            </div>
                                            <div className='flex flex-row gap-3'>
                                                <LabelInput text={'Confirmar nova senha *'}>
                                                    <Input width={80} type={'password'} value={newPasswordConfirm} name='newPasswordConfirm' onChange={(e) => { setNewPasswordConfirm(e.target.value) }}></Input>
                                                </LabelInput>
                                            </div>
                                        </div>
                                        :
                                        <div className=" gap-3 mt-3">
                                            <div className='flex flex-row gap-3'>
                                                {/* <LabelInput text={'Senha'}>
                                                    <Input width={'120px'} type={'text'} disabled={true} value={phone}></Input>
                                                </LabelInput> */}
                                            </div>
                                            <div className='flex  items-start justify-start gap-3 mt-5'>
                                                <a onClick={() => { setSelectButtonAlter(true) }} className="cursor-pointer text-sm dark:text-primaryDefaultDarkColor border-b border-secondaryDefaultLight ">Alterar senha</a>
                                            </div>
                                        </div>
                                }

                                <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                                    <Button
                                        approval={false}
                                        shadow={true}
                                        onClick={() => {navigate('/'); setShowModificationModal(false)}}
                                    >Cancelar
                                    </Button>
                                    <Button
                                        approval={true}
                                        shadow={true}
                                        onClick={() => handleSubmit()}
                                    >Editar perfil
                                    </Button>
                                </div>
                            </div>
                    }
                </Body>
            </Container>
        </>


    )


}