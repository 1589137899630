// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
// components 
import { TitlePage } from '../../../components/titlePages/title.page'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
//services
import { api } from '../../../services/api/api'
import { getPermissions, getPermissionGroupEspecific } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// utils
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { validateFields } from '../../../utils/form.validator'

export function PermissionGroupsEdit() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { id } = useParams()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { setShowUniversalModal } = useThemeContext()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const { screenY, screenX } = useScreenSizeContext()
    const screenSize = screenY - 200

    const [selectedName, setSelectedName] = useState('')
    const [selectedDescription, setSelectedDescription] = useState('')
    const [ permissionList, setPermissionList ] = useState([])
    const [ permissionData, setPermissionData ] = useState([])
    const [selectedPermissions, setSelectedPermissions] = useState([])

    useEffect(() => {
        setLoading(true)

        async function getPermissionsAPI(){
            try {
    
                const permissionAPI = await getPermissions(userData[0].token)
    
                setPermissionList(permissionAPI.data)

            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        async function getData(){
            try {
    
                const permissionAPI = await getPermissionGroupEspecific(userData[0].token, id)
    
                setPermissionData(permissionAPI.data[0])
                setSelectedPermissions(permissionAPI.data[0].permissions.map(permission => {
                    return permission
                }))
                setLoading(false)

            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getPermissionsAPI()
        getData()

    }, [])

    async function handleSubmit(){
        setLoading(true)

        const fields =
        [
            { "name": 'selectedName', "value": permissionData?.name, "required": true, "type": 'string' }
        ]
        
        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }
        
        if (selectedPermissions.length == 0){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Preencha uma permissão')
        }

        try {
            
            await api.put(`api/v1/permissionGroups/${id}`, {
                name: permissionData?.name,
                description: permissionData?.description ? permissionData?.description : undefined,
                permissions: selectedPermissions
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setLoading(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/permissionGroups')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDelete(){
        setLoading(true)

        try {

            await api.delete(`/api/v1/permissionGroups/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setLoading(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/permissionGroups')
            setShowUniversalModal(false)

        } catch (error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowUniversalModal(false)
            setShowNotificationModalText('Grupo já está vinculado com usuários, ação não permitida')
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function changePermission(permission){
        if (selectedPermissions.includes(permission)){
            const position = (selectedPermissions.indexOf(permission))
            selectedPermissions.splice(position, 1)
            setSelectedPermissions([...selectedPermissions])
        } else {
            setSelectedPermissions([...selectedPermissions, permission])
        }
    }

    function changePermissionAll(permissionType){

        let permissionsToFeature = []
        let permissionstoFeatureSelected = []

        permissionList.map((feature) => {
            if (feature.feature === permissionType) {
                feature.permissions.map((permission) => permissionsToFeature.push(permission))
            }
        })

        selectedPermissions.map((feature) => {
            permissionsToFeature.map(permissionFeature => {
                if (feature === permissionFeature?.value) {
                    permissionstoFeatureSelected.push(feature)
                }
            })
        })

        permissionsToFeature = permissionsToFeature.map(permission => {
            return permission?.value
        })

        if (permissionsToFeature?.length == permissionstoFeatureSelected?.length){
            permissionsToFeature.map(permission => {
                if (selectedPermissions.includes(permission)){
                    const position = (selectedPermissions.indexOf(permission))
                    selectedPermissions.splice(position, 1)
                    setSelectedPermissions([...selectedPermissions])
                }
            })
        } else {
            permissionsToFeature.map(permission => {
                if (selectedPermissions.includes(permission) === false) {
                    selectedPermissions.push(permission)
                }
            })
            setSelectedPermissions([...selectedPermissions])
        }
    }

    return (
        <>
            <ScreenModal title={'Exlusão de grupo'} visible={showModal} width={400} height={200} >            
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir o grupo ?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={`Edição de grupo - ${permissionData?.name}`}></Title>
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <DefaultLoader/>
                        :
                        <>
                        <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                            <form className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                    <Input charLimit={255} id='selectedName' type='text' value={permissionData?.name ? permissionData?.name : ''} onChange={(e) => setPermissionData(prev => ({...prev, name: e.target.value}))}/>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Descrição</a>
                                    <Input charLimit={255} id='selectedUserEmail' type='e-mail' value={permissionData?.description ? permissionData?.description : ''} onChange={(e) => setPermissionData(prev => ({...prev, description: e.target.value}))}/>
                                </label>
                            </form>
                            <p className='mt-10'>Selecione as permissões do grupo:</p>
                            <div className='flex flex-wrap gap-5 p-11 lg:items-start items-center lg:justify-start justify-center'>                                
                                {
                                    permissionList.map(permission => {                                    
                                    return <div className='lg:w-80 md:w-80 w-full border mt-7 rounded-sm shadow-xl text-gray-400 text-sm'>
                                        <div className='p-5'>
                                            <p className='mb-2 text-black font-medium flex gap-4'>                                                                                               
                                                <InputCheckBox
                                                    id={permission.feature}
                                                    value={
                                                        permission.permissions?.filter((permissionApi) => {
                                                            if (selectedPermissions.includes(permissionApi?.value)){
                                                                return permissionApi
                                                            }
                                                        }).length === permission?.permissions.length ?
                                                            true : false
                                                    }                                                                                       
                                                    onClick={(e) => {
                                                        changePermissionAll(permission?.feature)
                                                    }}
                                                />
                                                <span className='border-b-2'>{permission.feature}</span>
                                            </p>
                                            <lu className='flex flex-col gap-4 mt-5'>
                                                {
                                                    permission.permissions.map(per => {
                                                        return <span className='flex items-center gap-2'>
                                                            <InputCheckBox 
                                                                value={selectedPermissions.includes(per.value) ? true : false}
                                                                id={per.value}                                                                                                                  
                                                                onClick={(e) => {
                                                                    changePermission(per.value)
                                                                }}
                                                            />{per.name}
                                                        </span>                                                        
                                                    })
                                                }
                                            </lu>
                                        </div>
                                    </div>                    
                                    })
                                }
                            </div>
                            <div className='mt-10 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                <Button
                                    approval={false}
                                    shadow={true}
                                    onClick={() => {navigate('/permissionGroups'); setShowModificationModal(false)}}
                                >Cancelar
                                </Button>
                                <div className='flex gap-2'>
                                    {
                                        (userData[0]?.permissions?.indexOf('permissionGroups:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            approval={false}
                                            shadow={true}
                                            onClick={() => setShowUniversalModal(true)}
                                        >Excluir
                                        </Button>
                                    }
                                    {
                                        (userData[0]?.permissions?.indexOf('permissionGroups:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            approval={true}
                                            shadow={true}
                                            onClick={() => handleSubmit()}
                                        >Editar grupo
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                        </>
                    }
                </Body>
            </Container>
        </>
    )


}