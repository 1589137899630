import React, { useState, useEffect } from 'react'
import { FaArrowDown, FaLock, FaUnlock } from 'react-icons/fa'

export function InputAutoComplete({ icon, colorIcon, colorCompareIcon, data, id, onChange, placeHolder, selectedLabel, preSelectedValue, optionList, width, height, value, inactive, disabled, autoFocus, typeInactive, separator, heightInput, widthForce }) {

    const [showThisList, setShowThisList] = useState(null)
    const [showList, setShowList] = useState(false)
    const [selectedValue, setSelectedValue] = useState(value ? value : '')
    const [mouseHover, setMouseHover] = useState(false)
    const [cursor, setCursor] = useState(-1)
    const widthInput = width > 0 ? width : 80
    const heightList = height > 0 && height + 'px'

    async function handleSelectedValue(dataValue, selectedLabel) {

        setMouseHover(true)
        setSelectedValue(dataValue[selectedLabel])
        setShowThisList(null)
        onChange(dataValue)
        await new Promise(resolve => setTimeout(resolve, 300))
        setMouseHover(false)

    }

    function keyBoardNavigation(e) {

        let options = document.getElementById('user-select-options')?.children
        if (e.key === 'ArrowDown') {

            setCursor(c => (c < options.length - 1 ? c + 1 : c))
            cursor > 0 && Array.from(options)[cursor - 1].scrollIntoView()
        }

        if (e.key === 'ArrowUp') {
            setCursor(c => (c > 0 ? c - 1 : 0))
            cursor > 0 && Array.from(options)[cursor - 1].scrollIntoView()
        }

        if (e.key === 'Enter') {
            setSelectedValue(options[cursor].children[0].getAttribute('value'))
            onChange(JSON.parse(options[cursor].children[0].getAttribute('selectedData')))
            setShowThisList(null)
        }

        if (e.key === 'Escape') {
            setCursor(-1)
            setShowThisList(null)
        }
    }

    function handleSelectValueDefault(value) {

        const valueSelected = data.filter((dataValue) => {
            if (dataValue[selectedLabel] === value) {
                return dataValue
            }
        })

        onChange(valueSelected[0])
    }

    useEffect(() => {
        setSelectedValue(preSelectedValue)
    }, [preSelectedValue])

    useEffect(() => {
        const listOptions = document.getElementById('user-select-options')

        if (showThisList && listOptions) {
            listOptions.style.opacity = '1'
        } else if (listOptions) {
            listOptions.style.opacity = '0'
        }

    }, [showThisList])

    return (
        <>
        
            <div className='hidden sm:flex flex-col scroll-smooths relative'>
                <input disabled={inactive || disabled ? true : false}
                    autoFocus={autoFocus ? true : false}
                    autoComplete="off" type='text'
                    onKeyDown={e => keyBoardNavigation(e)}
                    id={id}
                    value={value ? value : selectedValue}
                    onFocus={() => { !mouseHover && setShowThisList(1); setShowList(true) }}
                    onClick={() => { !mouseHover && setShowThisList(1); setShowList(true) }}
                    onBlur={() => !mouseHover && setShowThisList(null)}
                    placeholder={separator ? separator : ''}
                    onChange={(e) => { !typeInactive && setSelectedValue(e.target.value); !typeInactive && onChange(e.target.value); !typeInactive && setShowList(false) }}
                    datavalue='text'
                    className={`pl-2 text-lg sm:text-base border border-titleGrayTextDark focus:bg-titleGrayTextLight dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark h-16 ${heightInput ? 'sm:h-6' : 'sm:h-8'} outline-none focus:shadow-borderShadow ${widthForce ? `${widthForce}` : `w-${widthInput}`}  ${inactive || disabled ? 'bg-gray-300 dark:bg-thirdDefaultDark' : ''}`}>
                </input>
                <div className={`flex flex-row w-full items-center pr-4 absolute z-20 justify-end h-16 ${heightInput ? 'sm:h-6' : 'sm:h-8'} ${widthForce ? `${widthForce}` : `w-${widthInput}`}`}>
                    <FaArrowDown className={`${showThisList === 1 ? 'rotate-180 text-primaryDefaultLight' : 'rotate-0 text-titleBlackTextLight'} text-lg sm:text-base transition-all duration-300 dark:text-primaryDefaultDarkColor`} size='10'></FaArrowDown>
                </div>
                {
                    showThisList === 1 &&
                    <ul id='user-select-options' style={{ maxHeight: heightList?.length > 0 ? heightList : '200px', overflowX: 'hidden' }} onMouseMove={() => { setMouseHover(true) }} onMouseLeave={() => { setMouseHover(false) }} className={`opacity-0 transition-all duration-300 flex z-30 bg-bgPrimaryLight boxShadow flex-col items-start absolute translate-y-20 ${heightInput ? 'sm:translate-y-7' : 'sm:translate-y-9'} ${widthForce ? `${widthForce}` : `w-${widthInput}`} dark:scrollbar-thumb-gray-700 dark:scrollbar-track-gray-900 scrollbar-thin scrollbar-track-gray-400 scrollbar-thumb-gray-300`}>

                        {
                            data?.length === 0 ?
                                <li className={`p-2 scroll-smooth bg-bgPrimaryLight dark:bg-thirdDefaultDark dark:text-titleGrayTextLight' cursor-not-allowed w-full transition-all duration-300 dark:text-titleGrayTextDark`}>
                                    Sem dados
                                </li>
                                :
                                data?.map((dataValue, index) => {

                                    let toSearch = ''
                                    optionList?.length > 0 ? optionList?.map((option, index) => {
                                        toSearch = toSearch.concat(dataValue[option]?.toString()).concat(' ')
                                    }) : toSearch = toSearch.concat(dataValue[selectedLabel]?.toString()).concat(' ')
                                    
                                    if (toSearch?.normalize('NFD').replace(/[\u0300-\u036f]/g, "")?.toLowerCase()?.indexOf(selectedValue?.toString()?.toLowerCase()) > -1 || showList) {
                                        return <li id={`li-option-${index}`} key={index} className={`p-2 hover:bg-bgSecondaryLight dark:hover:bg-secondaryDefaultDark focus:bg-bgSecondaryLight dark:focus:bg-secondaryDefaultDark scroll-smooth ${cursor === index ? 'bg-bgSecondaryLight dark:bg-secondaryDefaultDark dark:text-titleGrayTextLight ' : 'bg-bgPrimaryLight dark:bg-thirdDefaultDark dark:text-titleGrayTextLight'} cursor-pointer w-full transition-all duration-300`} onClick={() => handleSelectedValue(dataValue, selectedLabel)}>
                                            {
                                                icon ?
                                                    <a className='sm:text-base text-2xl flex items-center gap-2' selectedData={JSON.stringify(dataValue)} value={
                                                        dataValue[selectedLabel]}>
                                                        {dataValue?.success ? <div className={`${colorIcon}`}>{icon.icon}</div> : <div className={`${colorCompareIcon}`}>{icon.compareIcon}</div> }
                                                        {optionList?.length > 0 ? optionList?.map((option, index) => {
                                                            
                                                            if (index < optionList.length - 1) {
                                                                return placeHolder !== undefined ?
                                                                    dataValue[option] + placeHolder : dataValue[option] + ' - '
                                                            }
                                                            return dataValue[option]
                                                        }) : dataValue[selectedLabel]
                                                        }
                                                    </a>
                                                    :
                                                    <a className='sm:text-base text-2xl flex items-center' selectedData={JSON.stringify(dataValue)} value={
                                                        dataValue[selectedLabel]}>
                                                        {optionList?.length > 0 ? optionList?.map((option, index) => {
                                                            if (index < optionList.length - 1) {
                                                                return placeHolder !== undefined ?
                                                                    dataValue[option] + placeHolder : dataValue[option] + ' - '
                                                            }
                                                            return dataValue[option]
                                                        }) : dataValue[selectedLabel]
                                                        }
                                                    </a>
                                            }
                                        </li>
                                    }
                                })
                        }
                    </ul>
                }
            </div>
            <div className='flex sm:hidden'>
                <select onChange={(e) => { setSelectedValue(e.target.value); handleSelectValueDefault(e.target.value); setShowList(false) }} className="dark:hover:bg-secondaryDefaultDark dark:bg-secondaryDefaultDark focus:hover:primaryDefaultLight dark:focus:bg-secondaryDefaultDark border border-gray-200 border-opacity-50 dark:text-gray-200 text-gray-800 lg:h-16 text-lg outline-none focus:ring-blue-500 focus:border-primaryDefaultLight block w-56 lg:w-80 p-2.5">
                    {
                        !selectedValue &&
                        <option selected={true}>-Selecione-</option>
                    }
                    {data?.map((dataValue, index) => {
                        return <option selected={!selectedValue ? false : selectedValue === dataValue[selectedLabel] ? selectedValue : false} >{dataValue[selectedLabel]}</option>
                    })}
                </select>
            </div>
        </>
    )
}