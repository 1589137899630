import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { FaArrowDown, FaCloudUploadAlt, FaEdit, FaFilter } from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { Button } from '../../../components/buttons/button.default';
import { Body } from '../../../components/container/Body';
import { TablePaginationDefault } from '../../../components/table/tablePagination.default';
import FileDropZone from '../../../components/dropZone/dropZone';
import { ScreenModal } from '../../../components/modals/notification/screenModal';
import { DefaultLoader } from '../../../components/loaders/defaultLoader';
import { Input } from '../../../components/input/input';
import { justNumber } from '../../../utils/validators/justNumber'
import { FiSlash } from 'react-icons/fi'
import { InputAutoComplete } from '../../../components/input/input.autocomplete';
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//utils
import { typeProducts } from "../../../utils/typeProduct/typeProduct";
import { unitMeasures } from "../../../utils/unitMeasure/unitMeasureList";
//api
import { api } from "../../../services/api/api";
import moment from 'moment';
import { BsCardChecklist } from 'react-icons/bs';
import Multiselect from '../../../components/selects/mult.select';
import { LabelInput } from '../../../components/label/label.input';

export function Shopping() {

    const { userData, selectedCompany } = useAuth()
    const navigate = useNavigate()
    const [filter, setFilter] = useState({})
    const [filterConstructor, setFilterConstructor] = useState({})
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { setShowUniversalModal } = useThemeContext()
    const typeProductsList = typeProducts()
    const unitMeasureList = unitMeasures()
    const [loading, setLoading] = useState(false)

    const [companies, setCompanies] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const [productsList, setProductsList] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [showImports, setShowImports] = useState(false)
    const [sendFile, setSendFile] = useState('')
    const [detectModal, setDetectModal] = useState('')

    useEffect(() => {
        setLoading(true)

        async function getData() {
            try {

                const getCompaniesAPI = await api.get(`api/v1/companies/readCompaniesSelected`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                setCompanies(getCompaniesAPI?.data?.data)

                const getSuppliers = await api.get(`api/v1/general/supplierAndCustomer?isSupplier=true`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                setSupplierList(getSuppliers?.data?.data)

                const getProducts = await api.get(`api/v1/lm/products?active=true`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                setProductsList(getProducts?.data?.data)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getData()
        setLoading(false)

    }, [])

    async function handleEdit(shopping) {
        navigate(`/shoppingEdit/${shopping?.id}`)
    }

    async function downloadCSV() {
        setLoading(true)

        const dataExportsModel = await api.get(`/api/v1/lm/imports/exportModelShopping`, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                authorization: `Bearer ${userData[0].token}`
            }
        })

        let fileObj = URL.createObjectURL(dataExportsModel?.data)
        let myElement = document.createElement('a')
        myElement.setAttribute('href', fileObj)
        myElement.setAttribute("download", `Compras_${Number((new Date))}`)
        myElement.click()

        setLoading(false)
    }

    async function handleImports() {

        if (!sendFile) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowUniversalModal(false)
            return setShowNotificationModalText("Selecione um arquivo para realizar a importação.")
        }

        setLoading(true)
        setShowUniversalModal(false)
        
        try {

            const formData = new FormData();
            formData.append('files', sendFile);

            await api.post(`/api/v1/lm/imports/importShopping`, formData, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })
            setSendFile('')
            setShowModificationModal(true)
            setShowNotificationModalText('Importação realizada com sucesso')            
            setShowNotificationModalSuccess(true)
            setShowImports(false)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function exportShoppings(){
        try {

        const urlContructor = ['']

        let formatCompanies = []
        if (filterConstructor?.companies_id?.length > 0) {
            formatCompanies = filterConstructor?.companies_id?.map(item => { return item?.id })
        } else {
            formatCompanies = [ selectedCompany?.id ]
        }

        const newObj = { ...filterConstructor, companies_id: formatCompanies?.toString() }

        Object.keys(newObj).map(key => {
            if(![null, undefined, '', NaN].includes(newObj[key])){                
                urlContructor.push(`${key}=${newObj[key]}`)
            }
        })
        
        const dataExports = await api.get('/api/v1/lm/shopping/exports' + '?' + urlContructor.join('&'), {
            headers: {
                authorization: `Bearer ${userData[0].token}`
            },
            responseType: 'blob',
        })

        const url = window.URL.createObjectURL(dataExports?.data)
        const downloadElement = document.createElement('a')
        downloadElement.setAttribute('href', url)
        downloadElement.setAttribute('download', `Compras_${new Date().toISOString().split('T')[0]}`)
        downloadElement.click()

        } catch(error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }
    }

    return (
        <>
            {
                detectModal == '1' &&
                <ScreenModal title={'Importar Compras'}>
                    <div className='w-full h-full flex flex-col items-center justify-center mt-5'>
                        <p className='text-base text-titleBlackTextLight'>Deseja realmente importar as compras?</p>
                        <div className='flex flex-row gap-4 mt-5 pb-5'>
                            <Button onClick={() => handleImports()}>Sim </Button>
                            <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            {
                detectModal == '2' &&
                <ScreenModal title={'Filtros'}>
                    {
                        loading
                            ? <DefaultLoader />
                            : (
                                <div className='flex flex-col pt-2 pb-5 px-12 gap-2 items-center'>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                        <Input
                                            charLimit={255} id='id' type='text'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                            value={filterConstructor?.id}
                                            width={80}
                                        ></Input>
                                    </label>
                                    <LabelInput text={'Empresas *'} id={'labelCompanies'} icon={<BsCardChecklist className='flex items-center ml-2 text-lg' />}>
                                        <Multiselect
                                            items={companies}
                                            mult
                                            onChange={e => setFilterConstructor({ ...filterConstructor, companies_id: e })}
                                            selectedLabel={'businessName'}
                                            value={filterConstructor?.companies_id ? filterConstructor?.companies_id : []}
                                            id={`selectedCompanies`}
                                            height={8}
                                        />
                                    </LabelInput>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Fornecedor *</a>
                                        <InputAutoComplete
                                            id='supplierFilter'
                                            data={supplierList}
                                            height={96}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, supplierAndCustomer_id: e?.id })}
                                            optionList={['id', 'businessName']}
                                            selectedLabel={'businessName'}
                                        />
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Produto *</a>
                                        <InputAutoComplete
                                            id='productFilter'
                                            data={productsList}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, products_id: e?.id })}
                                            optionList={["code", "description", "typeProduct"]}
                                            selectedLabel={'description'}
                                            height={96}
                                        />
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>De *</a>
                                        <Input
                                            id='startDate'
                                            type='date'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, startDate: e.target.value })}
                                            value={filterConstructor?.startDate}
                                            width={80}
                                        />
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Até *</a>
                                        <Input
                                            id='finishDate'
                                            type='date'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, finishDate: e.target.value })}
                                            value={filterConstructor?.finishDate}
                                            width={80}
                                        />
                                    </label>
                                    <div className='mt-5'>
                                        <Button onClick={() => {
                                            let formatCompanies = []
                                            if (filterConstructor?.companies_id) {
                                                formatCompanies = filterConstructor?.companies_id?.map(item => { return item?.id })
                                            }
                                            setFilter({ ...filterConstructor, companies_id: formatCompanies?.toString() })
                                            setShowUniversalModal(false)
                                        }}>Filtrar</Button>
                                    </div>
                                </div>
                            )
                    }
                </ScreenModal>
            }
            <Container validPeriod_lm={true}>
                <TitlePage>
                    <div className='flex flex-row gap-2 items-center justify-between  pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Compras'}></Title>
                            <div className='flex flex-row gap-2 md:w-48 items-center'>
                                <h3 className='text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity} Compras</h3>
                                <div className='hidden md:flex flex-row justify-start items-center gap-1 cursor-pointer ml-3' onClick={() => exportShoppings()}>
                                    <Tippy
                                        content={<span>Exportar lista em excel</span>}
                                        arrow={true}
                                        animation='perspective'
                                        placement='bottom'
                                        delay={80}
                                    >
                                        <p className='flex flex-row items-center justify-center gap-1 text-xs 2xl:text-sm dark:text-gray-300'>Exportar <FaArrowDown className='dark:text-titleGrayTextDark text-xs 2xl:text-sm' /></p>
                                    </Tippy>
                                </div>
                            </div>
                        </div>
                        {
                            [1].includes(userData[0]?.typeAccess_id) &&
                            <div className='w-full flex justify-end items-end mr-[15px] mt-[10px]'>
                                <Tippy
                                    content={<span>Importar Compras</span>}
                                    arrow={true}
                                    animation='perspective'
                                    placement='top'
                                    delay={80}
                                >
                                    <div onClick={() => setShowImports(true)} className="text-xl text-blue-600 cursor-pointer">
                                        <FaCloudUploadAlt />
                                    </div>
                                </Tippy>
                            </div>
                        }
                        {
                            (userData[0]?.permissions?.indexOf('lm_shopping:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                            <>
                                <div className='hidden sm:flex flex-row gap-5 items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/shoppingCreate')}>
                                    <Button shadow={true} width={40} onClick={() => navigate('/shoppingCreate')}>+ Nova Compra</Button>
                                </div>
                                <div className='sm:hidden flex flex-col items-center justify-end' onClick={() => navigate('/shoppingCreate')}>
                                    <Button shadow={true} onClick={() => navigate('/shoppingCreate')}>Novo</Button>
                                </div>
                            </>
                        }
                    </div>
                </TitlePage>
                <Body>
                    {
                        showImports ?
                            <>
                                {
                                    loading ?
                                    <DefaultLoader/>
                                    :
                                    <>
                                        <div className="flex flex-col justify-start items-start gap-1 w-full">
                                            <h1 className="text-md text-red-500">Atenção às regras de importação.</h1>
                                            <p className='text-sm'>
                                                <span className="font-semibold">Regras de importação:</span> Utilizar o arquivo base para importação, o arquivo poderá ser baixado no botão abaixo ou clicando <button className="underline" onClick={() => downloadCSV()}>aqui</button>
                                            </p>
                                            <p className='text-sm'>
                                                <span className="font-semibold">Campos obrigatórios:</span> "Data de Entrada", "Fornecedor", "Produto", "Quantidade", "Conversão UM", "Preço Unitário" e "ICMS"
                                            </p>
                                            <p className='text-sm flex flex-col mb-1'>
                                                <i>ICMS: Em porcentagem</i>
                                                <i>Obs: Valores monetários informar com vírgula, exemplo: 1000,90</i>
                                            </p>
                                            <Button onClick={() => downloadCSV()}>Baixar</Button>
                                        </div>
                                        <div className='w-full'>
                                            <FileDropZone setSendFile={setSendFile} />
                                        </div>
                                        <div className="flex gap-10">
                                            <Button onClick={() => setShowImports(false)} approval={false} width={20}>Voltar</Button>
                                            <Button onClick={() => { setDetectModal("1"); setShowUniversalModal(true) }}  >Importar</Button>
                                        </div>
                                    </>
                                }
                            </>
                            :
                            <>
                                <div className='w-full sm:mt-0 mt-2 items-center flex gap-4'>
                                    <Button onClick={() => { setDetectModal("2"); setShowUniversalModal(true) }}>
                                        <FaFilter className='text-white' />
                                        <p className='text-white'> Filtrar </p>
                                    </Button>
                                    {filter && JSON.stringify(filter) != JSON.stringify({})
                                        ? <Tippy
                                            key={`clearFilter`}
                                            content={<span>Limpar filtro</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='right'
                                            delay={100}>
                                            <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                                <FiSlash className='cursor-pointer' onClick={async () => {
                                                    setLoading(true)
                                                    setFilter({})
                                                    setFilterConstructor({})
                                                    await new Promise(resolve => setTimeout(resolve, 500))
                                                    setLoading(false)
                                                }} />
                                            </div>
                                        </Tippy>
                                        : <></>
                                    }
                                </div>
                                <TablePaginationDefault
                                    onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                                    quantity={(e) => setQuantity(e)}
                                    apiUrl={'/api/v1/lm/shopping'}
                                    filters={filter}
                                    mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                    colSpan={10}
                                    collumns={[
                                        {
                                            name: 'id',
                                            type: 'number',
                                            description: 'Código'
                                        },
                                        {
                                            name: 'dateEntry',
                                            type: 'string',
                                            description: 'Entrada',
                                            func: (item) => {
                                                return moment(item?.dateEntry)?.utc(false).format('DD/MM/YYYY')
                                            }
                                        },
                                        {
                                            name: 'supplier_name',
                                            type: 'string',
                                            description: 'Fornec.'
                                        },
                                        {
                                            name: 'product_description',
                                            type: 'string',
                                            description: 'Produto'
                                        },
                                        {
                                            name: 'unitPrice',
                                            type: 'number',
                                            description: 'Preço Unitário',
                                            func: (item) => {
                                                return Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency'}).format(item.unitPrice)
                                            }
                                        },
                                        {
                                            name: 'quantity',
                                            type: 'number',
                                            description: 'Quant.'
                                        },
                                        {
                                            name: 'convertUnitMeasure',
                                            type: 'number',
                                            description: 'Conv. U.M'
                                        },
                                        {
                                            name: 'total',
                                            type: 'number',
                                            description: 'Total',
                                            func: (item) => {
                                                return Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency'}).format(item.total)
                                            }
                                        },
                                        {
                                            name: 'cost',
                                            type: 'number',
                                            description: 'Custo',
                                            func: (item) => {
                                                return Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency'}).format(item.cost)
                                            }
                                        },
                                        {
                                            name: 'created_at',
                                            type: 'date',
                                            description: 'Lanç. em'
                                        }
                                    ]}
                                />
                            </>
                    }
                </Body>
            </Container >
        </>
    )
}