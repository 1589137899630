import React from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { Login } from '../pages/security/login'
import { SignUp } from '../pages/signUp/signUp'
import { useAuth } from '../contexts/useAuth'
import { PreLoader } from '../pages/preLoader'

export function LoginRoutes() {

    const { loadingAuth } = useAuth()

    return (
        <BrowserRouter>
            {
                loadingAuth ?
                <PreLoader/>
                :
                <Routes>
                    <Route path='/*' element={<Login />}/>
                    <Route path='/' element={<Login />} />
                </Routes>
            }
        </BrowserRouter>
    )
}