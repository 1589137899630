// hooks 
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// components 
import { FaArrowDown, FaEdit, FaFilter } from 'react-icons/fa'
import { FiSlash } from 'react-icons/fi'
import { TablePaginationDefault } from '../../../components/table/tablePagination.default'
import { TitlePage } from '../../../components/titlePages/title.page'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import { Tooltip } from '../../../components/toolTip/toolTip'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
//services
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// utils
import { justNumber } from '../../../utils/validators/justNumber'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'

export function PermissionGroupsTable() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { setShowUniversalModal } = useThemeContext()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const [loading, setLoading] = useState(false)
    const { screenY, screenX } = useScreenSizeContext()
    const screenSize = screenY - 200
    const [quantity, setQuantity] = useState(0)
    const [filterConstructor, setFilterConstructor] = useState({ })
    const [filter, setFilter] = useState({ })

    function handleEdit(data) {
        navigate(`/permissionGroupsEdit/${data?.id}`)
    }

    return (
        <>
            <ScreenModal title={'Filtros'}>
            {
                loading
                    ? <DefaultLoader />
                    : (
                        <div className='flex flex-col pt-0 pb-5 px-0 gap-2 items-center'>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                <Input
                                    charLimit={255} id='id' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                    value={filterConstructor?.id} autoFocus={true}
                                    width={80}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                <Input
                                    charLimit={255} id='name' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, name: e.target.value })}
                                    value={filterConstructor?.name} autoFocus={true}
                                    width={80}
                                ></Input>
                            </label>                            
                            <div className='mt-5'>
                                <Button onClick={() => {
                                    setFilter(filterConstructor)
                                    setShowUniversalModal(false)
                                }}>Filtrar</Button>
                            </div>
                        </div>
                    )
            }
            </ScreenModal>
            <Container>
                <TitlePage displayNotification={showNotification} success={successData} setDisplayNotification={e => setShowNotication(e)}>
                    <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Grupos de Permissão'}></Title>
                            <div className='flex flex-row gap-2 md:w-48 items-center'>
                                <h3 className='hidden sm:flex text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity == 1 ? quantity + ' Grupo' : quantity + ' Grupos'}</h3>                                
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/permissionGroupsCreate')}>
                            {
                                (userData[0]?.permissions?.indexOf('permissionGroups:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                                <Button shadow={true} onClick={() => navigate('/permissionGroupsCreate')}>+ Novo grupo</Button>
                            }
                        </div>
                    </div>
                </TitlePage>
                <Body>
                    <div className='w-full sm:mt-0 mt-2 items-center flex gap-4'>
                        <Button onClick={() => setShowUniversalModal(true)}>
                            <FaFilter className='text-white' /> 
                            <p className='text-white'> Filtrar </p>
                        </Button>
                        {filter && JSON.stringify(filter) != JSON.stringify({ })
                            ? <Tippy
                                key={`clearFilter`}
                                content={<span>Limpar filtro</span>}
                                arrow={true}
                                animation='perspective'
                                placement='right'
                                delay={100}>
                                <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                    <FiSlash className='cursor-pointer' onClick={async () => {
                                        setLoading(true)
                                        setFilter({ })
                                        setFilterConstructor({ })
                                        await new Promise(resolve => setTimeout(resolve, 500))
                                        setLoading(false)
                                    }} />
                                </div>
                            </Tippy>
                            : <></>
                        }
                    </div>
                    <TablePaginationDefault
                        onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                        quantity={(e) => setQuantity(e)}
                        apiUrl={'/api/v1/permissionGroups'}
                        filters={filter}
                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                        collumns={[
                            {
                                name: 'id',
                                type: 'number',
                                description: 'Código'
                            },
                            {
                                name: 'name',
                                type: 'string',
                                description: 'Nome'
                            },
                            {
                                name: 'description',
                                type: 'string',
                                description: 'Descrição',
                                func: (item) => {
                                    if (item?.description){
                                        let sliceString = item.description.length > 130 ? item.description.slice(0, 130) : item.description
                                        return sliceString + ' ...'
                                    } else {
                                        return ' - '
                                    }
                                }
                            },
                            {
                                name: 'created_at',
                                type: 'date',
                                description: 'Criado em'
                            }
                        ]}
                    />
                </Body>
            </Container>
        </>
    )


}