// hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/useAuth'
// contexts
import { useThemeContext } from '../../../contexts/themeContext'
// components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Input } from "../../../components/input/input";
import { LabelInput } from "../../../components/label/label.input";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
// utils
import { typeProducts } from "../../../utils/typeProduct/typeProduct";
import { unitMeasures } from "../../../utils/unitMeasure/unitMeasureList";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { justNumber } from '../../../utils/validators/justNumber'
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//services
import { api } from '../../../services/api/api'

export function ProductsCreate() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const typeProductsList = typeProducts()
    const unitMeasureList = unitMeasures()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [typeProduct, setTypeProduct] = useState('')
    const [unitMeasure, setUnitMeasure] = useState('')
    const [convertUnitMeasure, setConvertUnitMeasure] = useState('')
    const [externalId, setExternalId] = useState('')
    const [code, setCode] = useState('')
    const [description, setDescription] = useState('')
    const [lastCodesList, setLastCodeList] = useState([])
    const [productsGroupList, setProductsGroupList] = useState([])
    const [selectedProductGroup, setSelectedProductGroup] = useState('')
    const [selectedUseDatasheet, setSelectedUseDatasheet] = useState(true)

    useEffect(() => {
        async function getLastCode(){
            setLoading(true)

            try {
                const getLastCodesAPI = await api.get('/api/v1/lm/products/lastCodes', {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setLastCodeList(getLastCodesAPI.data.data)

                const getGroupsAPI = await api.get(`api/v1/lm/productsGroup`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                setProductsGroupList(getGroupsAPI?.data?.data)

                setLoading(false)

            } catch (error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getLastCode()
    }, [])

    async function handleSubmit() {

        setLoading(true)

        const requiredFields = [
            { name: 'typeProduct', value: typeProduct, required: true, type: 'object' },
            { name: 'code', value: code, required: true, type: 'string' },
            { name: 'description', value: description, required: true, type: 'string' },
            { name: 'unitMeasure', value: unitMeasure, required: true, type: 'object' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {

            await api.post('api/v1/lm/products', {
                typeProduct: typeProduct?.typeProduct,
                code: code,
                description: description,
                unitMeasure: unitMeasure?.id,
                externalId: externalId ? externalId : undefined,
                productsGroup_id: selectedProductGroup ? selectedProductGroup?.id : undefined,
                useDatasheet: typeProduct?.typeProduct == 'PA' ? [true, false]?.includes(selectedUseDatasheet) ? selectedUseDatasheet : false : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/products')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    async function verifyCode(item){
        const filterLast = lastCodesList?.filter(last => item?.typeProduct == last?.typeProduct)[0]
        if (filterLast) {
            setCode(Number(filterLast?.code) + 1)
        } else {
            const stringNumber = `${item?.codeProduct}00000`
            setCode(Number(stringNumber))
        }
    }

    const options = [
        {
            value: true,
            description: 'Sim'
        },
        {
            value: false,
            description: 'Não'
        }
    ]

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Cadastro de Produtos'} />
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                    <DefaultLoader />
                    :
                    <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                        <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                            <LabelInput text={'Tipo do produto'} required={true}>
                                <InputAutoComplete
                                    width={80}
                                    data={typeProductsList}
                                    optionList={["name"]}
                                    selectedLabel={'name'}
                                    value={typeProduct ? typeProductsList.filter(op => op.typeProduct == typeProduct.typeProduct)[0].name : ''}
                                    id='typeProduct'
                                    onChange={(e) => {
                                        setCode('')
                                        setTypeProduct(e)
                                        typeof(e) == 'object' && verifyCode(e)                                        
                                    }}
                                />
                            </LabelInput>
                            {
                                !typeProduct?.typeProduct ?
                                <LabelInput text={'Código'} required={true}>
                                    <Input
                                        type={'text'}
                                        width={80}
                                        id='code'
                                        onChange={(e) => setCode(justNumber(e.target.value))}
                                        charLimit={6}
                                        disabled={true}
                                    />
                                </LabelInput> :
                                <LabelInput text={'Código'} required={true}>
                                    <Input
                                        type={'text'}
                                        width={80}
                                        value={!code ? typeProduct ? typeProductsList.filter(op => op.typeProduct == typeProduct)[0].codeProduct : '' : code}
                                        id='code'
                                        onChange={(e) => setCode(justNumber(e.target.value))}
                                        charLimit={6}
                                        disabled={true}
                                    />
                                </LabelInput>
                            }
                            <LabelInput text={'Descrição'} required={true}>
                                <Input
                                    type={'text'}
                                    width={80}
                                    value={description}
                                    id='description'
                                    onChange={(e) => setDescription(e.target.value)}
                                    charLimit={255}
                                />
                            </LabelInput>
                            <LabelInput 
                                text={'Unidade de Medida'} 
                                required={true}
                                showIconInfo={true}
                                messageIconInfo={'Definir como é mensurável o produto.'}
                            >
                                <InputAutoComplete
                                    width={80}
                                    data={unitMeasureList}
                                    optionList={["unitMeasure"]}
                                    selectedLabel={'unitMeasure'}
                                    value={unitMeasure ? unitMeasureList.filter(op => op.id == unitMeasure?.id)[0].unitMeasure : ''}
                                    id='unitMeasure'
                                    onChange={(e) => setUnitMeasure(e)}
                                />
                            </LabelInput>
                            <LabelInput text={'Código antigo'} >
                                <Input
                                    type={'text'}
                                    width={80}
                                    value={externalId}
                                    id='externalId'
                                    onChange={(e) => setExternalId(e.target.value)}
                                    charLimit={255}
                                />
                            </LabelInput>
                            <LabelInput 
                                text={'Grupo do produto'}
                                showIconInfo={true}
                                messageIconInfo={'Definir qual o grupo que o produto se encaixa.'}
                            >
                                <InputAutoComplete
                                    data={productsGroupList}
                                    onChange={(e) => setSelectedProductGroup(e)}
                                    value={selectedProductGroup ? selectedProductGroup?.description : ''}
                                    optionList={['id', 'description']}
                                    selectedLabel={'description'}
                                    width={80}
                                />
                            </LabelInput>
                            {
                                typeProduct?.typeProduct == 'PA' ?
                                <LabelInput text={'Utiliza ficha técnica'}>
                                    <InputAutoComplete
                                        width={80}
                                        data={options}
                                        optionList={["description"]}
                                        selectedLabel={'description'}
                                        value={options?.find(op => op?.value == selectedUseDatasheet)?.description}
                                        id='useDatasheet'
                                        onChange={(e) => setSelectedUseDatasheet(e?.value)}
                                    />
                                </LabelInput>
                                : <></>
                            }
                        </form>
                        <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                            <Button
                                onClick={() => navigate('/products')}
                                shadow={true}
                                approval={false}
                            >Cancelar
                            </Button>
                            <Button
                                shadow={true}
                                onClick={() => handleSubmit()}
                            >Cadastrar
                            </Button>
                        </div>
                    </div>
                }
            </Body>
        </Container>
    )
}