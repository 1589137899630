// hooks 
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// components 
import { FaAddressCard, FaArrowLeft, FaArrowRight } from "react-icons/fa";
// utils
import { configItems as configItemsFile} from "./configuration.items";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import { NotificationModal } from "../../../components/modals/notification/notification.modal";
import { useThemeContext } from "../../../contexts/themeContext";
import { Container } from "../../../components/container/container";


export function Configuration() {

    const { userData, selectedModule } = useAuth()
    const configItems = selectedModule == 'lm' ? configItemsFile : configItemsFile?.filter(item => !["Parâmetros Panorama", "Parâmetros", "Tabela ICMS", "Produtos"].includes(item?.name))
    const navegate = useNavigate()
    const { screenX, screenY } = useScreenSizeContext()

    return (
        <Container bg={'bg-bgTercearyLight'}>
            <div id='configuration-menu' className="overflow-y-auto flex flex-col w-full p-4" style={{ height: screenX < 640 ? screenY - 126 : 'auto' }}>
                <NotificationModal />
                <div id='configuration-menu-items' className="mt-10 flex flex-wrap gap-8 items-start justify-start">
                    {
                        configItems.map((configItem, index) => {                            
                            if (
                                (configItem?.permission?.indexOf(userData[0]?.typeAccess_id) > -1) 
                                ||
                                userData[0]?.permissions?.filter((userPermission) => {
                                    if (configItem.permission.indexOf(userPermission) > -1) {
                                        return userPermission
                                    }
                                }).length > 0 
                                || configItem.permission.includes(0)
                            ) {
                                return <div id={`${configItem.name.replace(' ', '-').toLocaleLowerCase()}-config`} key={index} className="flex flex-col w-[190px] justify-between items-start">
                                    <div key={`${index}-${configItem.name}`} className="flex flex-row gap-3 items-start justify-start">
                                        <div className="flex flex-col text-primaryDefaultLight dark:text-primaryDefaultDarkColor text-xl w-5 h-5"> {configItem.icon}</div>
                                        <Tippy content={configItem.legend}
                                            animation="shift-away"
                                            arrow={true}
                                            placement="right"
                                            delay={500}
                                        >
                                            <h2 className="text-sm 2xl:text-base text-primaryDefaultLight font-normal">{configItem.name}</h2>
                                        </Tippy>
                                    </div>
                                    {
                                        configItem.subItems.map((subItem, subIndex) => {
                                            if ((subItem.permission.indexOf(userData[0]?.typeAccess_id) > -1)
                                                ||
                                                userData[0]?.permissions?.filter((userPermission) => {
                                                    if (subItem.permission.indexOf(userPermission) > -1) {
                                                        return userPermission
                                                    }
                                                }).length > 0
                                                ||
                                                configItem.permission.includes(0)
                                            ) {
                                                return <div className="flex flex-row items-start justify-start gap-1">
                                                    <div className="flex flex-col w-7"></div>
                                                    <h3 key={`${index}-${subIndex}`} onClick={() => navegate(subItem.path)} className="cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark font-light hover:brightness-50 dark:hover:brightness-200 transition-all duration-75 text-xs 2xl:text-sm">{subItem.name}</h3>
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            }
                        })
                    }
                </div>
            </div>
        </Container>
    )
}

