//hooks
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { FaAngleDown, FaAngleUp, FaCheck, FaEdit, FaFilePdf, FaMinus, FaPlus, FaTrash } from "react-icons/fa";
//contexts
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
//services
import { api } from "../../../services/api/api";
//utils
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { justNumber } from "../../../utils/validators/justNumber";
import { validateFields } from "../../../utils/form.validator";
//components
import { Button } from "../../../components/buttons/button.default";
import { Container } from "../../../components/container/container";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Body } from "../../../components/container/Body";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Input } from "../../../components/input/input";
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";
import { FaXmark } from "react-icons/fa6";
import { useScreenSizeContext } from "../../../contexts/screenSizeContext";

export function SalesEdit() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { id } = useParams()
    const { screenX } = useScreenSizeContext()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    const [detectedModal, setDetectedModal] = useState('')
    const [salesData, setSalesData] = useState('')
    const [oldQuantity, setOldQuantity] = useState('')
    const [newQuantity, setNewQuantity] = useState('')
    const [newUnitPriceComposition, setNewUnitPriceComposition] = useState('')
    const [newQuantityComposition, setNewQuantityComposition] = useState('')
    const [newProductComposition, setNewProductComposition] = useState('')
    const [newDiscount, setNewDiscount] = useState('')
    const [newTypeDiscount, setNewTypeDiscount] = useState('')
    const [newPercentDiscount, setNewPercentDiscount] = useState('')
    const [salesCompositionList, setSalesCompositionList] = useState([])
    const [supplierAndCustomerData, setSupplierAndCustomerData] = useState([])
    const [productsData, setProductsData] = useState([])
    const [sellerData, setSellerData] = useState([])
    const [paymentConditionData, setPaymentConditionData] = useState([])
    const [viewSalesCompositions, setViewSalesCompositions] = useState(false)
    const [newSaleComposition, setNewSaleComposition] = useState(false)
    const [idQuantityDelete, setIdQuantityDelete] = useState()

    useEffect(() => {
        
        async function getData(){
            setLoading(true)
            try {
                const supplierAndCustomer = await api.get(`/api/v1/general/supplierAndCustomer?isCustomer=true`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setSupplierAndCustomerData(supplierAndCustomer?.data?.data)

                const productsDataApi = await api.get('/api/v1/lm/products', {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setProductsData(productsDataApi?.data?.data?.map(prod => {
                    return {
                        ...prod,
                        descriptionInput: `${prod?.code} - ${prod?.description} - ${prod?.typeProduct}`
                    }
                }))

                const paymentCondition = await api.get(`/api/v1/lm/paymentCondition`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setPaymentConditionData(paymentCondition?.data?.data.filter(item => item.textDays = 'Dias'))

                const seller = await api.get(`/api/v1/lm/seller?active=true`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setSellerData(seller?.data?.data)
                setLoading(false)

            } catch (error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getData()
        getSalesApi()
        
    }, [])

    async function getSalesApi() {
        try {
            const salesApi = await api.get(`/api/v1/lm/sales/${id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setSalesCompositionList(salesApi?.data?.data?.composition)
            setOldQuantity(salesApi?.data?.data?.composition.map((quant) => quant.quantity))
            setSalesData(salesApi?.data?.data)
        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            }
        }
    }

    async function handleDeleteSale() {
        setLoading(true)
        try {
            await api.delete(`/api/v1/lm/sales/${id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowUniversalModal(false)
            setShowNotificationModalText('Venda excluída com sucesso!')
            setLoading(false)
            navigate('/sales')
            return
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDeleteComposition() {
        setLoading(true)

        if (salesCompositionList.length > 1) {
            try {
                await api.delete(`/api/v1/lm/salesComposition/${idQuantityDelete}`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })

                setShowModificationModal(true)
                setShowNotificationModalSuccess(true)
                setShowUniversalModal(false)
                setShowNotificationModalText('Composição excluída com sucesso!')
                // setSalesCompositionList(salesCompositionList.filter(comp => comp?.id != idQuantityDelete))
                getSalesApi()
                setLoading(false)
                return

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        } else {
            setLoading(false)
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowUniversalModal(false)
            return setShowNotificationModalText('É obrigatório existir no mínimo uma composição!')
        }
    }

    async function handleQuantity(item, index) {
        if (newQuantity > 0 || item.quantity > 0) {
            try {
                await api.put(`/api/v1/lm/salesComposition/${item.id}`, {
                    quantity: newQuantity || item.quantity
                }, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })

                item.edit = false
                setShowModificationModal(true)
                setShowNotificationModalSuccess(true)
                setShowNotificationModalText('Quantidade editada com sucesso!')
                getSalesApi()

            } catch (error) {
                if (responseError(error).length > 0) {
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        } else {
            item.edit = false
            item.quantity = oldQuantity[index]
            calculatorTotalSale()
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('A quantidade não pode ser igual a 0.')
        }
    }

    async function handleSubmit() {
        setLoading(true)

        const requiredFields = [
            { name: 'saleDate', value: salesData?.saleDate, required: true, type: 'string' },
        ]

        if (salesData?.supplierAndCustomer_id == undefined) {
            requiredFields.push({ name: 'supplierAndCustomerId', value: salesData?.supplierAndCustomer_id, required: true, type: 'object' })
        } else {
            requiredFields.push({ name: 'supplierAndCustomerId', value: salesData?.supplierAndCustomer_id, required: true, type: 'number' })
        }

        if (salesData?.paymentCondition_id == undefined) {
            requiredFields.push({ name: 'paymentConditionId', value: salesData?.paymentCondition_id, required: true, type: 'object' })
        } else {
            requiredFields.push({ name: 'paymentConditionId', value: salesData?.paymentCondition_id, required: true, type: 'number' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.put(`/api/v1/lm/sales/${id}`, {
                saleDate: salesData?.saleDate,
                saleOrder: salesData?.saleOrder ? salesData?.saleOrder : undefined,
                nf: salesData?.nf ? salesData?.nf : undefined,
                supplierAndCustomer_id: salesData?.supplierAndCustomer_id,
                paymentCondition_id: salesData?.paymentCondition_id,
                seller_id: salesData?.seller_id ? salesData?.seller_id : undefined,
                discount: salesData?.discount ? salesData?.discount : undefined,
                freight: salesData?.freight ? salesData?.freight : undefined,
                commission: salesData?.commission ? salesData?.commission : undefined,
                useTaxationDefault: salesData?.useTaxationDefault,
                simplePercentage: salesData?.simplePercentage ? salesData?.simplePercentage : 0,
                pis: salesData?.pis ? salesData?.pis : 0,
                cofins: salesData?.cofins ? salesData?.cofins : 0,
                ircs: salesData?.ircs ? salesData?.ircs : 0,
                iva: salesData?.iva ? salesData?.iva : 0,
                icms: salesData?.icms ? salesData?.icms : 0,
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Venda editada com sucesso!')
            setLoading(false)
            navigate('/sales')
            return

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmitComposition() {
        setLoading(true)

        const requiredFields = [
            { name: 'products_id_Child', value: newProductComposition, required: true, type: 'object' },
            { name: 'quantity_Child', value: newQuantityComposition, required: true, type: 'string' },
            { name: 'unitPrice_Child', value: newUnitPriceComposition, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {

            await api.post(`/api/v1/lm/salesComposition`, {
                sales_id: salesData.id,
                products_id: newProductComposition?.id,
                unitPrice: newUnitPriceComposition,
                quantity: newQuantityComposition,
                discount: newDiscount ? newDiscount : newPercentDiscount ? (newPercentDiscount * (newUnitPriceComposition * newQuantityComposition)) / 100 : 0
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalSuccess(true)
            getSalesApi()
            clearStates()
            setNewSaleComposition(false)
            setShowNotificationModalText('Composição criada com sucesso!')
            setLoading(false)
            return

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function clearStates(){
        setNewProductComposition('')
        setNewQuantityComposition('')
        setNewUnitPriceComposition('')
        setNewDiscount('')
        setNewTypeDiscount('')
        setNewPercentDiscount('')
    }

    async function calculatorTotalSale(){
        const calculate = salesCompositionList?.map(item => {
            const quantity = item?.quantity ? item?.quantity : 0
            const price = item?.unitPrice ? item?.unitPrice : 0
            return (Number(price) * Number(quantity)) - Number(item?.discount)
        })

        const totalValue = calculate.reduce((accumulator, currentValue) => accumulator + currentValue)
        setSalesData(prev => ({...prev, totalSale: totalValue?.toFixed(5)}))
    }

    const typeDescont = [
        {
            id: 1,
            name: 'Monetário'
        },
        {
            id: 2,
            name: 'Porcentagem'
        }
    ]

    async function handlePrint(){
        setLoading(true)
        try {            
            const file = await api.get(`/api/v1/lm/sales/print/${id}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            let fileObj = URL.createObjectURL(file?.data)
            setLoading(false)
            return window.open(fileObj)

        } catch (error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const options = [
        {
            description: 'Sim',
            value: true
        },
        {
            description: 'Não',
            value: false
        },
    ]

    return (
        <>
            {
                detectedModal == "1" &&
                <ScreenModal title={'Exclusão de Venda'} width={400} height={200} >
                    <div className='w-full h-96 flex flex-col items-center justify-center'>
                        <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir?</p>
                        <div className='flex flex-row gap-1 mt-10'>
                            <Button shadow={true} onClick={() => handleDeleteSale()}>Sim</Button>
                            <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            {
                detectedModal == "2" &&
                <ScreenModal title={'Exclusão de Composição'} width={400} height={200} >
                    <div className='w-full h-96 flex flex-col items-center justify-center'>
                        <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir?</p>
                        <div className='flex flex-row gap-4 mt-10'>
                            <Button shadow={true} onClick={() => handleDeleteComposition()}>Sim</Button>
                            <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            <Container>
                <TitlePage>
                    <div className='flex justify-center md:justify-between w-full'>
                        <Title text={`Edição de Venda`} />
                        {
                            (userData[0]?.permissions?.indexOf('lm_sales:read') > -1 || userData[0]?.typeAccess_id == 1) &&
                            <Button width={24} height={7} onClick={() => handlePrint()}>
                                <FaFilePdf/> Gerar PDF
                            </Button>
                        }
                    </div>
                </TitlePage>
                <Body>
                {
                    loading ?
                        <DefaultLoader />
                    :
                    <div className="w-full">
                        <div className="flex flex-col items-center lg:items-start justify-start w-full">
                            <form className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2'>
                                <LabelInput text={'Data de venda'} required={true}>
                                    <Input
                                        type={'date'}
                                        width={80}
                                        value={salesData.saleDate}
                                        id='saleDate'
                                        onChange={(e) => setSalesData(prev => ({ ...prev, saleDate: e.target.value }))}
                                    />
                                </LabelInput>
                                <LabelInput text={'NF'}>
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={salesData.nf ? salesData.nf : ''}
                                        id='nf'
                                        onChange={(e) => setSalesData(prev => ({ ...prev, nf: e.target.value }))}
                                    />
                                </LabelInput>
                                <LabelInput text={'Número do pedido'} required={false}>
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={salesData.saleOrder ? salesData.saleOrder : ''}
                                        id='saleOrder'
                                        onChange={(e) => setSalesData(prev => ({ ...prev, saleOrder: e.target.value }))}
                                    />
                                </LabelInput>
                                <LabelInput text={'Cliente'} required={true}>
                                    <InputAutoComplete
                                        width={80}
                                        data={supplierAndCustomerData}
                                        optionList={["id", "businessName"]}
                                        selectedLabel={["businessName"]}
                                        value={salesData?.client_description ? supplierAndCustomerData.filter(item => item.id == salesData?.supplierAndCustomer_id)[0]?.businessName : ''}
                                        preSelectedValue={salesData?.client_description ? supplierAndCustomerData.filter(item => item.id == salesData?.supplierAndCustomer_id)[0]?.businessName : ''}
                                        id='supplierAndCustomerId'
                                        onChange={(e) => setSalesData(prev => ({ ...prev, supplierAndCustomer_id: e.id }))}
                                    />
                                </LabelInput>
                                <LabelInput text={'Condição de pagamento'} required={true}>
                                    <InputAutoComplete
                                        width={80}
                                        data={paymentConditionData}
                                        optionList={["days", "textDays"]}
                                        selectedLabel={["days"]}
                                        value={salesData?.days ? paymentConditionData.filter(item => item.id == salesData?.paymentCondition_id)[0]?.days == undefined ? '' : paymentConditionData.filter(item => item.id == salesData?.paymentCondition_id)[0]?.days + ' Dias' : ''}
                                        preSelectedValue={salesData?.days ? paymentConditionData.filter(item => item.id == salesData?.paymentCondition_id)[0]?.days == undefined ? '' : paymentConditionData.filter(item => item.id == salesData?.paymentCondition_id)[0]?.days + ' Dias' : ''}
                                        id='paymentConditionId'
                                        onChange={(e) => setSalesData(prev => ({ ...prev, paymentCondition_id: e.id }))}
                                    />
                                </LabelInput>
                                <LabelInput text={'Vendedor'} required={false}>
                                    <InputAutoComplete
                                        width={80}
                                        data={sellerData}
                                        optionList={["id", "name"]}
                                        selectedLabel={["name"]}
                                        value={salesData?.seller_id ? sellerData?.filter(item => item.id == salesData?.seller_id)[0]?.name : ''}
                                        preSelectedValue={salesData?.seller_id ? sellerData?.filter(item => item.id == salesData?.seller_id)[0]?.name : ''}
                                        id='seller_id'
                                        onChange={(e) => setSalesData(prev => ({ ...prev, seller_id: e.id }))}
                                    />
                                </LabelInput>
                                <LabelInput text={'Frete R$'} required={false}>
                                    <CurrencyInput id='freight' placeHolder={salesData?.freight ? "R$ " + salesData?.freight.toString()?.replace('.', ',') : 'R$ 0,00'}
                                        onChange={(e) => {
                                            setSalesData(prev => ({ ...prev, freight: parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')) }))
                                        }}></CurrencyInput>
                                </LabelInput>
                                <LabelInput text={'Comissão (%)'}>
                                    <Input 
                                        type={'number'} 
                                        width={80} 
                                        value={salesData?.commission ? salesData?.commission : ''} 
                                        id='commission' 
                                        onChange={(e) => setSalesData(prev => ({...prev, commission: e?.target?.value}))}
                                        charLimit={9} 
                                    />
                                </LabelInput>
                                <LabelInput 
                                    text={'Impostos padrão'} 
                                    required={true}
                                    showIconInfo={true}
                                    messageIconInfo={'Definir se a venda vai ter valores de impostos individuais ou com base na definição padrão.'}
                                >
                                    <InputAutoComplete
                                        width={80}
                                        data={options}
                                        optionList={["description"]}
                                        selectedLabel={["description"]}
                                        value={options.filter(type => type.value == salesData?.useTaxationDefault)[0]?.description}
                                        id='useTaxationDefault'
                                        onChange={(e) => {
                                            setSalesData(prev => ({
                                                ...prev, 
                                                useTaxationDefault: e?.value,
                                                simplePercentage: 0,
                                                pis: 0,
                                                cofins: 0,
                                                ircs: 0,
                                                iva: 0,
                                                icms: 0
                                            }))
                                        }}
                                    />
                                </LabelInput>
                                <LabelInput text={'Simples Nacional %'} required={true}>
                                    <Input
                                        type={'number'}
                                        width={80}
                                        value={salesData?.simplePercentage ? salesData?.simplePercentage : ''}
                                        id='simplePercentage'
                                        onChange={(e) => setSalesData(prev => ({...prev, simplePercentage: e?.target?.value}))}
                                        disabled={salesData?.useTaxationDefault ? true : false}
                                    />
                                </LabelInput>
                                <LabelInput text={'PIS %'} required={true}>
                                    <Input
                                        type={'number'}
                                        width={80}
                                        value={salesData?.pis ? salesData?.pis : ''}
                                        id='pis'
                                        onChange={(e) => setSalesData(prev => ({...prev, pis: e?.target?.value}))}
                                        disabled={salesData?.useTaxationDefault ? true : false}
                                    />
                                </LabelInput>
                                <LabelInput text={'COFINS %'} required={true}>
                                    <Input
                                        type={'number'}
                                        width={80}
                                        value={salesData?.cofins ? salesData?.cofins : ''}
                                        id='cofins'
                                        onChange={(e) => setSalesData(prev => ({...prev, cofins: e?.target?.value}))}
                                        disabled={salesData?.useTaxationDefault ? true : false}
                                    />
                                </LabelInput>
                                <LabelInput text={'IR/CS %'} required={true}>
                                    <Input
                                        type={'number'}
                                        width={80}
                                        value={salesData?.ircs ? salesData?.ircs : ''}
                                        id='ircs'
                                        onChange={(e) => setSalesData(prev => ({...prev, ircs: e?.target?.value}))}
                                        disabled={salesData?.useTaxationDefault ? true : false}
                                    />
                                </LabelInput>
                                <LabelInput text={'IVA %'} required={true}>
                                    <Input
                                        type={'number'}
                                        width={80}
                                        value={salesData?.iva ? salesData?.iva : ''}
                                        id='iva'
                                        onChange={(e) => setSalesData(prev => ({...prev, iva: e?.target?.value}))}
                                        disabled={salesData?.useTaxationDefault ? true : false}
                                    />
                                </LabelInput>
                                <LabelInput text={'ICMS %'} required={true}>
                                    <Input
                                        type={'number'}
                                        width={80}
                                        value={salesData?.icms ? salesData?.icms : ''}
                                        id='icms'
                                        onChange={(e) => setSalesData(prev => ({...prev, icms: e?.target?.value}))}
                                        disabled={salesData?.useTaxationDefault ? true : false}
                                    />
                                </LabelInput>
                                {
                                    salesData?.totalSale ?
                                    <div className="absolute right-3 top-1 flex flex-row text-md text-md font-light gap-3">
                                        <p className="ml-1 text-sm">Total:</p>
                                        <p className="text-sm">{Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency'}).format(salesData.totalSale)}</p>
                                    </div>
                                    :
                                    <div className="absolute right-3 top-1 flex flex-row text-md text-md font-light gap-3">
                                        <p className="ml-1 text-sm">Total:</p>
                                        <p className="text-sm">{Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency'}).format(0)}</p>
                                    </div>
                                }
                            </form>
                            <div className="my-[30px] p-[5px] rounded-md border border-gray-400 bg-gray-50 pb-3 w-full overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400">
                                <div className="my-2 ml-2">
                                    Composições
                                </div>
                                {
                                    salesCompositionList.map((item, index) => {
                                        const hiddenLabel = index == 0 ? true : false
                                        return (
                                            <div className="flex flex-col lg:flex lg:flex-row gap-2 ml-10 mt-2 items-center">
                                                <label className='flex flex-col gap-1 items-start justify-center'>
                                                {hiddenLabel && <a className='text-sm text-inputPlaceholderLight'>Produto *</a>}
                                                    <InputAutoComplete
                                                        data={productsData}
                                                        selectedLabel={'descriptionInput'}
                                                        optionList={['code', 'description']}
                                                        onChange={e => { }}
                                                        value={productsData.filter(product => product.id == item.products_id)[0]?.descriptionInput}
                                                        preSelectedValue={productsData.filter(product => product.id == item.products_id)[0]?.descriptionInput}
                                                        id={`${index} - products_id`}
                                                        widthForce={screenX > 1536 ? 'w-[45rem]' : screenX > 1024 && screenX < 1280 ? 'w-[20rem]' : screenX < 1024 ? false : 'w-[33rem]'}
                                                        disabled={true}
                                                    />
                                                </label>
                                                <LabelInput>
                                                    {hiddenLabel && <a className='text-sm text-inputPlaceholderLight'>Quantidade *</a>}
                                                    <Input
                                                        type={'number'}
                                                        value={item.quantity ? item.quantity : ''}
                                                        onChange={(e) => {
                                                            setSalesCompositionList(salesCompositionList.map(comp => {
                                                                if (comp.id == item.id) {
                                                                    comp.quantity = e.target.value
                                                                    setNewQuantity(comp.quantity)
                                                                    calculatorTotalSale()
                                                                    return comp
                                                                }
                                                                return comp
                                                            }))
                                                        }}
                                                        id={`${index} - quantity`}
                                                        width={'110px'}
                                                        disabled={item.edit ? false : true}
                                                    />
                                                </LabelInput>
                                                <LabelInput>
                                                    {hiddenLabel && <a className='text-sm text-inputPlaceholderLight'>Preço Unitário *</a>}
                                                    <Input id={`${index} - unitPrice`} value={item.unitPrice ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(item.unitPrice) : 'R$ 0,00'} disabled={true}
                                                        onChange={(e) => { }} width={'140px'}/>
                                                </LabelInput>
                                                <LabelInput>
                                                    {hiddenLabel && <a className='text-sm text-inputPlaceholderLight'>Desconto R$</a>}
                                                    <Input 
                                                        id={`${index} - discount`} 
                                                        value={item.discount ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(item.discount) : 'R$ 0,00'}
                                                        onChange={(e) => {}} 
                                                        width={'110px'} 
                                                        disabled={true}>
                                                    </Input>
                                                </LabelInput>
                                                <div className={`flex flex-row ${index == 0 ? 'mt-6' : 'mt-1'} items-center gap-1`}>
                                                    {
                                                        ((item?.unitPrice * item?.quantity) - item?.discount) > 0 &&
                                                        <div className="flex flex-col justify-end text-xs">
                                                            <p>Total produto</p>
                                                            <p>{Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency'}).format(((item?.unitPrice * item?.quantity) - item?.discount))}</p>
                                                        </div>
                                                    }
                                                    {
                                                        item.edit ? 
                                                        <div className='h-[25px] w-[55px] mt-2 pr-5 flex flex-row gap-3 justify-center items-center lg:ml-3 hover:cursor-pointer text-sm text-blue-600'>
                                                            <Tippy
                                                                content={<span>Confirmar</span>}
                                                                arrow={true}
                                                                animation='perspective'
                                                                placement='top'
                                                                delay={100}>
                                                                <div onClick={() => handleQuantity(item, index)}><FaCheck className="text-sm"/></div>
                                                            </Tippy>
                                                            <Tippy
                                                                content={<span>Cancelar</span>}
                                                                arrow={true}
                                                                animation='perspective'
                                                                placement='top'
                                                                delay={100}>
                                                                <div onClick={() => {
                                                                    setSalesCompositionList(salesCompositionList.map(comp => {
                                                                        item.quantity = oldQuantity[index]
                                                                        comp.edit = false
                                                                        calculatorTotalSale()
                                                                        return comp
                                                                    }))
                                                                }}>
                                                                    <FaPlus className="text-red-900 rotate-45 text-sm" />
                                                                </div>
                                                            </Tippy>
                                                        </div>
                                                        :
                                                        <div className='h-[25px] w-[55px] mt-2 pr-5 flex flex-row gap-3 justify-center items-center lg:ml-3 hover:cursor-pointer text-md text-blue-600'>
                                                            {
                                                                (userData[0]?.permissions?.indexOf('lm_compositionSale:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                                                <Tippy
                                                                    content={<span>Editar</span>}
                                                                    arrow={true}
                                                                    animation='perspective'
                                                                    placement='top'
                                                                    delay={100}>
                                                                    <div onClick={() => {
                                                                        setSalesCompositionList(salesCompositionList.map(comp => {
                                                                            if (comp.id == item.id) {
                                                                                comp.edit = true
                                                                                calculatorTotalSale()
                                                                                return comp
                                                                            } else {
                                                                                comp.edit = false
                                                                                return comp
                                                                            }
                                                                        }))
                                                                    }}>
                                                                        <FaEdit className="text-sm"/>
                                                                    </div>
                                                                </Tippy>
                                                            }
                                                            {
                                                                (userData[0]?.permissions?.indexOf('lm_compositionSale:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                                                <Tippy
                                                                    content={<span>Excluir</span>}
                                                                    arrow={true}
                                                                    animation='perspective'
                                                                    placement='top'
                                                                    delay={100}>
                                                                    <div onClick={() => { setShowUniversalModal(true); setDetectedModal("2"); setIdQuantityDelete(item.id) }}>
                                                                        <FaTrash className="text-red-900 text-sm" />
                                                                    </div>
                                                                </Tippy>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                {
                                    !newSaleComposition ?
                                    <div className="flex justify-start items-start w-full">
                                        {
                                            (userData[0]?.permissions?.indexOf('lm_compositionSale:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <p className="flex items-center gap-2 text-blue-600 text-md hover:cursor-pointer" onClick={() => setNewSaleComposition(true)}><FaPlus className="text-xs text-blue-600" /> Criar composição</p>
                                        }
                                    </div>
                                    :
                                    <div className="w-full mt-5 flex flex-row">
                                        <form className='flex flex-wrap w-full gap-2 mb-5'>
                                            <label className='flex flex-col gap-1'>
                                            <a className='text-sm text-inputPlaceholderLight'>Produto *</a>
                                                <InputAutoComplete
                                                    data={productsData?.filter(item => item?.active)}
                                                    selectedLabel={'description'}
                                                    optionList={['code', 'description']}
                                                    onChange={e => setNewProductComposition(e)}
                                                    value={productsData.filter(item => item.id == newProductComposition?.id)[0]?.description}
                                                    preSelectedValue={productsData.filter(item => item.id == newProductComposition?.id)[0]?.description}
                                                    id={`products_id_Child`}
                                                    widthForce={screenX > 1536 ? 'w-[45rem]' : screenX > 1024 && screenX < 1280 ? 'w-[20rem]' : screenX < 1024 ? false : 'w-[33rem]'}
                                                />
                                            </label>
                                            <LabelInput text={'Quantidade'} required={true}>
                                                <Input
                                                    type={'number'}
                                                    value={newQuantityComposition ? newQuantityComposition : ''}
                                                    onChange={e => {
                                                        setNewQuantityComposition(e.target.value)
                                                    }}
                                                    id={`quantity_Child`}
                                                    width={20}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Preço Unitário'} required={true}>
                                                <CurrencyInput id={`unitPrice_Child`} placeHolder={newUnitPriceComposition ? "R$ " + newUnitPriceComposition.toString()?.replace('.', ',') : 'R$ 0,00'}
                                                    width={32}
                                                    onChange={(e) => {
                                                        setNewUnitPriceComposition(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                    }}></CurrencyInput>
                                            </LabelInput>
                                            <LabelInput text={'Tipo de desconto'}>
                                                <InputAutoComplete
                                                    width={36}
                                                    data={typeDescont}
                                                    optionList={["name"]}
                                                    selectedLabel={["name"]}
                                                    value={typeDescont.filter(type => type.id == newTypeDiscount)[0]?.name}
                                                    id=''
                                                    onChange={(e) => {
                                                        setNewDiscount('')
                                                        setNewPercentDiscount('')
                                                        setNewTypeDiscount(e?.id)
                                                    }}
                                                />
                                            </LabelInput>
                                            {
                                                newTypeDiscount == 1 ?
                                                    <LabelInput text={'Desconto R$'} required={false}>
                                                        <CurrencyInput id='discount_child' placeHolder={newDiscount ? "R$ " + newDiscount.toString()?.replace('.', ',') : 'R$ 0,00'}
                                                            width={32}
                                                            onChange={(e) => {
                                                                setNewDiscount(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                            }}>
                                                        </CurrencyInput>
                                                    </LabelInput>
                                                    :
                                                    newTypeDiscount == 2 ?
                                                    <LabelInput text={'Desconto %'}>
                                                        <Input
                                                            type={'number'}
                                                            width={20}
                                                            value={newPercentDiscount ? newPercentDiscount : ''}
                                                            id='discount'
                                                            onChange={(e) => {
                                                                setNewPercentDiscount(e.target.value)
                                                            }}
                                                        />
                                                    </LabelInput>
                                                    :
                                                    <LabelInput text={'Desconto'}>
                                                        <Input
                                                            type={'number'}
                                                            width={'150px'}
                                                            value={[]}
                                                            id=''
                                                            onChange={{}}
                                                            disabled={true}
                                                        />
                                                    </LabelInput>

                                            }
                                            <div className={`flex flex-row gap-2 justify-center pb-2 ml-1 mt-8 h-5`}>
                                                <Tippy
                                                    content={<span>Adicionar</span>}
                                                    arrow={true}
                                                    animation='perspective'
                                                    placement='top'
                                                    delay={100}>
                                                    <div><span onClick={() => handleSubmitComposition()} className="text-blue-800 hover:cursor-pointer"><FaCheck className="text-sm"/></span></div>
                                                </Tippy>
                                                <Tippy
                                                    content={<span>Cancelar</span>}
                                                    arrow={true}
                                                    animation='perspective'
                                                    placement='top'
                                                    delay={100}>
                                                    <div>
                                                        <span 
                                                            onClick={() => {clearStates(); setNewSaleComposition(false)}}
                                                            className="text-lg text-red-800 hover:cursor-pointer"><FaXmark className="text-sm"/>
                                                        </span>
                                                    </div>
                                                </Tippy>
                                            </div>
                                        </form>
                                    </div>
                                }
                            </div>
                            <div className={`w-full flex flex-row justify-between items-start lg:pb-24 xl:pb-0 mt-10 ${!viewSalesCompositions ? 'h-10' : 'h-[115px]'} lg:h-full`}>
                                <Button
                                    onClick={() => navigate('/sales')}
                                    shadow={true}
                                    approval={false}
                                >Voltar
                                </Button>
                                <div className="flex gap-3">
                                    {
                                        (userData[0]?.permissions?.indexOf('lm_sales:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            shadow={true}
                                            approval={false}
                                            onClick={() => { setShowUniversalModal(true); setDetectedModal("1") }}
                                        >Excluir
                                        </Button>
                                    }
                                    {
                                        (userData[0]?.permissions?.indexOf('lm_sales:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            shadow={true}
                                            approval={true}
                                            onClick={() => handleSubmit()}
                                        >Editar
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                </Body>
            </Container>
        </>
    )
}