import React, { createContext, useContext, useState, useEffect } from "react";

const ThemeContext = createContext()

export function ThemeContextProvider({ children }) {
    //user
    const [bgPrimaryLight, setBgPrimaryLight] = useState('#FFF')
    const [bgSecondaryLight, setBgSecondaryLight] = useState('#ebf3ff')
    const [primaryDefaultLight, setPrimaryDefaultLight] = useState('#317abe')
    const [companyColor, setCompanyColor] = useState('#e5e7eb')

    const [secondaryDefaultLight, setSecondaryDefaultLight] = useState('#858789')
    const [hoverLight, setHoverLight] = useState('#d8e4e8')
    const [titleBlackTextLight, setTitleBlackTextLight] = useState('#9c9a96')
    const [titleGrayTextLight, setTitleGrayTextLight] = useState('#FFFF')
    const [primaryBorder, setPrimaryBorder] = useState('#f4f4f5')

    const [inputPlaceholderLight, setInputPlaceholderLight] = useState('#8F8F8F')
    const [primaryLightSuccess, setPrimaryLightSuccess] = useState('#64B694')
    const [primaryDefaultDark, setPrimaryDefaultDark] = useState('#1C2128')
    const [secondaryDefaultDark, setSecondaryDefaultDark] = useState('#22272E')
    const [thirdDefaultDark, setThirdDefaultDark] = useState('#373e47')
    const [hoverDark, setHoverDark] = useState('#2a2f36')
    const [titleBlackTextDark, setTitleBlackTextDark] = useState('#2a2f36')
    const [titleGrayTextDark, setTitleGrayTextDark] = useState('#C4C4C4')
    const [primaryBorderDark, setPrimaryBorderDark] = useState('#363E48')
    const [secondaryBorderDark, setSecondaryBorderDark] = useState('#444c56')
    const [darkMode, setDarkMode] = useState(false)

    const [showUniversalModal, setShowUniversalModal] = useState(false)
    const [showNotificationModal, setShowModificationModal] = useState(false)
    const [showNotificationModalText, setShowNotificationModalText] = useState('')
    const [showNotificationModalSuccess, setShowNotificationModalSuccess] = useState(false)


    useEffect(() => {

        if (localStorage.getItem('darkMode') === 'true') {
            document.documentElement.classList.add('dark')
            setDarkMode(true)
        } else {
            document.documentElement.classList.remove('dark')
        }

        //cores padroes cliente
        document.documentElement.style.setProperty('--primaryDefaultLight', "#317ABE")
        document.documentElement.style.setProperty('--secondaryDefaultLight', "#3162be")
        document.documentElement.style.setProperty('--companyColor', companyColor)

        //dark colors clients
        document.documentElement.style.setProperty('--primaryDefaultDark', primaryDefaultDark)
        document.documentElement.style.setProperty('--secondaryDefaultDark', secondaryDefaultDark)
        document.documentElement.style.setProperty('--thirdDefaultDark', thirdDefaultDark)
        
        if (!localStorage.getItem('bgPrimaryLight')) { document.documentElement.style.setProperty('--bgPrimaryLight', bgPrimaryLight) }
        else {
            document.documentElement.style.setProperty('--bgPrimaryLight', localStorage.getItem('bgPrimaryLight'))
            setBgPrimaryLight(localStorage.getItem('bgPrimaryLight'))
        }

        !localStorage.getItem('companyColor') ? document.documentElement.style.setProperty('--companyColor', companyColor) :
            document.documentElement.style.setProperty('--companyColor', localStorage.getItem('companyColor'))

        !localStorage.getItem('bgSecondaryLight') ? document.documentElement.style.setProperty('--bgSecondaryLight', bgSecondaryLight) :
            document.documentElement.style.setProperty('--bgSecondaryLight', localStorage.getItem('bgSecondaryLight'))

        !localStorage.getItem('hoverLight') ? document.documentElement.style.setProperty('--hoverLight', hoverLight) :
            document.documentElement.style.setProperty('--hoverLight', localStorage.getItem('hoverLight'))

        !localStorage.getItem('titleBlackTextLight') ? document.documentElement.style.setProperty('--titleBlackTextLight', titleBlackTextLight) :
            document.documentElement.style.setProperty('--titleBlackTextLight', localStorage.getItem('titleBlackTextLight'))

        !localStorage.getItem('titleGrayTextLight') ? document.documentElement.style.setProperty('--titleGrayTextLight', titleGrayTextLight) :
            document.documentElement.style.setProperty('--titleGrayTextLight', localStorage.getItem('titleGrayTextLight'))

        !localStorage.getItem('primaryBorder') ? document.documentElement.style.setProperty('--primaryBorder', primaryBorder) :
            document.documentElement.style.setProperty('--primaryBorder', localStorage.getItem('primaryBorder'))

        !localStorage.getItem('inputPlaceholderLight') ? document.documentElement.style.setProperty('--inputPlaceholderLight', inputPlaceholderLight) :
            document.documentElement.style.setProperty('--inputPlaceholderLight', localStorage.getItem('inputPlaceholderLight'))

        !localStorage.getItem('primaryLightSuccess') ? document.documentElement.style.setProperty('--primaryLightSuccess', primaryLightSuccess) :
            document.documentElement.style.setProperty('--primaryLightSuccess', localStorage.getItem('primaryLightSuccess'))

        //darkColors
        !localStorage.getItem('hoverDark') ? document.documentElement.style.setProperty('--hoverDark', hoverDark) :
            document.documentElement.style.setProperty('--hoverDark', localStorage.getItem('hoverDark'))

        !localStorage.getItem('titleBlackTextDark') ? document.documentElement.style.setProperty('--titleBlackTextDark', titleBlackTextDark) :
            document.documentElement.style.setProperty('--titleBlackTextDark', localStorage.getItem('titleBlackTextDark'))

        !localStorage.getItem('titleGrayTextDark') ? document.documentElement.style.setProperty('--titleGrayTextDark', titleGrayTextDark) :
            document.documentElement.style.setProperty('--titleGrayTextDark', localStorage.getItem('titleGrayTextDark'))

        !localStorage.getItem('primaryBorderDark') ? document.documentElement.style.setProperty('--primaryBorderDark', primaryBorderDark) :
            document.documentElement.style.setProperty('--primaryBorderDark', localStorage.getItem('primaryBorderDark'))

        !localStorage.getItem('secondaryBorderDark') ? document.documentElement.style.setProperty('--secondaryBorderDark', secondaryBorderDark) :
            document.documentElement.style.setProperty('--secondaryBorderDark', localStorage.getItem('secondaryBorderDark'))


    }, [])

    function changeTheme(darkMode) {
        darkMode ? localStorage.setItem('darkMode', true) : localStorage.setItem('darkMode', '')
        darkMode ? document.documentElement.classList.add('dark') : document.documentElement.classList.remove('dark')
        setDarkMode(darkMode)
    }

    return <ThemeContext.Provider value={{

        bgPrimaryLight, setBgPrimaryLight,
        bgSecondaryLight, setBgSecondaryLight,
        primaryDefaultLight, setPrimaryDefaultLight,
        secondaryDefaultLight, setSecondaryDefaultLight,
        hoverLight, setHoverLight,
        titleBlackTextLight, setTitleBlackTextLight,
        titleGrayTextLight, setTitleGrayTextLight,
        primaryBorder, setPrimaryBorder,
        inputPlaceholderLight, setInputPlaceholderLight,
        primaryLightSuccess, setPrimaryLightSuccess,
        primaryDefaultDark, setPrimaryDefaultDark,
        secondaryDefaultDark, setSecondaryDefaultDark,
        thirdDefaultDark, setThirdDefaultDark,
        hoverDark, setHoverDark,
        titleBlackTextDark, setTitleBlackTextDark,
        primaryBorderDark, setPrimaryBorderDark,
        secondaryBorderDark, setSecondaryBorderDark,
        companyColor, setCompanyColor,
        darkMode,
        changeTheme,
        showUniversalModal, setShowUniversalModal,
        showNotificationModal, setShowModificationModal,
        showNotificationModalText, setShowNotificationModalText,
        showNotificationModalSuccess, setShowNotificationModalSuccess
    }}>

        {children}
    </ThemeContext.Provider>
}

export function useThemeContext() {

    return useContext(ThemeContext)
}