import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// components 
import Tippy from '@tippyjs/react'
import { FaArrowDown, FaEdit, FaFilter } from 'react-icons/fa'
import { FiSlash } from 'react-icons/fi'
import 'tippy.js/animations/perspective.css'
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css'
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { TablePaginationDefault } from '../../../components/table/tablePagination.default'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Tooltip } from '../../../components/toolTip/toolTip'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
// utils
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { api } from '../../../services/api/api'
import { justNumber } from '../../../utils/validators/justNumber'

export function BanksTable(){

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { setShowUniversalModal } = useThemeContext()
    const [showNotification, setShowNotication] = useState('')
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [filterConstructor, setFilterConstructor] = useState({ active: 1 })
    const [filter, setFilter] = useState({ active: 1 })

    async function handleEdit(bank){
        navigate(`/banksEdit/${bank?.banks_id}`)
    }

    async function exportAccountsBank(){
        try {

        const urlContructor = ['']

        Object.keys(filterConstructor).map(key => {
            if(![null, undefined, '', NaN].includes(filterConstructor[key])){
                urlContructor.push(`${key}=${filterConstructor[key]}`)
            }
        })
        
        const dataExports = await api.get('/api/v1/fc/banksAccounts/exports' + '?' + urlContructor.join('&'), {
            headers: {
                authorization: `Bearer ${userData[0].token}`
            },
            responseType: 'blob',
        })

        const url = window.URL.createObjectURL(dataExports?.data)
        const downloadElement = document.createElement('a')
        downloadElement.setAttribute('href', url)
        downloadElement.setAttribute('download', `ContasBanco_${new Date().toISOString().split('T')[0]}`)
        downloadElement.click()

        } catch(error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Filtros'}>
            {
                loading
                    ? <DefaultLoader />
                    : (
                        <div className='flex flex-col pt-2 pb-5 px-0 gap-2 items-center'>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Status *</a>
                                <InputAutoComplete
                                    data={[{ id: 0, description: 'Inativo' }, { id: 1, description: 'Ativo' }]}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                    onChange={e => setFilterConstructor({ ...filterConstructor, active: e.id })}
                                    preSelectedValue={[{ id: 0, description: 'Inativo' }, { id: 1, description: 'Ativo' }]?.filter(filter => filter == filterConstructor.active)[0]?.description}
                                    id='active'
                                    width={80}
                                />
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Código Conta *</a>
                                <Input
                                    charLimit={255} id='id' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                    value={filterConstructor?.id}
                                    width={80}
                                />
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>N° Conta *</a>
                                <Input
                                    charLimit={255} id='account' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, account: justNumber(e.target.value) })}
                                    value={filterConstructor?.account}
                                    width={80}
                                />
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Descrição *</a>
                                <Input
                                    charLimit={255} id='description' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, description: e.target.value })}
                                    value={filterConstructor?.description}
                                    width={80}
                                />
                            </label>
                            <div className='mt-5'>
                                <Button onClick={() => {
                                    setFilter(filterConstructor)
                                    setShowUniversalModal(false)
                                }}>Filtrar</Button>
                            </div>
                        </div>
                    )
            }
            </ScreenModal>
            <Container>
                <TitlePage displayNotification={showNotification} setDisplayNotification={e => setShowNotication(e)}>
                <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                    <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                        <Title text={'Bancos'}></Title>
                        <div className='flex flex-row gap-5 md:w-44 items-center'>
                            <h3 className='text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity} Bancos</h3>
                            <div className='hidden md:flex flex-row justify-start items-center gap-1 cursor-pointer' onClick={() => exportAccountsBank()}>
                                <Tooltip
                                    component={<a className='dark:text-titleGrayTextDark text-xs 2xl:text-sm'>Exportar</a>}
                                    title="Exportar lista em excel"
                                />
                                <FaArrowDown className='dark:text-titleGrayTextDark text-xs 2xl:text-sm' />
                            </div>
                        </div>
                    </div>
                    {
                        (userData[0]?.permissions?.indexOf('fc_banks:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                        <div className='flex flex-col items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/banksCreate')}>
                            <Button shadow={true} onClick={() => navigate('/banksCreate')}>+ Novo banco</Button>
                        </div>
                    }
                </div>
                </TitlePage>
                <Body>
                    <div className='w-full sm:mt-0 mt-2 items-center flex gap-4'>
                        <Button onClick={() => setShowUniversalModal(true)}>
                            <FaFilter className='text-white' /> 
                            <p className='text-white'> Filtrar </p>
                        </Button>
                        {filter && JSON.stringify(filter) != JSON.stringify({ active: 1 })
                            ? <Tippy
                                key={`clearFilter`}
                                content={<span>Limpar filtro</span>}
                                arrow={true}
                                animation='perspective'
                                placement='right'
                                delay={100}>
                                <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                    <FiSlash className='cursor-pointer' onClick={async () => {
                                        setLoading(true)
                                        setFilter({ active: 1 })
                                        setFilterConstructor({ })
                                        await new Promise(resolve => setTimeout(resolve, 500))
                                        setLoading(false)
                                    }} />
                                </div>
                            </Tippy>
                            : <></>
                        }
                    </div>
                    <TablePaginationDefault
                        onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                        quantity={(e) => setQuantity(e)}
                        apiUrl={'/api/v1/fc/banksAccounts'}
                        filters={filter}
                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                        collumns={[                        
                            {
                                name: 'id',
                                type: 'number',
                                description: 'Código Conta'
                            },
                            {
                                name: 'account',
                                type: 'string',
                                description: 'N° Conta'
                            },
                            {
                                name: 'description',
                                type: 'string',
                                description: 'Descrição'
                            },
                            {
                                name: 'balance',
                                type: 'number',
                                description: 'Saldo',
                                func: (item) => {
                                    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.balance)
                                }
                            },
                            {
                                name: 'created_at',
                                type: 'date',
                                description: 'Criado em'
                            }
                        ]}
                    />
                </Body>
            </Container>
        </>
    )
}