// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/useAuth'
import { useParams } from 'react-router-dom'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
// components 
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { validateFields } from '../../../utils/form.validator'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
// utils 
import { validateEmail } from '../../../utils/validators/email.validator'
import { cnpjMask } from '../../../utils/validators/cnpjMask'
import { maskCep } from '../../../utils/validators/cepMask'
import { searchCep } from '../../../utils/validators/searchCep'
import { justNumber } from '../../../utils/validators/justNumber'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { changeThemeColor } from '../../theme/changeColors'
//services
import { api } from '../../../services/api/api'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { FaInfoCircle } from 'react-icons/fa'

export function CompaniesEdit() {

    const navigate = useNavigate()
    const { id } = useParams()
    const { userData, selectedCompany } = useAuth()
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [businessName, setBusinessName] = useState('')
    
    // Fields
    const [ customerData, setCustomerData ] = useState({})
    const [typeBusiness, setTypeBusiness] = useState('')
    const [companiesList, setCompaniesList] = useState([])
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [addressState, setAddressState] = useState('')
    const [addressStateList, setAddressStateList] = useState([])
    const [addressCityList, setAddressCityList] = useState([])
    const [alterColor, setAlterColor] = useState(false)

    useEffect(() => {

        async function getStates() {

            setLoading(true)

            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()

                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        async function getCustomers(){
            try {
                
                const getCompaniesAPI = await api.get(`/api/v1/companies/${id}`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setBusinessName(getCompaniesAPI?.data?.data[0]?.businessName)
                setCustomerData(getCompaniesAPI?.data?.data[0])
                
                const getCustomersAPI = await api.get(`/api/v1/customers`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setCompaniesList(getCustomersAPI?.data?.data)

                
                setLoading(false)

            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getStates()
        getCustomers()

    }, [])

    async function getCityApi(data) {

        setLoading(true)

        try {

            const getCity = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data?.id}/distritos`)
            const getCityData = await getCity.json()
            
            setAddressCityList(getCityData)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleCep(){

        const cepData = await searchCep(customerData?.cep)

        setCustomerData(prev => ({
            ...prev,
            addressState: cepData?.uf,
            addressCity: cepData?.localidade,
            addressDistrict: cepData?.bairro,
            addressRoad: cepData?.logradouro,
            addressNumber: '',
            addressComplement: '',
        }))

    }

    async function handleSubmit(){
        
        setLoading(true)

        const requiredFields = [
            { name: 'businessName', value: customerData?.businessName, required: true, type: 'string' },
            { name: 'addressState', value: customerData?.addressState, required: true, type: 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (customerData?.email){
            if (!isValidEmail) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Email inválido')
                return setLoading(false)
            }
        }

        try {
            
            await api.put(`api/v1/companies/${id}`, {
                businessName: customerData?.businessName,
                fantasyName: customerData?.fantasyName ? customerData.fantasyName : undefined,
                cnpj: customerData?.cnpj ? customerData.cnpj?.replace('-', '')?.replace('/', '')?.replaceAll('.', '') : undefined,
                email: customerData?.email ? customerData.email : undefined,
                colorIdentifier: customerData?.colorIdentifier ? customerData.colorIdentifier : undefined,
                phone: customerData?.phone ? customerData.phone : undefined,
                cep: customerData?.cep ? customerData.cep?.replace('-', '') : undefined,
                addressState: customerData?.addressState ? customerData.addressState : undefined,
                addressCity: customerData?.addressCity ? customerData.addressCity : undefined,
                addressDistrict: customerData?.addressDistrict ? customerData.addressDistrict : undefined,
                addressRoad: customerData?.addressRoad ? customerData.addressRoad : undefined,
                addressNumber: customerData?.addressNumber ? customerData.addressNumber : undefined,
                addressComplement: customerData?.addressComplement ? customerData.addressComplement : undefined,
                customers_id: customerData?.customers_id ? customerData.customers_id : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            if (alterColor){
                if (id == selectedCompany?.id){
                    document.documentElement.style.setProperty('--companyColor', customerData?.colorIdentifier ? customerData?.colorIdentifier : '#3162be')
                    localStorage.setItem('companyColor',  customerData?.colorIdentifier ? customerData.colorIdentifier : '#3162be')
                }
            }

            setAlterColor(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/companies')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    async function handleDelete(){
        setLoading(true)

        try {

            await api.delete(`/api/v1/companies/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/companies')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Exclusão de Empresa'} visible={showModal} width={400} height={200} >
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir a empresa ?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Edição de Cliente - ${businessName}`} />
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <DefaultLoader/>
                        :
                        <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                            <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                <LabelInput text={'Razão social *'}>
                                    <Input 
                                        type={'text'} 
                                        width={80}
                                        value={customerData?.businessName} 
                                        id='businessName' 
                                        onChange={(e) => setCustomerData((prev) => ({...prev, businessName: e.target.value}))}
                                        charLimit={200} 
                                    />
                                </LabelInput>
                                <LabelInput text={'Nome fantasia'}>
                                    <Input 
                                        type={'text'} 
                                        width={80}
                                        value={customerData?.fantasyName} 
                                        id='name' 
                                        onChange={(e) => setCustomerData(prev => ({...prev, fantasyName: e.target.value}))}
                                        charLimit={200} 
                                    />
                                </LabelInput>
                                <LabelInput text={'E-mail'} valid={!isValidEmail ? false : true}>
                                    <Input type={'text'} width={80} value={customerData?.email} id='email' onChange={(e) => {
                                        const isValid = validateEmail(e.target.value)
                                        if (isValid === true) {
                                            setIsValidEmail(true)
                                        } else {
                                            setIsValidEmail(false)
                                        }
                                        setCustomerData(prev => ({...prev, email: e.target.value}))
                                    }} charLimit={255} />
                                </LabelInput>
                                <LabelInput text={'CNPJ'}>
                                    <Input type={'text'} 
                                        value={cnpjMask(customerData?.cnpj)} 
                                        id='cnpj' 
                                        width={80}
                                        onChange={(e) => setCustomerData(prev => ({...prev, cnpj: e.target.value}))} 
                                        charLimit={18} 
                                    />
                                </LabelInput>
                                <LabelInput text={'Cliente *'}>
                                    <InputAutoComplete 
                                        type={'text'} 
                                        width={80}
                                        preSelectedValue={companiesList.filter(e => e?.id == customerData?.customers_id)[0]?.businessName}
                                        value={companiesList.filter(e => e?.id == customerData?.customers_id)[0]?.businessName} 
                                        id='customer' 
                                        onChange={(e) => setCustomerData(prev => ({...prev, customers_id: e?.id}))}
                                        data={companiesList}
                                        optionList={['id', 'businessName']}
                                        selectedLabel={'businessName'}
                                    />
                                </LabelInput>
                                <LabelInput text={'CEP'}>
                                    <Input 
                                        type={'text'} 
                                        width={80}
                                        value={customerData?.cep ? maskCep(customerData?.cep?.toString()) : ''} 
                                        id='cep' 
                                        onChange={(e) => setCustomerData(prev => ({...prev, cep: e.target.value}))} 
                                        charLimit={9} 
                                        onBlur={(e) => {
                                            handleCep()
                                        }}
                                    />
                                </LabelInput>
                                <LabelInput text={'Estado'} required={true}>
                                    <InputAutoComplete
                                        preSelectedValue={customerData?.addressState ? addressStateList.filter(e => { return e?.sigla == customerData?.addressState })[0]?.nome : ''}
                                        value={addressState?.nome}
                                        selectedLabel={'nome'} id='addressState'
                                        data={addressStateList} optionList={['nome']}
                                        onChange={e => {
                                            {
                                                setCustomerData(prev => ({...prev, addressState: e?.sigla}))
                                                e?.nome !== undefined && getCityApi(e)
                                            }
                                        }}>
                                    </InputAutoComplete>
                                </LabelInput>
                                {
                                    !customerData?.addressState ?
                                        <LabelInput text={'Cidade'}>
                                            <Input width={80} type={'text'} value={customerData?.addressCity} id='addressCity' disabled={true} />
                                        </LabelInput>
                                        :
                                        <LabelInput text={'Cidade'}>
                                            <InputAutoComplete
                                                preSelectedValue={customerData?.addressCity ? customerData?.addressCity : ''}
                                                selectedLabel={'nome'} width={80} id='addressCity'
                                                data={addressCityList} optionList={['nome']}
                                                onChange={e => {
                                                    setCustomerData(prev => ({...prev, addressCity: e?.nome}))
                                                }}>
                                            </InputAutoComplete>
                                        </LabelInput>
                                }
                                <LabelInput text={'Bairro'}>
                                    <Input width={80} type={'text'} value={customerData?.addressDistrict} onChange={(e) => setCustomerData(prev => ({...prev, addressDistrict: e.target.value}))}  charLimit={255} />
                                </LabelInput>
                                <LabelInput text={'Logradouro'}>
                                    <Input width={80} type={'text'} value={customerData?.addressRoad} onChange={(e) => setCustomerData(prev => ({...prev, addressRoad: e.target.value}))} charLimit={255} />
                                </LabelInput>
                                <LabelInput text={'Número'}>
                                    <Input width={80} type={'text'} id='addressNumber' value={customerData?.addressNumber} onChange={(e) => setCustomerData(prev => ({...prev, addressNumber: justNumber(e.target.value)}))} charLimit={8} />
                                </LabelInput>
                                <LabelInput text={'Complemento'}>
                                    <Input width={80} type={'text'} value={customerData?.addressComplement} id='addressComplement' onChange={(e) => setCustomerData(prev => ({...prev, addressComplement: e.target.value}))} charLimit={100} />
                                </LabelInput>
                                <LabelInput text={'Telefone com DDD'}>
                                    <Input charLimit={12} type={'text'} width={80} onChange={(e) => setCustomerData(prev => ({...prev, phone: e.target.value}))} value={customerData?.phone}/>
                                </LabelInput>
                                <LabelInput 
                                    text={'Cor do cliente'} 
                                    icon={
                                        <Tippy
                                            key={'iconInfo'}
                                            content={'Definir a cor que aparecerá no rodapé do sistema.'}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={100}>
                                            <div className='flex items-center ml-3'>
                                                <FaInfoCircle className='text-sm cursor-help text-gray-700 flex items-center justify-center'/>                            
                                            </div>
                                        </Tippy>
                                    }
                                >
                                    <input 
                                        value={customerData?.colorIdentifier ? customerData.colorIdentifier : ''} 
                                        onChange={(e) => { changeThemeColor('selectedColor', e.target.value); setCustomerData(prev => ({...prev, colorIdentifier: e.target.value})); setAlterColor(true)} }
                                        className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-16 h-16 sm:w-12 sm:h-8 outline-none dark:bg-secondaryDefaultDark' 
                                        type='color'
                                    />
                                </LabelInput>
                            </form>
                            <div className='mt-8 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>                                
                                <Button
                                    onClick={() => navigate(-1)}
                                    shadow={true}
                                    approval={false}
                                >Cancelar
                                </Button>
                                <div className='flex gap-2'>
                                    {
                                        (userData[0]?.permissions?.indexOf('companies:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            shadow={true}
                                            approval={false}
                                            onClick={() => setShowUniversalModal(true)}
                                        >Excluir
                                        </Button>
                                    }
                                    {
                                        (userData[0]?.permissions?.indexOf('companies:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            shadow={true}
                                            onClick={() => handleSubmit()}
                                        >Editar empresa
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    }            
                </Body>
            </Container>
        </>
    )
}