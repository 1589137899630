import React, { createContext, useContext, useState, useEffect } from 'react'

const ScreenSizeContext = createContext()

export function ScreenSizeProvider({ children }) {

    const [screenX, setScreenX] = useState(0)
    const [screenY, setScreenY] = useState(0)

    useEffect(() => {
        
        setScreenX(window.innerWidth)
        setScreenY(window.innerHeight)

        window.addEventListener('resize', () => getSize())

        return () => window.removeEventListener('resize', () => getSize())

        function getSize() {

            setScreenX(window.innerWidth)
            setScreenY(window.innerHeight)
        }

    }, [])

    return <ScreenSizeContext.Provider value={{
        screenX,
        setScreenX,
        screenY,
        setScreenY
    }}>
        {children}
    </ScreenSizeContext.Provider>
}

export function useScreenSizeContext() {
    return useContext(ScreenSizeContext)
}