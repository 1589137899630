export const monthsList = [
    {
        id: 1,
        name: 'Jan'
    },
    {
        id: 2,
        name: 'Fev'
    },
    {
        id: 3,
        name: 'Mar'
    },
    {
        id: 4,
        name: 'Abr'
    },
    {
        id: 5,
        name: 'Mai'
    },
    {
        id: 6,
        name: 'Jun'
    },
    {
        id: 7,
        name: 'Jul'
    },
    {
        id: 8,
        name: 'Ago'
    },
    {
        id: 9,
        name: 'Set'
    },
    {
        id: 10,
        name: 'Out'
    },
    {
        id: 11,
        name: 'Nov'
    },
    {
        id: 12,
        name: 'Dez'
    }
]