import React, { useEffect } from "react";

export default function ButtonFile({ setFileButton, setFileButtonName }) {

    useEffect(() => {
        document.getElementById('btnSelecionarArquivo').addEventListener('click', function () {
            document.getElementById('inputArquivo').click()
        })

        document.getElementById('inputArquivo').addEventListener('change', function () {

            const fileButton = this.files[0]
            setFileButtonName(fileButton.name)
            setFileButton(fileButton)
        })
    }, [])

    return (
        <>
            <button id="btnSelecionarArquivo" className="bg-primaryDefaultLight text-titleGrayTextLight p-1 rounded-md">Selecionar arquivo</button>
        </>
    )
}