import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
import { TablePaginationDefault } from '../../../components/table/tablePagination.default'
import { Body } from "../../../components/container/Body";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { Container } from "../../../components/container/container";
import { Button } from "../../../components/buttons/button.default";
import { FiSlash } from "react-icons/fi";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import Tippy from "@tippyjs/react";
//icons
import { FaEdit, FaFilter, FaLock, FaLockOpen } from "react-icons/fa";
//context
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";

export function DeterminationPeriod() {

    const navigate = useNavigate()
    const { setShowUniversalModal } = useThemeContext()
    const { userData } = useAuth()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [loading, setLoading] = useState(false)
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({})
    const years = Array.from({length: 100}, (e, index) => {
        return {
            value: 2000 + index
        }
    })
    const months = [
        {
            id: 1,
            name: 'Janeiro'
        },
        {
            id: 2,
            name: 'Fevereiro'
        },
        {
            id: 3,
            name: 'Março'
        },
        {
            id: 4,
            name: 'Abril'
        },
        {
            id: 5,
            name: 'Maio'
        },
        {
            id: 6,
            name: 'Junho'
        },
        {
            id: 7,
            name: 'Julho'
        },
        {
            id: 8,
            name: 'Agosto'
        },
        {
            id: 9,
            name: 'Setembro'
        },
        {
            id: 10,
            name: 'Outubro'
        },
        {
            id: 11,
            name: 'Novembro'
        },
        {
            id: 12,
            name: 'Dezembro'
        }
    ]

    async function handleEdit(data) {
        navigate(`/determinationPeriodEdit/${data.id}`)
    }

    return (
        <>
            <ScreenModal title={'Filtros'}>
                {
                    loading ? 
                    <DefaultLoader /> 
                    :
                    <div className='flex flex-col pb-5 gap-1 items-center justify-center'>
                        <label className='flex flex-col gap-1 items-start justify-center'>
                            <a className='text-sm text-inputPlaceholderLight'>Mês *</a>
                            <InputAutoComplete
                                height={96}
                                data={months}
                                optionList={['name']}
                                selectedLabel={'name'}
                                onChange={(e) => setFilterConstructor({ ...filterConstructor, month: e?.id })}
                            />
                        </label>
                        <label className='flex flex-col gap-1 items-start justify-center'>
                            <a className='text-sm text-inputPlaceholderLight'>Ano *</a>
                            <InputAutoComplete
                                height={72}
                                data={years}
                                optionList={['value']}
                                selectedLabel={'value'}
                                onChange={(e) => setFilterConstructor({ ...filterConstructor, year: e?.value })}
                            />
                        </label>
                        <div className='mt-5'></div>
                            <Button onClick={() => {
                                setFilter(filterConstructor)
                                setShowUniversalModal(false)
                            }}> 
                                Filtrar
                            </Button>
                        </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage displayNotification={showNotification} success={successData} setDisplayNotification={e => setShowNotication(e)}>
                    <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Períodos de Apuração'}></Title>
                        </div>
                        <div className='flex flex-col items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/determinationPeriodCreate')}>
                            {
                                (userData[0]?.permissions?.indexOf('periods:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                                <Button shadow={true} onClick={() => navigate('/determinationPeriodCreate')}>+ Novo periodo</Button>
                            }
                        </div>
                    </div>
                </TitlePage>
                <Body>
                    <>
                        <div className='w-full sm:mt-0 mt-2 items-center flex gap-4'>
                            <Button onClick={() => setShowUniversalModal(true)}>
                                <FaFilter className='text-white' />
                                <p className='text-white'> Filtrar </p>
                            </Button>
                            {
                                filter && JSON.stringify(filter) != JSON.stringify({}) ? 
                                <Tippy
                                    key={`clearFilter`}
                                    content={<span>Limpar filtro</span>}
                                    arrow={true}
                                    animation='perspective'
                                    placement='right'
                                    delay={100}>
                                    <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                        <FiSlash className='cursor-pointer' onClick={async () => {
                                            setLoading(true)
                                            setFilter({})
                                            setFilterConstructor({})
                                            await new Promise(resolve => setTimeout(resolve, 500))
                                            setLoading(false)
                                        }} />
                                    </div>
                                </Tippy>
                                : <></>
                            }
                        </div>
                        <div className="w-full pb-5 justify-center items-center flex">
                            <TablePaginationDefault
                                onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                                quantity={(e) => setQuantity(e)}
                                apiUrl={'/api/v1/general/periods'}
                                filters={filter}
                                mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                collumns={[
                                    {
                                        name: 'month',
                                        type: 'string',
                                        description: 'Mês',
                                        func: (item) => {
                                            if (item?.month) {
                                                return months?.filter(e => e?.id == item?.month)[0]?.name
                                            } else {
                                                return ' - '
                                            }
                                        }
                                    },
                                    {
                                        name: 'year',
                                        type: 'number',
                                        description: 'Ano'
                                    }, {
                                        name: 'isOpen',
                                        type: 'number',
                                        description: 'Status',
                                        func: (item) => {
                                            if (item?.isOpen === true) {
                                                return  <div className="flex justify-center items-center gap-2 text-green-800 dark:text-green-500">Aberto <FaLockOpen/></div>
                                            } else {
                                                return <div className="flex justify-center items-center gap-2 text-red-800 dark:text-red-500">Fechado <FaLock /></div>
                                            }
                                        }
                                    },
                                ]}
                            />
                        </div>
                    </>
                </Body>
            </Container>
        </>
    )
}