// hooks 
import React from 'react'
// contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { ScreenSizeProvider, useScreenSizeContext } from '../../../contexts/screenSizeContext'
// components 
import { TitlePage } from '../../../components/titlePages/title.page'
import { Tooltip } from '../../../components/toolTip/toolTip'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
// utils
import { changeThemeColor } from '../../theme/changeColors'
import { menuItems } from '../../../components/menu/menuItems'

export function ThemesCenter() {

    const { darkMode,
        setDarkMode,
        changeTheme,
        primaryDefaultLight, setPrimaryDefaultLight
    } = useThemeContext()
    const { screenY } = useScreenSizeContext()

    return (

        <Container>
            <TitlePage>
                <Title text={'Temas'}></Title>
            </TitlePage>
            <Body>
                <h2 className='mt-10 2xl:mt-20 hidden md:flex text-base font-bold text-secondaryDefaultLight dark:text-titleGrayTextDark'>Selecione o esquema de cores</h2>
                <div className='mb-20 rounded-2xl flex flex-col md:flex-row md:w-[50rem] p-5 sm:p-0 2xl:w-[1100px] boxShadow items-center justify-between gap-10 '>
                    <div className='flex flex-col sm:flex-row xl:flex-row gap-12 w-full items-center justify-center rounded-2xl mt-16 mb-10 lg:h-80'>
                        <div onClick={() => changeTheme(false)} className={`cursor-pointer border-2 ${darkMode ? 'border-transparent' : 'border-primaryDefaultLight'}  rounded-2xl flex flex-col boxShadow w-80 2xl:w-96 h-64 2xl:h-80 bg-bgPrimaryColor dark:bg-thirdDefaultDark dark:border dark:border-secondaryBorderDark items-center justify-center gap-4`}>
                            <div className='flex flex-row shadow-lg shadow-titleBlackTextLight/60 dark:shadow-titleGrayTextDark/50'>
                                <div className='fle flex-col w-4 2xl:w-8 bg-primaryDefaultLight h-36 2xl:h-48'>
                                    <div className='flex flex-col items-center gap-2 mt-2'>
                                        {menuItems.map((item) => {
                                            return <div className='flex flex-col items-center text-xs text-titleGrayTextLight'>
                                                {item.icon}
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className='flex flex-col bg-bgSecondaryLight w-72 gap-2 pl-1 pr-1'>
                                    <div className='w-full bg-bgPrimaryLight h-8 pl-1 pr-1 flex flex-row justify-between items-center'>
                                        <h2 className='text-xs text-titleBlackTextLight'>Exemplo</h2>
                                        <button className='flex flex-col items-center justify-center bg-primaryDefaultLight w-10 h-4 text-xs text-titleGrayTextLight p-px rounded-md hover:bg-secondaryDefaultLight' >Botão</button>
                                    </div>
                                    <div className='w-full bg-bgPrimaryLight h-full pl-1'>
                                        <h2 className='text-xs text-titleBlackTextDark'>Exemplo</h2>
                                    </div>
                                </div>
                            </div>
                            <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>Claro</a>
                        </div>
                        <div onClick={() => { changeTheme(true); console.log(darkMode) }} className={`cursor-pointer border-2 ${darkMode ? 'border-primaryDefaultLight' : 'border-transparent'}  rounded-2xl flex flex-col boxShadow w-80 2xl:w-96 h-64 2xl:h-80 bg-bgPrimaryColor dark:bg-thirdDefaultDark items-center justify-center gap-4`}>
                            <div className='flex flex-row shadow-lg shadow-titleBlackTextLight/60 dark:shadow-titleGrayTextDark/50'>
                                <div className='flex flex-col w-6 2xl:w-8 bg-secondaryDefaultDark h-36 2xl:h-48 border border-primaryBorderDark'>
                                    <div className='flex flex-col items-center gap-2 mt-2'>
                                        {menuItems.map((item) => {
                                            return <div className='flex flex-col items-center text-xs text-titleGrayTextLight'>
                                                {item.icon}
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className='flex flex-col bg-primaryDefaultDark w-72 gap-2 pl-1 pr-1'>
                                    <div className='w-full bg-secondaryDefaultDark border border-primaryBorderDark h-8 pl-1 pr-1 flex flex-row justify-between items-center'>
                                        <h2 className='text-xs text-titleGrayTextDark'>Exemplo</h2>
                                        <button className='flex flex-col items-center justify-center bg-primaryDefaultLight w-10 h-4 text-xs text-titleGrayTextLight p-px rounded-md hover:bg-secondaryDefaultLight' >Botão</button>
                                    </div>
                                    <div className='w-full bg-secondaryDefaultDark border border-secondaryBorderDark h-full pl-1'>
                                        <h2 className='text-xs text-titleGrayTextDark'>Exemplo</h2>
                                    </div>
                                </div>
                            </div>
                            <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>Escuro</a>
                        </div>
                    </div>
                </div>
            </Body>
        </Container>
    )
}