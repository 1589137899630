//hooks
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import moment from "moment";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Input } from "../../../components/input/input";
import { LabelInput } from "../../../components/label/label.input";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Button } from "../../../components/buttons/button.default";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext';
//services
import { api } from "../../../services/api/api";
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { justNumber } from "../../../utils/validators/justNumber";

export function ShoppingEdit() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    const [shoppingData, setShoppingData] = useState('')
    const [productsData, setProductsData] = useState([])
    const [customersOfCustomer, setCustomersOfCustomer] = useState([])

    useEffect(() => {
        async function getShoppingAPI() {
            setLoading(true)
            try {
                const shoppingApiData = await api.get(`/api/v1/lm/shopping/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                setShoppingData(shoppingApiData?.data?.data)
    
                const getCustomersOfCustomer = await api.get(`/api/v1/general/supplierAndCustomer?isSupplier=true`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setCustomersOfCustomer(getCustomersOfCustomer?.data?.data)
    
                const getProductsAPI = await api.get(`/api/v1/lm/products`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setProductsData(getProductsAPI?.data?.data)
                setLoading(false)
            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    setShowUniversalModal(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    setShowUniversalModal(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        getShoppingAPI()

    }, [])

    async function handleDelete() {
        setLoading(true)
        try {
            await api.delete(`/api/v1/lm/shopping/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/shopping')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Exclusão de Compra'}>
                <div className='w-full h-32 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir?</p>
                    <div className='flex flex-row gap-1 mt-10 pb-5'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Visualização de compra`} />
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <DefaultLoader />
                        :
                        <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                            <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                <LabelInput text={'Data de entrada'} required={true}>
                                    <Input
                                        type={'date'}
                                        width={80}
                                        value={moment(shoppingData?.dateEntry).utc(false).format('YYYY-MM-DD')}
                                        id='dateEntry'
                                        disabled={true}
                                    />
                                </LabelInput>
                                <LabelInput text={'Fornecedor'} required={true}>
                                    <InputAutoComplete
                                        width={80}
                                        data={customersOfCustomer}
                                        optionList={["id", "businessName"]}
                                        selectedLabel={["businessName"]}
                                        preSelectedValue={customersOfCustomer.filter(cust => cust.id == shoppingData?.supplierAndCustomer_id)[0]?.businessName}
                                        value={customersOfCustomer.filter(cust => cust.id == shoppingData?.supplierAndCustomer_id)[0]?.businessName}
                                        id='supplierAndCustomer_id'
                                        disabled={true}
                                    />
                                </LabelInput>
                                <LabelInput text={'Produto'} required={true}>
                                    <InputAutoComplete
                                        width={80}
                                        data={productsData}
                                        optionList={["code", "description", "typeProduct"]}
                                        selectedLabel={["description"]}
                                        preSelectedValue={productsData.filter(prod => prod.id == shoppingData?.products_id)[0]?.description}
                                        value={productsData.filter(prod => prod.id == shoppingData?.products_id)[0]?.description}
                                        id='products_id'
                                        disabled={true}
                                    />
                                </LabelInput>
                                <LabelInput text={'Quantidade'} required={true}>
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={shoppingData?.quantity ? shoppingData?.quantity : ''}
                                        id='quantity'
                                        disabled={true}
                                    />
                                </LabelInput>
                                <LabelInput text={'Conversão U.M'} required={true}>
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={shoppingData?.convertUnitMeasure ? shoppingData?.convertUnitMeasure : ''}
                                        id='convertUnitMeasure'
                                        disabled={true}
                                    />
                                </LabelInput>
                                <LabelInput text={'Preço Unitário'} required={true}>
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(shoppingData?.unitPrice)}
                                        id='unitPrice'
                                        disabled={true}
                                    />
                                </LabelInput>
                                <LabelInput text={'ICMS %'} required={true}>
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={shoppingData?.icms ? shoppingData?.icms : ''}
                                        id='icms'
                                        disabled={true}
                                    />
                                </LabelInput>
                                <LabelInput text={'Frete'} required={true}>
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(shoppingData?.freight)}
                                        id='freight'
                                        disabled={true}
                                    />
                                </LabelInput>
                                <LabelInput text={'ICMS Frete %'} required={shoppingData?.freight ? true : false} >
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={shoppingData?.icmsFreight ? shoppingData?.icmsFreight : ''}
                                        id='icmsFreight'
                                        disabled={true}
                                    />
                                </LabelInput>
                                <LabelInput text={'Lançado em'}>
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={moment(shoppingData?.created_at, 'YYYY-MM-DD')?.utc(false)?.format('DD/MM/YYYY')}
                                        id='created_at'
                                        disabled={true}
                                    />
                                </LabelInput>
                            </form>
                            <div className='mt-8 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                <Button
                                    onClick={() => navigate('/shopping')}
                                    shadow={true}
                                    approval={false}
                                >Voltar
                                </Button>
                                <div className='flex gap-2'>
                                    {
                                        (userData[0]?.permissions?.indexOf('lm_shopping:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            shadow={true}
                                            approval={false}
                                            onClick={() => setShowUniversalModal(true)}
                                        >Excluir
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </Body>
            </Container>
        </>
    )
}