//hooks
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
//components
import { FaArrowDown, FaEdit, FaFilter } from "react-icons/fa";
import { FiSlash } from "react-icons/fi";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { Button } from "../../../components/buttons/button.default";
import { Body } from "../../../components/container/Body";
import { TablePaginationDefault } from "../../../components/table/tablePagination.default";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Input } from "../../../components/input/input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
//utils
import { justNumber } from '../../../utils/validators/justNumber'
import { useAuth } from "../../../contexts/useAuth";
import { api } from "../../../services/api/api";
import { responseError } from "../../../utils/responsesFunctions/error.response";

export function PaymentCondition() {

    async function handleEdit(payCondition) {
        navigate(`/paymentConditionEdit/${payCondition?.id}`)
    }

    const {userData} = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({ active: true })
    const [filterConstructor, setFilterConstructor] = useState({})
    const { setShowUniversalModal } = useThemeContext()

    const [quantity, setQuantity] = useState(0)

    async function exportPayment(){
        try {

        const urlContructor = ['']

        Object.keys(filterConstructor).map(key => {
            if(![null, undefined, '', NaN].includes(filterConstructor[key])){
                urlContructor.push(`${key}=${filterConstructor[key]}`)
            }
        })
        
        const dataExports = await api.get('/api/v1/lm/paymentCondition/exports' + '?' + urlContructor.join('&'), {
            headers: {
                authorization: `Bearer ${userData[0].token}`
            },
            responseType: 'blob',
        })

        const url = window.URL.createObjectURL(dataExports?.data)
        const downloadElement = document.createElement('a')
        downloadElement.setAttribute('href', url)
        downloadElement.setAttribute('download', `CondicaoPagem_${new Date().toISOString().split('T')[0]}`)
        downloadElement.click()

        } catch(error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Filtros'}>
                {
                    loading ?
                        <DefaultLoader />
                        :
                        <div className='flex flex-col pt-2 pb-5 px-0 gap-2 items-center'>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                <Input
                                    charLimit={255} id='id' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                    value={filterConstructor?.id}
                                    width={80}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Status *</a>
                                <InputAutoComplete
                                    data={[{ id: false, description: 'Inativo' }, { id: true, description: 'Ativo' }]}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                    onChange={e => setFilterConstructor({ ...filterConstructor, active: e.id })}
                                    preSelectedValue={[{ id: false, description: 'Inativo' }, { id: true, description: 'Ativo' }]?.filter(filter => filter == filterConstructor.active)[0]?.description}
                                    id='active'
                                    width={80}
                                    height={64}
                                />
                            </label>
                            <div className='mt-5'>
                                <Button onClick={() => {
                                    setFilter(filterConstructor)
                                    setShowUniversalModal(false)
                                }}>Filtrar</Button>
                            </div>
                        </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex flex-row gap-2 items-center justify-between  pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Condição de Pagamento'}></Title>
                            <div className='flex flex-row gap-2 md:w-48 items-center'>
                                <h3 className='text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity} Condi.</h3>
                                <div className='hidden md:flex flex-row justify-start items-center gap-1 cursor-pointer ml-3' onClick={() => exportPayment()}>
                                    <Tippy
                                        content={<span>Exportar lista em excel</span>}
                                        arrow={true}
                                        animation='perspective'
                                        placement='bottom'
                                        delay={80}
                                    >
                                        <p className='flex flex-row items-center justify-center gap-1 text-xs 2xl:text-sm'>Exportar <FaArrowDown className='dark:text-titleGrayTextDark text-xs 2xl:text-sm' /></p>
                                    </Tippy>
                                </div>
                            </div>
                        </div>
                        {
                            (userData[0]?.permissions?.indexOf('lm_paymentCondition:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                            <>
                                <div className='hidden sm:flex flex-row gap-5 items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/paymentConditionCreate')}>
                                    <Button shadow={true} width={40} onClick={() => navigate('/paymentConditionCreate')}>+ Nova Condição</Button>
                                </div>
                                <div className='sm:hidden flex flex-col items-center justify-end' onClick={() => navigate('/paymentConditionCreate')}>
                                    <Button shadow={true} onClick={() => navigate('/paymentConditionCreate')}>Nova</Button>
                                </div>
                            </>
                        }
                    </div>
                </TitlePage>
                <Body>
                    <div className='w-full sm:mt-0 mt-2 items-center flex gap-4'>
                        <Button onClick={() => { setShowUniversalModal(true) }}>
                            <FaFilter className='text-white' />
                            <p className='text-white'> Filtrar </p>
                        </Button>
                        {filter && JSON.stringify(filter) != JSON.stringify({ active: true })
                            ? <Tippy
                                key={`clearFilter`}
                                content={<span>Limpar filtro</span>}
                                arrow={true}
                                animation='perspective'
                                placement='right'
                                delay={100}>
                                <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                    <FiSlash className='cursor-pointer' onClick={async () => {
                                        setLoading(true)
                                        setFilter({ active: true })
                                        setFilterConstructor({})
                                        await new Promise(resolve => setTimeout(resolve, 500))
                                        setLoading(false)
                                    }} />
                                </div>
                            </Tippy>
                            : <></>
                        }
                    </div>
                    <TablePaginationDefault
                        onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                        quantity={(e) => setQuantity(e)}
                        apiUrl={'/api/v1/lm/paymentCondition'}
                        filters={filter}
                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                        colSpan={9}
                        collumns={[
                            {
                                name: 'id',
                                type: 'number',
                                description: 'Código'
                            },
                            {
                                name: 'days',
                                type: 'string',
                                description: 'Dias'
                            },
                            {
                                name: 'description',
                                type: 'string',
                                description: 'Descrição',
                                func: (item) => {
                                    if (item?.description) {
                                        if (item?.description?.length > 40) {
                                            return `${item?.description?.slice(0,40)}...`
                                        } else {
                                            return item?.description
                                        }
                                    } else {
                                        return ' - '
                                    }
                                }
                            },
                            {
                                name: 'status',
                                type: 'string',
                                description: 'Status',
                                func: (item) => {
                                    if (item.active === false) {
                                        return 'Inativo'
                                    } else {
                                        return 'Ativo'
                                    }
                                }
                            },
                            {
                                name: 'created_at',
                                type: 'date',
                                description: 'Criado em'
                            },
                        ]}
                    />
                </Body>
            </Container>
        </>
    )
}