import React, { useEffect, useState } from 'react'
import { TooltipBars } from '../../components/toolTip/toolTipBars'
import ApexCharts from 'apexcharts';
import randomColor from 'randomcolor';
//contexts
import { useThemeContext } from '../../contexts/themeContext';

export function BarsGraph({ data, title, bgGradient, bgGradientFirstColor, bgGradientSecondColor, bgColor, }) {

    const { primaryDefaultLight, secondaryDefaultLight } = useThemeContext()

    useEffect(() => {

        const dataValue = data.map(apiData => apiData.value)
        const namesValue = data.map(apiData => apiData.name)

        var options = {
            series: [{
                name: 'Valor',
                data: dataValue
            }],
            chart: {
                height: 350,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                  
                    backgroundBarColors: ['#fb00b9'],
                    // color: primaryDefaultLight,
                    borderRadius: 5,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return  " R$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: [primaryDefaultLight]
                }
            },

            xaxis: {
                
                categories: namesValue,
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: primaryDefaultLight,
                            colorTo: secondaryDefaultLight,
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    formatter: function (val) {
                        return  "R$ " + val;
                    }
                }

            },
            colors: [primaryDefaultLight],
            title: {
                text: title,
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
                    color: primaryDefaultLight
                }
            }
        };

        var chart = new ApexCharts(document.querySelector("#chart"), options);
        chart.render();

    }, [])

    return (
        <div id='chart'>

        </div>
    )

    // const [maxValue, setMaxValue] = useState()
    // const [valuesList, setValuesList] = useState([])
    // const [ isSum, setIsSum ] = useState(true)
    // const [objectValue, setObjectValue] = useState(data)

    // useEffect(() => {
    //     const arrayOfValues = data.map((graphData) => {
    //         return graphData.value
    //     })

    //     const thisMaxValue = arrayOfValues.reduce(function (a, b) {
    //         return Math.max(a, b);
    //     }, -Infinity);

    //     setValuesList(arrayOfValues)
    //     setMaxValue(thisMaxValue)

    // }, [])

    // useEffect(() => {


    //     const sleep = ms => {
    //         return new Promise(resolve => setTimeout(resolve, ms))
    //     }
    //     objectValue.map(async (value, index) => {

    //         let thisObjectValue = value.value
    //         let thisPercent = (thisObjectValue / 100) * 10
    //         let totalValue = 0
    //         const totalValueSum = receivedValue => {

    //             return sleep(30).then(v => totalValue = receivedValue)
    //         }

    //         while(totalValue < thisObjectValue){
    //             let thisValue = totalValue + thisPercent
    //             const sumValue = await totalValueSum(thisValue)
    //             totalValue = thisValue
    //             objectValue[index].value = totalValue
    //             setObjectValue([...objectValue])
    //             setIsSum(false)
    //         }
    //     })

    // },[])

    // return (
    //     <div className='flex flex-col items-center gap-4 p-4'>
    //         <h2 className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>{title}</h2>
    //         <div className='flex flex-row gap-1 md:gap-2 justify-end items-end mb-6' style={{ height: '200px' }}>
    //             {
    //                 objectValue.map((graphData, index) => {
    //                     const percent = objectValue[index].value / maxValue
    //                     const firstTreeLetters = graphData.name.slice(0,3)

    //                     return <>
    //                         <TooltipBars title={parseInt(data[index].value)} component={<div className={`transition-all ease-in duration-75 flex flex-col justify-end ${bgGradient ? `bg-gradient-to-t from-${bgGradientFirstColor} to-${bgGradientSecondColor}`: `bg-${bgColor}` }  w-5 2xl:w-8`} style={{ height: isSum ? '0%' : graphData.value === maxValue ? '100%' : `${percent * 100}%` }} >
    //                             <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark translate-y-8'>{firstTreeLetters}</p>
    //                         </div>}>
    //                         </TooltipBars>
    //                     </>

    //                 })
    //             }
    //         </div>
    //     </div>

    // )
}
