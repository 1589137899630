import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/buttons/button.default';
import { Input } from '../../../components/input/input';
import { LoadingPulse } from '../../../components/loaders/loadingPulse';
import { Title } from '../../../components/titlePages/title';
import { Body } from '../../../components/container/Body';
import { Container } from '../../../components/container/container';
import { ScreenModal } from '../../../components/modals/notification/screenModal';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { DefaultLoader } from '../../../components/loaders/defaultLoader';
import { TitlePage } from "../../../components/titlePages/title.page";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
//contexts
import { useAuth } from '../../../contexts/useAuth';
import { useThemeContext } from '../../../contexts/themeContext';
import { useScreenSizeContext } from '../../../contexts/screenSizeContext';
// utils
import { justNumber } from '../../../utils/validators/justNumber';
import { validateFields } from "../../../utils/form.validator";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { FaAngleDown, FaAngleRight, FaCheck, FaSearch, FaTimes } from "react-icons/fa";
// services
import { api } from "../../../services/api/api";
import { FiSlash } from "react-icons/fi";
import moment from "moment";

export function TemplateAccountsPlans(){

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { screenX, screenY } = useScreenSizeContext()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const [loading, setLoading] = useState(false)

    const [filter, setFilter] = useState('')
    const [showFilter, setShowFilter] = useState(false)
    const [accountsPlans, setAccountsPlans] = useState('')
    const [accountsPlansHistory, setAccountsPlansHistory] = useState('')
    const [loadingPulse, setLoadingPulse] = useState(false)
    const [loadingPulseAccount, setLoadingPulseAccount] = useState(false)
    const [updateGroups, setUpdateGroups] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [itemTemp, setItemTemp] = useState('')
    const [showDelete, setShowDelete] = useState(false)
    const [showDeleteSub, setShowDeleteSub] = useState(false)
    const [showDeleteGroup, setShowDeleteGroup] = useState(false)

    // States para criação de SubGrupo
    const [subSelectedCode, setSubSelectedCode] = useState('')
    const [subSelectedName, setSubSelectedName] = useState('')
    const [subSelectedDescription, setSubSelectedDescription] = useState('')
    const [subSelectedOrigin, setSubSelectedOrigin] = useState('')
    const [subSelectedRule, setSubSelectedRule] = useState('')
    const [subGroupsRulesList, setsubGroupsRulesList] = useState('')

    // States para criação de plano de contas
    const [accSelectedCode, setAccSelectedCode] = useState('')
    const [accSelectedDescription, setAccSelectedDescription] = useState('')
    const [accSelectedOrigin, setAccSelectedOrigin] = useState('')
    const [accSelectedComposeCashBalance , setAccSelectedComposeCashBalance] = useState('')
    const [accSelectedComposeDebtPosition , setAccSelectedComposeDebtPosition] = useState('')
    const [accSelectedContractFinishDate , setAccSelectedContractFinishDate] = useState('')

    // States apra criação de grupo
    const [groupCode, setGroupCode] = useState('')
    const [groupDescription, setGroupDescription] = useState('')
    
    useEffect(() => {
        setUpdateGroups(false)
        async function getData(){
            const getDataAPI = await api.get('api/v1/general/template/accountPlans/allPlans', {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            const getDataManagerial = await api.get('api/v1/general/rules', {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            const newPropsManagerial = getDataManagerial?.data?.data

            const newPropsAPI = getDataAPI?.data?.data.map(group => {
                const newSub = group.subGroups.map(sub => {
                    return {
                        ...sub,
                        show: false
                    }
                })
                return {
                    ...group,
                    subGroups: newSub,
                    show: false
                }
            })

            setsubGroupsRulesList(newPropsManagerial)
            setAccountsPlans(newPropsAPI)
            setAccountsPlansHistory(structuredClone(newPropsAPI))
            setLoading(false)
        }

        getData()

    }, [updateGroups])

    useEffect(() => {
        if (!showUniversalModal){
            setGroupCode('')
            setGroupDescription('')
            setShowEdit(false)
            setItemTemp('')
            setShowDelete(false)
            setShowDeleteSub(false)
            setShowDeleteGroup(false)
        }
    }, [showUniversalModal])

    async function verifyFilter(){
        if (showFilter && !filter){
            setAccountsPlans(accountsPlansHistory)

            setShowFilter(false)
        } else {
            
            const newFilteredAccounts = accountsPlans.map(group => {
                if (group.group_description?.toLowerCase().includes(filter?.toLowerCase())) {
                    group.isFind = true
                    return {
                        ...group,
                        show: true
                    }
                } else {
                    const newSubs = group.subGroups.map(sub => {
                        console.log('Aqui',sub)
                        if (sub.description?.toLowerCase().includes(filter?.toLowerCase())){
                            console.log('Aqui - description')
                            sub.isFind = true
                            return {
                                ...sub,
                                show: true
                            }
                        } else {
                            const newAccounts = sub.accounts.map(acc => {
                                if (acc.description?.toLowerCase().includes(filter?.toLowerCase())){
                                    acc.isFind = true
                                    return acc
                                } else {
                                    acc.isFind = false
                                    return acc
                                }
                            }).filter(e => e?.isFind)

                            if (newAccounts.length > 0) {
                                sub.isFind = true
                                return {
                                    ...sub,
                                    show: true,
                                    accounts: newAccounts
                                }
                            } else {
                                sub.isFind = false
                                return {
                                    ...sub,
                                    accounts: newAccounts
                                }
                            }
                        }                        
                        
                    }).filter(e => e?.isFind)

                    if (newSubs.length == 0){
                        group.isFind = false
                        return group
                    } else {
                        group.isFind = true
                         return {
                            ...group,
                            subGroups: newSubs
                        }
                    }

                   

                    // if (newSubs.length > 0) {
                    //     group.isFind = true
                    // console.log('Aqui - 4')

                    //     return {
                    //         ...group,
                    //         show: true,
                    //         subGroups: newSubs
                    //     }
                    // } else {
                    //     group.isFind = false
                    //     return {
                    //         ...group,
                    //         subGroups: newSubs
                    //     }
                    // }
                }
                
            }).filter(e => e?.isFind)
            
            if(newFilteredAccounts.length == 0) {
                setAccountsPlans(accountsPlansHistory)
            } else {
                setAccountsPlans(newFilteredAccounts)
            }
        }
    }

    async function hiddenOrShowGroup(item){
        const status = !item.show
        setAccountsPlans(accountsPlans.map((group, index) => {
            if (group?.group_code == item?.group_code){
                group.show = status
                return group
            } else {
                return group
            }
        }))
    }

    async function hiddenOrShowSubGroup(item){
        const status = !item.show
        setAccountsPlans(accountsPlans.map(group => {
            const newSub = group.subGroups.map(sub => {
                if (sub.code == item?.code){

                    sub.show = status
                    return sub
                } else {
                    return sub
                }
            })
            return {
                ...group,
                subGroups: newSub
            }
        }))
    }

    async function handleChangeDescriptionGroup(item){
        const newAccouts = accountsPlans.filter(group => {
            if (group?.group_id == item){
                
                return {
                    ...group,
                    group_description: group.group_description.toUpperCase()
                }
            }
        })[0]
        
        const oldAccounts = accountsPlansHistory.filter(old => {
            if (old?.group_id == item){

                return {
                    ...old,
                    group_description: old.group_description.toUpperCase()
                }
            }
        })[0]
        
        if (oldAccounts?.group_description.toUpperCase() != newAccouts?.group_description.toUpperCase()){
            try {

                await api.put(`api/v1/general/template/accountPlansGroup/${item}`, {
                    description: newAccouts?.group_description
                }, {
                    headers: {
                        authorization: `Bearer ${userData[0]?.token}`
                    }
                })

                setAccountsPlansHistory(structuredClone(accountsPlans))
                setShowModificationModal(true)
                setShowNotificationModalText('Descrição atualizada com sucesso')
                setShowNotificationModalSuccess(true)

            } catch (error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }
    }

    async function handleChangeDescriptionSubGroup(item){
        let hasChange = false
        let description

        accountsPlans?.map((group) => {
            group?.subGroups.map((sub) => {
                if (sub?.code == item ){
                    accountsPlansHistory.map((group2) => {
                        group2?.subGroups.map((sub2) => {                            
                            if (sub2?.code == sub?.code && sub2?.description != sub.description){
                                hasChange = true
                                description = sub?.description
                            }
                        })
                    })
                }
            })
        })

        if (hasChange){
            try {

                await api.put(`api/v1/general/template/accountPlansSubGroup/${item}`, {
                    description
                }, {
                    headers: {
                        authorization: `Bearer ${userData[0]?.token}`
                    }
                })

                setAccountsPlansHistory(structuredClone(accountsPlans))
                setShowModificationModal(true)
                setShowNotificationModalText('Descrição atualizada com sucesso')
                setShowNotificationModalSuccess(true)

            } catch (error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }
    }

    async function handleNewSubGroup(group){
        const requiredFields = [
            { name: 'subCode', value: subSelectedCode, required: true, type: 'string' },
            { name: 'subName', value: subSelectedName, required: true, type: 'string' },
            { name: 'subDescription', value: subSelectedDescription, required: true, type: 'string' },
            { name: 'subOrigin', value: subSelectedOrigin, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            setLoadingPulse(true)

            const createSubGroup = await api.post(`api/v1/general/template/accountPlansSubGroup`, {
                code: subSelectedCode, 
                name: subSelectedName,
                description: subSelectedDescription,
                origin: subSelectedOrigin,
                g_subGroupsRules_id: subSelectedRule ? subSelectedRule : undefined,
                g_accountPlansGroups_id: group
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            const formatCreated = createSubGroup?.data?.data.map(created => {
                return {
                    ...created,
                    accounts: [],
                    show: false
                }
            })

            setLoadingPulse(false)
            
            const newAccounts = accountsPlans.map(acc => {
                if(acc.group_id == group){
                    acc?.subGroups.push(formatCreated[0])
                    acc.inIncrement = false
                } else {
                    acc.inIncrement = false
                }
                return acc
            })

            setAccountsPlans(newAccounts)
            setAccountsPlansHistory(structuredClone(newAccounts))

            setSubSelectedCode('')
            setSubSelectedName('')
            setSubSelectedDescription('')
            setSubSelectedOrigin('')

            setShowModificationModal(true)
            setShowNotificationModalText('Subgrupo criado com sucesso')
            setShowNotificationModalSuccess(true)


        } catch (error){
            if (responseError(error).length > 0) {
                setLoadingPulse(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoadingPulse(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }


    }

    async function handleNewAccount(sub) {

        const requiredFields = [
            { name: 'accCode', value: accSelectedCode, required: true, type: 'string' },
            { name: 'accDescription', value: accSelectedDescription, required: true, type: 'string' },
            { name: 'accOrigin', value: accSelectedOrigin, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            setLoadingPulseAccount(true)

            const createAccount = await api.post(`api/v1/general/template/accountPlans/createPlan`, {
                code: accSelectedCode,
                name: accSelectedDescription,
                description: accSelectedDescription,
                origin: accSelectedOrigin,
                composeCashBalance: accSelectedComposeCashBalance ? accSelectedComposeCashBalance : undefined,
                composeDebtPosition: accSelectedComposeDebtPosition ? accSelectedComposeDebtPosition : undefined,
                contractFinishDate: accSelectedContractFinishDate ? accSelectedContractFinishDate : undefined,
                g_accountPlansSubGroups_code: sub
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })
            
            const formatCreated = createAccount?.data?.data.map(created => {
                return {
                    ...created,
                    code_SubGroup: sub,
                    description_SubGroup: createAccount?.data?.data[0]?.description
                }
            })

            setLoadingPulseAccount(false)
            
            const newAccounts = accountsPlans.map(acc => {
                const newSubgroups = acc?.subGroups.map(sub2 => {
                    if (sub2?.code == sub){
                        sub2?.accounts.push(formatCreated[0])
                        sub2.inIncrement = false
                    } else {
                        sub2.inIncrement = false
                    }
                    return sub2
                })
                return {
                    ...acc,
                    subGroups: newSubgroups
                }
            })

            setAccountsPlans(newAccounts)
            setAccountsPlansHistory(structuredClone(newAccounts))

            setAccSelectedCode('')
            setAccSelectedDescription('')
            setAccSelectedOrigin('')
            setAccSelectedComposeCashBalance('')
            setAccSelectedComposeDebtPosition('')
            setAccSelectedContractFinishDate('')

            setShowModificationModal(true)
            setShowNotificationModalText('Plano de conta criado com sucesso')
            setShowNotificationModalSuccess(true)


        } catch (error){
            if (responseError(error).length > 0) {
                setLoadingPulseAccount(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoadingPulseAccount(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleNewGroup() {
        const requiredFields = [
            { name: 'groupCode', value: groupCode, required: true, type: 'string' },
            { name: 'groupDescription', value: groupDescription, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            setLoading(true)
            const createGroup = await api.post(`api/v1/general/template/accountPlansGroup`, {
                code: groupCode,
                description: groupDescription
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })
            
            setLoading(false)
            setUpdateGroups(true)
            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Grupo criado com sucesso')
            setShowNotificationModalSuccess(true)


        } catch (error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleEditAccount(item){
        
        const requiredFields = [
            { name: 'accDescriptionEdit', value: item?.description, required: true, type: 'string' },
            { name: 'accOriginEdit', value: item?.origin, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            
            await api.put(`api/v1/general/template/accountPlans/${item?.code}`, {
                description: item?.description,
                origin: item?.origin,
                composeCashBalance: item?.composeCashBalance,
	            composeDebtPosition: item?.composeDebtPosition
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })
            
            setAccountsPlans(accountsPlans.map(acc => {
                const newSubgroups = acc?.subGroups.map(sub2 => {
                    let newAccounts = null
                    if (sub2?.code == item?.code_SubGroup){
                        newAccounts = sub2?.accounts.map(acc2 => {
                            if (acc2?.code == item?.code){
                                acc2 = item
                            }
                            return acc2
                        })
                    }
                    return {
                        ...sub2,
                        accounts: newAccounts ? newAccounts : sub2?.accounts
                    }
                })
                return {
                    ...acc,
                    subGroups: newSubgroups
                }
            }))
            
            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Plano de contas editada com sucesso')
            setShowNotificationModalSuccess(true)

        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    async function handleDeleteAccount(item){
        try {
            let positionItem 
            
            await api.delete(`api/v1/general/template/accountPlans/${item?.code}`, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            const formatAccounts = accountsPlans.map(group => {
                const newSubs = group?.subGroups.map(sub => {
                    positionItem = sub?.accounts.indexOf(item)
                    if (positionItem > -1){
                        sub?.accounts.splice(positionItem, 1)
                    }
                    return sub
                })
                return {
                    ...group,
                    subGroups: newSubs
                }
            })

            setAccountsPlans(formatAccounts)
            setAccountsPlansHistory(structuredClone(formatAccounts))
            
            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Plano de contas excluído com sucesso')
            setShowNotificationModalSuccess(true)

        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDeleteSubgroup(item){
        
        try {
            let positionItem 
            
            await api.delete(`api/v1/general/template/accountPlansSubGroup/${item?.code}`, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            const formatAccounts = accountsPlans.map(group => {
                positionItem = group?.subGroups.indexOf(item)
                if (positionItem > -1){
                    group?.subGroups.splice(positionItem, 1)
                }
                return group
            })

            setAccountsPlans(formatAccounts)
            setAccountsPlansHistory(structuredClone(formatAccounts))
            
            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Subgrupo excluído com sucesso')
            setShowNotificationModalSuccess(true)

        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDeleteGroup(item){

        try {
            await api.delete(`api/v1/general/template/accountPlansGroup/${item?.group_id}`, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })

            const formatAccounts = accountsPlans.filter(group => group?.group_id != item?.group_id)

            setAccountsPlans(formatAccounts)
            setAccountsPlansHistory(structuredClone(formatAccounts))
            
            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Grupo excluído com sucesso')
            setShowNotificationModalSuccess(true)

        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function formatAccountCode(item){
        let newFormatCode = `${item?.code}01`

        if (item?.accounts.length > 0) {
            const sortAccounts = item?.accounts.sort((a, b) => { return a?.code < b?.code ? -1 : a?.code > b?.code ? 1 : 0 })
            const lastItemOfArray = sortAccounts.at(-1)
            let accountCode = lastItemOfArray?.code
            newFormatCode = Number(accountCode) + 1
            setAccSelectedCode(Number(newFormatCode))
        } else {
            setAccSelectedCode(Number(newFormatCode))
        }

        return newFormatCode
    }

    const originList = [
        {
            name: "Devedora",
            value: "D"
        },
        {
            name: "Credora",
            value: "C"
        }
    ]

    const options = [
        {
            name: "Compõe",
            value: true
        },
        {
            name: "Não compõe",
            value: false
        }
    ]

    return (
        <>
            <ScreenModal title={`${showEdit ? 'Edição de plano de contas' : showDelete ? 'Exclusão de plano de contas' : showDeleteSub ? 'Exclusão de subgrupo' : showDeleteGroup ? 'Exclusão de grupo' : 'Criação de grupo'}`}>
                {
                    !showEdit && !showDelete && !showDeleteSub && !showDeleteGroup ?
                    <>
                        <div className='flex flex-col pt-2 pb-10 px-12 gap-4 items-center'>
                            <LabelInput text={'Código'} required={true}>
                                <Input
                                    charLimit={255} 
                                    id='groupCode' 
                                    type='text'
                                    autoFocus={true}
                                    onChange={(e) => setGroupCode(justNumber(e.target.value))}
                                    value={groupCode ? groupCode : ''}
                                />
                            </LabelInput>
                            <LabelInput text={'Descrição'} required={true}>
                                <Input
                                    charLimit={255} 
                                    id='groupDescription' 
                                    type='text'
                                    onChange={(e) => setGroupDescription(e.target.value)}
                                    value={groupDescription ? groupDescription : ''}
                                />
                            </LabelInput>
                        </div>
                        <div className="flex flex-row justify-center pb-8 w-full gap-5">
                            <Button 
                                approval={false}
                                onClick={() => setShowUniversalModal(false)}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                approval={true}
                                onClick={() => handleNewGroup()}
                            >
                                Cadastrar
                            </Button>
                        </div>
                    </>
                    : showDelete ?
                    <>
                        <div className='w-full h-32 flex flex-col items-center justify-center'>
                            <p className='text-sm text-titleBlackTextLight'>Deseja realmente excluir o plano de contas?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button shadow={true} onClick={() => {handleDeleteAccount(itemTemp)}}>Sim</Button>
                                <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                            </div>
                        </div>
                    </>
                    : showDeleteSub ?
                        <div className='w-full h-32 flex flex-col items-center justify-center'>
                            <p className='text-sm text-titleBlackTextLight'>Deseja realmente excluir o subgrupo?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button shadow={true} onClick={() => {handleDeleteSubgroup(itemTemp)}}>Sim</Button>
                                <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                            </div>
                        </div>
                    : showDeleteGroup ?
                    <div className='w-full h-32 flex flex-col items-center justify-center'>
                        <p className='text-sm text-titleBlackTextLight'>Deseja realmente excluir o grupo?</p>
                        <div className='flex flex-row gap-1 mt-10'>
                            <Button shadow={true} onClick={() => {handleDeleteGroup(itemTemp)}}>Sim</Button>
                            <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                    :
                    <>
                        <div className='flex flex-col pt-2 pb-7 px-12 gap-2 items-center'>
                            <LabelInput text={'Código'} required={false}>
                                <Input
                                    charLimit={255} 
                                    id='accCode' 
                                    type='text'
                                    disabled={true}
                                    width={80}
                                    value={itemTemp?.code ? itemTemp?.code : ''}
                                />
                            </LabelInput>
                            <LabelInput text={'Descrição'} required={true}>
                                <textarea className='outline-none border border-gray-400 border-opacity-75 rounded-lg w-80 h-36 lg:h-32 resize-none
                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark
                                    focus:border focus:border-primaryDefaultLight
                                    dark:focus:border dark:focus:border-primaryDefaultLight p-2' 
                                    maxLength={255} 
                                    id='accDescriptionEdit'
                                    value={itemTemp?.description ? itemTemp?.description : ''}
                                    onChange={(e) => {
                                        setItemTemp(prev => ({...prev, description: e.target.value}))
                                    }}
                                />
                            </LabelInput>
                            <LabelInput text={'Tipo'} required={true}>
                                <InputAutoComplete
                                    data={originList}
                                    id={'accOriginEdit'}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    onChange={(e) => {
                                        setItemTemp(prev => ({...prev, origin: e?.value}))
                                    }}
                                    preSelectedValue={itemTemp?.origin ? originList.filter(e => e?.value == itemTemp?.origin)[0]?.name : ''}
                                    value={itemTemp?.origin ? originList.filter(e => e?.value == itemTemp?.origin)[0]?.name : ''}
                                />
                            </LabelInput>
                            <LabelInput text={'Saldo fluxo de caixa'}>
                                <InputAutoComplete
                                    data={options}
                                    id={'accComposeCashEdit'}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    onChange={(e) => {
                                        setItemTemp(prev => ({...prev, composeCashBalance: e?.value}))
                                    }}
                                    preSelectedValue={itemTemp?.composeCashBalance ? options.filter(e => e?.value == itemTemp?.composeCashBalance)[0]?.name : ''}
                                    value={itemTemp?.composeCashBalance || itemTemp?.composeCashBalance == false ? options.filter(e => e?.value == itemTemp?.composeCashBalance)[0]?.name : 'Não compõe'}
                                />
                            </LabelInput>
                            <LabelInput text={'Posição endividamento'}>
                                <InputAutoComplete
                                    data={options}
                                    id={'accComposeDebtEdit'}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    onChange={(e) => {
                                        setItemTemp(prev => ({...prev, composeDebtPosition: e?.value}))
                                    }}
                                    preSelectedValue={itemTemp?.composeDebtPosition ? options.filter(e => e?.value == itemTemp?.composeDebtPosition)[0]?.name : ''}
                                    value={itemTemp?.composeDebtPosition ||  itemTemp?.composeDebtPosition == false ? options.filter(e => e?.value == itemTemp?.composeDebtPosition)[0]?.name : 'Não compõe'}
                                />
                            </LabelInput>
                            <LabelInput text={'Data término contrato'}>
                                <input 
                                    type={'date'}
                                    id={'accContractFinishEdit'}
                                    className={`border border-gray-400 border-opacity-75 rounded-sm bg-white px-2 w-80 h-8 cursor-text transition-all duration-200`}
                                    onChange={(e) => {
                                        setItemTemp(prev => ({...prev, contractFinishDate: e.target.value}))
                                    }}
                                    value={itemTemp?.contractFinishDate ? itemTemp?.contractFinishDate : ''}
                                />
                            </LabelInput>
                        </div>
                        <div className="flex flex-row justify-center pb-5 w-full gap-5">
                            <Button 
                                approval={false}
                                onClick={() => setShowUniversalModal(false)}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                approval={true}
                                onClick={() => {
                                    handleEditAccount(itemTemp)
                                }}
                            >
                                Cadastrar
                            </Button>
                        </div>
                    </>
                }
            </ScreenModal>
            <Container>
                <TitlePage displayNotification={showNotification} success={successData} setDisplayNotification={e => setShowNotication(e)}>
                    <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-full sm:w-[50%] md:w-auto'>
                            <Title text={'Template - plano de contas padrão'}></Title>
                        </div>
                    </div>
                </TitlePage>
                {
                    loading ?
                    <DefaultLoader/>
                    :
                    <Body>
                        {
                            accountsPlans.length == 0 ?
                            <div className="flex flex-col justify-center items-center w-full">
                                <p className="text-md">Nenhum plano de contas configurado para o cliente</p>
                                <div className="flex flex-row gap-10 items-center mt-5">
                                    <a 
                                        className="underline text-primaryDefaultLight text-md cursor-pointer hover:brightness-75"
                                        onClick={() => {setShowUniversalModal(true)}}
                                    >
                                        Criar do início
                                    </a>                              
                                </div>
                            </div>
                            :
                            <>
                                <div className="hidden lg:flex w-full gap-5 mt-1">
                                    <Button
                                        onClick={() => {setShowUniversalModal(true)}}
                                    >
                                        Criar grupo
                                    </Button>
                                    {
                                        showFilter ?
                                        <div className="flex flex-row gap-3 items-center">
                                            <Input 
                                                placeholder={'Pesquisar'}
                                                charLimit={255}
                                                value={filter ? filter : ''}
                                                onChange={e => setFilter(e.target.value)}
                                                autoFocus={true}
                                                onBlur={() => verifyFilter()}
                                                onKeyDown={e => {
                                                    if (e?.code == 'Enter' || e?.code == 'NumpadEnter'){
                                                        verifyFilter()
                                                    }
                                                }}
                                            />
                                            {
                                                filter &&
                                                <div>
                                                    <Tippy
                                                        content={<span>Limpar filtro</span>}
                                                        arrow={true}
                                                        animation='perspective'
                                                        placement='top'
                                                        delay={100}>
                                                        <div>
                                                            <FiSlash 
                                                                className="text-sm cursor-pointer"
                                                                onClick={() => {
                                                                    setAccountsPlans(accountsPlansHistory)
                                                                    setShowFilter(false)
                                                                    setFilter('')
                                                                }}
                                                            />
                                                        </div>
                                                    </Tippy>                                                
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div className="flex items-center gap-1 rounded-md pr-2 pl-2 hover:bg-gray-200 cursor-pointer" 
                                        onClick={() => {setShowFilter(true)}}>
                                            <p><FaSearch className="text-sm"/></p>
                                            <button className="text-sm">Pesquisar</button>
                                        </div>                        
                                    }
                                </div>
                                {
                                    accountsPlans.map(group => {
                                        return (
                                            <>
                                                <div className={`hidden lg:flex w-full mt-2 p-2 rounded-md ${!group.show ? 'border border-blue-200 flex-row justify-between' : 'flex-col'}`} id={group.group_code}>
                                                    <div className={`flex flex-col`}>
                                                        <div className="flex items-center gap-2">
                                                            <Tippy
                                                                content={<span>{group.show ? 'Comprimir grupo' : 'Expandir grupo'}</span>}
                                                                arrow={true}
                                                                animation='perspective'
                                                                placement='top'
                                                                delay={100}>
                                                                <div>
                                                                    <p className="text-sm cursor-pointer" onClick={() => hiddenOrShowGroup(group)}>{group.show ? <FaAngleDown className="text-sm"/> : <FaAngleRight className="text-sm"/>}</p>                                                                     
                                                                </div>
                                                            </Tippy>
                                                            <div className="flex flex-row gap-2 w-full h-7"
                                                                onBlur={() => {
                                                                    group.focus = false
                                                                    setAccountsPlans(accountsPlans.map(group => {
                                                                        return group
                                                                    }))
                                                                }}
                                                            >
                                                                {
                                                                    group.focus ?
                                                                    <input 
                                                                        type={'text'}
                                                                        className="text-md font-semibold select-none min-w-96 w-[50%] pl-2 pr-2 rounded-md outline-none border border-blue-200" 
                                                                        value={`${group?.group_description.toUpperCase()}`}
                                                                        onBlur={() => handleChangeDescriptionGroup(group.group_id)}
                                                                        autoFocus={true}
                                                                        onChange={(e) => {
                                                                            group.group_description = e.target.value
                                                                            setAccountsPlans(accountsPlans.map(group => {
                                                                                return group
                                                                            }))
                                                                        }}
                                                                    />
                                                                    :
                                                                    <Tippy
                                                                        content={<span>Clique para editar</span>}
                                                                        arrow={true}
                                                                        animation='perspective'
                                                                        placement='top'
                                                                        delay={100}
                                                                    >
                                                                        <p 
                                                                            className="text-sm font-semibold select-text pl-2 pr-2 border border-white rounded-md hover:border-gray-200"
                                                                            onClick={() => {
                                                                                group.focus = true
                                                                                setAccountsPlans(accountsPlans.map(group => {
                                                                                    return group
                                                                                }))
                                                                            }}
                                                                        >{group?.group_code} - {group?.group_description.toUpperCase()}</p>
                                                                    </Tippy>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        !group.show &&
                                                        <div className="flex flex-row items-center">
                                                            <p className="flex flex-row">
                                                                <a
                                                                    className="underline text-sm text-primaryDefaultLight hover:brightness-50 cursor-pointer text-red-700"
                                                                    onClick={() => {
                                                                        setItemTemp(group)
                                                                        setShowDeleteGroup(true)
                                                                        setShowUniversalModal(true)
                                                                    }}
                                                                >
                                                                    Excluir
                                                                </a>
                                                            </p>
                                                        </div>         
                                                    }         
                                                    <div className={`${!group.show && 'hidden'} animate-[wiggleTop_0.2s_ease-in-out]`}>                                            
                                                        {
                                                            group?.subGroups.map(sub => {
                                                                return (
                                                                    <>
                                                                        <div className={`flex w-[97%] p-2 rounded-md ml-12 mt-1 border items-center gap-1 bg-zinc-100 justify-between`}>
                                                                            <div className="flex flex-row text-sm">
                                                                                <Tippy
                                                                                    content={<span>{sub?.show ? 'Recolher subitens' : 'Expandir subitens'}</span>}
                                                                                    arrow={true}
                                                                                    animation='perspective'
                                                                                    placement='top'
                                                                                    delay={100}>
                                                                                    <div>
                                                                                        <p className="text-sm cursor-pointer" onClick={() => hiddenOrShowSubGroup(sub)}>{sub?.show ? <FaAngleDown/> : <FaAngleRight/>}</p>                                                                     
                                                                                    </div>
                                                                                </Tippy>
                                                                                <div className={`${sub.focus ? 'w-full h-[1.4rem]' : ''} flex flex-row gap-2`}
                                                                                    onBlur={() => {
                                                                                        sub.focus = false
                                                                                        setAccountsPlans(accountsPlans.map(group => {
                                                                                            return group
                                                                                        }))
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        sub.focus ?
                                                                                        <input 
                                                                                            type={'text'}
                                                                                            className="text-sm select-none min-w-96 w-[50%] px-2 rounded-md outline-none border border-blue-200" 
                                                                                            value={`${sub?.description}`}
                                                                                            onBlur={() => {handleChangeDescriptionSubGroup(sub?.code)}}
                                                                                            autoFocus={true}
                                                                                            onChange={(e) => {
                                                                                                sub.description = e.target.value
                                                                                                setAccountsPlans(accountsPlans.map(group => {
                                                                                                    return group
                                                                                                }))
                                                                                            }}
                                                                                        />
                                                                                        : 
                                                                                        <Tippy
                                                                                            content={<span>Clique para editar</span>}
                                                                                            arrow={true}
                                                                                            animation='perspective'
                                                                                            placement='top'
                                                                                            delay={100}
                                                                                        >
                                                                                            <p 
                                                                                                className="font-medium text-sm px-2 select-none cursor-text border border-zinc-100 rounded-md hover:border-gray-300"
                                                                                                onClick={() => {
                                                                                                    sub.focus = true
                                                                                                    setAccountsPlans(accountsPlans.map(group => {
                                                                                                        return group
                                                                                                    }))
                                                                                                }}
                                                                                            >
                                                                                                {sub.code} - {sub.description}
                                                                                            </p>
                                                                                        </Tippy>
                                                                                    }
                                                                                </div>                                                                            
                                                                                {
                                                                                    !sub.focus && !sub.show &&
                                                                                    <p className="ml-1 text-xs 2xl:text-sm font-semibold">
                                                                                        {sub?.g_subGroupsRules_id ? `${ subGroupsRulesList.filter(e => e.id == sub?.g_subGroupsRules_id)[0].description }` : ''}
                                                                                    </p>
                                                                                }
                                                                            </div>
                                                                            <div className="flex flex-row items-center">
                                                                                <p className="flex flex-row">
                                                                                    <a
                                                                                        className="underline text-sm text-primaryDefaultLight hover:brightness-50 cursor-pointer text-red-700"
                                                                                        onClick={() => {
                                                                                            setItemTemp(sub)
                                                                                            setShowDeleteSub(true)
                                                                                            setShowUniversalModal(true)
                                                                                        }}
                                                                                    >
                                                                                        Excluir
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className={`${!sub?.show && 'hidden'} animate-[wiggleTop_0.2s_ease-in-out]`}>                                                                
                                                                            <>
                                                                                <div className={`lg:w-[93%] xl:w-[94.1%] 2xl:w-[95.2%] p-2 rounded-md flex flex-col ml-20 mt-1 border duration-200 transition-all bg-zinc-100`}>
                                                                                    {
                                                                                        sub?.accounts.length == 0 && !sub?.inIncrement ?
                                                                                        <></>
                                                                                        :
                                                                                        <div className={`${!sub?.show && 'hidden'} w-[95.3%] grid grid-cols-12 mt-1 items-center gap-1 border-b border-b-black`}>
                                                                                            <p className="text-sm font-semibold select-none col-span-1">Código</p>
                                                                                            <p className="text-sm font-semibold select-none col-span-2">Descrição</p>
                                                                                            <p className="text-sm font-semibold select-none col-span-1">Tipo</p>
                                                                                            <p className="text-sm font-semibold select-none col-span-2">Saldo fluxo de caixa</p>
                                                                                            <p className="text-sm font-semibold select-none col-span-2">Posição endividamento</p>
                                                                                            <p className="text-sm font-semibold select-none col-span-2">Data término contrato</p>
                                                                                            <p className="text-sm font-semibold select-none col-span-1">Status</p>
                                                                                            <p className="text-sm font-semibold select-none col-span-1">Ação</p>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        sub?.accounts.map(account => {
                                                                                            return (
                                                                                                <div className={`${!sub?.show && 'hidden'} lg:w-[94.1%] xl:w-[94.1%] 2xl:w-[95.2%] rounded-md grid grid-cols-12 mt-1 items-center gap-1`}>
                                                                                                    <p className="text-sm select-none col-span-1">{account?.code}</p>      
                                                                                                    {
                                                                                                        account?.description.length > 35 ?
                                                                                                        <Tippy
                                                                                                            content={<span>{account?.description}</span>}
                                                                                                            arrow={true}
                                                                                                            animation='perspective'
                                                                                                            placement='top'
                                                                                                            delay={100}>
                                                                                                                <p className="text-sm select-none col-span-2 cursor-help">
                                                                                                                    {
                                                                                                                        account?.description.length > 27 ? `${account?.description.slice(0,27)}...` : account?.description
                                                                                                                    }
                                                                                                                </p>
                                                                                                        </Tippy>
                                                                                                        :
                                                                                                        <p className="text-sm select-none col-span-2">
                                                                                                            {account?.description}
                                                                                                        </p>
                                                                                                    }                                      
                                                                                                    <p className={`${account?.origin == 'C' ? 'text-green-700' : 'text-red-700'} text-sm select-none col-span-1 font-bold`}>{account?.origin == 'C' ? 'Credora' : 'Devedora'}</p>
                                                                                                    <p className="text-sm select-none col-span-2">{account?.composeCashBalance ? 'Compõe' : 'Não compõe'}</p>
                                                                                                    <p className="text-sm select-none col-span-2">{account?.composeDebtPosition ? 'Compõe' : 'Não compõe'}</p>
                                                                                                    <p className="text-sm select-none col-span-2">{account?.contractFinishDate ? moment(account?.contractFinishDate)?.utc(false)?.format('DD/MM/YYYY') : ' - '}</p>
                                                                                                    <div className="flex flex-row items-center">
                                                                                                        <div className={`w-2 h-2 bg-green-700  rounded-full mr-2`}></div>
                                                                                                        <p className="text-sm select-none col-span-1">Ativo</p>
                                                                                                    </div>
                                                                                                    <div className="text-sm select-none col-span-1 flex flex-row gap-3">
                                                                                                        <p className="">
                                                                                                            <a 
                                                                                                                className="underline text-primaryDefaultLight hover:brightness-50 cursor-pointer text-red-700"
                                                                                                                onClick={() => {
                                                                                                                    setItemTemp(account)
                                                                                                                    setShowDelete(true)
                                                                                                                    setShowUniversalModal(true)
                                                                                                                }}
                                                                                                            >
                                                                                                                Excluir
                                                                                                            </a>
                                                                                                        </p>
                                                                                                        <p className="">
                                                                                                            <a 
                                                                                                                className="underline text-primaryDefaultLight hover:brightness-50 cursor-pointer"
                                                                                                                onClick={() => {
                                                                                                                    setItemTemp(account)
                                                                                                                    setShowEdit(true)
                                                                                                                    setShowUniversalModal(true)
                                                                                                                }}
                                                                                                            >
                                                                                                                Editar
                                                                                                            </a>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    <div className={`${!sub?.show && 'hidden'} lg:w-[93%] xl:w-[94.1%] 2xl:w-[95.2%] grid grid-cols-12 mt-1 items-center gap-1 ${loadingPulseAccount ? 'animate-pulse' : ''} `}>
                                                                                        {
                                                                                            sub.inIncrement ?
                                                                                            <>
                                                                                                <div className="text-sm font-semibold select-none col-span-1">
                                                                                                    <Input
                                                                                                        placeholder="Código"
                                                                                                        className={`outline-none border focus:border-primaryDefaultLight rounded-md`}
                                                                                                        value={accSelectedCode ? accSelectedCode : formatAccountCode(sub)}
                                                                                                        height={6}
                                                                                                        id={'accCode'}
                                                                                                        width={screenX > 1536 ? '70px' : screenX > 1280 ? '60px' : screenX >= 1024 ? '45px' : '30px'}
                                                                                                        disabled={true}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="text-sm font-semibold select-none col-span-2">
                                                                                                <Input
                                                                                                    placeholder="Descrição"
                                                                                                    className="outline-none border focus:border-primaryDefaultLight rounded-md px-2 w-60"
                                                                                                    value={accSelectedDescription ? accSelectedDescription : ''}
                                                                                                    charLimit={255}
                                                                                                    height={6}
                                                                                                    id={'accDescription'}
                                                                                                    width={screenX > 1536 ? '252px' : screenX > 1279 ? '150px' : screenX > 1023 ? '100px' : ''}
                                                                                                    autoFocus={true}
                                                                                                    onChange={(e) => {
                                                                                                        setAccSelectedDescription(e.target.value)
                                                                                                    }}
                                                                                                />
                                                                                                </div>
                                                                                                <div className="text-sm font-semibold select-none col-span-1">
                                                                                                    <select 
                                                                                                        id={'accOrigin'}
                                                                                                        className={`${accSelectedOrigin ? 'text-black' : 'text-gray-400'} border border-gray-400 border-opacity-75 rounded-sm bg-white px-1 w-14 lg:w-14 xl:w-16 2xl:w-24 h-6 cursor-pointer transition-all duration-200`}
                                                                                                        onChange={e => {
                                                                                                            setAccSelectedOrigin(e?.target?.value)
                                                                                                        }}
                                                                                                        value={accSelectedOrigin ? accSelectedOrigin : ''}
                                                                                                    >
                                                                                                        <option value={''} disabled selected>Tipo</option>
                                                                                                        <option value={'D'} className={'text-black'}>Devedora</option>
                                                                                                        <option value={'C'} className={'text-black'}>Credora</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <div className="text-sm font-semibold select-none col-span-2">
                                                                                                    <select 
                                                                                                        id={'accComposeBalance'}
                                                                                                        className={`${accSelectedComposeCashBalance ? 'text-black' : 'text-gray-400'} border border-gray-400 border-opacity-75 rounded-sm bg-white px-1 w-14 lg:w-24 xl:w-32 2xl:w-40 h-6 cursor-pointer transition-all duration-200`}
                                                                                                        onChange={e => {
                                                                                                            setAccSelectedComposeCashBalance(e?.target?.value)
                                                                                                        }}
                                                                                                        value={accSelectedComposeCashBalance ? accSelectedComposeCashBalance : ''}
                                                                                                    >
                                                                                                        <option value={''} disabled selected>Saldo fluxo de caixa</option>
                                                                                                        <option value={true} className={'text-black'}>Compõe</option>
                                                                                                        <option value={false} className={'text-black'}>Não Compõe</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <div className="text-sm font-semibold select-none col-span-2">
                                                                                                    <select 
                                                                                                        id={'accComposeBalance'}
                                                                                                        className={`${accSelectedComposeDebtPosition ? 'text-black' : 'text-gray-400'} border border-gray-400 border-opacity-75 rounded-sm bg-white px-2 w-14 lg:w-24 xl:w-32 2xl:w-52 h-6 cursor-pointer transition-all duration-200`}
                                                                                                        onChange={e => {
                                                                                                            setAccSelectedComposeDebtPosition(e?.target?.value)
                                                                                                        }}
                                                                                                        value={accSelectedComposeDebtPosition ? accSelectedComposeDebtPosition : ''}
                                                                                                    >
                                                                                                        <option value={''} disabled selected>Posição endividamento</option>
                                                                                                        <option value={true} className={'text-black'}>Compõe</option>
                                                                                                        <option value={false} className={'text-black'}>Não Compõe</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <div className="text-sm font-semibold select-none col-span-2">
                                                                                                    <input 
                                                                                                        type={'date'}
                                                                                                        id={'accContractFinish'}
                                                                                                        className={`${accSelectedContractFinishDate ? 'text-black' : 'text-gray-400'} border border-gray-400 border-opacity-75 rounded-sm bg-white px-2 w-14 lg:w-24 xl:w-32 2xl:w-44 h-6 cursor-text transition-all duration-200`}
                                                                                                        onChange={(e) => {setAccSelectedContractFinishDate(e.target.value)}}
                                                                                                        value={accSelectedContractFinishDate ? accSelectedContractFinishDate : ''}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="text-gray-400 text-sm select-none col-span-1">
                                                                                                    <div className="flex flex-row items-center">
                                                                                                        <div className={`w-2 h-2 bg-green-700 rounded-full mr-2 opacity-50`}></div>
                                                                                                        <p className="text-sm select-none col-span-1">Ativo</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    loadingPulseAccount ?
                                                                                                    <div className="flex justify-center items-center h-0">
                                                                                                        <LoadingPulse/>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="text-sm font-semibold select-none col-span-1 flex flex-row gap-2 items-center">
                                                                                                        <Tippy
                                                                                                            content={<span>Cadastrar</span>}
                                                                                                            arrow={true}
                                                                                                            animation='perspective'
                                                                                                            placement='top'
                                                                                                            delay={100}>
                                                                                                            <button
                                                                                                                onKeyDown={e => {
                                                                                                                    if (e?.code == 'Enter' || e?.code == 'NumpadEnter'){
                                                                                                                        handleNewAccount(sub?.code)
                                                                                                                    }
                                                                                                                }}
                                                                                                                className={'rounded-sm focus:outline-none focus:border focus:border-primaryDefaultLight'}
                                                                                                            >
                                                                                                                <FaCheck 
                                                                                                                    className="text-green-700 cursor-pointer text-sm"
                                                                                                                    onClick={() => {
                                                                                                                        handleNewAccount(sub?.code)
                                                                                                                    }}
                                                                                                                />
                                                                                                            </button>
                                                                                                        </Tippy>
                                                                                                        <Tippy
                                                                                                            content={<span>Cancelar</span>}
                                                                                                            arrow={true}
                                                                                                            animation='perspective'
                                                                                                            placement='top'
                                                                                                            delay={100}>
                                                                                                            <button
                                                                                                                onKeyDown={e => {
                                                                                                                    if (e?.code == 'Enter' || e?.code == 'NumpadEnter'){
                                                                                                                        setAccountsPlans(accountsPlans.map(acc => {
                                                                                                                            const newSubs = acc?.subGroups?.map(sub2 => {
                                                                                                                                if(sub2.code == sub.code){
                                                                                                                                    sub2.inIncrement = false
                                                                                                                                }
                                                                                                                                return sub2
                                                                                                                            })
                            
                                                                                                                            return {
                                                                                                                                ...acc,
                                                                                                                                subGroups: newSubs
                                                                                                                            }
                                                                                                                        }))
                                                                                                                        setAccSelectedCode('')
                                                                                                                        setAccSelectedDescription('')
                                                                                                                        setAccSelectedOrigin('')
                                                                                                                        setAccSelectedComposeCashBalance('')
                                                                                                                        setAccSelectedComposeDebtPosition('')
                                                                                                                        setAccSelectedContractFinishDate('')
                                                                                                                    }
                                                                                                                }}
                                                                                                                className={'rounded-sm focus:outline-none focus:border focus:border-primaryDefaultLight'}
                                                                                                            >
                                                                                                                <FaTimes 
                                                                                                                    className="text-red-700 cursor-pointer text-sm"
                                                                                                                    onClick={() => {
                                                                                                                        setAccountsPlans(accountsPlans.map(acc => {
                                                                                                                            const newSubs = acc?.subGroups?.map(sub2 => {
                                                                                                                                if(sub2.code == sub.code){
                                                                                                                                    sub2.inIncrement = false
                                                                                                                                }
                                                                                                                                return sub2
                                                                                                                            })
                            
                                                                                                                            return {
                                                                                                                                ...acc,
                                                                                                                                subGroups: newSubs
                                                                                                                            }
                                                                                                                        }))
                                                                                                                        setAccSelectedCode('')
                                                                                                                        setAccSelectedDescription('')
                                                                                                                        setAccSelectedOrigin('')
                                                                                                                        setAccSelectedComposeCashBalance('')
                                                                                                                        setAccSelectedComposeDebtPosition('')
                                                                                                                        setAccSelectedContractFinishDate('')
                                                                                                                    }}
                                                                                                                />
                                                                                                            </button>
                                                                                                        </Tippy>
                                                                                                    </div>
                                                                                                }
                                                                                            </>
                                                                                            : !loadingPulseAccount ?
                                                                                            <div className="col-span-12 flex gap-2 flex-col">
                                                                                                {sub.accounts.length == 0 ? <p>Nenhuma conta cadastrada</p> : <></>}
                                                                                                <a 
                                                                                                    className="flex flex-row text-sm gap-2 items-center text-primaryDefaultLight underline hover:brightness-50 cursor-pointer w-fit"
                                                                                                    onClick={() => {
                                                                                                        setAccSelectedCode('')
                                                                                                        setAccSelectedDescription('')
                                                                                                        setAccSelectedOrigin('')
                                                                                                        setAccSelectedComposeCashBalance('')
                                                                                                        setAccSelectedComposeDebtPosition('')
                                                                                                        setAccSelectedContractFinishDate('')
                                                                                                        setAccountsPlans(accountsPlans.map(acc => {
                                                                                                            const newSubs = acc?.subGroups?.map(sub2 => {
                                                                                                                if(sub2.code == sub.code){
                                                                                                                    sub2.inIncrement = true
                                                                                                                } else {
                                                                                                                    sub2.inIncrement = false
                                                                                                                }
                                                                                                                return sub2
                                                                                                            })

                                                                                                            return {
                                                                                                                ...acc,
                                                                                                                subGroups: newSubs
                                                                                                            }
                                                                                                        }))
                                                                                                    }}>
                                                                                                        Adicionar conta analítica
                                                                                                    </a>
                                                                                            </div>
                                                                                            :
                                                                                            <a className="col-span-12 text-primaryDefaultLight select-none">
                                                                                                Cadastrando plano de conta, aguarde...
                                                                                            </a>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </>                                                          
                                                                        </div>                                                            
                                                                    </>
                                                                )
                                                            })
                                                        } 
                                                        <div className={`flex w-[97%] p-2 rounded-md ml-12 mt-1 border items-center gap-1 bg-zinc-100 ${loadingPulse ? 'animate-pulse' : ''}`}>
                                                            {
                                                                group?.inIncrement ?
                                                                <div className="flex flex-row w-full items-center gap-5">
                                                                    <input
                                                                        placeholder="Código"
                                                                        className={`outline-none border border-gray-400 focus:border-primaryDefaultLight rounded-sm px-2 w-28`}
                                                                        value={subSelectedCode ? subSelectedCode : `${group?.group_code}`}
                                                                        height={6}
                                                                        id={'subCode'}
                                                                        autoFocus={true}
                                                                        width={'128px'}
                                                                        onChange={(e) => {
                                                                            setSubSelectedCode(justNumber(e.target.value))
                                                                        }}
                                                                    />
                                                                    <input
                                                                        placeholder="Nome"
                                                                        className="outline-none border border-gray-400 focus:border-primaryDefaultLight rounded-sm px-2 w-40"
                                                                        value={subSelectedName ? subSelectedName : ''}
                                                                        charLimit={255}
                                                                        height={6}
                                                                        id={'subName'}
                                                                        width={'240px'}
                                                                        onChange={(e) => {
                                                                            setSubSelectedName(e.target.value)
                                                                        }}
                                                                    />
                                                                    <input
                                                                        placeholder="Descrição"
                                                                        className="outline-none border border-gray-400 focus:border-primaryDefaultLight rounded-sm px-2 w-40"
                                                                        value={subSelectedDescription ? subSelectedDescription : ''}
                                                                        charLimit={255}
                                                                        height={6}
                                                                        id={'subDescription'}
                                                                        width={'240px'}
                                                                        onChange={(e) => {
                                                                            setSubSelectedDescription(e.target.value)
                                                                        }}
                                                                    />
                                                                    <select 
                                                                        id={'subOrigin'}
                                                                        className={`${subSelectedOrigin ? 'text-black' : 'text-gray-400'} border border-gray-400 border-opacity-75 rounded-sm bg-white px-2 w-52 h-6 cursor-pointer transition-all duration-200`}
                                                                        onChange={e => {
                                                                            setSubSelectedOrigin(e?.target?.value)
                                                                        }}
                                                                        value={subSelectedOrigin ? subSelectedOrigin : ''}
                                                                    >
                                                                        <option value={''} disabled selected>Tipo</option>
                                                                        <option value={'D'} className={'text-black'}>Devedora</option>
                                                                        <option value={'C'} className={'text-black'}>Credora</option>
                                                                    </select>
                                                                    <label>
                                                                        <InputAutoComplete 
                                                                            data={subGroupsRulesList}
                                                                            optionList={['description']}
                                                                            selectedLabel={'description'}
                                                                            onChange={(e) => {
                                                                                setSubSelectedRule(e.id)
                                                                            }}
                                                                            heightInput={true}
                                                                            width={60}
                                                                            separator={'Gerencial'}
                                                                        />
                                                                    </label>
                                                                    {
                                                                        loadingPulse ?
                                                                        <div className="flex justify-center items-center h-0">
                                                                            <LoadingPulse/>
                                                                        </div>
                                                                        :
                                                                        <div className="flex flex-col xl:flex xl:flex-row gap-[10px]">
                                                                            <Tippy
                                                                                content={<span>Cadastrar</span>}
                                                                                arrow={true}
                                                                                animation='perspective'
                                                                                placement='top'
                                                                                delay={100}>
                                                                                <button
                                                                                    onKeyDown={e => {
                                                                                        if (e?.code == 'Enter' || e?.code == 'NumpadEnter'){
                                                                                            handleNewSubGroup(group?.group_id)
                                                                                        }
                                                                                    }}
                                                                                    className={'rounded-sm focus:outline-none focus:border focus:border-primaryDefaultLight'}
                                                                                >
                                                                                    <FaCheck 
                                                                                        className="text-green-700 cursor-pointer focus:border focus:border-red-500 text-sm"
                                                                                        onClick={() => {
                                                                                            handleNewSubGroup(group?.group_id)
                                                                                        }}
                                                                                    />
                                                                                </button>
                                                                            </Tippy>
                                                                            <Tippy
                                                                                content={<span>Cancelar</span>}
                                                                                arrow={true}
                                                                                animation='perspective'
                                                                                placement='top'
                                                                                delay={100}>
                                                                                <button
                                                                                    onKeyDown={e => {
                                                                                        if (e?.code == 'Enter' || e?.code == 'NumpadEnter'){
                                                                                            setAccountsPlans(accountsPlans.map(acc2 => {
                                                                                                if(acc2.group_code == group.group_code){
                                                                                                    acc2.inIncrement = false
                                                                                                } else {
                                                                                                    acc2.inIncrement = false
                                                                                                }
                                                                                                return acc2
                                                                                            }))
                                                                                            setSubSelectedCode('')
                                                                                            setSubSelectedName('')
                                                                                            setSubSelectedDescription('')
                                                                                            setSubSelectedOrigin('')
                                                                                        }
                                                                                    }}
                                                                                    className={'rounded-sm focus:outline-none focus:border focus:border-primaryDefaultLight'}
                                                                                >
                                                                                    <FaTimes 
                                                                                        className="text-red-700 cursor-pointer text-sm"
                                                                                        onClick={() => {
                                                                                            setAccountsPlans(accountsPlans.map(acc2 => {
                                                                                                if(acc2.group_code == group.group_code){
                                                                                                    acc2.inIncrement = false
                                                                                                } else {
                                                                                                    acc2.inIncrement = false
                                                                                                }
                                                                                                return acc2
                                                                                            }))
                                                                                            setSubSelectedCode('')
                                                                                            setSubSelectedName('')
                                                                                            setSubSelectedDescription('')
                                                                                            setSubSelectedOrigin('')
                                                                                        }}
                                                                                    />
                                                                                </button>
                                                                            </Tippy>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                : !loadingPulse ?
                                                                <a 
                                                                    className="flex flex-row text-sm gap-2 items-center text-primaryDefaultLight underline hover:brightness-50 cursor-pointer w-fit"
                                                                    onClick={() => {
                                                                        setSubSelectedCode('')
                                                                        setSubSelectedName('')
                                                                        setSubSelectedDescription('')
                                                                        setSubSelectedOrigin('')
                                                                        setAccountsPlans(accountsPlans.map(acc2 => {
                                                                            if(acc2.group_code == group.group_code){
                                                                                acc2.inIncrement = true
                                                                            } else {
                                                                                acc2.inIncrement = false
                                                                            }
                                                                            return acc2
                                                                        }))
                                                                    }}
                                                                >
                                                                    Adicionar conta sintética
                                                                </a>
                                                                :
                                                                <a className="flex flex-row gap-2 items-center text-primaryDefaultLight w-fit select-none">
                                                                    Cadastrando subgrupo, aguarde...
                                                                </a>
                                                            }
                                                        </div>                                           
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                <div className="w-full hidden lg:flex justify-start mt-3">
                                    <Button
                                        onClick={() => {setShowUniversalModal(true)}}
                                    >
                                        Criar grupo
                                    </Button>
                                </div>
                                <div className="flex lg:hidden text-center">
                                    <p>Esta funcionalidade está disponível apenas para desktop</p>
                                </div>
                            </>
                        }                        
                    </Body>
                }
            </Container>
        </>
    )
}