import React, { useEffect, useState } from 'react'
import randomColor from 'randomcolor';
import { useThemeContext } from '../../contexts/themeContext';
import ApexCharts from 'apexcharts'
import style from '../dashboard/styles/dashboard.module.css'
export function SliceGraph({ data, title }) {

    const { primaryDefaultLight, secondarrDefaultLight } = useThemeContext()
    const [objectColor, setObjectColor] = useState([])

    useEffect(() => {
        if (data) {

            const luminosityFill = document.getElementsByClassName('dark').length > 0 ? 'dark' : 'light'


            const dataObject = []
            for (let i = 0; i < data.length; i++) {

                if (i < 5) {
                    dataObject.push(data[i])
                }
            }

            let colors = []

            try {
                colors = randomColor({
                    count: dataObject.length,
                    hue: primaryDefaultLight,
                    luminosity: luminosityFill,
                });
            } catch (error) {

                colors = randomColor({
                    count: dataObject.length,
                    hue: '#FFF',
                    luminosity: luminosityFill,
                });
            }

            colors.map((color, index) => {
                dataObject[index].color = color
            })
            setObjectColor([...dataObject])

            var options = {
                stroke: {
                    show: false,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 0,
                    dashArray: 0,
                },

                colors: [colors[0], colors[1], colors[2], colors[3]],

                series: [300, 100, 230, 400,],
                labels: [data[0]?.name, data[1]?.name, data[2]?.name, data[3]?.name],
                chart: {

                    dropShadow: {
                        enabled: true,
                        top: 0,
                        left: 0,
                        blur: 3,
                        opacity: 0.5
                    },
                    type: 'donut',
                },
                responsive: [{
                    breakpoint: 400,
                    options: {
                        chart: {

                            width: 350
                        },

                        legend: {
                            show: true,
                            position: 'bottom',
                            horizontalAlign: 'center',
                            labels: {
                                colors: [`'${primaryDefaultLight}'`],
                                useSeriesColors: true
                            }

                        }
                    }
                }]
            };

            var chart = new ApexCharts(document.querySelector("#chartSlice"), options);
            chart.render();
        }
    }, [primaryDefaultLight, secondarrDefaultLight])

    return (
        <div id='SvgjsG1013' className={`sm:w-[400px] ${style.apexcharts} `}>
            <div id='chartSlice' className='stroke-transparent'>
                {/* <h2 className='mb-5 text-titleBlackTextLight dark:text-titleGrayTextDark'>{title}</h2>
            <canvas width="200" height="200"></canvas>
            <div className='grid grid-cols-2 mt-10 borderitems-center justify-center'>
                {
                    objectColor.map((thisData) => {

                        let color = thisData.color
                        return <div className='ml-4 flex flex-row gap-1 items-center justify-start'>
                            <div style={{ background: color }} className='h-4 w-4'></div>
                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{thisData.name}</p>
                        </div>
                    })
                }
            </div> */}
            </div>
        </div>
    )
}
