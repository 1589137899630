import React, { useEffect } from 'react'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { BarsGraph } from '../../../components/dashboard/bars'
import { SliceGraph } from '../../../components/dashboard/slice'
import { Navigate } from 'react-router-dom'

export function Dashboard() {

    const apiData = [
        { "name": "Jan", value: 10 },
        { "name": "Fev", value: 33 },
        { "name": "Mar", value: 90 },
        { "name": "Abr", value: 80 },
        { "name": "Mai", value: 120 },
        { "name": "Jun", value: 120 },
        { "name": "Jul", value: 140 },
        { "name": "Ago", value: 150 },
        { "name": "Set", value: 165 },
        { "name": "Out", value: '99' },
        { "name": "Nov", value: 190 },
        { "name": "Dez", value: 220 },
    ]

    const apiData2 = [
        { name: "Quiosques", total: 4, color: `#${Math.floor(Math.random() * 16777215).toString(16)}` },
        { name: "Quadras", total: 4, color: `#${Math.floor(Math.random() * 16777215).toString(16)}` },
        { name: "Boliche", total: 7, color: `#${Math.floor(Math.random() * 16777215).toString(16)}` },
        { name: "Salas", total: 10, color: `#${Math.floor(Math.random() * 16777215).toString(16)}` },
    ]

    useEffect(()=> {

     
    },[])

    return (
        <Container>
            <TitlePage>
                <Title text={'Dashboard'}></Title>
            </TitlePage>
            <Body background={false} padding={false}>
                <div className='flex flex-col-reverse md:flex-row w-full gap-3 items-start md:justify-center h-full'>
                    <div className='flex flex-col w-full md:w-[70%] gap-3 h-full'>
                        <div className=' w-full h-full p-8 bg-bgPrimaryLight dark:bg-secondaryDefaultDark border border-primaryBorder dark:border-primaryBorderDark rounded-lg'>
                            <h2 className='text-lg text-primaryDefaultLight dark:text-titleGrayTextDark font-bold mt-2 '>Olá usuário</h2>
                            <div className='grid grid-cols-3 mt-10'>
                                <div className='flex flex-col gap-2 w-full'>
                                    <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Total do mês</p>
                                    <p className='text-base text-secondaryDefaultLight font-semibold'>R$ 10.000,00</p>
                                </div>
                                <div className='flex flex-col gap-2 w-full'>
                                    <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Total do mês</p>
                                    <p className='text-base text-secondaryDefaultLight font-semibold'>R$ 10.000,00</p>
                                </div>
                                <div className='flex flex-col gap-2 w-full'>
                                    <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Total do mês</p>
                                    <p className='text-base text-secondaryDefaultLight font-semibold'>R$ 10.000,00</p>
                                </div>
                            </div>
                            <div className='grid grid-cols-3 mt-10'>
                                <div className='flex flex-col gap-2 w-full'>
                                    <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Total do mês</p>
                                    <p className='text-base text-secondaryDefaultLight font-semibold'>R$ 10.000,00</p>
                                </div>
                                <div className='flex flex-col gap-2 w-full'>
                                    <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Total do mês</p>
                                    <p className='text-base text-secondaryDefaultLight font-semibold'>R$ 10.000,00</p>
                                </div>
                                <div className='flex flex-col gap-2 w-full'>
                                    <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Total do mês</p>
                                    <p className='text-base text-secondaryDefaultLight font-semibold'>R$ 10.000,00</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex xl:flex-row flex-col gap-4 h-full'>
                            <div className='bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-8 w-full xl:w-[50%] border border-primaryBorder dark:border dark:border-primaryBorderDark rounded-lg'>
                                <BarsGraph
                                    title={'Análise anual'}
                                    data={apiData}
                                    bgGradient={true}
                                    bgGradientFirstColor={'primaryDefaultLight'}
                                    bgGradientSecondColor={'secondaryDefaultLight'}
                                />
                            </div>
                            <div className='overflow-hidden p-8 bg-bgPrimaryLight dark:bg-secondaryDefaultDark flex flex-col items-center lg:justify-center w-full xl:w-[50%] border border-primaryBorder dark:border dark:border-primaryBorderDark rounded-lg'>
                                <div className='animate-[wiggle_1s_ease-in-out]'>
                                    <SliceGraph title={'Locações por setor'} data={apiData2} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-bgPrimaryLight dark:bg-secondaryDefaultDark w-full md:w-[30%] h-full items-start justify-center border border-primaryBorder dark:border dark:border-primaryBorderDark rounded-lg'>
                        <div className='p-8'>
                            <h2 className='text-lg text-primaryDefaultLight font-bold mt-10'>Últimas atualizações</h2>
                        </div>
                        <div className='bg-bgPrimaryLight dark:bg-secondaryDefaultDark h-full p-8 max-h-80 overflow-x-hidden w-full flex flex-col items-start scrollbar-thin scrollbar-thumb-bgPrimaryLight scrollbar-track-zinc-300 dark:scrollbar-thumb-thirdDefaultDark dark:scrollbar-track-primaryDefaultDark overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full'>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>João reservou a quadra 10 as 09:11  - 20/04/2022</p>
                        </div>
                    </div>

                </div>


            </Body>
        </Container>
        // <Container>
        //     <TitlePage>
        //         <Title text={'Calendário'} />
        //     </TitlePage>
        //     <div className='flex flex-row gap-4 w-full bg-bgPrimaryLight'>
        //         <div className='w-72 flex flex-col items-start justify-start shadow-lg pt-2'>
        //             <CalendarMain onClick={(e) => console.log(e)} />
        //         </div>
        //         <div className='flex flex-col w-full pt-2'>
        //             <DayCalendar />
        //         </div>
        //     </div>
        // </Container>

    )
}