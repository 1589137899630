import { FaCog, FaHome, FaUsers, FaSitemap, FaBoxes, FaShoppingCart, FaClipboardCheck, FaCalendarAlt, FaTag, FaRegChartBar, FaChartLine } from 'react-icons/fa'

export const menuItemsLM = [
    {
        icon: <FaHome />,
        name: 'Home',
        type: 'normal',
        path: '/',
        permissions: [1, 2, 3],
    },
    {
        icon: <FaUsers />,
        name: 'Clientes/Fornecedores',
        type: 'normal',
        path: '/customersOfCustomer',
        permissions: ['customersOfCustomer:create', 'customersOfCustomer:read', 1],
    },
    {
        icon: <FaBoxes />,
        name: 'Produtos',
        type: 'normal',
        path: '/products',
        permissions: ['lm_products:create', 'lm_products:read', 1],
    },
    {
        icon: <FaShoppingCart />,
        name: 'Compras',
        type: 'normal',
        path: '/shopping',
        permissions: ['lm_shopping:create', 'lm_shopping:read', 1],
    },
    {
        icon: <FaClipboardCheck />,
        name: 'Ficha Técnica',
        type: 'normal',
        path: '/datasheet',
        permissions: ['lm_datasheet:create', 'lm_datasheet:read', 1],
    },
    {
        icon: <FaTag />,
        name: 'Vendas',
        type: 'normal',
        path: '/sales',
        permissions: ['lm_sales:create', 'lm_sales:read', 1],
    },
    {
        icon: <FaCalendarAlt />,
        name: 'Períodos de Apuração',
        type: 'normal',
        path: '/determinationPeriod',
        permissions: ['periods:create', 'periods:read', 1],
    },
    {
        icon: <FaChartLine />,
        name: 'Margem de Contribuição',
        type: 'normal',
        path: '/contributionMargin',
        permissions: ['lm_datasheet:read', 'lm_sales:read', 1],
    },
    {
        icon: <FaCog />,
        name: 'Ajustes',
        type: 'normal',
        path: '/configuration',
        permissions: [1, 2, 3],
    },
]
