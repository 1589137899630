import React from 'react'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'

export const Body = ({ children, padding, background, shadow, hasFooter, dontAnimate }) => {

    const { screenX, screenY } = useScreenSizeContext()
    const offSet = hasFooter && screenX < 640 ? 230 : hasFooter && screenX > 640 && screenX < 1920 ? 153 : 170
    const screenSize = screenY - offSet

    return (
        <div style={{ maxHeight: screenX > 640 ? screenX > 1024 ? hasFooter ? screenY - 200 + 'px' : screenY - 100 : screenY + 'px' : screenSize - 20 + 'px', minHeight: screenX < 1920 ? screenSize - 20 : screenSize }} className={`
        scrollbar-thin scrollbar-thumb-zinc-400 scrollbar-track-zinc-200 dark:scrollbar-thumb-thirdDefaultDark ${!dontAnimate ? 'sm:animate-[wiggleLeft_.8s_ease-in-out]' : ''}
        dark:scrollbar-track-primaryDefaultDark scrollbar-thumb-rounded-full scrollbar-track-rounded-full
        overflow-auto w-full flex flex-col items-center justify-start py-2 relative h-[100vh]`}>
            <div
                className={`rounded-lg flex ${shadow === false ? '' : 'shadow-lg'}                 
            flex-col w-[96%] 3xl:w-full gap-4 items-center dark:border dark:border-primaryBorderDark 
            ${background === undefined ? 'bg-bgPrimaryLight dark:bg-secondaryDefaultDark' : background} 
            ${padding || padding === undefined ? 'p-2 md:p-8' : ''} max-w-[1950px] absolute`}>
                {children}
            </div>
        </div>

    )
}