//hooks
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import { FaMinus, FaPlus, FaTrash, FaCheck, FaEdit } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
//contexts
import { useThemeContext } from "../../../contexts/themeContext"
import { useAuth } from "../../../contexts/useAuth"
import { useScreenSizeContext } from "../../../contexts/screenSizeContext";
//services
import { api } from "../../../services/api/api"
import { v4 as uuid } from 'uuid'
//utils
import { responseError } from "../../../utils/responsesFunctions/error.response"
import { validatorDatasheet } from '../../../utils/validators/validatorDatasheet';
import { justNumber } from '../../../utils/validators/justNumber';
import { typeProducts } from "../../../utils/typeProduct/typeProduct"
//components
import { Button } from "../../../components/buttons/button.default"
import { Container } from "../../../components/container/container"
import { DefaultLoader } from "../../../components/loaders/defaultLoader"
import { Title } from "../../../components/titlePages/title"
import { TitlePage } from "../../../components/titlePages/title.page"
import { Body } from "../../../components/container/Body"
import { LabelInput } from "../../../components/label/label.input"
import { Input } from "../../../components/input/input"
import { InputAutoComplete } from "../../../components/input/input.autocomplete"
//Chackra
import { Accordion, AccordionItem, AccordionButton, AccordionIcon, Box } from '@chakra-ui/react'
import moment from "moment";
import { ScreenModal } from "../../../components/modals/notification/screenModal";

const typeProductsList = typeProducts()

export function DataSheetEdit() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { id, reference } = useParams()
    const [loading, setLoading] = useState(true)
    const { screenX } = useScreenSizeContext()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    const [dataSheetData, setDatasheetData] = useState([])
    const [dataSheetDataOriginal, setDatasheetDataOriginal] = useState([])
    const [dataSheetCompositions, setDatasheetCompositions] = useState([])
    // criação de composições (uuid)
    const [childsDatasheetList, setChildDatasheetList] = useState([])
    const [childDatasheetIdList, setChildDatasheetIdList] = useState([])
    const [productsData, setProductsData] = useState([])
    // excluir composição
    const [cancelDelete] = useState(true)
    // confirmação de envio em modal
    const [confirmationModal, setConfirmationModal] = useState('')

    useEffect(() => {
        getData()
    }, [id])

    async function getData(){
        try {
            const productsDataApi = await api.get('/api/v1/lm/products?active=true', {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setProductsData(productsDataApi?.data?.data?.map(prod => {
                return {
                    ...prod,
                    descriptionInput: `${prod?.code} - ${prod?.description} - ${prod?.typeProduct}`
                }
            }))
    
            const datasheetApi = await api.get(`/api/v1/lm/datasheet/${id}${reference ? `?referenceDate=${reference}` : ''}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setDatasheetCompositions(datasheetApi?.data?.data?.composition.map((item) => ({...item, showOptionsDelete: false})))
            setDatasheetData(datasheetApi?.data?.data)
            setDatasheetDataOriginal(datasheetApi?.data?.data)
            setLoading(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleConfirmAction() {
        let quantity // variavel para lógica de se há quantidade

        dataSheetCompositions.map((item) => {
            if (item.quantity == 0) {
                quantity = item.quantity
            }
        })

        const formatListChilds = childDatasheetIdList?.map(key => {
            return {
                products_id: childsDatasheetList[key]?.products_id ? childsDatasheetList[key]?.products_id : undefined,
                quantity: Number(childsDatasheetList[key]?.quantity)
            }
        })
      
        dataSheetCompositions.map(comp => {
            formatListChilds.push({
                products_id: comp.products_id,
                quantity: comp.quantity
            })
        })

        if (Object.keys(childsDatasheetList).length > 0) {
            if (validatorDatasheet(childsDatasheetList).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha os campos em vermelho')

            }
        }

        if (quantity == 0) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('É necessário haver quantidades para as composições.')
        } else {
            setConfirmationModal(dataSheetData.startDate == dataSheetDataOriginal.startDate ? 'update' : 'new')
            setShowUniversalModal(true)
        }
    }

    async function handleSubmit(){
        setShowUniversalModal(false)
        setConfirmationModal('')
        setLoading(true)
        try {
            const formatListChilds = childDatasheetIdList?.map(key => {
                return {
                    products_id: childsDatasheetList[key]?.products_id ? childsDatasheetList[key]?.products_id : undefined,
                    quantity: Number(childsDatasheetList[key]?.quantity)?.toFixed(5)
                }
            })
            dataSheetCompositions.map(comp => {
                formatListChilds.push({
                    products_id: comp.products_id,
                    quantity: Number(comp.quantity)?.toFixed(5)
                })
            })

            await api.post(`/api/v1/lm/datasheet`, {
                products_id: dataSheetData?.products_id,
                observation: dataSheetData?.observation ? dataSheetData?.observation : undefined,
                composition: formatListChilds,
                startDate: dataSheetData?.startDate
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
        
            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            setLoading(true)
            navigate('/datasheet')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDelete(compositionsId) {
        if(dataSheetCompositions.length == 1) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Deve haver no mínimo uma composição para a ficha técnica.')
            }
        else {
            setDatasheetCompositions(dataSheetCompositions.filter((item) => {
                return item?.products_id !== compositionsId?.products_id
            }))
        }
    }

    function addChildDatasheet() {

        const id = uuid()
        let add

        add = Object.assign(childsDatasheetList, {
            [id]: {
                products_id: null,
                linked_datasheet_id: null,
                quantity: null
            }
        })

        setChildDatasheetList(add)
        setChildDatasheetIdList([...childDatasheetIdList, id])
    }

    async function removeChildDatasheet(id) {
        await new Promise(resolve => setTimeout(resolve, 50))

        const replicaChildDatasheet = structuredClone(childsDatasheetList)
        const replicaChildDatasheetList = structuredClone(childDatasheetIdList)

        const position = childDatasheetIdList.indexOf(id)
        replicaChildDatasheetList.splice(position, 1)

        delete replicaChildDatasheet[id]

        setChildDatasheetList(replicaChildDatasheet)
        setChildDatasheetIdList([...replicaChildDatasheetList])
    }
    
    return (
        <>
            {
                confirmationModal == 'new' &&
                <ScreenModal title={'Alteração de Ficha Técnica'}>
                    <div className='w-full p-4 flex flex-col items-center justify-center'>
                        <p className='text-base text-titleBlackTextLight'>A partir da data de criação que você informou, os valores desse produto serão calculados com base na composição desta nova ficha técnica. Deseja realmente prosseguir?</p>
                        <div className='flex flex-row gap-2 mt-10'>
                            <Button shadow={true} onClick={() => handleSubmit()}>Sim</Button>
                            <Button shadow={true} approval={false} onClick={() => {
                                setShowUniversalModal(false); 
                                setConfirmationModal('')
                            }}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
             {
                confirmationModal == 'update' &&
                <ScreenModal title={'Alteração de Ficha Técnica'}>
                    <div className='w-full p-4 flex flex-col items-center justify-center'>
                        <p className='text-base text-titleBlackTextLight'>A data de criação não foi alterada, a versão da ficha técnica será mantida e os valores do produto serão alterados a partir desta data. Deseja realmente prosseguir?</p>
                        <div className='flex flex-row gap-2 mt-10'>
                            <Button shadow={true} onClick={() => handleSubmit()}>Sim</Button>
                            <Button shadow={true} approval={false} onClick={() => {
                                setShowUniversalModal(false); 
                                setConfirmationModal('')
                            }}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Edição de Ficha Técnica`} />
                    </div>
                </TitlePage>
                {
                    <Body>
                        {
                            loading ?
                            <DefaultLoader />
                            :
                            <div className="w-full">
                                <form className='w-full flex flex-row flex-wrap justify-start items-start gap-2'>
                                    <LabelInput text={'Tipo de produto'} required={true}>
                                        <Input
                                            type={'string'}
                                            width={'150px'}
                                            value={typeProductsList.filter(type => type?.typeProduct == dataSheetData?.type)[0]?.name}
                                            onChange={(e) => { }}
                                            disabled={true}
                                            id='productsMasterCode'
                                        />
                                    </LabelInput>
                                    <div className="sm:flex flex-row hidden">
                                        <LabelInput text={'Produto'} required={true}>
                                            <p className="w-[30rem] md:w-[27rem] lg:w-[45rem] h-8 px-1 border border-gray-400 placeholder-white border-opacity-75 rounded-sm outline-none transition-all duration-200
                                                dark:text-titleGrayTextDark dark:border-secondaryBorderDark bg-gray-300 dark:bg-thirdDefaultDark flex flex-row items-center"
                                            >
                                                {dataSheetData?.description ? dataSheetData?.description : ''}
                                            </p>
                                        </LabelInput>
                                    </div>
                                    <div className="flex flex-row sm:hidden">
                                        <LabelInput text={'Produto'} required={true}>
                                            <Input
                                                type={'string'}
                                                width={80}
                                                value={dataSheetData?.description ? dataSheetData?.description : ''}
                                                onChange={(e) => { }}
                                                disabled={true}
                                                id='productMaster'
                                            />
                                        </LabelInput>
                                    </div>
                                    <LabelInput text={'Observação'} required={false}>
                                        <Input
                                            type={'string'}
                                            width={80}
                                            value={dataSheetData?.observation ? dataSheetData?.observation : ''}
                                            id='observation'
                                            onChange={(e) => setDatasheetData((prev) => ({...prev, observation: e.target.value}))}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Data criação'} required={false}>
                                        <Input
                                            type={'date'}
                                            width={'120px'}
                                            value={dataSheetData?.startDate ? moment(dataSheetData?.startDate).format('YYYY-MM-DD') : ''}
                                            id='startDate'
                                            onChange={(e) => setDatasheetData((prev) => ({...prev, startDate: e.target.value}))}
                                        />
                                    </LabelInput>
                                    <div className="absolute right-3 top-1 flex flex-row text-md text-md font-light gap-3">
                                        <p className="ml-1 text-sm">Total:</p>
                                        <p className="text-sm">{Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency'}).format(dataSheetData?.cost ? dataSheetData?.cost : 0)}</p>
                                    </div>
                                </form>
                                <div className="my-[30px] p-[5px] rounded-md border border-gray-400 bg-gray-50 pb-3 w-full">
                                    <div className="my-2 ml-2">
                                        Composições
                                    </div>
                                    {
                                        dataSheetCompositions.map(compositions => {
                                        if(compositions.hasDatasheet){
                                            return (
                                                <div className="mx-10 mt-2 overflow-auto flex">
                                                    <Composition 
                                                        datasheet_id={compositions.hasDatasheet} 
                                                        description={compositions.description} 
                                                        type={compositions.type}
                                                        cost={compositions?.cost}
                                                        quantity={compositions.quantity}
                                                        quantityReturn={(e)=> {
                                                            setDatasheetCompositions(dataSheetCompositions.map(comp => {
                                                                if(comp.products_id == compositions.products_id){
                                                                    return { 
                                                                        ...comp,
                                                                        quantity: e
                                                                    }
                                                                }
                                                                return comp
                                                            }))
                                                        }}
                                                        disabled={false}
                                                    />
                                                    <div className={`flex flex-row justify-start lg:justify-end xl:justify-start items-start gap-1 text-xs pt-5 md:ml-3 xl:ml-0`}>
                                                        <div className="flex flex-col mr-1 ml-2">
                                                            <p>Custo:</p>
                                                            <p>{Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency'}).format((compositions?.cost * compositions?.quantity)?.toFixed(5))}</p>
                                                        </div>
                                                        <div className="pt-3">
                                                            <Tippy
                                                                content={<span>Excluir</span>}
                                                                arrow={true}
                                                                animation='perspective'
                                                                placement="top"
                                                                delay={100}>
                                                                <div>
                                                                    <span>{cancelDelete && !compositions.showOptionsDelete && <FaTrash 
                                                                        onClick={() => setDatasheetCompositions(dataSheetCompositions.map(comp => {
                                                                            if(comp.products_id == compositions.products_id){
                                                                                return { 
                                                                                    ...comp,
                                                                                    showOptionsDelete: true
                                                                                }
                                                                            }
                                                                            return comp
                                                                        }))}
                                                                        className="mb-1 text-sm text-red-800 hover:cursor-pointer"/>}
                                                                    </span>
                                                                </div>
                                                            </Tippy>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            compositions.showOptionsDelete && cancelDelete && 
                                                            <div className={`h-full pt-8 ml-1`}>
                                                                <div className="flex gap-2">
                                                                    <Tippy
                                                                        content={<span>Confirmar</span>}
                                                                        arrow={true}
                                                                        animation='perspective'
                                                                        placement='top'
                                                                        delay={100}>
                                                                        <div><span onClick={() => handleDelete(compositions)} className="text-blue-800 hover:cursor-pointer"><FaCheck /></span></div>
                                                                    </Tippy>
                                                                    <Tippy
                                                                        content={<span>Cancelar</span>}
                                                                        arrow={true}
                                                                        animation='perspective'
                                                                        placement='top'
                                                                        delay={100}>
                                                                        <div>
                                                                            <span 
                                                                                onClick={() => setDatasheetCompositions(dataSheetCompositions.map(comp => {
                                                                                    if(comp.id == compositions.id){
                                                                                        return { 
                                                                                            ...comp,
                                                                                            showOptionsDelete: false
                                                                                        }
                                                                                    }
                                                                                    return comp
                                                                                }))}
                                                                                className="text-lg text-red-800 hover:cursor-pointer"><FaXmark />
                                                                            </span>
                                                                        </div>
                                                                    </Tippy>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return (    
                                            <div className="flex flex-col lg:flex lg:flex-row gap-2 ml-10 mt-2 overflow-auto lg:overflow-hidden">
                                                <LabelInput text={'Tipo de produto'} required={true}>
                                                    <Input
                                                        type={'string'}
                                                        width={'165px'}
                                                        value={typeProductsList.filter(type => type.typeProduct == productsData.filter(item => item.id == compositions?.products_id)[0]?.typeProduct)[0]?.name}
                                                        onChange={(e) => { }}
                                                        disabled={true}
                                                        id=''
                                                    />
                                                </LabelInput>
                                                <div className="sm:flex flex-row hidden">
                                                    <LabelInput text={'Produto'} required={true}>
                                                        <p className="w-[30rem] md:w-[27rem] lg:w-[30rem] 2xl:w-[45rem] h-8 px-1 border border-gray-400 placeholder-white border-opacity-75 rounded-sm outline-none transition-all duration-200
                                                            dark:text-titleGrayTextDark dark:border-secondaryBorderDark bg-gray-300 dark:bg-thirdDefaultDark flex flex-row items-center"
                                                        >
                                                            {compositions?.description ? compositions?.description : ''}
                                                        </p>
                                                    </LabelInput>
                                                </div>
                                                <div className="flex flex-row sm:hidden">
                                                    <LabelInput text={`Produto`} required={true}>
                                                        <Input
                                                            type={'string'}
                                                            width={80}
                                                            value={productsData.filter(item => item.id == compositions?.products_id)[0].description}
                                                            onChange={(e) => { }}
                                                            disabled={true}
                                                            id=''
                                                        />
                                                    </LabelInput>
                                                </div>
                                                <LabelInput text={'Quantidade'} required={false} >
                                                    <Input
                                                        type={'number'}
                                                        width={20}
                                                        value={compositions?.quantity ? compositions?.quantity : ''}
                                                        onChange={(e) => 
                                                        setDatasheetCompositions(dataSheetCompositions.map(comp => {
                                                            if(comp.products_id == compositions.products_id){
                                                                return { 
                                                                    ...comp,
                                                                    quantity: e.target.value
                                                                }
                                                            }
                                                            return comp
                                                        }))}
                                                        charLimit={12}
                                                        id='noQuantity'
                                                    />
                                                </LabelInput>
                                                <div className="flex flex-col justify-end text-xs">
                                                    <p>Custo:</p>
                                                    <p>{Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency'}).format((compositions?.cost * compositions?.quantity)?.toFixed(5))}</p>
                                                </div>
                                                <div className="flex flex-col justify-center">
                                                    <Tippy
                                                        content={<span>Excluir</span>}
                                                        arrow={true}
                                                        animation='perspective'
                                                        placement='right'
                                                        className="lg:mt-[35px]"
                                                        delay={100}>
                                                        <div>
                                                            <span>
                                                                {
                                                                    cancelDelete && !compositions.showOptionsDelete && 
                                                                    <FaTrash 
                                                                        onClick={() => setDatasheetCompositions(dataSheetCompositions.map(comp => {
                                                                            if(comp.products_id == compositions.products_id){
                                                                                return { 
                                                                                    ...comp,
                                                                                    showOptionsDelete: true
                                                                                }
                                                                            }
                                                                            return comp
                                                                        }))}
                                                                        className="lg:mt-[35px] text-red-800 hover:cursor-pointer text-sm"
                                                                    />
                                                                }
                                                            </span>
                                                        </div>
                                                    </Tippy>
                                                    <div>
                                                        {
                                                            compositions.showOptionsDelete && cancelDelete && 
                                                            <div className="flex gap-3 justify-start lg:justify-end items-end h-5 lg:h-12 ml-2">
                                                                <Tippy
                                                                    content={<span>Confirmar</span>}
                                                                    arrow={true}
                                                                    animation='perspective'
                                                                    placement='top'
                                                                    delay={100}>
                                                                    <div><span onClick={() => handleDelete(compositions)} className="text-blue-800 hover:cursor-pointer"><FaCheck className="text-sm"/></span></div>
                                                                </Tippy>
                                                                <Tippy
                                                                    content={<span>Cancelar</span>}
                                                                    arrow={true}
                                                                    animation='perspective'
                                                                    placement='top'
                                                                    delay={100}>
                                                                    <div>
                                                                        <span 
                                                                            onClick={() => setDatasheetCompositions(dataSheetCompositions.map(comp => {
                                                                                if(comp.products_id == compositions.products_id){
                                                                                    return { 
                                                                                        ...comp,
                                                                                        showOptionsDelete: false
                                                                                    }
                                                                                }
                                                                                return comp
                                                                            }))}
                                                                            className="text-sm text-red-800 hover:cursor-pointer"><FaXmark className="text-sm"/>
                                                                        </span>
                                                                    </div>
                                                                </Tippy>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>  
                                            )
                                        })  
                                    }
                                    <div>
                                        {
                                            childDatasheetIdList == 0 ?
                                            <div className='w-full flex flex-row my-2'>
                                                <a onClick={() => addChildDatasheet()} className='flex items-center gap-2 underline p-2 hover:text-primaryDefaultLight text-blue-500/90 hover:cursor-pointer text-sm'>+ Compor</a>
                                            </div>
                                            :
                                            <div>
                                                <p className='text-blue-500/90 mt-5 mb-5 text-sm'>Adicione-as clicando em "Salvar".</p>
                                                <table className={`w-fit mt-5 flex flex-col justify-start items-start rounded-md border border-gray-400 bg-gray-50`}>
                                                    <thead className='flex flex-row gap-4 sticky -top-2 z-[21] bg-blue-50 items-start justify-start w-full rounded-md'>
                                                        <tr className='p-1 text-gray-700'>
                                                            <th className='w-52 font-medium'>Tipo de produto</th>
                                                            <th className={`font-medium ${screenX > 1536 ? 'w-[45rem]' : screenX > 1024 && screenX < 1280 ? 'w-[20rem]' : screenX < 1024 ? false : 'w-[30rem]'}`}>Produto</th>
                                                            <th className='w-20 font-medium translate-x-4'>Quantidade</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='p-1 pb-4'>
                                                        {
                                                            childDatasheetIdList.map(child => {
                                                                return (
                                                                    <tr className={`lg:flex lg:flex-row gap-2 justify-start items-center px-2`}>
                                                                        <LabelInput>
                                                                            <InputAutoComplete
                                                                                data={dataSheetData?.type == 'PI' ? typeProductsList?.filter(type => type?.typeProduct != 'PA') : dataSheetData?.type == 'PA' ? typeProductsList?.filter(type => type?.typeProduct != 'PA') : typeProductsList}
                                                                                selectedLabel={'name'}
                                                                                optionList={['name']}
                                                                                type={'string'}
                                                                                width={52}
                                                                                value={typeProductsList.filter(type => type.codeProduct == childsDatasheetList[child]?.typeProducts)[0]?.name}
                                                                                onChange={(e) => {
                                                                                    childsDatasheetList[child].typeProducts = e?.codeProduct
                                                                                    childsDatasheetList[child].products_id = ''
                                                                                    setChildDatasheetList(childsDatasheetList)
                                                                                    setChildDatasheetIdList([...childDatasheetIdList])
                                                                                }}
                                                                                id={`${child} - typeProduct`}
                                                                            />
                                                                        </LabelInput>
                                                                        <LabelInput>
                                                                            <InputAutoComplete
                                                                                data={productsData?.filter(type => type.typeProduct == typeProductsList.filter(type => childsDatasheetList[child]?.typeProducts == type.codeProduct)[0]?.typeProduct && type?.id !== dataSheetData?.products_id)}
                                                                                selectedLabel={'descriptionInput'}
                                                                                optionList={['code', 'description']}
                                                                                type={'string'}
                                                                                widthForce={screenX > 1536 ? 'w-[45rem]' : screenX > 1024 && screenX < 1280 ? 'w-[20rem]' : screenX < 1024 ? false : 'w-[30rem]'}
                                                                                value={childsDatasheetList[child].products_id ? productsData.filter(prod => prod.id == childsDatasheetList[child].products_id)[0]?.descriptionInput : ''}
                                                                                preSelectedValue={childsDatasheetList[child].products_id ? productsData.filter(prod => prod.id == childsDatasheetList[child].products_id)[0]?.descriptionInput : ''}
                                                                                onChange={(e) => {
                                                                                    childsDatasheetList[child].products_id = e?.id
                                                                                    setChildDatasheetList(childsDatasheetList)
                                                                                    setChildDatasheetIdList([...childDatasheetIdList])
                                                                                }}
                                                                                disabled={typeProductsList.filter(type => type.codeProduct == childsDatasheetList[child]?.typeProducts)[0]?.name ? false : true}
                                                                                id={`${child} - products_id`}
                                                                            />
                                                                        </LabelInput>
                                                                        <LabelInput>
                                                                            <Input
                                                                                type={'number'}
                                                                                value={childsDatasheetList[child].quantity ? childsDatasheetList[child].quantity : ''}
                                                                                width={20}
                                                                                onChange={(e) => {
                                                                                    childsDatasheetList[child].quantity = e.target.value
                                                                                    setChildDatasheetList(childsDatasheetList)
                                                                                    setChildDatasheetIdList([...childDatasheetIdList])
                                                                                }}
                                                                                id={`${child} - quantity`}
                                                                            />
                                                                        </LabelInput>
                                                                        <div className='flex flex-row gap-3 justify-center items-center ml-3 hover:cursor-pointer text-md text-blue-600'>
                                                                            <FaMinus className="text-sm" onClick={() => { removeChildDatasheet(child) }} />
                                                                            <FaPlus className="text-sm" onClick={() => addChildDatasheet()} />
                                                                        </div>
                                                                    </tr>                                                                    
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <p className="text-xs 2xl:text-sm font-semibold">* É necessário confirmar suas alterações clicando em "<span className="text-blue-500">Salvar</span>".</p>
                            </div>
                        }
                        <div className={`w-full flex justify-between ${loading && 'hidden'}`}>
                            <Button onClick={() => navigate(-1)} shadow={true} approval={false}>
                                Voltar
                            </Button>
                            {
                                (userData[0]?.permissions?.indexOf('lm_datasheet:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                <Button shadow={true} onClick={() => handleConfirmAction()}>
                                    Salvar
                                </Button>
                            }
                        </div>
                    </Body>
                }
            </Container>
        </>
    )
}

function Composition({ description, datasheet_id, type, quantity, disabled, quantityReturn, cost }){

    const navigate = useNavigate()
    
    const [data, setData] = useState(null)
    const [expanded, setExpanded] = useState(false)
    const [loading, setLoading] = useState(false)
    const [quantityComposition, setQuantityComposition] = useState(quantity)
    const { userData } = useAuth()
    const {reference} = useParams()

    useEffect(() => {
        const fetchData = async () => {
            
            setLoading(true)
            try {
                const response = await api.get(`/api/v1/lm/datasheet/${datasheet_id}${reference ? `?referenceDate=${reference}` : ''}`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                
                setData(response.data.data.composition)
                setLoading(false)
            } catch (error) {
                console.error("Erro ao buscar dados da API:", error)
                setLoading(false)
            }
        }
    
        if (expanded && !data) {
            fetchData()
        }
    }, [expanded, data, datasheet_id])
    
    return (
        <Accordion allowMultiple>
        <AccordionItem>
            <h2>
                <Box as="span" gap={8} textAlign='left' className="flex flex-col lg:flex lg:flex-row">
                    <LabelInput text={'Tipo de produto'} required={true}>
                        <Input
                            type={'string'}
                            width={'165px'}
                            value={typeProductsList.filter(t => t?.typeProduct == type)[0]?.name}
                            onChange={(e) => { }}
                            disabled={true}
                            id=''
                        />
                    </LabelInput>
                    <div className="sm:flex flex-row hidden">
                        <LabelInput text={'Produto'} required={true}>
                            <p className="w-[30rem] md:w-[27rem] lg:w-[30rem] 2xl:w-[45rem] h-8 px-1 border border-gray-400 placeholder-white border-opacity-75 rounded-sm outline-none transition-all duration-200
                                dark:text-titleGrayTextDark dark:border-secondaryBorderDark bg-gray-300 dark:bg-thirdDefaultDark flex flex-row items-center"
                            >
                                {description}
                            </p>
                        </LabelInput>
                    </div>
                    <div className="flex flex-row sm:hidden">
                        <LabelInput text={`Produto`} required={true}>
                            <Input
                                type={'string'}
                                width={80}
                                value={description}
                                onChange={(e) => { }}
                                disabled={true}
                                id=''
                            />
                        </LabelInput>
                    </div>
                    <LabelInput text={'Quantidade'} required={false} >
                        <Input
                            type={'number'}
                            width={20}
                            value={quantityComposition}
                            onChange={(e) => { setQuantityComposition(e.target.value); quantityReturn(e.target.value) }}
                            disabled={disabled}
                            id='noDatasheetQuantity'
                        />
                    </LabelInput>
                    <AccordionButton onClick={() => setExpanded(!expanded)}>
                        <AccordionIcon className={"lg:mt-[25px] text-lg border rounded-full border-primaryDefaultLight bg-blue-300"} />
                    </AccordionButton>
                </Box>
            </h2>
        </AccordionItem>
            {   
                loading ? 
                <p className="text-blue-500 text-sm animate-pulse mt-2 ml-2">Buscando...</p>
                : expanded && data && (
                <div className="border-l border-gray-400">
                    {
                        data.map((compositions) => {
                            if(compositions.hasDatasheet){
                                return <>
                                    <div className={`absolute ml-[10px] mt-[35px] hover:cursor-pointer`}>
                                            <Tippy
                                                content={<span>Editar</span>}
                                                arrow={true}
                                                animation='perspective'
                                                placement='top'
                                                delay={100}>
                                                <div onClick={() =>  { navigate(`/datasheetEdit/${datasheet_id}`); window.location.reload(true) }}>
                                                    <span className="text-blue-500"><FaEdit className="text-sm"/></span>
                                                </div>
                                            </Tippy>
                                    </div>
                                    <div className="ml-10 mt-2 flex">
                                        <Composition 
                                            description={compositions.description} 
                                            type={compositions.type}
                                            cost={compositions.cost}
                                            quantity={compositions.quantity}
                                            datasheet_id={compositions.hasDatasheet}
                                            disabled={true}
                                        />
                                    </div>
                                </>
                            }
                            return (
                                <>
                                    <div className={`absolute ml-[10px] mt-[35px] hover:cursor-pointer`}>
                                            <Tippy
                                                content={<span>Editar</span>}
                                                arrow={true}
                                                animation='perspective'
                                                placement='top'
                                                delay={100}>
                                                <div onClick={() =>  { navigate(`/datasheetEdit/${datasheet_id}`); }}>
                                                    <span className="text-blue-500"><FaEdit className="text-sm"/></span>
                                                </div>
                                            </Tippy>
                                    </div>
                                    <div className="ml-10 flex flex-col lg:flex lg:flex-row gap-2 mt-2">
                                        <LabelInput text={'Tipo de produto'} required={true}>
                                            <Input
                                                type={'string'}
                                                width={'165px'}
                                                value={typeProductsList.filter(type => type?.typeProduct == compositions?.type)[0]?.name}
                                                onChange={(e) => { }}
                                                disabled={true}
                                                id=''
                                            />
                                        </LabelInput>
                                        <div className="sm:flex flex-row hidden">
                                            <LabelInput text={`Produto * (Custo: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(compositions.cost)})`}>
                                                <p className="w-[30rem] md:w-[27rem] lg:w-[32rem] 2xl:w-[43rem] h-8 px-1 border border-gray-400 placeholder-white border-opacity-75 rounded-sm outline-none transition-all duration-200
                                                    dark:text-titleGrayTextDark dark:border-secondaryBorderDark bg-gray-300 dark:bg-thirdDefaultDark flex flex-row items-center"
                                                >
                                                    {compositions?.description ? compositions?.description : ''}
                                                </p>
                                            </LabelInput>
                                        </div>
                                        <div className="flex flex-row sm:hidden">
                                            <LabelInput text={`Produto`} required={true} >
                                                <Input
                                                    type={'string'}
                                                    width={80}
                                                    value={compositions?.description ? compositions?.description : ''}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                    id=''
                                                />
                                            </LabelInput>
                                        </div>
                                        <LabelInput text={'Quantidade'} required={false} >
                                            <Input
                                                type={'number'}
                                                width={20}
                                                value={compositions?.quantity ? compositions?.quantity : ''}
                                                onChange={(e) => { }}
                                                disabled={true}
                                                id=''
                                            />
                                        </LabelInput>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
                )
            }
        </Accordion>
    )
}
