import { responseError } from "../../../utils/responsesFunctions/error.response"
import { api } from "../api"
import moment from "moment"

function formattTimeToShow(apiTime) {

    let zPos = apiTime.indexOf('Z')
    let offset = moment().utcOffset()
    let time = apiTime
    time = apiTime.slice(0, zPos)
    time = time.replace('T', ' ')
    let localTime = moment.utc(time).utcOffset(offset * 2).format("L LT");
    return localTime
}

//isAuth token
export async function isAuth(tokenKey) {

    try {
        const isAuthData = await api.get('/auth/isAuth', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            statusCode: isAuthData.data.status,
            data: isAuthData.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                statusCode: error.response.data.statusCode,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                statusCode: error.response.data.statusCode,
                data: 'Erro inesperado'
            }
        }
    }

}

//get customers
export async function getCustomers(tokenKey) {

    try {
        const customersData = await api.get(`/api/v1/customers`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: customersData.data.data[0]
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//typeAccess get
export async function getTypeAccess(tokenKey) {

    try {
        const typeAccessData = await api.get('/api/v1/typeAccess', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: typeAccessData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get users
export async function getUsers(tokenKey) {

    try {
        const userData = await api.get(`/api/v1/users`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        userData.data.data.map((apiData, index) => {
            userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
            userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)

        })
        let newData = userData.data.data
        return {
            error: false,
            data: newData
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//user especific
export async function getUserEspecific(tokenKey, userId) {

    try {
        const userData = await api.get(`/api/v1/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: userData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//user client especific
export async function getUserClientEspecific(tokenKey, userId) {

    try {
        const userData = await api.get(`/api/v1/usersClient/${userId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: userData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//my profile
export async function getMyProfile(tokenKey) {

    try {
        const userData = await api.get(`/api/v1/myProfile`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: userData?.data?.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//user permissions get
export async function getPermissions(tokenKey) {

    try {
        const permissionsData = await api.get('/api/v1/permissions', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: permissionsData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//group permissions especific get
export async function getPermissionsEspecific(tokenKey, id) {

    try {
        const permissionsData = await api.get(`/api/v1/permissionGroups/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: permissionsData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//permissionGroups get
export async function getPermissionGroups(tokenKey) {

    try {
        const permissionGroupsData = await api.get('/api/v1/permissionGroups', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        let formattedPermission = []
        permissionGroupsData.data.data.map((apiData) => {
            formattedPermission.push(
                {
                    created_at: formattTimeToShow(apiData.created_at),
                    original_create_at: apiData.created_at,
                    customers_id: apiData.customers_id,
                    description: apiData.description,
                    id: apiData.id,
                    name: apiData.name
                }
            )
        })
        return {
            error: false,
            data: formattedPermission
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//permissionGroups especific get
export async function getPermissionGroupEspecific(tokenKey, id) {

    try {
        const permissionGroupData = await api.get(`/api/v1/permissionGroups/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        
        return {
            error: false,
            data: permissionGroupData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//customer linked to get user 
export async function getCustomerLinkToUser(tokenKey, users_id){

    try {
        const userData = await api.get(`/api/v1/customersUsersLink/user/${users_id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: userData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }

}

