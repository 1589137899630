import React, { useEffect, useState } from 'react'
import moment from 'moment/locale/pt-br'
import { FaArrowDown, FaArrowUp, FaArrowsAltV, FaQuestion, FaSearch } from 'react-icons/fa'
import { RiArrowUpDownLine } from 'react-icons/ri'
//components
import { Tooltip } from '../toolTip/toolTip'
export function TableDefault({ id, title, data, collumns, onClick, width, customFunctions, cursorPointer, fieldFilter, removeFilter, datasheetMark, descriptionHeader }) {

    const [sortBy, setSortBy] = useState('')
    const [sortTo, setSortTo] = useState(true)
    const [tableData, setTableData] = useState(data)
    const [startFilter, setStartFilter] = useState(false)
    const [filteredValue, setFilteredValue] = useState(null)

    function sortTable(sortBy) {

        let tableToSort = tableData
        let sortedArray = sortTo ? tableToSort.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : -1) : tableToSort.sort((a, b) => (b[sortBy] > a[sortBy]) ? 1 : -1)

        setTableData([...sortedArray])
        filterTable()

    }

    useEffect(() => {
        setTableData([...data])
        filterTable()

    }, [data])

    function filterTable() {

        let filterHasSpace = filteredValue?.indexOf(' ')
        let filterToSearch = filteredValue
        let filteToSearArray = []

        if (filterHasSpace > - 1) {
            while (filterHasSpace !== -1) {
                filteToSearArray.push(filterToSearch.slice(0, filterHasSpace + 1))
                filterToSearch = filterToSearch.replace(filterToSearch.slice(0, filterHasSpace + 1), '')
                filterHasSpace = filterToSearch?.indexOf(' ')
                if (filterHasSpace === - 1 && filterToSearch?.length > 0) {
                    filteToSearArray.push(filterToSearch)
                }
            }
        } else if (filterHasSpace === - 1) {
            filteToSearArray.push(filterToSearch)
        }
        if (tableData?.length === 0 || filteToSearArray?.length > 0) {

            const filter = tableData?.filter((dataOfTable, index) => {
                let collumNames = Object.keys(dataOfTable)

                const filterWords = filteToSearArray.filter((wordToCheck) => {

                    let wordFinded = false

                    const collumnFilter = collumNames.filter((wordInCollumn) => {
                        let wordToSearch = wordToCheck.toString()?.toLowerCase().replace(' ', '')
                        if (!wordFinded && tableData[index][wordInCollumn]?.toString()?.toLowerCase().indexOf(wordToSearch) > -1) {
                            wordFinded = true
                            return wordInCollumn
                        }
                    })

                    if (collumnFilter.length > 0) {
                        return wordToCheck
                    }
                })
                if (filterWords?.length > 0 && (filterWords?.length === filteToSearArray?.length)) {
                    return dataOfTable
                }
            })
            if (filter?.length > 0) {
                return filter
            } else {
                return tableData
            }
        }
    }

    useEffect(() => {

        if (startFilter) {
            filterTable()
        }

    }, [startFilter])

    return (
        <div class='overflow-x w-full overflow-y-auto dark:border dark:border-secondaryBorderDark rounded-md'>

            <table className='border-collapse table-auto overflow-y-auto w-full ' id={id}>
                <thead>
                    <tr className=''>
                        <th colSpan={title?.length} className='bg-primaryDefaultLight text-sm dark:bg-secondaryDefaultLight text-white font-extrabold h-10 top-0 sticky'>
                            {
                                fieldFilter ?
                                <></>
                                :
                                <div className='flex flex-row gap-2 items-center justify-start pl-6 pt-4 pb-1 h-full '>
                                    {
                                        removeFilter ? 
                                        <>
                                            <h2 className='pb-2'>{descriptionHeader ? descriptionHeader :  'Fichas Técnicas'}</h2>
                                        </>
                                        :
                                        <>
                                            <input onKeyDown={(e) => {
                                                if (e.code === 'Enter') {
                                                    setStartFilter(true)
                                                }
                                            }} type='text' placeholder='Filtrar' onChange={(e) => { if (e.target.value?.length === 0) { setStartFilter(false) }; setFilteredValue(e.target.value) }} className='px-2 py-1 outline-none bg-primaryDefaultLight brightness-90 dark:brightness-75 dark:bg-thirdDefaultDark placeholder:text-white'></input>
                                            <div title='Pesquisar' tabIndex={0} onKeyDown={(e) => { if (filteredValue?.length > 0 && e.code === 'Enter') { setStartFilter(true) } }} className='p-1 rounded-lg border border-transparent focus:border focus:border-secondaryDefaultLight outline-none cursor-pointer' onClick={() => { if (filteredValue?.length > 0) { setStartFilter(true) } }}>
                                                <FaSearch />
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                        </th>
                    </tr>
                    <tr>
                        {
                            title?.map((titleColumn, indexTh) => {
                                return <th onClick={(e) => { sortTable(titleColumn?.original_name); setSortBy(titleColumn?.original_name); setSortTo(!sortTo) }} key={indexTh} className='bg-primaryDefaultLight text-xs 2xl:text-sm dark:bg-thirdDefaultDark text-white font-extrabold h-10 top-12 sticky'>
                                    <div className='flex flex-row gap-2 items-center justify-center '>
                                        {titleColumn?.name}
                                        {
                                            sortBy !== titleColumn?.original_name ?
                                                <RiArrowUpDownLine size={'18'} />
                                                :
                                                sortTo ?
                                                    <FaArrowUp size={'12'} />
                                                    :
                                                    <FaArrowDown size={'12'} />
                                        }
                                    </div>
                                </th>
                            })
                        }
                    </tr>
                </thead>
                <tbody className=''>

                    {
                        tableData?.length === 0 ?
                            <tr key={'1'} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200'>
                                <td colSpan={title.length} key={`1-1`} className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>Sem registros</td>
                            </tr>
                            :
                            startFilter && filterTable()?.length === undefined ?
                                <tr key={'1'} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200'>
                                    <td colSpan={title.length} key={`1-1`} className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>Sem registros</td>
                                </tr>
                                :
                                startFilter ?
                                    filterTable()?.map((apiData, index) => {
                                        return <tr key={index} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200'>
                                            {
                                                collumns?.map((collumn, indexCollum) => {
                                                    return <td key={`${indexCollum}-${index}`} onClick={() => onClick(apiData)} className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>{apiData[collumn]}</td>

                                                })
                                            }
                                        </tr>
                                    })
                                    :
                                    tableData?.map((apiData, index) => {
                                        if (datasheetMark && index == 0){
                                            return <tr key={index} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200 bg-blue-200'>
                                                {
                                                    collumns?.map((collumn, indexCollum) => {
                                                        return (customFunctions?.isValid && customFunctions?.collumn == indexCollum) ? 
                                                        <td key={`${indexCollum}-${index}`} className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-gray-900 dark:text-titleGrayTextDark h-8 border-b border-gray-300 dark:border-primaryBorderDark`}>{apiData[collumn]}</td>
                                                        :
                                                        <td key={`${indexCollum}-${index}`} onClick={() => onClick(apiData)} className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-gray-900 dark:text-titleGrayTextDark h-8 border-b border-gray-300 dark:border-primaryBorderDark`}>{apiData[collumn]}</td>
                                                    })
                                                }
                                            </tr>
                                        } else {
                                            return <tr key={index} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200'>
                                                {
                                                    collumns?.map((collumn, indexCollum) => {
                                                        return (customFunctions?.isValid && customFunctions?.collumn == indexCollum) ? 
                                                        <td key={`${indexCollum}-${index}`} className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-gray-900 dark:text-titleGrayTextDark h-8 border-b border-gray-300 dark:border-primaryBorderDark`}>{apiData[collumn]}</td>
                                                        :
                                                        <td key={`${indexCollum}-${index}`} onClick={() => onClick(apiData)} className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-gray-900 dark:text-titleGrayTextDark h-8 border-b border-gray-300 dark:border-primaryBorderDark`}>{apiData[collumn]}</td>
                                                    })
                                                }
                                            </tr>
                                        }
                                    })
                    }
                </tbody>
            </table>
        </div >
    )
}