// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// components
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { LabelInput } from '../../../components/label/label.input'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toggle } from '../../../components/toogle/Toogle'
import { TableDefault } from '../../../components/table/table.default'
// services 
import { api } from '../../../services/api/api'
import { getPermissionGroups, getTypeAccess, getCustomerLinkToUser, getUserClientEspecific } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
//utils
import { validateEmail } from '../../../utils/validators/email.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { justNumber } from '../../../utils/validators/justNumber'
import { mCPF } from '../../../utils/validators/cpfMask'
import { formattTimeToShow } from '../../../utils/formatData/formatTimeToShow'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
import { FiTrash2 } from 'react-icons/fi'

export function UserClientsEdit() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [updatedUser, setUpdatedUser] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [showNotification, setShowNotication] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [successData, setSuccessData] = useState(false)
    const [typeAccessList, setTypeAccessList] = useState([])
    const [permissionGroupsData, setPermissionsGroupsData] = useState([])
    const [thisUserData, setThisUserData] = useState([])

    //selectedVariables
    const [selectedTypeAccesss, setSelectedTypeAccess] = useState('')
    const [selectedPermissionGroup, setSelectedPermissionGroup] = useState('')
    const [selectedFullname, setSelectedFullname] = useState('')
    const [selectedUserEmail, setSelectedUserEmail] = useState('')
    const [selectedBirthDay, setSelectedBirthDay,] = useState('')
    const [selectedDDDPhone, setSelectedDDDPhone] = useState('')
    const [selectedUserGender, setSelectedUserGender] = useState('')
    const [selectedPhone, setSelectedPhone,] = useState('')
    const [selecteduserCpf, setSelectedUserCpf] = useState('')
    const [selectedOldLink, setSelectedOldLink] = useState([])
    const [selectedLink, setSelectedLink] = useState('')
    const [exchangeLink, setExchangeLink] = useState(false)
    const [companiesList, setCompaniesList] = useState([])
    const [activeUsers, setActiveUsers] = useState(false)
    const [modalAlterPass, setModalAlterPass] = useState(false)
    const [modalRemove, setModalRemove] = useState(false)
    const [modalLinked, setModalLinked] = useState(false)
    const [tempCustomer, setTempCustomer] = useState({})

    useEffect(() => {

        setLoading(true)

        async function getCustomerLink() {

            try {
                const getCustomerLinkToUserAPI = await getCustomerLinkToUser(userData[0].token, id)
    
                setSelectedOldLink(getCustomerLinkToUserAPI?.data?.map(link => {
                    return {
                        ...link,
                        created_at: formattTimeToShow(link.created_at),
                        remove:
                            <Tippy content={<span>Remover Vínculo</span>}
                                arrow={true}
                                animation='perspective'
                                placement='top'
                                delay={100}>
                                <div className='flex justify-center items-center'>
                                    <FiTrash2 className='text-red-700 cursor-pointer' onClick={() => { setModalRemove(true); setShowUniversalModal(true); setTempCustomer(link?.customers_id) }} />
                                </div>
                            </Tippy>
                    }
                }))
            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    if (String(error?.message) == '_getCustomerLinkToUse.map is not a function'){
                        setLoading(false)
                        setShowNotificationModalSuccess(false)
                        setShowModificationModal(true)
                        return setShowNotificationModalText('Você não tem permissão para acessar clientes vinculados.')
                    } else {
                        setLoading(false)
                        setShowNotificationModalSuccess(false)
                        setShowModificationModal(true)
                        return setShowNotificationModalText('Erro inesperado')
                    }
                }
            }

        }

        async function getCustomers() {
            try {

                const getCustomersAPI = await api.get('/api/v1/customers', {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })

                const filteredCustomers = getCustomersAPI?.data?.data

                setCompaniesList(filteredCustomers?.map(customer => {
                    return {
                        ...customer,
                        inputDescription: `${customer?.id} - ${customer?.businessName}`
                    }
                }))

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        async function getData() {

            const typeAccessData = await getTypeAccess(userData[0].token)
            if (typeAccessData.error) {
                setLoading(false)
                setShowNotication(typeAccessData.data)
                return
            }
            setTypeAccessList(typeAccessData?.data?.filter(access => {
                if (access?.id > 2) {
                    return access
                }
            }))

            const userDataApi = await getUserClientEspecific(userData[0].token, id)
            if (userDataApi.error) {
                setLoading(false)
                setShowNotication(userDataApi.data)
                return
            }
            const thisUserTypeAccesss = typeAccessData.data.filter((typeAccess) => {
                if (typeAccess.id === userDataApi.data[0].typeAccess_id) {
                    return typeAccess
                }
            })

            const permissionGroupsData = await getPermissionGroups(userData[0].token)
            if (permissionGroupsData.error) {
                setLoading(false)
                setShowNotication(permissionGroupsData.data)
                return
            }
            setPermissionsGroupsData(permissionGroupsData.data)
            const thisUserPermissionGroups = permissionGroupsData.data.filter((permissionGroups) => {
                if (permissionGroups.id === userDataApi.data[0].permissionGroups_id) {
                    return permissionGroups
                }
            })

            setThisUserData(userDataApi.data[0])
            setSelectedFullname(userDataApi.data[0]?.fullname)
            setSelectedDDDPhone(userDataApi.data[0]?.dddphone)
            setSelectedPhone(userDataApi.data[0]?.phone)
            setSelectedUserEmail(userDataApi.data[0]?.email)
            setSelectedTypeAccess(thisUserTypeAccesss[0])
            setSelectedPermissionGroup(thisUserPermissionGroups[0])
            setSelectedBirthDay(userDataApi.data[0]?.birthday ? userDataApi.data[0]?.birthday : '')
            setSelectedUserCpf(userDataApi.data[0]?.cpf)
            setActiveUsers(userDataApi.data[0]?.active)

            setLoading(false)
        }

        getCustomerLink()
        getCustomers()
        getData()

    }, [updatedUser])

    useEffect(() => {
        if (!showUniversalModal) {
            setModalAlterPass(false)
            setModalLinked(false)
            setModalRemove(false)
            setSelectedLink('')
            setTempCustomer('')
        }
    }, [showUniversalModal])

    async function handleSubmit() {

        const fields =
            [
                { "name": 'selectedFullname', "value": selectedFullname, "required": true, "type": 'string' },
                { "name": 'selectedUserEmail', "value": selectedUserEmail, "required": true, "type": 'string' },
                { "name": 'selectedTypeAccesss', "value": selectedTypeAccesss, "required": true, "type": 'string' },
                { "name": 'selectedPermissionGroup', "value": selectedPermissionGroup, "required": [2, 3].includes(selectedTypeAccesss?.id) ? true : false, "type": 'object' }
            ]

        if (exchangeLink && !selectedLink) {
            fields.push({ "name": 'selectedLink', "value": selectedLink, "required": true, "type": 'string' })
        }

        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Campos obrigatórios não preenchidos!')
            return setShowNotificationModalSuccess(false)
        }

        try {

            const userEdit = await api.put(`/api/v1/usersClient/${id}`, {
                fullname: selectedFullname ? selectedFullname : undefined,
                birthday: selectedBirthDay ? selectedBirthDay : undefined,
                gender: selectedUserGender?.id?.length > 0 ? selectedUserGender.id : undefined,
                dddphone: selectedDDDPhone ? selectedDDDPhone : undefined,
                phone: selectedPhone?.toString()?.indexOf('-') > -1 ? selectedPhone?.replace('-', '') : selectedPhone?.toString()?.indexOf('-') < 0 ? parseInt(selectedPhone) : undefined,
                cpf: selecteduserCpf ? selecteduserCpf?.replace('-', '')?.replaceAll('.', '') : undefined,
                email: selectedUserEmail ? selectedUserEmail : undefined,
                typeAccess_id: selectedTypeAccesss ? selectedTypeAccesss?.id : undefined,
                permissionGroups_id: selectedPermissionGroup ? selectedPermissionGroup?.id : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/usersClients')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDelete() {
        setLoading(true)

        try {

            await api.delete(`/api/v1/usersClient/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/usersClients')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function usersStatus() {

        const status = !activeUsers
        setActiveUsers(status)
        // setLoading(true)

        try {

            await api.put(`/api/v1/usersClient/${id}/status/${status ? 1 : 0}`, {}, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            // setLoading(false)
            setShowNotificationModalText(status === false ? `Usuário ${selectedFullname} desativado com sucesso.` : `Usuário ${selectedFullname} ativado com sucesso.`)

        } catch (error) {
            setLoading(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            }
        }
    }

    async function handleAlterPass() {
        setLoading(true)

        try {
            const alterPass = await api.put(`api/v1/usersClient/alterPass/${id}`, {}, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowUniversalModal(false)
            setLoading(false)
            setModalAlterPass(false)
            setShowNotificationModalText(alterPass?.data?.message)
            setShowNotificationModalSuccess(true)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowUniversalModal(false)
                setModalAlterPass(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowUniversalModal(false)
                setModalAlterPass(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDeleteLink() {
        setLoading(true)

        try {

            await api.delete(`/api/v1/customersUsersLink/users_id/${id}/customers_id/${tempCustomer}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setModalRemove(false)
            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Vínculo removido com sucesso')
            setShowNotificationModalSuccess(true)
            setUpdatedUser(!updatedUser)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function addLink() {
        setLoading(true)

        try {

            await api.post(`api/v1/customersUsersLink`, {
                users_id: id,
                customers_id: selectedLink?.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setModalLinked(false)
            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Empresa vinculada com sucesso')
            setShowNotificationModalSuccess(true)
            setUpdatedUser(!updatedUser)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    const collumsTables = [
        {
            "name": 'Código Empresa',
            "original_name": 'customers_id'
        },
        {
            "name": 'Empresa',
            "original_name": 'customers_businessName'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        },
        {
            "name": 'Remover',
            "original_name": 'remove'
        },
    ]

    const customFunctions = {
        isValid: true,
        collumn: 3
    }

    return (
        <>
            <ScreenModal title={`${modalAlterPass ? 'Reenviar senha' : modalRemove ? 'Remover Vínculo' : modalLinked ? 'Vincular nova empresa' : 'Exclusão de usuário'}`} visible={showModal} >
                {
                    modalAlterPass ?
                        <div className='w-full p-2 h-32 flex flex-col items-center justify-center'>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente reenviar a senha?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button shadow={true} onClick={() => { handleAlterPass() }}>Sim</Button>
                                <Button shadow={true} onClick={() => { setShowUniversalModal(false); setModalAlterPass(false) }} approval={false}>Não</Button>
                            </div>
                        </div>
                        : modalRemove ?
                            <div className='w-full h-32 flex flex-col items-center justify-center'>
                                <p className='text-sm text-titleBlackTextLight'>Deseja realmente remover o vínculo?</p>
                                <div className='flex flex-row gap-1 mt-10'>
                                    <Button shadow={true} onClick={() => handleDeleteLink()}>Sim</Button>
                                    <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                                </div>
                            </div>
                            : modalLinked ?
                                <div className='w-full h-46 flex flex-col items-center justify-start p-10'>
                                    <div className='mt-5'>
                                        <LabelInput text={'Empresa *'}>
                                            <InputAutoComplete
                                                value={selectedLink?.businessName}
                                                height={80}
                                                selectedLabel={'businessName'} id='selectedLink'
                                                placeHolder={' - '}
                                                data={companiesList} optionList={["inputDescription"]}
                                                onChange={e => {
                                                    {
                                                        setSelectedLink(e)
                                                    }
                                                }}>
                                            </InputAutoComplete>
                                        </LabelInput>
                                    </div>
                                    <div className='flex flex-row gap-1 mt-7 pb-5'>
                                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Cancelar</Button>
                                        <Button shadow={true} onClick={() => addLink()}>Vincular</Button>
                                    </div>
                                </div>
                                :
                                <div className='w-full h-32 flex flex-col items-center justify-center'>
                                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir o usuário ?</p>
                                    <div className='flex flex-row gap-1 mt-10'>
                                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                                    </div>
                                </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={`Editar Usuário - ${selectedFullname}`} />
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='flex flex-col items-center justify-center lg:items-start lg:justify-start w-full'>
                                <form className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Nome Completo *</a>
                                        <Input width={80} charLimit={255} id='selectedFullname' type='text' value={selectedFullname} onChange={(e) => setSelectedFullname(e.target.value)}></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                        <Input width={80} charLimit={255} id='selectedUserEmail' type='e-mail' value={selectedUserEmail} onChange={(e) => { validateEmail(e.target.value); setSelectedUserEmail(e.target.value) }}></Input>
                                    </label>
                                    <label className='relative flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Tipo de acesso *</a>
                                        <InputAutoComplete
                                            data={typeAccessList}
                                            disabled={true}
                                            selectedLabel={'description'}
                                            optionList={['id', 'description']}
                                            value={selectedTypeAccesss?.description}
                                            onChange={selectedTypeAccess => setSelectedTypeAccess(selectedTypeAccess)}
                                            id='selectedTypeAccesss'
                                            width={80}
                                        />
                                    </label>
                                    {
                                        [2, 3].includes(selectedTypeAccesss.id) &&
                                        <LabelInput 
                                            text={'Permissão *'}
                                            showIconInfo={true}
                                            messageIconInfo={'Definir grupo de permissão que o usuário melhor se encaixa.'}
                                        >
                                            <InputAutoComplete
                                                data={permissionGroupsData}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                                value={selectedPermissionGroup?.name}
                                                onChange={value => setSelectedPermissionGroup(value)}
                                                id='selectedPermissionGroup'
                                                width={80}
                                            />
                                        </LabelInput>
                                    }
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>CPF</a>
                                        <Input
                                            charLimit={14}
                                            id='selecteduserCpf'
                                            type='text'
                                            width={80}
                                            value={selecteduserCpf ? mCPF(selecteduserCpf) : ''}
                                            onChange={(e) => setSelectedUserCpf(mCPF(e.target.value))}
                                        />
                                    </label>
                                    <div className='flex flex-row gap-3'>
                                        <LabelInput text={'DDD'}>
                                            <Input
                                                charLimit={2}
                                                width={'50px'}
                                                type={'text'}
                                                onChange={(e) => setSelectedDDDPhone(justNumber(e.target.value))}
                                                value={selectedDDDPhone ? selectedDDDPhone : ''}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Telefone'}>
                                            <Input
                                                charLimit={10}
                                                type={'text'}
                                                width={'256px'}
                                                value={selectedPhone ? maskPhone(selectedPhone) : ''}
                                                onChange={(e) => setSelectedPhone(maskPhone(e.target.value))}
                                            />
                                        </LabelInput>
                                    </div>
                                    {
                                        (userData[0]?.permissions?.indexOf('usersClient:update') > -1 || userData[0]?.typeAccess_id == 1) ?
                                        <div className='flex flex-col w-full items-center lg:items-start justify-center mt-2'>
                                            <Tippy content={<span>{activeUsers ? 'Desativar' : 'Ativar'}</span>}
                                                arrow={true}
                                                animation='perspective'
                                                placement='top'
                                                delay={100}>
                                                <div>
                                                    <Toggle status={activeUsers ? true : false} onClick={(e) => usersStatus()} />
                                                </div>
                                            </Tippy>
                                        </div> : <></>
                                    }

                                </form>

                                {
                                    (userData[0]?.permissions?.indexOf('customersUsersLink:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                                    <div className='mt-7 w-full shadow-xl max-h-64 flex flex-col gap-3'>
                                        <div className='flex justify-between items-center'>
                                            <Title text={'Vínculos'} />
                                            <Button approval={true} shadow={true} onClick={() => { setModalLinked(true); setShowUniversalModal(true) }}>+ Vínculo</Button>
                                        </div>
                                        <TableDefault
                                            data={selectedOldLink}
                                            title={collumsTables}
                                            customFunctions={customFunctions}
                                            onClick={() => {}}
                                            cursorPointer={true}
                                            collumns={["customers_id", "customers_businessName", "created_at", "remove"]}
                                        />
                                    </div>
                                }

                                <div className='mt-5 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                    <div className='flex gap-2'>
                                        <Button
                                            approval={false}
                                            shadow={true}
                                            onClick={() => { navigate(-1); setShowModificationModal(false) }}
                                        >Cancelar
                                        </Button>
                                        {
                                            (userData[0]?.permissions?.indexOf('usersClient:update') > -1 || userData[0]?.typeAccess_id == 1) ?
                                            <Button
                                                shadow={true}
                                                onClick={() => { setModalAlterPass(true); setShowUniversalModal(true) }}
                                            >Reenviar senha
                                            </Button> : <></>
                                        }
                                    </div>
                                    <div className='flex gap-2'>
                                        {
                                            (userData[0]?.permissions?.indexOf('usersClient:delete') > -1 || userData[0]?.typeAccess_id == 1) ?
                                            <Button
                                                approval={false}
                                                shadow={true}
                                                onClick={() => setShowUniversalModal(true)}
                                            >Excluir
                                            </Button> : <></>
                                        }
                                        {
                                            (userData[0]?.permissions?.indexOf('usersClient:update') > -1 || userData[0]?.typeAccess_id == 1) ?
                                            <Button
                                                approval={true}
                                                shadow={true}
                                                onClick={() => handleSubmit()}
                                            >Editar usuário
                                            </Button> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </Body>
            </Container>
        </>
    )
}
