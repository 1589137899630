// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// components 
import { TitlePage } from '../../../components/titlePages/title.page'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
//services
import { api } from '../../../services/api/api'
import { getPermissions } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// utils
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { validateFields } from '../../../utils/form.validator'

export function PermissionGroupsCreate() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { setShowUniversalModal } = useThemeContext()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const [loading, setLoading] = useState(false)
    const { screenY, screenX } = useScreenSizeContext()
    const screenSize = screenY - 200

    const [selectedName, setSelectedName] = useState('')
    const [selectedDescription, setSelectedDescription] = useState('')
    const [ permissionList, setPermissionList ] = useState([])
    const [selectedPermissions, setSelectedPermissions] = useState([])

    useEffect(() => {
        setLoading(true)

        async function getPermissionsAPI(){
            try {
    
                const permissionAPI = await getPermissions(userData[0].token)
    
                setPermissionList(permissionAPI.data)
                setLoading(false)

            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getPermissionsAPI()

    }, [])

    async function handleSubmit(){
        setLoading(true)

        const fields =
        [
            { "name": 'selectedName', "value": selectedName, "required": true, "type": 'string' }
        ]
        
        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }
        
        if (selectedPermissions.length == 0){
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Preencha uma permissão')
        }

        try {
            
            await api.post('api/v1/permissionGroups', {
                name: selectedName,
                description: selectedDescription ? selectedDescription : undefined,
                permissions: selectedPermissions
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setLoading(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/permissionGroups')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function check(insert, permissionType){

        let newPermissions = []

        permissionList.map((feature) => {
            feature?.permissions.map(permission => {
                if (permission.value === permissionType) {
                    newPermissions.push(permission)
                }
            })

        })

        if (insert) {
            newPermissions.forEach((newPermission) => {
                if (selectedPermissions.includes(newPermission.value) === false) {
                    selectedPermissions.push(newPermission.value)
                }
            })
            setSelectedPermissions([...selectedPermissions])
        }
        else {
            newPermissions.forEach((newPermission) => {
                if (selectedPermissions.includes(newPermission.value) === true) {
                    const position = (selectedPermissions.indexOf(newPermission.value))
                    selectedPermissions.splice(position, 1)
                }
            })
        }
        setSelectedPermissions([...selectedPermissions])
    }

    function checkAll(insert, permissionType) {

        let newPermissions = []

        permissionList.map((feature) => {
            if (feature.feature === permissionType) {
                feature.permissions.map((permission) => newPermissions.push(permission))
            }
        })

        if (insert) {
            newPermissions.forEach((newPermission) => {
                if (selectedPermissions.includes(newPermission.value) === false) {
                    selectedPermissions.push(newPermission.value)
                }
            })
            setSelectedPermissions([...selectedPermissions])
        }
        else {
            newPermissions.forEach((newPermission) => {
                if (selectedPermissions.includes(newPermission.value) === true) {
                    const position = (selectedPermissions.indexOf(newPermission.value))
                    selectedPermissions.splice(position, 1)
                }
            })
        }
        setSelectedPermissions([...selectedPermissions])
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <Title text={'Criação de grupo'}></Title>
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <DefaultLoader/>
                        :
                        <>
                        <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                            <form className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                    <Input charLimit={255} id='selectedName' type='text' value={selectedName} onChange={(e) => setSelectedName(e.target.value)} autoFocus={true}/>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Descrição</a>
                                    <Input charLimit={255} id='selectedUserEmail' type='e-mail' value={selectedDescription} onChange={(e) => setSelectedDescription(e.target.value)}/>
                                </label>
                            </form>
                            <p className='mt-6 text-sm'>Selecione as permissões do grupo:</p>
                            <div className='flex flex-wrap gap-5 p-8 lg:items-start items-center lg:justify-start justify-center'>                                
                                {
                                    permissionList.map(permission => {
                                    let permissionsFeature = permission.permissions.map((per) => {
                                        return per.value
                                    })
                                    let isNotAllChecked = true
                                    permissionsFeature.map(perm => {
                                        if (!selectedPermissions.includes(perm)){
                                            isNotAllChecked = false
                                        }
                                    })
                                    return <div className='lg:w-80 md:w-80 w-full border mt-1 rounded-sm shadow-xl text-gray-400 text-sm'>
                                        <div className='p-3'>
                                            <p className='mb-2 text-black font-medium flex gap-4 text-sm'>                                                
                                                <InputCheckBox
                                                    id={permission.feature}   
                                                    value={isNotAllChecked ? true : false}                                                                                                             
                                                    onClick={(e) => {
                                                        checkAll(e, permission?.feature)
                                                    }}
                                                />
                                                <span className='border-b-2'>{permission.feature}</span>
                                            </p>
                                            <lu className='flex flex-col gap-4 mt-5 text-sm'>
                                                {
                                                    permission.permissions.map(per => {
                                                        return <span className='flex items-center gap-2'>
                                                            <InputCheckBox 
                                                                value={selectedPermissions.includes(per.value) ? true : false}
                                                                id={per.value}                                                                                                                  
                                                                onClick={(e) => {
                                                                    check(e, per.value)
                                                                }}
                                                            />{per.name}
                                                        </span>                                                        
                                                    })
                                                }
                                            </lu>
                                        </div>
                                    </div>                    
                                    })
                                }
                            </div>
                            <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-between'>
                                <Button
                                    approval={false}
                                    shadow={true}
                                    onClick={() => {navigate('/permissionGroups'); setShowModificationModal(false)}}
                                >Cancelar
                                </Button>
                                <Button
                                    approval={true}
                                    shadow={true}
                                    onClick={() => handleSubmit()}
                                >+ Novo grupo
                                </Button>
                            </div>
                        </div>
                        </>
                    }
                </Body>
            </Container>
        </>
    )


}