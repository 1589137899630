//hooks
import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { FaMinus, FaPlus } from "react-icons/fa";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
//utils
import { justNumber } from "../../../utils/validators/justNumber";
import { validateFields } from "../../../utils/form.validator";
import { validatorSales } from "../../../utils/validators/validatorSales"
import { responseError } from "../../../utils/responsesFunctions/error.response";
//services
import { api } from "../../../services/api/api";
import { v4 as uuid } from 'uuid'
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";
import { Button } from "../../../components/buttons/button.default";
import moment from "moment";
import { useScreenSizeContext } from "../../../contexts/screenSizeContext";

export function SalesCreate() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const { screenX } = useScreenSizeContext()
    const [loading, setLoading] = useState(true)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    //sale
    const [supplierAndCustomerData, setSupplierAndCustomerData] = useState([])
    const [paymentConditionData, setPaymentConditionData] = useState([])
    const [productsData, setProductsData] = useState([])
    const [sellerData, setSellerData] = useState([])
    const [saleDate, setSaleDate] = useState(moment()?.utc(false)?.format('YYYY-MM-DD'))
    const [saleOrder, setSaleOrder] = useState('')
    const [nf, setNf] = useState('')
    const [supplierAndCustomerId, setSupplierAndCustomerId] = useState('')
    const [paymentConditionId, setPaymentConditionId] = useState('')
    const [seller_id, setSellerId] = useState('')
    const [monetaryFreight, setMonetaryFreight] = useState('')
    const [percentageFreight, setPercentageFreight] = useState('')
    //composition (uuid)
    const [childsSalesList, setChildsSalesList] = useState([])
    const [childsSalesIdList, setChildsSalesIdList] = useState([])
    const [totalSales, setTotalSale] = useState('')
    const [commission, setCommission] = useState(0)
    const [useTaxationDefault, setUseTaxationDefault] = useState(true)
    const [simplePercentage, setSimplePercentage] = useState('')
    const [pis, setPis] = useState('')
    const [cofins, setCofins] = useState('')
    const [ircs, setIrcs] = useState('')
    const [iva, setIva] = useState('')
    const [icms, setIcms] = useState('')
    const [regimeReference, setRegimeReference] = useState({})

    useEffect(() => {        
        async function getData(){
            setLoading(true)
            try {
                const supplierAndCustomer = await api.get(`/api/v1/general/supplierAndCustomer?isCustomer=true`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setSupplierAndCustomerData(supplierAndCustomer?.data?.data)

                const paymentCondition = await api.get(`/api/v1/lm/paymentCondition`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setPaymentConditionData(paymentCondition?.data?.data.filter(item => item.textDays = 'Dias'))

                const seller = await api.get(`/api/v1/lm/seller?active=true`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setSellerData(seller?.data?.data)

                const productsDataApi = await api.get('/api/v1/lm/products', {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setProductsData(productsDataApi?.data?.data?.map(prod => {
                    return {
                        ...prod,
                        descriptionInput: `${prod?.code} - ${prod?.description} - ${prod?.typeProduct}`
                    }
                }))

                const getTaxationAPI = await api.get(`/api/v1/lm/regimeTributary?active=true`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setRegimeReference(getTaxationAPI?.data?.data[0])

                setLoading(false)

            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getData()
        addChildSale()        
    }, [])

    const typeDescont = [
        {
            id: 1,
            name: 'Monetário'
        },
        {
            id: 2,
            name: 'Porcentagem'
        }
    ]

    const typeFreight = [
        {
            id: 1,
            name: 'Monetário'
        },
        {
            id: 2,
            name: 'Porcentagem'
        }
    ]
    const [selectedTypeFreight, setSelectedTypeFreight] = useState('')

    function addChildSale() {
        const id = uuid()
        let add

        add = Object.assign(childsSalesList, {
            [id]: {
                products_id: null,
                quantity: null,
                unitPrice: null,
                selectedTypeDescont: null,
                percentageDiscount: null,
                discount: null
            }
        })

        setChildsSalesList(add)
        setChildsSalesIdList([...childsSalesIdList, id])
    }

    async function removeChildSale(id) {
        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 50))

        const replicaChildDatasheet = structuredClone(childsSalesList)
        const replicaChildDatasheetList = structuredClone(childsSalesIdList)

        const position = childsSalesIdList.indexOf(id)
        replicaChildDatasheetList.splice(position, 1)

        delete replicaChildDatasheet[id]

        setChildsSalesList(replicaChildDatasheet)
        setChildsSalesIdList([...replicaChildDatasheetList])
        setLoading(false)
    }

    async function handleSubmit() {
        setLoading(true)

        const requiredFields = [
            { name: 'saleDate', value: saleDate, required: true, type: 'string' },
            { name: 'supplierAndCustomerId', value: supplierAndCustomerId, required: true, type: 'string' },
            { name: 'paymentConditionId', value: paymentConditionId, required: true, type: 'string' },
        ]

        if (!useTaxationDefault){
            requiredFields?.push({name: 'simplePercentage', value: simplePercentage, required: true, type: 'string'})
            requiredFields?.push({name: 'pis', value: pis, required: true, type: 'string'})
            requiredFields?.push({name: 'cofins', value: cofins, required: true, type: 'string'})
            requiredFields?.push({name: 'ircs', value: ircs, required: true, type: 'string'})
            requiredFields?.push({name: 'iva', value: iva, required: true, type: 'string'})
            requiredFields?.push({name: 'icms', value: icms, required: true, type: 'string'})
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (childsSalesIdList == 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('É necessário existir composições para a venda')
        }

        if (Object.keys(childsSalesList).length > 0) {
            if (validatorSales(childsSalesList).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha os campos em vermelho')
            }
        }

        try {

            let formatListChilds = childsSalesIdList?.map(key => {
                return {
                    products_id: childsSalesList[key]?.products_id,
                    quantity: childsSalesList[key]?.quantity,
                    unitPrice: childsSalesList[key]?.unitPrice,
                    discount: childsSalesList[key]?.discount ? childsSalesList[key]?.discount : childsSalesList[key]?.percentageDiscount ? (childsSalesList[key]?.percentageDiscount * (childsSalesList[key]?.unitPrice * childsSalesList[key]?.quantity)) / 100 : 0
                }
            })

            let totalValueCompositions = formatListChilds.map(item => (item.quantity * item.unitPrice) - item?.discount)
            let totalValue = totalValueCompositions.reduce((accumulator, currentValue) => accumulator + currentValue)

            await api.post(`/api/v1/lm/sales`, {
                saleDate: saleDate,
                saleOrder: saleOrder ? saleOrder : undefined,
                nf: nf ? nf : undefined,
                supplierAndCustomer_id: supplierAndCustomerId.id,
                paymentCondition_id: paymentConditionId.id,
                seller_id: seller_id ? seller_id.id : undefined,
                freight: monetaryFreight ? monetaryFreight : percentageFreight ? (percentageFreight * totalValue) / 100 : undefined,
                commission: commission ? commission : undefined,
                useTaxationDefault: useTaxationDefault,
                simplePercentage: simplePercentage ? simplePercentage : undefined,
                pis: pis ? pis : undefined,
                cofins: cofins ? cofins : undefined,
                ircs: ircs ? ircs : undefined,
                iva: iva ? iva : undefined,
                icms: icms ? icms : undefined,
                composition: formatListChilds
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Venda cadastrada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/sales')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setLoading(false)
    }

    async function calculatorTotalSale(){
        const calculate = Object.keys(childsSalesList)?.map(key => {
            const quantity = childsSalesList[key]?.quantity ? childsSalesList[key]?.quantity : 0
            const price = childsSalesList[key]?.unitPrice ? childsSalesList[key]?.unitPrice : 0
            const discount = childsSalesList[key]?.discount ? childsSalesList[key]?.discount : childsSalesList[key]?.percentageDiscount ? (childsSalesList[key]?.percentageDiscount * (price * quantity)) / 100 : 0
            return (Number(price) * Number(quantity)) - Number(discount)
        })
        const totalValue = calculate.reduce((accumulator, currentValue) => accumulator + currentValue)
        setTotalSale(totalValue?.toFixed(5))
    }

    const options = [
        {
            description: 'Sim',
            value: true
        },
        {
            description: 'Não',
            value: false
        },
    ]

    return (
        <>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={'Cadastro de Vendas'} />
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <DefaultLoader/>
                        :
                        <>
                            <div className="flex flex-col items-center lg:items-start justify-start w-full">
                                <form className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2'>
                                    <LabelInput text={'Data de venda'} required={true}>
                                        <Input
                                            type={'date'}
                                            width={80}
                                            value={saleDate ? saleDate : ''}
                                            id='saleDate'
                                            onChange={(e) => setSaleDate(e.target.value)}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'NF'}>
                                        <Input
                                            type={'string'}
                                            width={80}
                                            value={nf ? nf : ''}
                                            id='nf'
                                            onChange={(e) => setNf(justNumber(e.target.value))}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Número do pedido'} required={false}>
                                        <Input
                                            type={'string'}
                                            width={80}
                                            value={saleOrder ? saleOrder : ''}
                                            id='saleOrder'
                                            onChange={(e) => setSaleOrder(e.target.value)}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Cliente'} required={true}>
                                        <InputAutoComplete
                                            width={80}
                                            data={supplierAndCustomerData}
                                            optionList={["id", "businessName"]}
                                            selectedLabel={["businessName"]}
                                            value={supplierAndCustomerId ? supplierAndCustomerId?.businessName : ''}
                                            id='supplierAndCustomerId'
                                            onChange={(e) => {
                                                setSupplierAndCustomerId(e)
                                                setCommission(e?.commission)
                                            }}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Condição de pagamento'} required={true}>
                                        <InputAutoComplete
                                            width={80}
                                            data={paymentConditionData}
                                            optionList={["days", "textDays"]}
                                            selectedLabel={["days"]}
                                            value={paymentConditionId ? paymentConditionData.filter(item => item.id == paymentConditionId?.id)[0]?.days == undefined ? '' : paymentConditionData.filter(item => item.id == paymentConditionId?.id)[0]?.days + ' Dias' : ''}
                                            id='paymentConditionId'
                                            onChange={(e) => setPaymentConditionId(e)}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Vendedor'} required={false}>
                                        <InputAutoComplete
                                            width={80}
                                            data={sellerData}
                                            optionList={["id", "name"]}
                                            selectedLabel={["name"]}
                                            value={seller_id ? seller_id?.name : ''}
                                            id='seller_id'
                                            onChange={(e) => {
                                                setSellerId(e)
                                                setCommission(supplierAndCustomerId?.commission ? commission : e?.commission ? e?.commission : 0)                                                
                                            }}
                                        />
                                    </LabelInput>
                                    <LabelInput 
                                        text={'Tipo de Frete'} 
                                        required={false}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir como será preenchido o campo de frete, o sistema faz o cálculo internamente para ambos os tipos.'}
                                    >
                                        <InputAutoComplete
                                            width={80}
                                            data={typeFreight}
                                            optionList={["name"]}
                                            selectedLabel={["name"]}
                                            value={typeFreight.filter(type => type.id == selectedTypeFreight)[0]?.name}
                                            id=''
                                            onChange={(e) => setSelectedTypeFreight(e.id)}
                                        />
                                    </LabelInput>
                                    {
                                        selectedTypeFreight == 1 ?
                                            <LabelInput text={'Frete R$'} required={false}>
                                                <CurrencyInput id='freight' placeHolder='R$ 0,00'
                                                    onChange={(e) => {
                                                        setMonetaryFreight(parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')))
                                                    }}></CurrencyInput>
                                            </LabelInput>
                                            :
                                            selectedTypeFreight == 2 ?
                                                <LabelInput text={'Frete %'} required={false}>
                                                    <Input
                                                        type={'number'}
                                                        width={80}
                                                        value={percentageFreight ? percentageFreight : ''}
                                                        id='freight'
                                                        onChange={(e) => setPercentageFreight(e.target.value)}
                                                    />
                                                </LabelInput>
                                                :
                                                <LabelInput text={'Frete'} required={false}>
                                                    <Input
                                                        type={'number'}
                                                        width={80}
                                                        value={[]}
                                                        id=''
                                                        onChange={{}}
                                                        disabled={true}
                                                    />
                                                </LabelInput>

                                    }
                                    {
                                        supplierAndCustomerId?.id || seller_id?.id ?
                                        <LabelInput 
                                            text={'Comissão (%)'}
                                            showIconInfo={true}
                                            messageIconInfo={'Comissão sugerida com base no cliente, caso o cliente não tenha parametrização vai pegar do vendedor. Caso nenhum tenha comissão ficará vazio.'}
                                        >
                                            <Input 
                                                type={'number'} 
                                                width={80} 
                                                value={commission} 
                                                id='commission' 
                                                onChange={(e) => setCommission(e.target.value)} 
                                                charLimit={9} 
                                            />
                                        </LabelInput>
                                        :
                                        <></>
                                    }
                                    <LabelInput 
                                        text={'Impostos padrão'} 
                                        required={true}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir se a venda vai ter valores de impostos individuais ou com base na definição padrão.'}
                                    >
                                        <InputAutoComplete
                                            width={80}
                                            data={options}
                                            optionList={["description"]}
                                            selectedLabel={["description"]}
                                            value={options.filter(type => type.value == useTaxationDefault)[0]?.description}
                                            id='useTaxationDefault'
                                            onChange={(e) => {
                                                if (e?.value){
                                                    setSimplePercentage('')
                                                    setPis('')
                                                    setCofins('')
                                                    setIrcs('')
                                                    setIva('')
                                                    setIcms('')
                                                    setUseTaxationDefault(e.value)
                                                }else {
                                                    setSimplePercentage(regimeReference?.simplePercentage ? regimeReference?.simplePercentage : '')
                                                    setPis(regimeReference?.pis ? regimeReference?.pis : '')
                                                    setCofins(regimeReference?.cofins ? regimeReference?.cofins : '')
                                                    setIrcs(regimeReference?.ircs ? regimeReference?.ircs : '')
                                                    setIva(regimeReference?.iva ? regimeReference?.iva : '')
                                                    setIcms('')
                                                    setUseTaxationDefault(e.value)
                                                }
                                            }}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Simples Nacional %'} required={true}>
                                        <Input
                                            type={'number'}
                                            width={80}
                                            value={simplePercentage ? simplePercentage : ''}
                                            id='simplePercentage'
                                            onChange={(e) => setSimplePercentage(e.target.value)}
                                            disabled={useTaxationDefault ? true : false}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'PIS %'} required={true}>
                                        <Input
                                            type={'number'}
                                            width={80}
                                            value={pis ? pis : ''}
                                            id='pis'
                                            onChange={(e) => setPis(e.target.value)}
                                            disabled={useTaxationDefault ? true : false}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'COFINS %'} required={true}>
                                        <Input
                                            type={'number'}
                                            width={80}
                                            value={cofins ? cofins : ''}
                                            id='cofins'
                                            onChange={(e) => setCofins(e.target.value)}
                                            disabled={useTaxationDefault ? true : false}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'IR/CS %'} required={true}>
                                        <Input
                                            type={'number'}
                                            width={80}
                                            value={ircs ? ircs : ''}
                                            id='ircs'
                                            onChange={(e) => setIrcs(e.target.value)}
                                            disabled={useTaxationDefault ? true : false}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'IVA %'} required={true}>
                                        <Input
                                            type={'number'}
                                            width={80}
                                            value={iva ? iva : ''}
                                            id='iva'
                                            onChange={(e) => setIva(e.target.value)}
                                            disabled={useTaxationDefault ? true : false}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'ICMS %'} required={true}>
                                        <Input
                                            type={'number'}
                                            width={80}
                                            value={icms ? icms : ''}
                                            id='icms'
                                            onChange={(e) => setIcms(e.target.value)}
                                            disabled={useTaxationDefault ? true : false}
                                        />
                                    </LabelInput>
                                    {
                                        <div className="absolute right-3 top-1 flex flex-row text-md text-md font-light gap-3">
                                            <p className="ml-1 text-sm">Total:</p>
                                            <p className="text-sm">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(totalSales)}</p>
                                        </div>
                                    }
                                </form>
                                <div className="text-sm 2xl:text-base mt-5">
                                    Composições
                                </div>
                                <table className={`w-fit mt-3 flex flex-col justify-start items-start rounded-md border border-gray-400 bg-gray-50`}>
                                    <thead className='flex flex-row gap-4 sticky -top-2 z-[21] bg-blue-50 items-start justify-start w-full rounded-md'>
                                        <tr className='p-1 text-gray-700'>
                                        <th className={`font-medium ${screenX > 1536 ? 'w-[45rem]' : screenX > 1024 && screenX < 1280 ? 'w-[20rem]' : screenX < 1024 ? false : 'w-[30rem]'}`}>Produto</th>
                                        <th className='w-[110px] font-medium translate-x-2'>Quantidade</th>
                                        <th className='w-32 font-medium translate-x-3'>Preço Unitário</th>
                                        <th className='w-36 font-medium translate-x-5'>Tipo de desconto</th>
                                        <th className='w-32 font-medium translate-x-3'>Desconto</th>
                                        </tr>
                                    </thead>
                                    <tbody className='p-1 pb-4'>
                                    {
                                        childsSalesIdList.map((child, index) => {
                                            return (
                                                <tr className={`lg:flex lg:flex-row gap-2 justify-start items-center px-2`}>
                                                    <LabelInput>
                                                        <InputAutoComplete
                                                            data={productsData}
                                                            selectedLabel={'description'}
                                                            optionList={['code', 'description', 'typeProduct']}
                                                            onChange={e => {
                                                                childsSalesList[child].products_id = e?.id
                                                                setChildsSalesList(childsSalesList)
                                                                setChildsSalesIdList([...childsSalesIdList])
                                                            }}
                                                            value={productsData.filter(product => product.id == childsSalesList[child].products_id)[0]?.descriptionInput}
                                                            preSelectedValue={productsData.filter(product => product.id == childsSalesList[child].products_id)[0]?.descriptionInput}
                                                            id={`${child} - products_id`}
                                                            widthForce={screenX > 1536 ? 'w-[45rem]' : screenX > 1024 && screenX < 1280 ? 'w-[20rem]' : screenX < 1024 ? false : 'w-[30rem]'}
                                                        />
                                                    </LabelInput>
                                                    <LabelInput>
                                                        <Input
                                                            type={'number'}
                                                            value={childsSalesList[child].quantity ? childsSalesList[child].quantity : ''}
                                                            onChange={e => {
                                                                childsSalesList[child].quantity = e.target.value
                                                                setChildsSalesList(childsSalesList)
                                                                setChildsSalesIdList([...childsSalesIdList])
                                                                calculatorTotalSale()
                                                            }}
                                                            id={`${child} - quantity`}
                                                            width={'110px'}
                                                        />
                                                    </LabelInput>
                                                    <LabelInput>
                                                        <CurrencyInput 
                                                            id={`${child} - unitPrice`}
                                                            width={32}
                                                            placeHolder={childsSalesList[child].unitPrice ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(childsSalesList[child].unitPrice): 'R$ 0,00'}                                                        
                                                            onChange={(e) => {
                                                                childsSalesList[child].unitPrice = parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.'))
                                                                setChildsSalesList(childsSalesList)
                                                                setChildsSalesIdList([...childsSalesIdList])
                                                                calculatorTotalSale()
                                                            }}>                                                            
                                                        </CurrencyInput>
                                                    </LabelInput>
                                                    <LabelInput>
                                                        <InputAutoComplete
                                                            width={36}
                                                            data={typeDescont}
                                                            optionList={["name"]}
                                                            selectedLabel={["name"]}
                                                            value={typeDescont.filter(type => type.id == childsSalesList[child]?.selectedTypeDescont)[0]?.name}
                                                            id=''
                                                            onChange={(e) => {
                                                                childsSalesList[child].discount = null
                                                                childsSalesList[child].percentageDiscount = null
                                                                childsSalesList[child].selectedTypeDescont = e?.id
                                                                setChildsSalesList(childsSalesList)
                                                                setChildsSalesIdList([...childsSalesIdList])
                                                            }}
                                                        />
                                                    </LabelInput>
                                                    {
                                                        childsSalesList[child].selectedTypeDescont == 1 ?
                                                        <LabelInput>
                                                            <CurrencyInput 
                                                                id='discount' 
                                                                width={32}
                                                                placeHolder={childsSalesList[child].discount ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(childsSalesList[child].discount) : 'R$ 0,00'}
                                                                onChange={(e) => {
                                                                    childsSalesList[child].discount = parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.'))
                                                                    setChildsSalesList(childsSalesList)
                                                                    setChildsSalesIdList([...childsSalesIdList])
                                                                    calculatorTotalSale()
                                                                }}/>
                                                        </LabelInput>
                                                        :
                                                        childsSalesList[child].selectedTypeDescont == 2 ?
                                                        <LabelInput>
                                                            <Input
                                                                type={'number'}
                                                                width={20}
                                                                value={childsSalesList[child].percentageDiscount ? childsSalesList[child].percentageDiscount : ''}
                                                                id='discount'
                                                                onChange={(e) => {
                                                                    childsSalesList[child].percentageDiscount = e.target.value
                                                                    setChildsSalesList(childsSalesList)
                                                                    setChildsSalesIdList([...childsSalesIdList])
                                                                    calculatorTotalSale()
                                                                }}
                                                            />
                                                        </LabelInput>
                                                        :
                                                        <LabelInput>
                                                            <Input
                                                                type={'number'}
                                                                width={20}
                                                                value={[]}
                                                                id=''
                                                                onChange={{}}
                                                                disabled={true}
                                                            />
                                                        </LabelInput>

                                                    }
                                                    <div className={`flex flex-row gap-3 justify-center items-center ml-1 hover:cursor-pointer text-md text-blue-600`}>
                                                        {childsSalesIdList.length > 1 && <FaMinus className="text-sm" onClick={() => { removeChildSale(child) }} />}
                                                        <FaPlus className="text-sm" onClick={() => addChildSale()} />
                                                    </div>
                                                </tr>
                                            )
                                        })      
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-8 md:mt-5 flex flex-row gap-4 w-full items-center justify-center lg:justify-start'>
                                <Button
                                    onClick={() => navigate('/sales')}
                                    shadow={true}
                                    approval={false}
                                >Cancelar
                                </Button>
                                <Button
                                    shadow={true}
                                    onClick={() => handleSubmit()}
                                >Cadastrar
                                </Button>
                            </div>
                        </>
                    }
                </Body>
            </Container>
        </>
    )
}