import React from 'react'

export function Button({children, height, width, shadow, onClick, action, approval}){

    const widthPixel = width > 0 ? `w-[${width}px]` : 'w-min-40 px-5'
    const widthDefault = width > 0 ? `w-${width}` : 'w-min-40 px-5'

    const heightPixel = height > 0 ? `h-[${height}px]` : 'h-12 sm:h-8'
    const heightDefault = height > 0 ? `h-${height}` : 'h-8 sm:h-8'
    // ${width > 96 ? widthPixel : widthDefault}
    return (
        <button onClick={ () => onClick(action)} className={`focus:outline-none flex flex-row gap-1 items-center justify-center text-sm whitespace-nowrap py-1 px-2 ${approval === false ?'bg-red-800 hover:bg-red-900' : 'bg-primaryDefaultLight dark:bg-primaryDefaultDarkColor hover:bg-secondaryDefaultLight hover:dark:bg-primaryDefaultLight'} rounded-lg  transition-all duration-300 text-titleGrayTextLight ${shadow ? 'shadow-md shadow-gray-500/50 dark:shadow-md dark:shadow-secondaryDefaultDark' : ''}  ${height > 96 ? heightDefault : heightDefault}`}>{children}</button>
    )
}