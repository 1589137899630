import React, { useEffect, useState } from 'react'
import { BsCardChecklist } from 'react-icons/bs'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { FaInfoCircle } from 'react-icons/fa'

export function LabelInput({id, text, required, children, flexRow, error, zIndex, icon, showIconInfo, messageIconInfo }) {
    
    const [fieldError, setFieldError] = useState(false)

    useEffect(()=> {
        setFieldError(error)
    },[error])

    return (
        <label className={`flex ${flexRow ? 'flex-row-reverse' : 'flex-col'} relative gap-1 justify-start  ${zIndex ? `z-[${zIndex}]` : '' }`}>
            <a id={id} className={`tex-lg sm:text-sm  ${fieldError ? 'text-red-700' : 'text-inputPlaceholderLight' } flex ${showIconInfo ? 'justify-between' : ''}`}>
                <span className='flex flex-row'>{text} {required ? '*' : ''} {icon ? icon : ''}</span>
                {
                    showIconInfo ?
                    <span className='flex items-center'>
                        <Tippy
                            key={'iconInfo'}
                            content={messageIconInfo}
                            arrow={true}
                            animation='perspective'
                            placement='top'
                            delay={100}>
                            <div>
                                <FaInfoCircle className='text-sm cursor-help text-gray-700'/>                            
                            </div>
                        </Tippy>
                    </span>
                    : <></>
                }
            </a>
            {children}
        </label>
    )
}