import React, { useEffect, useState } from 'react';
import { Container } from '../../../components/container/container';
import { TitlePage } from '../../../components/titlePages/title.page';
import { Title } from '../../../components/titlePages/title';
import { Body } from '../../../components/container/Body';
import { api } from '../../../services/api/api';
import { LabelInput } from '../../../components/label/label.input';
import { InputAutoComplete } from '../../../components/input/input.autocomplete';
import moment from 'moment';
import { useAuth } from '../../../contexts/useAuth';
import { formatRulesList } from "../../../utils/rulesList";
import { LoadingPulse } from '../../../components/loaders/loadingPulse';
import { FaCloudDownloadAlt } from 'react-icons/fa';

export function ManagerCustomer() {

    const { userData } = useAuth()
    const [periods, setPeriods] = useState()
    const [loadingManager, setLoadingManager] = useState(true)
    const [manager, setManager] = useState([])
    const monthsWithPercent = ['Jan', '%', 'Fev', '%', 'Mar', '%', 'Abr', '%', 'Mai', '%', 'Jun', '%', 'Jul', '%', 'Ago', '%', 'Set', '%', 'Out', '%', 'Nov', '%', 'Dez', '%']
    const theadTableManage = ['Jan', 'A.V.', 'A.H.', 'Fev', 'A.V.', 'A.H.', 'Mar', 'A.V.', 'A.H.', 'Acumulado',
    'A.V.', 'Abr', 'A.V.', 'A.H.', 'Mai', 'A.V.', 'A.H.', 'Jun', 'A.V.', 'A.H.', 'Acumulado', 'A.V.', 
    'Jul', 'A.V.', 'A.H.', 'Ago', 'A.V.', 'A.H.', 'Set', 'A.V.', 'A.H.', 'Acumulado', 'A.V.', 'Out', 'A.V.', 'A.H.', 'Nov', 'A.V.', 'A.H.', 'Dez', 'A.V.', 'A.H.', 'Acumulado', 'A.V.']
    const [selectedPeriod, setSelectedPeriod] = useState(moment().utc(false).format('YYYY'))

    useEffect(() => {
        setPeriods(Array.from({ length: 100 }, (e, index) => {
            return {
                value: 2000 + index
            }
        }))

        async function getManager() {
            setLoadingManager(true)
            const getManagerAPI = await api.get(`api/v1/fc/sends/managerPerCustomer/${selectedPeriod}`, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })
            const formatValues = formatRulesList?.map((filter, indexManager) => {
                const valuesManager = getManagerAPI?.data?.data?.filter(item => item?.description == filter?.description)[0]
                if (!valuesManager) {
                    return {
                        ...filter,
                        values: Array.from({ length: 12 }, (_, index) => {
                            return {
                                month: index,
                                value: 0
                            }
                        })
                    }
                } else {
                    return {
                        ...valuesManager,
                        id: indexManager + 1
                    }
                }
            })

            let arrayRLValues = []
            const newFormatValuesRL = formatValues?.map(manager => {
                manager?.values?.map(value => {
                    if ([1, 2, 3]?.includes(manager?.id)) {
                        arrayRLValues.push(value)
                    }
                })

                if (manager?.id == 4) {
                    const valuesRL = arrayRLValues.reduce((acumulator, month) => {
                        if (!acumulator[month?.month]) {
                            acumulator[month?.month] = month?.value
                        } else {
                            acumulator[month?.month] = acumulator[month?.month] + month?.value
                        }
                        return acumulator
                    }, {})
                    const newValuesRL = Object.keys(valuesRL)?.map(key => {
                        return {
                            month: Number(key),
                            value: valuesRL[key]
                        }
                    })

                    return {
                        ...manager,
                        values: newValuesRL
                    }
                } else {
                    return manager
                }

            })

            let arrayMCValues = []
            const newFormatValuesMC = newFormatValuesRL?.map(manager => {
                manager?.values?.map(value => {
                    if ([4, 5, 6]?.includes(manager?.id)) {
                        arrayMCValues.push(value)
                    }
                })

                if (manager?.id == 7) {
                    const valuesMC = arrayMCValues.reduce((acumulator, month) => {
                        if (!acumulator[month?.month]) {
                            acumulator[month?.month] = month?.value
                        } else {
                            acumulator[month?.month] = acumulator[month?.month] + month?.value
                        }
                        return acumulator
                    }, {})
                    const newValuesMC = Object.keys(valuesMC)?.map(key => {
                        return {
                            month: Number(key),
                            value: valuesMC[key]
                        }
                    })

                    return {
                        ...manager,
                        values: newValuesMC
                    }
                } else {
                    return manager
                }

            })

            let arrayReturnValues = []
            const newFormatValuesReturn = newFormatValuesMC?.map(manager => {
                manager?.values?.map(value => {
                    if ([7, 8, 9]?.includes(manager?.id)) {
                        arrayReturnValues.push(value)
                    }
                })

                if (manager?.id == 10) {
                    const valuesReturn = arrayMCValues.reduce((acumulator, month) => {
                        if (!acumulator[month?.month]) {
                            acumulator[month?.month] = month?.value
                        } else {
                            acumulator[month?.month] = acumulator[month?.month] + month?.value
                        }
                        return acumulator
                    }, {})
                    const newValuesReturn = Object.keys(valuesReturn)?.map(key => {
                        return {
                            month: Number(key),
                            value: valuesReturn[key]
                        }
                    })

                    return {
                        ...manager,
                        values: newValuesReturn
                    }
                } else {
                    return manager
                }

            })

            let arraySecondMC = []
            let arraySecondGF = []
            let arraySecondDF = []
            let arraySecondReturn = []
            let arrayPE = []

            let arrayImDivisionRB = []
            let arrayEstorDivisionRB = []
            let arrayRLDivisionRB = []
            let arrayCVDivisionRL = []
            let arrayDVDivisionRL = []
            let arrayMCDivisionRL = []
            let arrayGFDivisionRL = []
            let arrayDFDivisionRL = []
            let arrayReturnDivisionRL = []
            let arrayParceDivisionRL = []
            let arrayEmpreDivisionRL = []

            let arrayRLAccumulated = []
            let arrayAccumulatedDivisionTemp = []
            let arrayAccumelatedMCDivisionRL = []
            let arrayAccumelatedGFDivisionRL = []
            let arrayAccumelatedDFDivisionRL = []
            let arrayAccumelatedReturnDivisionRL = []
            let arrayAccumelatedPE = {}

            const newSecondForms = newFormatValuesReturn.map(manager => {
                manager?.values?.map(value => {
                    if ([1,2,3,5,6,8,9,15,16]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayPE.push(value)
                    }
                    if ([1,2]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayImDivisionRB.push(value)
                    }
                    if ([1,3]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayEstorDivisionRB.push(value)
                    }
                    if ([1,4]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayRLDivisionRB.push(value)
                    }
                    if ([4,5]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayCVDivisionRL.push(value)
                    }
                    if ([4,6]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayDVDivisionRL.push(value)
                    }
                    if ([4,7]?.includes(manager?.id)){
                        value.id = manager.id
                        arraySecondMC.push(value)
                        arrayMCDivisionRL.push(value)
                    } 
                    if ([4,8]?.includes(manager?.id)){
                        value.id = manager.id
                        arraySecondGF.push(value)
                        arrayGFDivisionRL.push(value)
                    }
                    if ([4,9]?.includes(manager?.id)){
                        value.id = manager.id
                        arraySecondDF.push(value)
                        arrayDFDivisionRL.push(value)
                    }
                    if ([4,10]?.includes(manager?.id)){
                        value.id = manager.id
                        arraySecondReturn.push(value)
                        arrayReturnDivisionRL.push(value)
                    }
                    if ([4,15]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayParceDivisionRL.push(value)
                    }
                    if ([4,16]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayEmpreDivisionRL.push(value)
                    }
                })

                if (manager?.id == 2){
                    const valuesReturn = arrayImDivisionRB.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id1: 0, id2: 0 };
                        }
                        
                        if (month.id === 1) {
                            accumulator[month.month].id1 += month.value;
                        } else if (month.id === 2) {
                            accumulator[month.month].id2 += month.value;
                        }
                
                        return accumulator;
                    }, {});
                    let arrayAH = []
                    let arrayDivisionValues = []  

                    for (const month in valuesReturn) {
                        const { id1, id2 } = valuesReturn[month];
                        if (id1 == 0) {
                            arrayDivisionValues.push({month: Number(month), av: '0,0'})
                            arrayAH.push({month: Number(month), ah: '0,0'})
                        } else {
                            const division = ((id2 / id1) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), av: replaceDivision})

                            const divisionAH = ((valuesReturn[month]?.id2/valuesReturn[month - 1]?.id2 - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month), ah: !valuesReturn[month - 1]?.id2 ? '0,0' : replaceDivisionAH})
                        }
                    }
                    const oneAccumulated = (manager?.values[0]?.value + manager?.values[1]?.value + manager?.values[2]?.value)
                    const twoAccumulated = (manager?.values[3]?.value + manager?.values[4]?.value + manager?.values[5]?.value)
                    const threeAccumulated = (manager?.values[6]?.value + manager?.values[7]?.value + manager?.values[8]?.value)
                    const fourAccumulated = (manager?.values[9]?.value + manager?.values[10]?.value + manager?.values[11]?.value)
                    arrayRLAccumulated.push({
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    })
                    arrayAccumelatedPE[manager?.id] = {
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    }

                    const divisionOne = (oneAccumulated / arrayAccumulatedDivisionTemp[0]?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (twoAccumulated / arrayAccumulatedDivisionTemp[0]?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (threeAccumulated / arrayAccumulatedDivisionTemp[0]?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (fourAccumulated / arrayAccumulatedDivisionTemp[0]?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')
                    
                    
                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fourAccumulated?.toFixed(2)),
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 3){
                    const valuesReturn = arrayEstorDivisionRB.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id1: 0, id3: 0 };
                        }
                        
                        if (month.id === 1) {
                            accumulator[month.month].id1 += month.value;
                        } else if (month.id === 3) {
                            accumulator[month.month].id3 += month.value;
                        }
                
                        return accumulator;
                    }, {});

                    let arrayDivisionValues = []
                    let arrayAH = []                      
                    for (const month in valuesReturn) {
                        const { id1, id3 } = valuesReturn[month];
                        if (id1 == 0) {
                            arrayDivisionValues.push({month: Number(month), av: '0,0'})
                            arrayAH.push({month: Number(month), ah: '0,0'})
                        } else {
                            const division = ((id3 / id1) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), av: replaceDivision})

                            const divisionAH = ((valuesReturn[month]?.id3/valuesReturn[month - 1]?.id3 - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month), ah: !valuesReturn[month - 1]?.id3 ? '0,0' : replaceDivisionAH})
                        }
                    }
                    const oneAccumulated = (manager?.values[0]?.value + manager?.values[1]?.value + manager?.values[2]?.value)
                    const twoAccumulated = (manager?.values[3]?.value + manager?.values[4]?.value + manager?.values[5]?.value)
                    const threeAccumulated = (manager?.values[6]?.value + manager?.values[7]?.value + manager?.values[8]?.value)
                    const fourAccumulated = (manager?.values[9]?.value + manager?.values[10]?.value + manager?.values[11]?.value)
                    arrayRLAccumulated.push({
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    })
                    arrayAccumelatedPE[manager?.id] = {
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    }

                    const divisionOne = (oneAccumulated / arrayAccumulatedDivisionTemp[0]?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (twoAccumulated / arrayAccumulatedDivisionTemp[0]?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (threeAccumulated / arrayAccumulatedDivisionTemp[0]?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (fourAccumulated / arrayAccumulatedDivisionTemp[0]?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fourAccumulated?.toFixed(2)),
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 4){
                    const valuesReturn = arrayRLDivisionRB.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id1: 0, id4: 0 };
                        }
                        
                        if (month.id === 1) {
                            accumulator[month.month].id1 += month.value;
                        } else if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        }
                
                        return accumulator;
                    }, {});

                    let arrayDivisionValues = []  
                    let arrayAH = []                       
                    for (const month in valuesReturn) {
                        const { id1, id4 } = valuesReturn[month];
                        if (id1 == 0) {
                            arrayDivisionValues.push({month: Number(month), av: '0,0'})
                            arrayAH.push({month: Number(month), ah: '0,0'})
                        } else {
                            const division = ((id4 / id1) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), av: replaceDivision})

                            const divisionAH = ((valuesReturn[month]?.id4/valuesReturn[month - 1]?.id4 - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month), ah: !valuesReturn[month - 1]?.id4 ? '0,0' : replaceDivisionAH})
                        }
                    }
                    const sumObjects = arrayRLAccumulated.reduce((accumulator, currentObject) => {
                        accumulator.oneAccumulated += currentObject.oneAccumulated;
                        accumulator.twoAccumulated += currentObject.twoAccumulated;
                        accumulator.threeAccumulated += currentObject.threeAccumulated;
                        accumulator.fourAccumulated += currentObject.fourAccumulated;
                        return accumulator;
                    }, { oneAccumulated: 0, twoAccumulated: 0, threeAccumulated: 0, fourAccumulated: 0 })

                    const divisionOne = (sumObjects?.oneAccumulated / arrayAccumulatedDivisionTemp[0]?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (sumObjects?.twoAccumulated / arrayAccumulatedDivisionTemp[0]?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (sumObjects?.threeAccumulated / arrayAccumulatedDivisionTemp[0]?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (sumObjects?.fourAccumulated / arrayAccumulatedDivisionTemp[0]?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    arrayAccumulatedDivisionTemp = sumObjects

                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(sumObjects?.oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(sumObjects?.twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(sumObjects?.threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(sumObjects?.fourAccumulated?.toFixed(2)),
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 5){
                    const valuesReturn = arrayCVDivisionRL.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id5: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 5) {
                            accumulator[month.month].id5 += month.value;
                        }
                
                        return accumulator;
                    }, {});

                    let arrayDivisionValues = []     
                    let arrayAH = []                        
                    for (const month in valuesReturn) {
                        const { id4, id5 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), av: '0,0'})
                            arrayAH.push({month: Number(month), ah: '0,0'})
                        } else {
                            const division = ((id5 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), av: replaceDivision})

                            const divisionAH = ((valuesReturn[month]?.id5/valuesReturn[month - 1]?.id5 - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month), ah: !valuesReturn[month - 1]?.id5 ? '0,0' : replaceDivisionAH})
                        }
                    }
                    const oneAccumulated = (manager?.values[0]?.value + manager?.values[1]?.value + manager?.values[2]?.value)
                    const twoAccumulated = (manager?.values[3]?.value + manager?.values[4]?.value + manager?.values[5]?.value)
                    const threeAccumulated = (manager?.values[6]?.value + manager?.values[7]?.value + manager?.values[8]?.value)
                    const fourAccumulated = (manager?.values[9]?.value + manager?.values[10]?.value + manager?.values[11]?.value)
                    arrayAccumelatedPE[manager?.id] = {
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    }

                    const divisionOne = (oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fourAccumulated?.toFixed(2)),
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 6){
                    const valuesReturn = arrayDVDivisionRL.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id6: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 6) {
                            accumulator[month.month].id6 += month.value;
                        }
                
                        return accumulator;
                    }, {});

                    let arrayDivisionValues = []    
                    let arrayAH = []                       
                    for (const month in valuesReturn) {
                        const { id4, id6 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), av: '0,0'})
                            arrayAH.push({month: Number(month), ah: '0,0'})
                        } else {
                            const division = ((id6 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), av: replaceDivision})

                            const divisionAH = ((valuesReturn[month]?.id6/valuesReturn[month - 1]?.id6 - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month), ah: !valuesReturn[month - 1]?.id6 ? '0,0' : replaceDivisionAH})
                        }
                    }
                    const oneAccumulated = (manager?.values[0]?.value + manager?.values[1]?.value + manager?.values[2]?.value)
                    const twoAccumulated = (manager?.values[3]?.value + manager?.values[4]?.value + manager?.values[5]?.value)
                    const threeAccumulated = (manager?.values[6]?.value + manager?.values[7]?.value + manager?.values[8]?.value)
                    const fourAccumulated = (manager?.values[9]?.value + manager?.values[10]?.value + manager?.values[11]?.value)
                    arrayAccumelatedPE[manager?.id] = {
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    }

                    const divisionOne = (oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fourAccumulated?.toFixed(2)),
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 7){
                    const valuesReturn = arrayMCDivisionRL.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id7: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 7) {
                            accumulator[month.month].id7 += month.value;
                        }
                
                        return accumulator;
                    }, {});

                    let arrayDivisionValues = []     
                    let arrayAH = []                    
                    for (const month in valuesReturn) {
                        const { id4, id7 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), av: '0,0'})
                            arrayAH.push({month: Number(month), ah: '0,0'})
                        } else {
                            const division = ((id7 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), av: replaceDivision})

                            const divisionAH = ((valuesReturn[month]?.id7/valuesReturn[month - 1]?.id7 - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month), ah: !valuesReturn[month - 1]?.id7 ? '0,0' : replaceDivisionAH})
                        }
                    }
                    const oneAccumulated = (manager?.values[0]?.value + manager?.values[1]?.value + manager?.values[2]?.value)
                    const twoAccumulated = (manager?.values[3]?.value + manager?.values[4]?.value + manager?.values[5]?.value)
                    const threeAccumulated = (manager?.values[6]?.value + manager?.values[7]?.value + manager?.values[8]?.value)
                    const fourAccumulated = (manager?.values[9]?.value + manager?.values[10]?.value + manager?.values[11]?.value)

                    const divisionOne = (oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    arrayAccumelatedMCDivisionRL.push({
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    })

                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fourAccumulated?.toFixed(2)),
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 8){
                    const valuesReturn = arrayGFDivisionRL.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id8: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 8) {
                            accumulator[month.month].id8 += month.value;
                        }
                
                        return accumulator;
                    }, {});

                    let arrayDivisionValues = []    
                    let arrayAH = []                      
                    for (const month in valuesReturn) {
                        const { id4, id8 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), av: '0,0'})
                            arrayAH.push({month: Number(month), ah: '0,0'})
                        } else {
                            const division = ((id8 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), av: replaceDivision})

                            const divisionAH = ((valuesReturn[month]?.id8/valuesReturn[month - 1]?.id8 - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month), ah: !valuesReturn[month - 1]?.id8 ? '0,0' : replaceDivisionAH})
                        }
                    }
                    const oneAccumulated = (manager?.values[0]?.value + manager?.values[1]?.value + manager?.values[2]?.value)
                    const twoAccumulated = (manager?.values[3]?.value + manager?.values[4]?.value + manager?.values[5]?.value)
                    const threeAccumulated = (manager?.values[6]?.value + manager?.values[7]?.value + manager?.values[8]?.value)
                    const fourAccumulated = (manager?.values[9]?.value + manager?.values[10]?.value + manager?.values[11]?.value)
                    arrayAccumelatedPE[manager?.id] = {
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    }

                    const divisionOne = (oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    arrayAccumelatedGFDivisionRL.push({
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    })

                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fourAccumulated?.toFixed(2)),
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 9){
                    const valuesReturn = arrayDFDivisionRL.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id9: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 9) {
                            accumulator[month.month].id9 += month.value;
                        }
                
                        return accumulator;
                    }, {});

                    let arrayDivisionValues = []        
                    let arrayAH = []                  
                    for (const month in valuesReturn) {
                        const { id4, id9 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), av: '0,0'})
                            arrayAH.push({month: Number(month), ah: '0,0'})
                        } else {
                            const division = ((id9 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), av: replaceDivision})

                            const divisionAH = ((valuesReturn[month]?.id9/valuesReturn[month - 1]?.id9 - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month), ah: !valuesReturn[month - 1]?.id9 ? '0,0' : replaceDivisionAH})
                        }
                    }
                    const oneAccumulated = (manager?.values[0]?.value + manager?.values[1]?.value + manager?.values[2]?.value)
                    const twoAccumulated = (manager?.values[3]?.value + manager?.values[4]?.value + manager?.values[5]?.value)
                    const threeAccumulated = (manager?.values[6]?.value + manager?.values[7]?.value + manager?.values[8]?.value)
                    const fourAccumulated = (manager?.values[9]?.value + manager?.values[10]?.value + manager?.values[11]?.value)
                    arrayAccumelatedPE[manager?.id] = {
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    }

                    const divisionOne = (oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    arrayAccumelatedDFDivisionRL.push({
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    })

                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fourAccumulated?.toFixed(2)),
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 10){
                    const valuesReturn = arrayReturnDivisionRL.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id10: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 10) {
                            accumulator[month.month].id10 += month.value;
                        }
                
                        return accumulator;
                    }, {});

                    let arrayDivisionValues = []   
                    let arrayAH = []                        
                    for (const month in valuesReturn) {
                        const { id4, id10 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), av: '0,0'})
                            arrayAH.push({month: Number(month), ah: '0,0'})
                        } else {
                            const division = ((id10 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), av: replaceDivision})

                            const divisionAH = ((valuesReturn[month]?.id10/valuesReturn[month - 1]?.id10 - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month), ah: !valuesReturn[month - 1]?.id10 ? '0,0' : replaceDivisionAH})
                        }
                    }
                    const oneAccumulated = (manager?.values[0]?.value + manager?.values[1]?.value + manager?.values[2]?.value)
                    const twoAccumulated = (manager?.values[3]?.value + manager?.values[4]?.value + manager?.values[5]?.value)
                    const threeAccumulated = (manager?.values[6]?.value + manager?.values[7]?.value + manager?.values[8]?.value)
                    const fourAccumulated = (manager?.values[9]?.value + manager?.values[10]?.value + manager?.values[11]?.value)

                    const divisionOne = (oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    arrayAccumelatedReturnDivisionRL.push({
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    })

                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fourAccumulated?.toFixed(2)),
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 11){
                    const valuesReturn = arraySecondMC.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id7: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 7) {
                            accumulator[month.month].id7 += month.value;
                        }
                
                        return accumulator;
                    }, {});

                    let arrayDivisionValues = []                          
                    for (const month in valuesReturn) {
                        const { id4, id7 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), value: 0})
                        } else {
                            const division = ((id7 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), value: replaceDivision})
                        }
                    }

                    const divisionOne = (arrayAccumelatedMCDivisionRL[0]?.oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (arrayAccumelatedMCDivisionRL[0]?.twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (arrayAccumelatedMCDivisionRL[0]?.threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (arrayAccumelatedMCDivisionRL[0]?.fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    return {
                        ...manager,
                        av: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                ah: '0,0'
                            }
                        }),
                        values: arrayDivisionValues,
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 12){
                    const valuesReturn = arraySecondGF.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id8: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 8) {
                            accumulator[month.month].id8 += month.value;
                        }
                
                        return accumulator;
                    }, {});
                    let arrayDivisionValues = []
                      
                    for (const month in valuesReturn) {
                        const { id4, id8 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), value: 0})
                        } else {
                            const division = ((id8 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), value: replaceDivision})
                        }
                    }

                    const divisionOne = (arrayAccumelatedGFDivisionRL[0]?.oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (arrayAccumelatedGFDivisionRL[0]?.twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (arrayAccumelatedGFDivisionRL[0]?.threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (arrayAccumelatedGFDivisionRL[0]?.fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    return {
                        ...manager,
                        av: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                ah: '0,0'
                            }
                        }),
                        values: arrayDivisionValues,
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 13){
                    const valuesReturn = arraySecondDF.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id9: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 9) {
                            accumulator[month.month].id9 += month.value;
                        }
                
                        return accumulator;
                    }, {});
                    let arrayDivisionValues = []
                      
                    for (const month in valuesReturn) {
                        const { id4, id9 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), value: 0})
                        } else {
                            const division = ((id9 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), value: replaceDivision})
                        }
                    }

                    const divisionOne = (arrayAccumelatedDFDivisionRL[0]?.oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (arrayAccumelatedDFDivisionRL[0]?.twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (arrayAccumelatedDFDivisionRL[0]?.threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (arrayAccumelatedDFDivisionRL[0]?.fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    return {
                        ...manager,
                        av: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                ah: '0,0'
                            }
                        }),
                        values: arrayDivisionValues,
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 14){
                    const valuesReturn = arraySecondReturn.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id10: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 10) {
                            accumulator[month.month].id10 += month.value;
                        }
                
                        return accumulator;
                    }, {});
                    let arrayDivisionValues = []
                      
                    for (const month in valuesReturn) {
                        const { id4, id10 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), value: 0})
                        } else {
                            const division = ((id10 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), value: replaceDivision})
                        }
                    }

                    const divisionOne = (arrayAccumelatedReturnDivisionRL[0]?.oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (arrayAccumelatedReturnDivisionRL[0]?.twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (arrayAccumelatedReturnDivisionRL[0]?.threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (arrayAccumelatedReturnDivisionRL[0]?.fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    return {
                        ...manager,
                        av: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                ah: '0,0'
                            }
                        }),
                        values: arrayDivisionValues,
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 15){
                    const valuesReturn = arrayParceDivisionRL.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id15: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 15) {
                            accumulator[month.month].id15 += month.value;
                        }
                
                        return accumulator;
                    }, {});

                    let arrayDivisionValues = []                          
                    for (const month in valuesReturn) {
                        const { id4, id15 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), av: '0,0'})
                        } else {
                            const division = ((id15 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), av: replaceDivision})
                        }
                    }
                    const oneAccumulated = (manager?.values[0]?.value + manager?.values[1]?.value + manager?.values[2]?.value)
                    const twoAccumulated = (manager?.values[3]?.value + manager?.values[4]?.value + manager?.values[5]?.value)
                    const threeAccumulated = (manager?.values[6]?.value + manager?.values[7]?.value + manager?.values[8]?.value)
                    const fourAccumulated = (manager?.values[9]?.value + manager?.values[10]?.value + manager?.values[11]?.value)
                    arrayAccumelatedPE[manager?.id] = {
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    }

                    const divisionOne = (oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                ah: '0,0'
                            }
                        }),
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fourAccumulated?.toFixed(2)),
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 16){
                    const valuesReturn = arrayEmpreDivisionRL.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id4: 0, id16: 0 };
                        }
                        
                        if (month.id === 4) {
                            accumulator[month.month].id4 += month.value;
                        } else if (month.id === 16) {
                            accumulator[month.month].id16 += month.value;
                        }
                
                        return accumulator;
                    }, {});

                    let arrayDivisionValues = []                          
                    for (const month in valuesReturn) {
                        const { id4, id16 } = valuesReturn[month];
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month), av: '0,0'})
                        } else {
                            const division = ((id16 / id4) * 100)?.toFixed(1);
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month), av: replaceDivision})
                        }
                    }
                    const oneAccumulated = (manager?.values[0]?.value + manager?.values[1]?.value + manager?.values[2]?.value)
                    const twoAccumulated = (manager?.values[3]?.value + manager?.values[4]?.value + manager?.values[5]?.value)
                    const threeAccumulated = (manager?.values[6]?.value + manager?.values[7]?.value + manager?.values[8]?.value)
                    const fourAccumulated = (manager?.values[9]?.value + manager?.values[10]?.value + manager?.values[11]?.value)
                    arrayAccumelatedPE[manager?.id] = {
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    }

                    const divisionOne = (oneAccumulated / arrayAccumulatedDivisionTemp?.oneAccumulated * 100)?.toFixed(1)
                    const replaceDivisionOne = isNaN(divisionOne) || divisionOne == 0 || divisionOne == '-Infinity' ? '0,0' : divisionOne?.replace('.', ',')

                    const divisionTwo = (twoAccumulated / arrayAccumulatedDivisionTemp?.twoAccumulated * 100)?.toFixed(1)
                    const replaceDivisionTwo = isNaN(divisionTwo) || divisionTwo == 0 || divisionTwo == '-Infinity' ? '0,0' : divisionTwo?.replace('.', ',')

                    const divisionThree = (threeAccumulated / arrayAccumulatedDivisionTemp?.threeAccumulated * 100)?.toFixed(1)
                    const replaceDivisionThree = isNaN(divisionThree) || divisionThree == 0 || divisionThree == '-Infinity' ? '0,0' : divisionThree?.replace('.', ',')

                    const divisionFour = (fourAccumulated / arrayAccumulatedDivisionTemp?.fourAccumulated * 100)?.toFixed(1)
                    const replaceDivisionFour = isNaN(divisionFour) || divisionFour == 0 || divisionFour == '-Infinity' ? '0,0' : divisionFour?.replace('.', ',')

                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                ah: '0,0'
                            }
                        }),
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fourAccumulated?.toFixed(2)),
                        oneAccumulatedPorcent: replaceDivisionOne,
                        twoAccumulatedPorcent: replaceDivisionTwo,
                        threeAccumulatedPorcent: replaceDivisionThree,
                        fourAccumulatedPorcent: replaceDivisionFour,
                    }
                } else if (manager?.id == 17){
                    const valuesReturn = arrayPE.reduce((accumulator, month) => {
                        if (!accumulator[month.month]) {
                            accumulator[month.month] = { id1: 0, id2: 0, id3: 0, id5: 0, id6: 0, id8: 0, id9: 0, id15: 0, id16: 0};
                        }
                        
                        if (month.id === 1) {
                            accumulator[month.month].id1 += month.value;
                        } else if (month.id === 2) {
                            accumulator[month.month].id2 += month.value;
                        } else if (month.id === 3) {
                            accumulator[month.month].id3 += month.value;
                        } else if (month.id === 5) {
                            accumulator[month.month].id5 += month.value;
                        } else if (month.id === 6) {
                            accumulator[month.month].id6 += month.value;
                        } else if (month.id === 8) {
                            accumulator[month.month].id8 += month.value;
                        } else if (month.id === 9) {
                            accumulator[month.month].id9 += month.value;
                        } else if (month.id === 15) {
                            accumulator[month.month].id15 += month.value;
                        } else if (month.id === 16) {
                            accumulator[month.month].id16 += month.value;
                        }
                
                        return accumulator;
                    }, {});
                    
                    let arrayDivisionValues = []                          
                    for (const month in valuesReturn) {
                        const { id1,id2,id3,id5,id6,id8,id9,id15,id16 } = valuesReturn[month];

                        const result = (-id8-id15-id16)/(1-(-id2-id3-id5-id6-id9)/id1)
                        if (isNaN(result) || result == 0) {
                            arrayDivisionValues.push({month: Number(month), value: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(0), porcent: '0,0'})
                        } else {
                            arrayDivisionValues.push({month: Number(month), value: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(result?.toFixed(2)), porcent: ((id1/result) * 100)?.toFixed(1)})         
                        }
                    }

                    let oneAccumulated
                    let twoAccumulated
                    let threeAccumulated
                    let fourAccumulated
                    let oneAccumulatedPorcent
                    let twoAccumulatedPorcent
                    let threeAccumulatedPorcent
                    let fourAccumulatedPorcent

                    const resultOne = (
                        -arrayAccumelatedPE[8]?.oneAccumulated-arrayAccumelatedPE[15]?.oneAccumulated-arrayAccumelatedPE[16]?.oneAccumulated) / (1-(-arrayAccumelatedPE[2]?.oneAccumulated-arrayAccumelatedPE[3]?.oneAccumulated-arrayAccumelatedPE[5]?.oneAccumulated-arrayAccumelatedPE[6]?.oneAccumulated-arrayAccumelatedPE[9]?.oneAccumulated)/arrayAccumelatedPE[1]?.oneAccumulated
                    )
                    if (isNaN(resultOne) || resultOne == 0){
                        oneAccumulated = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(0)
                        oneAccumulatedPorcent = '0,0'
                    } else {
                        oneAccumulated = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(resultOne?.toFixed(2))
                        oneAccumulatedPorcent = ((arrayAccumelatedPE[1]?.oneAccumulated/resultOne) * 100)?.toFixed(1)
                    }

                    const resultTwo = (
                        -arrayAccumelatedPE[8]?.twoAccumulated-arrayAccumelatedPE[15]?.twoAccumulated-arrayAccumelatedPE[16]?.twoAccumulated) / (1-(-arrayAccumelatedPE[2]?.twoAccumulated-arrayAccumelatedPE[3]?.twoAccumulated-arrayAccumelatedPE[5]?.twoAccumulated-arrayAccumelatedPE[6]?.twoAccumulated-arrayAccumelatedPE[9]?.twoAccumulated)/arrayAccumelatedPE[1]?.twoAccumulated
                    )
                    if (isNaN(resultTwo) || resultTwo == 0){
                        twoAccumulated = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(0)
                        twoAccumulatedPorcent = '0,0'
                    } else {
                        twoAccumulated = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(resultOne?.toFixed(2))
                        twoAccumulatedPorcent = ((arrayAccumelatedPE[1]?.twoAccumulated/resultTwo) * 100)?.toFixed(1)
                    }

                    const resultThree = (
                        -arrayAccumelatedPE[8]?.threeAccumulated-arrayAccumelatedPE[15]?.threeAccumulated-arrayAccumelatedPE[16]?.threeAccumulated) / (1-(-arrayAccumelatedPE[2]?.threeAccumulated-arrayAccumelatedPE[3]?.threeAccumulated-arrayAccumelatedPE[5]?.threeAccumulated-arrayAccumelatedPE[6]?.threeAccumulated-arrayAccumelatedPE[9]?.threeAccumulated)/arrayAccumelatedPE[1]?.threeAccumulated
                    )
                    if (isNaN(resultThree) || resultThree == 0){
                        threeAccumulated = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(0)
                        threeAccumulatedPorcent = '0,0'
                    } else {
                        threeAccumulated = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(resultOne?.toFixed(2))
                        threeAccumulatedPorcent = ((arrayAccumelatedPE[1]?.threeAccumulated/resultThree) * 100)?.toFixed(1)
                    }

                    const resultFour = (
                        -arrayAccumelatedPE[8]?.fourAccumulated-arrayAccumelatedPE[15]?.fourAccumulated-arrayAccumelatedPE[16]?.fourAccumulated) / (1-(-arrayAccumelatedPE[2]?.fourAccumulated-arrayAccumelatedPE[3]?.fourAccumulated-arrayAccumelatedPE[5]?.fourAccumulated-arrayAccumelatedPE[6]?.fourAccumulated-arrayAccumelatedPE[9]?.fourAccumulated)/arrayAccumelatedPE[1]?.fourAccumulated
                    )
                    if (isNaN(resultFour) || resultFour == 0){
                        fourAccumulated = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(0)
                        fourAccumulatedPorcent = '0,0'
                    } else {
                        fourAccumulated = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(resultOne?.toFixed(2))
                        threeAccumulatedPorcent = ((arrayAccumelatedPE[1]?.fourAccumulated/resultFour) * 100)?.toFixed(1)
                    }

                    return {
                        ...manager,
                        av: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                ah: '0,0'
                            }
                        }),
                        values: arrayDivisionValues,
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated,
                        oneAccumulatedPorcent,
                        twoAccumulatedPorcent,
                        threeAccumulatedPorcent,
                        fourAccumulatedPorcent
                    }
                } else {
                    const oneAccumulated = (manager?.values[0]?.value + manager?.values[1]?.value + manager?.values[2]?.value)
                    const twoAccumulated = (manager?.values[3]?.value + manager?.values[4]?.value + manager?.values[5]?.value)
                    const threeAccumulated = (manager?.values[6]?.value + manager?.values[7]?.value + manager?.values[8]?.value)
                    const fourAccumulated = (manager?.values[9]?.value + manager?.values[10]?.value + manager?.values[11]?.value)
                    arrayRLAccumulated.push({
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    })
                    arrayAccumulatedDivisionTemp.push({
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    })
                    arrayAccumelatedPE[manager?.id] = {
                        oneAccumulated,
                        twoAccumulated,
                        threeAccumulated,
                        fourAccumulated
                    }
                    return {
                        ...manager,
                        av: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: 12}, (_,index) => {
                            return {
                                month: index + 1,
                                ah: '0,0'
                            }
                        }),
                        oneAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(oneAccumulated?.toFixed(2)),
                        twoAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(twoAccumulated?.toFixed(2)),
                        threeAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(threeAccumulated?.toFixed(2)),
                        fourAccumulated: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(fourAccumulated?.toFixed(2)),
                    }
                }
            })

            setManager(structuredClone(newSecondForms))
            setLoadingManager(false)
        }

        getManager()
    }, [selectedPeriod])

    return (
        <Container>
            <TitlePage>
                <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                    <div className='flex flex-col items-start justify-center w-full sm:w-[50%] md:w-auto'>
                        <Title text={'Gerencial Cliente'}></Title>
                    </div>
                </div>
            </TitlePage>
            <Body>
                <div className="flex text-center sm:hidden">
                    <p>Essa funcionalidade está disponivel apenas para computador</p>
                </div>
                <div className='w-full hidden sm:flex items-center gap-10'>
                    <LabelInput>
                        <InputAutoComplete
                            data={periods}
                            preSelectedValue={selectedPeriod ? selectedPeriod : ''}
                            value={selectedPeriod ? selectedPeriod : ''}
                            selectedLabel={'value'}
                            optionList={['value']}
                            onChange={(e) => {
                                setSelectedPeriod(e?.value)
                            }}
                            placeHolder={'Selecione o ano'}
                        />
                    </LabelInput>
                    <div>
                        <FaCloudDownloadAlt className="text-2xl" />
                    </div>
                </div>
                <div className='hidden sm:flex sm:flex-col h-[63vh] relative mt-5 w-full'>
                    {
                        loadingManager ?
                            <div className="flex flex-col pb-2 pr-2 bg-gray-200  animate-pulse w-full h-full rounded-md">
                                <p className="text-center flex flex-row justify-center items-center m-auto"><LoadingPulse /></p>
                            </div>
                            :
                            <div className="flex flex-col pb-2 shadow-xl pr-2 overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 relative rounded-md">
                                <table className='border-collapse'>
                                    <thead className='m-0 p-0 bg-primaryDefaultLight text-white font-bold relative'>
                                        <tr className='sticky top-0 z-20'>
                                            <th className='sticky left-0 top-0 bg-primaryDefaultLight '>
                                                <div className='min-w-[20rem] z-5 p-1 border-0 border-r-2  border-r-gray-300 text-base'>
                                                    Painel Gerencial Operacional
                                                </div>
                                            </th>
                                            {
                                                theadTableManage.map(month => {
                                                    return (
                                                        <th className='bg-primaryDefaultLight  text-base'>{month}</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className='pb-2 m-0'>
                                        {
                                            manager.map((manager, index) => {
                                                // vem com base no arquivo ../../../utils/rulesList.js
                                                if (![11,12,13,14,15,16,17].includes(manager?.id)){
                                                    return (
                                                        <>
                                                            <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                                                <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                                    <div className='min-w-[10rem] py-1 px-1 items-center dark:text-white text-sm'>{manager?.description}</div>
                                                                </td>
                                                                {
                                                                    manager?.values?.map((value, indexValue) => {
                                                                        return (
                                                                            <>
                                                                                <td className={`m-0 p-0 sticky left-0 z-3 border-l border-l-gray-600`}>
                                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value?.value)}</div>
                                                                                </td>
                                                                                <td className={`m-0 p-0 sticky left-0 z-3`}>
                                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{manager?.id == 1 ? '' : `${manager?.av[indexValue]?.av}%`}</div>
                                                                                </td>
                                                                                <td className={`m-0 p-0 sticky left-0 z-3`}>
                                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{manager?.id == 1 ? '' : indexValue + 1 == 1 ? '0,0%' : `${manager?.ah[indexValue]?.ah}%`}</div>
                                                                                </td>
                                                                                {
                                                                                    // REGRAS PARA PARA CONTROLAR O ACUMULADO COM BASE NA POSIÇÃO 3 EM 3 TRAS DETALHES ACUMULADOS
                                                                                    [2,5,8,11].includes(indexValue) &&
                                                                                    <>
                                                                                        <td className={`m-0 p-0 sticky left-0 z-3 border-l border-l-gray-600`}>
                                                                                            {
                                                                                                manager?.id == 1 ?                                                                                                            
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 2 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 3 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 4 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 5 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 6 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 7 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 8 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 9 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </td>
                                                                                        <td className={`m-0 p-0 sticky left-0 z-3 border-r border-r-gray-600`}>
                                                                                            {
                                                                                                manager?.id == 1 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '></div>
                                                                                                : manager?.id == 2 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulatedPorcent + '%'
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulatedPorcent + '%'
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulatedPorcent + '%'
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulatedPorcent + '%'
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 3 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulatedPorcent + '%'
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulatedPorcent + '%'
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulatedPorcent + '%'
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulatedPorcent + '%'
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 4 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulatedPorcent + '%'
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulatedPorcent + '%'
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulatedPorcent + '%'
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulatedPorcent + '%'
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 5 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulatedPorcent + '%'
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulatedPorcent + '%'
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulatedPorcent + '%'
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulatedPorcent + '%'
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 6 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulatedPorcent + '%'
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulatedPorcent + '%'
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulatedPorcent + '%'
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulatedPorcent + '%'
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 7 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulatedPorcent + '%'
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulatedPorcent + '%'
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulatedPorcent + '%'
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulatedPorcent + '%'
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 8 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulatedPorcent + '%'
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulatedPorcent + '%'
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulatedPorcent + '%'
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulatedPorcent + '%'
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                : manager?.id == 9 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulatedPorcent + '%'
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulatedPorcent + '%'
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulatedPorcent + '%'
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulatedPorcent + '%'
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulatedPorcent + '%'
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulatedPorcent + '%'
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulatedPorcent + '%'
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulatedPorcent + '%'
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </td>                                                                                                
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                        {
                                            Array.from({ length: 2 }, (_, indexL) => {                                                            
                                                return (
                                                    <tr className={`bg-blue-100   dark:bg-thirdDefaultDark`}>
                                                        {
                                                            // 17 = É IGUAL 12 MESES + COLUNA 1 (DESCRIÇÃO) + 4 ACUMULADOS TRIMESTRAIS
                                                            Array.from({length: 17}, (_, indexTD) => {
                                                                if (indexTD + 1 == 1){
                                                                    return (
                                                                        <td className={`z-50 bg-blue-100 m-0 p-0 sticky left-0 `}>
                                                                            <div className='w-full z-3 py-1 px-1 h-full bg-bg-blue-100 flex items-center dark:text-white text-sm'></div>
                                                                        </td>
                                                                    )
                                                                }
                                                                
                                                                // POSIÇÃO DOS ACUMULADOS
                                                                if([5,9,13,17].includes(indexTD + 1)){
                                                                    return (
                                                                        <>
                                                                            <td className="border-l border-l-gray-700"><div className='min-w-[10rem] py-1 px-1 h-5 flex items-center dark:text-white text-sm'></div></td>
                                                                            <td className={`m-0 p-0 sticky left-0 border-r border-r-gray-700`}><div className='min-w-[10rem] py-1 px-1 h-5 border-0 flex items-center text-sm justify-center dark:text-white '></div></td>
                                                                        </>
                                                                    )
                                                                }

                                                                return (
                                                                    <>
                                                                        <td className={`m-0 p-0 sticky left-0 border-l border-l-gray-700`}><div className='min-w-[10rem] py-1 px-1 h-5 border-0 flex items-center text-sm justify-center dark:text-white '></div></td>
                                                                        <td><div className='min-w-[10rem] py-1 px-1 h-5 flex items-center dark:text-white text-sm'></div></td>
                                                                        <td><div className='min-w-[10rem] py-1 px-1 h-5 flex items-center dark:text-white text-sm'></div></td>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            manager.map((manager, index) => {
                                                if ([11,12,13,14].includes(manager?.id)){
                                                    return (
                                                        <>
                                                            <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                                                <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                                    <div className='min-w-[10rem] py-1 px-1 flex items-center dark:text-white text-sm'>{manager?.description}</div>
                                                                </td>
                                                                {
                                                                    manager?.values?.map((value, indexValue) => {
                                                                        return (
                                                                            <>
                                                                                <td className={`m-0 p-0 sticky left-0 z-3 border-l border-l-gray-700`} colSpan={3}>
                                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{value?.value}%</div>
                                                                                </td>
                                                                                {
                                                                                    [2,5,8,11].includes(indexValue) &&
                                                                                    <td className={`m-0 p-0 sticky left-0 z-3 border-l border-l-gray-700 border-r border-r-gray-700`} colSpan={2}>
                                                                                        {
                                                                                            manager?.id == 11 ?
                                                                                            <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                {
                                                                                                    indexValue == 2 ?
                                                                                                    manager?.oneAccumulatedPorcent + '%'
                                                                                                    : indexValue == 5 ?
                                                                                                    manager?.twoAccumulatedPorcent + '%'
                                                                                                    : indexValue == 8 ?
                                                                                                    manager?.threeAccumulatedPorcent + '%'
                                                                                                    : indexValue == 11 ?
                                                                                                    manager?.fourAccumulatedPorcent + '%'
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                            : manager?.id == 12 ?
                                                                                            <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                {
                                                                                                    indexValue == 2 ?
                                                                                                    manager?.oneAccumulatedPorcent + '%'
                                                                                                    : indexValue == 5 ?
                                                                                                    manager?.twoAccumulatedPorcent + '%'
                                                                                                    : indexValue == 8 ?
                                                                                                    manager?.threeAccumulatedPorcent + '%'
                                                                                                    : indexValue == 11 ?
                                                                                                    manager?.fourAccumulatedPorcent + '%'
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                            : manager?.id == 13 ?
                                                                                            <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                {
                                                                                                    indexValue == 2 ?
                                                                                                    manager?.oneAccumulatedPorcent + '%'
                                                                                                    : indexValue == 5 ?
                                                                                                    manager?.twoAccumulatedPorcent + '%'
                                                                                                    : indexValue == 8 ?
                                                                                                    manager?.threeAccumulatedPorcent + '%'
                                                                                                    : indexValue == 11 ?
                                                                                                    manager?.fourAccumulatedPorcent + '%'
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                {
                                                                                                    indexValue == 2 ?
                                                                                                    manager?.oneAccumulatedPorcent + '%'
                                                                                                    : indexValue == 5 ?
                                                                                                    manager?.twoAccumulatedPorcent + '%'
                                                                                                    : indexValue == 8 ?
                                                                                                    manager?.threeAccumulatedPorcent + '%'
                                                                                                    : indexValue == 11 ?
                                                                                                    manager?.fourAccumulatedPorcent + '%'
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </td>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                        {
                                            Array.from({ length: 2 }, (_, indexL) => {                                                            
                                                return (
                                                    <tr className={`bg-blue-100   dark:bg-thirdDefaultDark`}>
                                                        {
                                                            // 17 = É IGUAL 12 MESES + COLUNA 1 (DESCRIÇÃO) + 4 ACUMULADOS TRIMESTRAIS
                                                            Array.from({length: 17}, (_, indexTD) => {
                                                                if (indexTD + 1 == 1){
                                                                    return (
                                                                        <td className={`m-0 p-0 z-3 bg-blue-100 sticky left-0`}>
                                                                            <div className='min-w-[10rem] py-1 px-1 h-full bg-bg-blue-100 flex items-center dark:text-white text-sm'></div>
                                                                        </td>
                                                                    )
                                                                }
                                                                
                                                                // POSIÇÃO DOS ACUMULADOS
                                                                if([5,9,13,17].includes(indexTD + 1)){
                                                                    return (
                                                                        <>
                                                                            <td className="border-l border-l-gray-700"><div className='min-w-[10rem] py-1 px-1 h-5 flex items-center dark:text-white text-sm'></div></td>
                                                                            <td className={`m-0 p-0 sticky left-0 border-r border-r-gray-700`}><div className='min-w-[10rem] py-1 px-1 h-5 border-0 flex items-center text-sm justify-center dark:text-white '></div></td>
                                                                        </>
                                                                    )
                                                                }

                                                                return (
                                                                    <>
                                                                        <td className={`m-0 p-0 sticky left-0 border-l border-l-gray-700`}><div className='min-w-[10rem] py-1 px-1 h-5 border-0 flex items-center text-sm justify-center dark:text-white '></div></td>
                                                                        <td><div className='min-w-[10rem] py-1 px-1 h-5 flex items-center dark:text-white text-sm'></div></td>
                                                                        <td><div className='min-w-[10rem] py-1 px-1 h-5 flex items-center dark:text-white text-sm'></div></td>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            manager.map((manager, index) => {
                                                if ([15,16].includes(manager?.id)){
                                                    return (
                                                        <>
                                                            <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                                                <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                                    <div className='min-w-[10rem] py-1 px-1 flex items-center dark:text-white text-sm'>{manager?.description}</div>
                                                                </td>
                                                                {
                                                                    manager?.values?.map((value, indexValue) => {
                                                                        return (
                                                                            <>
                                                                                <td className={`m-0 p-0 sticky left-0 z-3 border-l border-l-gray-700`}>
                                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value?.value)}</div>
                                                                                </td>
                                                                                <td className={`m-0 p-0 sticky left-0 z-3`}>
                                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{manager?.id == 1 ? '' : `${manager?.av[indexValue]?.av}%`}</div>
                                                                                </td>
                                                                                <td className={`m-0 p-0 sticky left-0 z-3`}>
                                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>0,0%</div>
                                                                                </td>
                                                                                {
                                                                                    [2,5,8,11].includes(indexValue) &&
                                                                                    <>
                                                                                        <td className={`m-0 p-0 sticky left-0 z-3 border-l border-l-gray-700`}>
                                                                                            {
                                                                                                manager?.id == 15 ? 
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulated
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulated
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulated
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulated
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </td>
                                                                                        <td className={`m-0 p-0 sticky left-0 z-3 border-r border-r-gray-700`}>
                                                                                            {
                                                                                                manager?.id == 15 ?
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulatedPorcent + '%'
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulatedPorcent + '%'
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulatedPorcent + '%'
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulatedPorcent + '%'
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                                    {
                                                                                                        indexValue == 2 ?
                                                                                                        manager?.oneAccumulatedPorcent + '%'
                                                                                                        : indexValue == 5 ?
                                                                                                        manager?.twoAccumulatedPorcent + '%'
                                                                                                        : indexValue == 8 ?
                                                                                                        manager?.threeAccumulatedPorcent + '%'
                                                                                                        : indexValue == 11 ?
                                                                                                        manager?.fourAccumulatedPorcent + '%'
                                                                                                        : ''
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </td>                                                                                                
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                        {
                                            Array.from({ length: 2 }, (_, indexL) => {                                                            
                                                return (
                                                    <tr className={`bg-blue-100   dark:bg-thirdDefaultDark`}>
                                                        {
                                                            // 17 = É IGUAL 12 MESES + COLUNA 1 (DESCRIÇÃO) + 4 ACUMULADOS TRIMESTRAIS
                                                            Array.from({length: 17}, (_, indexTD) => {
                                                                if (indexTD + 1 == 1){
                                                                    return (
                                                                        <td className={`m-0 p-0 z-3 bg-blue-100 sticky left-0`}>
                                                                            <div className='min-w-[10rem] py-1 px-1 h-full bg-bg-blue-100 flex items-center dark:text-white text-sm'></div>
                                                                        </td>
                                                                    )
                                                                }
                                                                
                                                                // POSIÇÃO DOS ACUMULADOS
                                                                if([5,9,13,17].includes(indexTD + 1)){
                                                                    return (
                                                                        <>
                                                                            <td className="border-l border-l-gray-700"><div className='min-w-[10rem] py-1 px-1 h-5 flex items-center dark:text-white text-sm'></div></td>
                                                                            <td className={`m-0 p-0 sticky left-0 border-r border-r-gray-700`}><div className='min-w-[10rem] py-1 px-1 h-5 border-0 flex items-center text-sm justify-center dark:text-white '></div></td>
                                                                        </>
                                                                    )
                                                                }

                                                                return (
                                                                    <>
                                                                        <td className={`m-0 p-0 sticky left-0 border-l border-l-gray-700`}><div className='min-w-[10rem] py-1 px-1 h-5 border-0 flex items-center text-sm justify-center dark:text-white '></div></td>
                                                                        <td><div className='min-w-[10rem] py-1 px-1 h-5 flex items-center dark:text-white text-sm'></div></td>
                                                                        <td><div className='min-w-[10rem] py-1 px-1 h-5 flex items-center dark:text-white text-sm'></div></td>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            manager.map((manager, index) => {
                                                if ([17].includes(manager?.id)){
                                                    return (
                                                        <>
                                                            <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                                                <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                                    <div className='min-w-[10rem] py-1 px-1 flex items-center dark:text-white text-sm'>{manager?.description}</div>
                                                                </td>
                                                                {
                                                                    manager?.values?.map((value, indexValue) => {
                                                                        return (
                                                                            <>
                                                                                <td className={`m-0 p-0 sticky left-0 z-3 border-l border-l-gray-700`} colSpan={3} rowSpan={2}>
                                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{value?.value}</div>
                                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{value?.porcent}%</div>
                                                                                </td>
                                                                                {
                                                                                    [2,5,8,11].includes(indexValue) &&
                                                                                    <td className={`m-0 p-0 sticky left-0 z-3 border-x border-x-gray-700`} colSpan={2} rowSpan={2}>
                                                                                        <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                        {                                                                                                        
                                                                                            indexValue == 2 ?
                                                                                            manager?.oneAccumulated
                                                                                            : indexValue == 5 ?
                                                                                            manager?.twoAccumulated
                                                                                            : indexValue == 8 ?
                                                                                            manager?.threeAccumulated
                                                                                            : indexValue == 11 ?
                                                                                            manager?.fourAccumulated
                                                                                            : ''
                                                                                        }
                                                                                        </div>
                                                                                        <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>
                                                                                        {
                                                                                            indexValue == 2 ?
                                                                                            manager?.oneAccumulatedPorcent + '%'
                                                                                            : indexValue == 5 ?
                                                                                            manager?.twoAccumulatedPorcent + '%'
                                                                                            : indexValue == 8 ?
                                                                                            manager?.threeAccumulatedPorcent + '%'
                                                                                            : indexValue == 11 ?
                                                                                            manager?.fourAccumulatedPorcent + '%'
                                                                                            : ''
                                                                                        }
                                                                                        </div>
                                                                                    </td>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </Body>
        </Container>
    );
}