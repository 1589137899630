import React, { useState } from 'react'
import { useThemeContext } from '../../contexts/themeContext'

export function Tooltip(props) {

    const [activeToolTip, setActiveToolTip] = useState(false)
    const [activeShowToolTip, setActiveShowToolTip] = useState(false)
    const { darkMode } = useThemeContext()

    async function changeToolTipDisplay(display) {

        if (activeShowToolTip === false) {
            setActiveShowToolTip(true)
            await new Promise(resolve => setTimeout(resolve, 500))
            setActiveToolTip(display)
        }
    }

    async function changeToolTipDisplayNone(display) {
        if (activeShowToolTip) {
            setActiveToolTip(false)
            setActiveShowToolTip(false)
        }
    }

    return (

        <div className='flex flex-col items-center justify-center' onMouseEnter={() => changeToolTipDisplay(true)} onMouseLeave={() => changeToolTipDisplayNone(false)}>
            <div className={`${activeShowToolTip ? 'flex' : 'hidden'} absolute  z-40 ${props.translateX === 'right' ? 'translate-x-8' : '-translate-x-8'}  ${props.translateY === 'bottom' ? 'translate-y-2' : '-translate-y-7'} `}>
                <p style={{ marginLeft: activeToolTip ? '30px' : '0px', opacity: activeToolTip ? '1' : 0 }} className={`text-titleGrayTextLight dark:text-titleGrayTextLight bg-secondaryDefaultDark dark:bg-zinc-700 transition-all duration-500 ease-in-out p-1 rounded-md`}>{props.title}</p>
            </div>
            {props.component}
        </div>
    )
}