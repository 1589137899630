import { FaCog, FaHome, FaUniversity, FaUsers, FaSitemap, FaMoneyCheck, FaCalendarAlt, FaClipboardList, FaFileImport, FaCloudUploadAlt, FaUsersCog } from 'react-icons/fa'

export const menuItemsFC = [
    {
        icon: <FaHome />,
        name: 'Home',
        type: 'normal',
        path: '/',
        permissions: [1, 2, 3],
    },
    {
        icon: <FaUsers />,
        name: 'Clientes/Fornecedores',
        type: 'normal',
        path: '/customersOfCustomer',
        permissions: ['customersOfCustomer:create', 'customersOfCustomer:read', 1],
    },
    {
        icon: <FaUniversity />,
        name: 'Banco',
        type: 'normal',
        path: '/banks',
        permissions: ['fc_banks:create', 'fc_banks:read', 1],
    },
    {
        icon: <FaSitemap />,
        name: 'Plano de contas',
        type: 'normal',
        path: '/accountsPlans',
        permissions: ['accountPlans:create', 'accountPlans:read', 1],
    },
    {
        "icon": <FaMoneyCheck />,
        "name": "Lançamentos",
        "type": "normal",
        "path": "/cashFlow",
        "permissions": ['fc_sends:create', 'fc_sends:read', 1]
    },
    {
        icon: <FaCalendarAlt />,
        name: 'Períodos de Apuração',
        type: 'normal',
        path: '/determinationPeriod',
        permissions: ['periods:create', 'periods:read', 1],
    },
    {
        icon: <FaClipboardList />,
        name: 'Demonstrativo',
        type: 'normal',
        path: '/agglutinated',
        permissions: ['fc_sends:read', 'accountPlans:read', 1],
    },
    {
        icon: <FaCog />,
        name: 'Ajustes',
        type: 'normal',
        path: '/configuration',
        permissions: [1, 2, 3],
    }
]
