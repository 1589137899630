// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// components
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import Multiselect from '../../../components/selects/mult.select'
import { LabelInput } from '../../../components/label/label.input'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toggle } from '../../../components/toogle/Toogle'
// services 
import { api } from '../../../services/api/api'
import { getPermissionGroups, getTypeAccess, getUserEspecific } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
//utils
import { validateEmail } from '../../../utils/validators/email.validator'
import { removePontuaction } from '../../../utils/validators/removePontuaction'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { justNumber } from '../../../utils/validators/justNumber'
import { mCPF } from '../../../utils/validators/cpfMask'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { BsCardChecklist } from 'react-icons/bs'

export function UserEdit() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [updatedUser, setUpdatedUser] = useState(false)
    const [showNotification, setShowNotication] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [customersData, setCustomersData] = useState([])
    const [typeAccessList, setTypeAccessList] = useState([])
    const [permissionGroupsData, setPermissionsGroupsData] = useState([])
    const [thisUserData, setThisUserData] = useState([])

    //selectedVariables
    const [selectedTypeAccesss, setSelectedTypeAccess] = useState('')
    const [selectedPermissionGroup, setSelectedPermissionGroup] = useState('')
    const [selectedFullname, setSelectedFullname] = useState('')
    const [selectedUserEmail, setSelectedUserEmail] = useState('')
    const [selectedBirthDay, setSelectedBirthDay,] = useState('')
    const [selectedDDDPhone, setSelectedDDDPhone] = useState('')
    const [selectedUserGender, setSelectedUserGender] = useState('')
    const [selectedPhone, setSelectedPhone,] = useState('')
    const [selecteduserCpf, setSelectedUserCpf] = useState('')

    const [activeUsers, setActiveUsers] = useState(false)
    const [modalAlterPass, setModalAlterPass] = useState(false)

    const [idsCustomersBlocked, setIdsCustomersBlocked] = useState([])

    //temporary API
    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    useEffect(() => {        
        async function getData() {
            setLoading(true)

            try {
                const typeAccessData = await getTypeAccess(userData[0].token)
                if (typeAccessData.error) {
                    setLoading(false)
                    setShowModificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModalText(typeAccessData.data)
                    return
                }
                setTypeAccessList(typeAccessData.data.filter(access => {
                    if (access?.id < 3) {
                        return access
                    }
                }))

                const userDataApi = await getUserEspecific(userData[0].token, id)
                if (userDataApi.error) {
                    setLoading(false)
                    setShowModificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModalText(userDataApi.data)
                    return
                }
                const thisUserTypeAccesss = typeAccessData.data.filter((typeAccess) => {
                    if (typeAccess.id === userDataApi.data[0].typeAccess_id) {
                        return typeAccess
                    }
                })

                const permissionGroupsData = await getPermissionGroups(userData[0].token)
                if (permissionGroupsData.error) {
                    setLoading(false)
                    setShowModificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModalText(permissionGroupsData.data)
                    return
                }
                setPermissionsGroupsData(permissionGroupsData.data)
                const thisUserPermissionGroups = permissionGroupsData.data.filter((permissionGroups) => {
                    if (permissionGroups.id === userDataApi.data[0].permissionGroups_id) {
                        return permissionGroups
                    }
                })

                const thiUserGender = sexTypes.filter((gender) => {
                    if (gender.id === userDataApi.data[0].gender) {
                        return gender
                    }
                })

                const getCustomersAPI = await api.get('/api/v1/customers', {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setCustomersData(getCustomersAPI?.data?.data)

                if (userDataApi.data[0]?.blockedCustomers && userDataApi.data[0]?.blockedCustomers.length > 0) {
                    getCustomersAPI?.data?.data.map(customer => {
                        if (userDataApi.data[0]?.blockedCustomers.includes(customer?.id)) {
                            setIdsCustomersBlocked(prev => ([...prev, customer]))
                        }
                    })
                }


                setThisUserData(userDataApi.data[0])
                setSelectedFullname(userDataApi.data[0]?.fullname)
                setSelectedDDDPhone(userDataApi.data[0]?.dddphone)
                setSelectedPhone(userDataApi.data[0]?.phone)
                setSelectedUserEmail(userDataApi.data[0]?.email)
                setSelectedTypeAccess(thisUserTypeAccesss[0])
                setSelectedPermissionGroup(thisUserPermissionGroups[0])
                setSelectedBirthDay(userDataApi.data[0]?.birthday ? userDataApi.data[0]?.birthday : '')
                setSelectedUserGender(thiUserGender[0])
                setSelectedUserCpf(userDataApi.data[0]?.cpf)
                setActiveUsers(userDataApi.data[0].active)

                setLoading(false)
            } catch(error){
                if (responseError(error).length > 0) {
                    setShowModificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModalText(responseError(error))
                    setLoading(false)
                } else {
                    setShowModificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModalText(responseError(error))
                    setLoading(false)
                }
            }
        }

        getData()

    }, [updatedUser])

    async function handleSubmit() {

        const fields =
            [
                { "name": 'selectedFullname', "value": selectedFullname, "required": true, "type": 'string' },
                { "name": 'selectedUserEmail', "value": selectedUserEmail, "required": true, "type": 'string' },
                { "name": 'selectedTypeAccesss', "value": selectedTypeAccesss, "required": true, "type": 'string' },
                { "name": 'selectedPermissionGroup', "value": selectedPermissionGroup, "required": [2, 3].includes(selectedTypeAccesss?.id) ? true : false, "type": 'object' },

            ]

        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Campos obrigatórios não preenchidos!')
            return setShowNotificationModalSuccess(false)
        }

        const formatIdsCustomersBlocked = []

        if (idsCustomersBlocked.length > 0) {
            idsCustomersBlocked.map(customer => {
                formatIdsCustomersBlocked.push(customer?.id)
            })
        }

        try {

            const userEdit = await api.put(`/api/v1/users/${id}`, {
                fullname: selectedFullname ? selectedFullname : undefined,
                birthday: selectedBirthDay ? selectedBirthDay : undefined,
                gender: selectedUserGender?.id?.length > 0 ? selectedUserGender.id : undefined,
                dddphone: selectedDDDPhone ? selectedDDDPhone : undefined,
                phone: selectedPhone?.toString()?.indexOf('-') > -1 ? selectedPhone?.replace('-', '') : selectedPhone?.toString()?.indexOf('-') < 0 ? parseInt(selectedPhone) : undefined,
                cpf: selecteduserCpf ? selecteduserCpf?.replace('-', '')?.replaceAll('.', '') : undefined,
                email: selectedUserEmail ? selectedUserEmail : undefined,
                typeAccess_id: selectedTypeAccesss ? selectedTypeAccesss?.id : undefined,
                permissionGroups_id: selectedPermissionGroup ? selectedPermissionGroup?.id : undefined,
                blockedCustomers: idsCustomersBlocked.length > 0 ? formatIdsCustomersBlocked : []
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/users')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDelete() {
        setLoading(true)

        try {

            await api.delete(`/api/v1/users/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/users')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function usersStatus() {

        const status = !activeUsers
        setActiveUsers(status)

        try {

            await api.put(`/api/v1/users/${id}/status/${status ? 1 : 0}`, {}, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            setShowNotificationModalText(status === false ? `Usuário ${selectedFullname} desativado com sucesso.` : `Usuário ${selectedFullname} ativado com sucesso.`)

        } catch (error) {
            setLoading(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            }
        }
    }

    async function handleAlterPass() {
        setLoading(true)

        try {
            const alterPass = await api.put(`api/v1/users/alterPass/${id}`, {}, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowUniversalModal(false)
            setLoading(false)
            setModalAlterPass(false)
            setShowNotificationModalText(alterPass?.data?.message)
            setShowNotificationModalSuccess(true)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowUniversalModal(false)
                setModalAlterPass(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowUniversalModal(false)
                setModalAlterPass(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal title={`${modalAlterPass ? 'Reenviar senha' : 'Exlusão de usuário'}`} visible={showModal} width={400} height={200} >
                {
                    modalAlterPass ?
                        <div className='w-full p-2 h-96 flex flex-col items-center justify-center'>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente reenviar a senha?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button shadow={true} onClick={() => { handleAlterPass() }}>Sim</Button>
                                <Button shadow={true} onClick={() => { setShowUniversalModal(false); setModalAlterPass(false) }} approval={false}>Não</Button>
                            </div>
                        </div>
                        :
                        <div className='w-full h-96 flex flex-col items-center justify-center'>
                            <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir o usuário ?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                                <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                            </div>
                        </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={`Editar Usuário - ${selectedFullname}`} />
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                                <form className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Nome Completo *</a>
                                        <Input charLimit={255} width={80} id='selectedFullname' type='text' value={selectedFullname} onChange={(e) => setSelectedFullname(e.target.value)} autoFocus={true}></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                        <Input charLimit={255} id='selectedUserEmail' width={80} type='e-mail' value={selectedUserEmail} onChange={(e) => { validateEmail(e.target.value); setSelectedUserEmail(e.target.value) }}></Input>
                                    </label>
                                    <label className='relative flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Tipo de acesso *</a>
                                        <InputAutoComplete
                                            data={typeAccessList}
                                            selectedLabel={'description'}
                                            optionList={['id', 'description']}
                                            value={selectedTypeAccesss?.description}
                                            onChange={selectedTypeAccess => setSelectedTypeAccess(selectedTypeAccess)}
                                            id='selectedTypeAccesss'
                                            width={80}
                                        />
                                    </label>
                                    {
                                        [2, 3].includes(selectedTypeAccesss.id) &&
                                        <LabelInput 
                                            text={'Permissão *'}
                                            showIconInfo={true}
                                            messageIconInfo={'Definir grupo de permissão que o usuário melhor se encaixa.'}
                                        >
                                            <InputAutoComplete
                                                data={permissionGroupsData}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                                value={selectedPermissionGroup?.name}
                                                onChange={value => setSelectedPermissionGroup(value)}
                                                id='selectedPermissionGroup'
                                                width={80}
                                            />
                                        </LabelInput>
                                    }
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>CPF</a>
                                        <Input
                                            charLimit={14}
                                            width={80}
                                            id='selecteduserCpf'
                                            type='text'
                                            value={selecteduserCpf ? mCPF(selecteduserCpf) : ''}
                                            onChange={(e) => setSelectedUserCpf(mCPF(e.target.value))}
                                        />
                                    </label>
                                    <div className='flex flex-row gap-3'>
                                        <LabelInput text={'DDD'}>
                                            <Input
                                                charLimit={2}
                                                width={'50px'}
                                                type={'text'}
                                                onChange={(e) => setSelectedDDDPhone(justNumber(e.target.value))}
                                                value={selectedDDDPhone ? selectedDDDPhone : ''}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Telefone'}>
                                            <Input
                                                charLimit={10}
                                                type={'text'}
                                                width={'256px'}
                                                value={selectedPhone ? maskPhone(selectedPhone) : ''}
                                                onChange={(e) => setSelectedPhone(maskPhone(e.target.value))}
                                            />
                                        </LabelInput>
                                    </div>
                                    <div>
                                        {
                                            [2].includes(selectedTypeAccesss?.id) &&
                                            <LabelInput 
                                                text={'Clientes para usuário não visualizar*'} 
                                                icon={<BsCardChecklist className='flex items-center ml-2 text-lg' />}
                                                showIconInfo={true}
                                                messageIconInfo={'Definir clientes que não irão aparecer para o usuário.'}
                                            >
                                                <Multiselect
                                                    items={customersData}
                                                    mult
                                                    onChange={e => { setIdsCustomersBlocked(e) }}
                                                    selectedLabel={'businessName'}
                                                    value={idsCustomersBlocked}
                                                    id={`selectedLink`}
                                                />
                                            </LabelInput>
                                        }
                                    </div>
                                    {
                                        (userData[0]?.permissions?.indexOf('users:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <div className='flex flex-col w-full items-center lg:items-start justify-center mt-7'>
                                            <Tippy content={<span>{activeUsers ? 'Desativar' : 'Ativar'}</span>}
                                                arrow={true}
                                                animation='perspective'
                                                placement='top'
                                                delay={100}>
                                                <div>
                                                    <Toggle status={activeUsers ? true : false} onClick={(e) => usersStatus()} />
                                                </div>
                                            </Tippy>
                                        </div>
                                    }
                                </form>
                                <div className='mt-10 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                    <div className='flex gap-2'>
                                        <Button
                                            approval={false}
                                            shadow={true}
                                            onClick={() => { navigate('/users'); setShowModificationModal(false) }}
                                        >Cancelar
                                        </Button>
                                        {
                                            (userData[0]?.permissions?.indexOf('users:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                shadow={true}
                                                onClick={() => { setModalAlterPass(true); setShowUniversalModal(true) }}
                                            >Reenviar senha
                                            </Button>
                                        }
                                    </div>
                                    <div className='flex gap-2'>
                                        {
                                            (userData[0]?.permissions?.indexOf('users:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                approval={false}
                                                shadow={true}
                                                onClick={() => setShowUniversalModal(true)}
                                            >Excluir
                                            </Button>
                                        }
                                        {
                                            (userData[0]?.permissions?.indexOf('users:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                approval={true}
                                                shadow={true}
                                                onClick={() => handleSubmit()}
                                            >Editar usuário
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </Body>
            </Container>
        </>
    )


}