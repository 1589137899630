export function typeProducts() {
    const productsList = [
        {
            name: 'Produto Acabado',
            codeProduct: 5,
            typeProduct: 'PA'
        },
        {
            name: 'Produto Intermediário',
            codeProduct: 4,
            typeProduct: 'PI'
        },
        {
            name: 'Produto de Revenda',
            codeProduct: 3,
            typeProduct: 'PR'
        },
        {
            name: 'Matéria Prima',
            codeProduct: 1,
            typeProduct: 'MP'
        },
        {
            name: 'Embalagem',
            codeProduct: 2,
            typeProduct: 'E'
        },
        {
            name: 'Serviço',
            codeProduct: 8,
            typeProduct: 'S'
        }
    ]

    return productsList
}