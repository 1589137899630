export default function tableToExcel(name){
    let mimetype = 'application/vnd.ms-excel'
    let link = document.getElementById('link-to-download')
    let table = document.getElementById('TableToExport')
    let clone = table.cloneNode(true)

    link.href = window.URL.createObjectURL(new Blob([clone.outerHTML], {
        type: mimetype
    }))
    link.download = name
    link.click()
}