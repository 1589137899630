//hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
//contexts
import { useAuth } from '../contexts/useAuth'
import { useThemeContext } from '../contexts/themeContext'
import { DefaultLoader } from '../components/loaders/defaultLoader'
import { Container } from '../components/container/container'
import { Body } from '../components/container/Body'
import { TitlePage } from '../components/titlePages/title.page'

export function PreLoader() {

    const { loadingAuth, setLoadingAuth } = useAuth()

    return (
        <Container>
            <TitlePage>
                <TitlePage/>
            </TitlePage>
            <Body>
                <DefaultLoader/>
            </Body>
        </Container>
    )
}