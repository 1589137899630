//hooks
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaUserAlt, FaDoorOpen } from 'react-icons/fa'
import { useAuth } from '../../contexts/useAuth'
import { menuItems } from './menuItems'
import { clientMenuItems } from './clientMenuItems'
import { menuItemsFC } from './menuItemsFC'
import { menuItemsLM } from './menuItemsLM'
import styles from './styles/menu.module.css'
import { api } from '../../services/api/api'
//components
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { Button } from '../buttons/button.default'
import { InputAutoComplete } from '../input/input.autocomplete'
import { LabelInput } from '../label/label.input'
//contexts
import { useThemeContext } from '../../contexts/themeContext'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { ScreenModal } from '../modals/notification/screenModal'
//moment
import moment from "moment";

export function Menu() {

    const { userData, setUserData, setSigned, selectedCompany, selectedModule, setSelectedModule, setSelectedPeriod } = useAuth()
    const [loading, setLoading] = useState(false)
    const [thisMenuItems, setThisMenuItems] = useState(menuItems)
    const { screenX } = useScreenSizeContext()
    const { setShowModificationModal, setShowUniversalModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [animateThisModal, setAnimateThisModal] = useState(false)
    const navigate = useNavigate()
    const [showExitModal, setShowExitModal] = useState(false)
    const [showMyProfileInternalModal, setShowMyProfileInternalModal] = useState(false)
    const modalRef = useRef()
    const openModalRef = useRef()
    const [userPic, setUserPic] = useState('')
    const [showMyProfile, setShowMyProfile] = useState(false)
    const [menuHeight, setMenuHeight] = useState('0')
    const [filteredIconsItens, setFilteredIconsItens] = useState(thisMenuItems)
    const [removedMenuItens, setRemovedMenuItens] = useState([])
    const [displayMenuHide, setDisplayMenuHide] = useState('none')
    const [displayCom, setDisplayCom] = useState('flex')
    const [showSubMenu, setShowSubMenu] = useState(false)
    const [menuSubListDisplay, setMenuSubListDisplay] = useState('none')
    const [isToHideMenu, setIsToHideMenu] = useState(true)
    let removedItens = []
    let newFilteredItens = filteredIconsItens
    const navegate = useNavigate()

    useEffect(() => {
        const menu = document.getElementById('menuBar')
        checkWindowSize()
        window.addEventListener('resize', () => checkWindowSize())

        function checkWindowSize() {
            if (menu.getClientRects()[0]?.height < window.innerHeight && window.innerWidth > 640) {
                setMenuHeight('100vh')
                filteredIcons()
            } else if (window.innerWidth > 640) {
                setMenuHeight(`${window.innerHeight}px`)
                filteredIcons()
            } else {
                setMenuHeight(`68px`)
                setRemovedMenuItens([])
                setFilteredIconsItens(thisMenuItems)
            }
        }

        function filteredIcons() {

            const menuHide = document.getElementById('menuHideDiv')
            if (menuHide && newFilteredItens.length < thisMenuItems?.length) {
                menuHide.children[1].style.bottom = '10px'
            } else if (menuHide) {
                menuHide.children[1].style.bottom = ''
            }

            if ((thisMenuItems?.length + 1) * 70 <= window.innerHeight) {
                removedItens = []
                newFilteredItens = thisMenuItems
                setRemovedMenuItens(removedItens)
                setFilteredIconsItens(newFilteredItens)
            }
            else if (newFilteredItens?.length * 70 >= window?.innerHeight) {

                while ((newFilteredItens.length + 1) * 70 >= window.innerHeight) {
                    removedItens.push(newFilteredItens.slice(newFilteredItens.length - 1, newFilteredItens.length)[0])
                    newFilteredItens = newFilteredItens.slice(0, newFilteredItens.length - 1)
                    // setRemovedMenuItens(removedItens)
                    // setFilteredIconsItens(newFilteredItens)
                }
            }
            else if ((newFilteredItens.length + 1) * 70 < window.innerHeight) {
                if (removedItens[removedItens.length - 1]) {
                    newFilteredItens.push(removedItens[removedItens.length - 1])
                    removedItens = removedItens.slice(0, removedItens.length - 1)
                    setRemovedMenuItens(removedItens)
                    setFilteredIconsItens(newFilteredItens)
                }
            }
        }

        return () => window.removeEventListener('resize', (e) => checkWindowSize(e))

    }, [filteredIconsItens, thisMenuItems])

    useEffect(() => {

        async function alterMenu() {
            setLoading(true)
            await new Promise(resolve => setTimeout(resolve, 50))
            setLoading(false)
            if (selectedModule) {
                if (selectedModule == 'fc') {
                    setThisMenuItems(menuItemsFC)
                    setFilteredIconsItens(menuItemsFC)
                } else if (selectedModule == 'lm') {
                    setThisMenuItems(menuItemsLM)
                    setFilteredIconsItens(menuItemsLM)
                }
            } else {
                setThisMenuItems(menuItems)
                setFilteredIconsItens(menuItems)
            }
        }

        alterMenu()

    }, [selectedModule])

    useEffect(() => {
        if (isToHideMenu) {
            handleStatusMenu(true)
        }
    }, [isToHideMenu])

    useEffect(() => {

        async function getPic() {

            try {

                const response = await api.get('/api/v1/myProfile/photo', {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: 'Bearer ' + userData[0].token
                    }
                })
                setUserPic(URL.createObjectURL(response.data))

            } catch (error) {
                setUserPic('')
            }

        }

        getPic()

    }, [userData])

    useEffect(() => {
        navigate
        function handler(event) {
            if (!modalRef.current?.contains(event.target) && !openModalRef.current?.contains(event.target)) {
                if (modalRef?.current?.className?.indexOf('hidden') === -1) {
                    setShowMyProfile(false)
                }
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, [])

    useEffect(() => {

        async function animateModal(status) {

            await new Promise(resolve => setTimeout(resolve, 60))
            showExitModal ? setAnimateThisModal(true) : setAnimateThisModal(false)
        }

        if (showExitModal) {
            animateModal(true)
        } else {
            animateModal(false)
        }
    }, [showExitModal])

    useEffect(() => {

        async function animateProfile(status) {

            if (status) {
                await new Promise(resolve => setTimeout(resolve, 200))
                setShowMyProfileInternalModal(true)
            } else {
                setShowMyProfileInternalModal(false)
                await new Promise(resolve => setTimeout(resolve, 200))
                setShowMyProfile(false)
            }
        }

        animateProfile(showMyProfile)


    }, [showMyProfile])

    async function handleStatusMenu() {

        const ul = document.getElementById('ulHide')?.getAttribute('disabled')

        if (ul === 'true') {
            await new Promise(resolve => setTimeout(resolve, 500))

            setDisplayCom('flex')
            setDisplayMenuHide('none')
        }
    }

    const handleExit = async () => {

        try {

            const logout = await api.post('/auth/logout', {
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            localStorage.removeItem('@auth:token')
            localStorage.removeItem('@company')
            localStorage.removeItem('@module')
            setSelectedModule('')
            setSelectedPeriod('')
            setUserData('')
            setSigned(false)
        } catch (error) {
            console.log(error)
        }

    }

    function changeUlHide(hide) {

        const ul = document.querySelectorAll('#ulHide')

        if (hide) {
            ul[0].setAttribute("disabled", "true");
            return
        }
        ul[0].setAttribute("disabled", "false");
    }

    async function handleMenuSubListDisplay() {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setMenuSubListDisplay('')
    }

    return (
        <>
            {
                showExitModal &&
                <div className='flex flex-col'>
                    <div className={`${showExitModal ? 'flex' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[800]`}>
                        <div className={`${animateThisModal ? 'translate-y-0' : '-translate-y-96'} w-[350px] transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow`}>
                            <div className='flex flex-row justify-between items-center p-2'>
                                <h1 className='text-base text-titleBlackTextLight dark:text-titleGrayTextLight'>Sair do sistema</h1>
                                <button onClick={() => setShowExitModal(false)} className='bg-gray-300 w-6 h-6 rounded-full'>x</button>
                            </div>
                            <div className='flex flex-col items-center justify-center overflow-x-hidden'>
                                <div className='flex flex-col items-center justify-center w-96 p-4'>
                                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base mb-6'>Deseja realmente sair do sistema?</p>
                                    <div className='flex flex-row w-full justify-center gap-4 items-center'>
                                        <Button approval={true} onClick={handleExit} shadow={true}>Sim</Button>
                                        <Button approval={false} onClick={() => setShowExitModal(false)} shadow={true}>Não</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${showExitModal ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[1000vh] sm:h-full z-50`}></div>
                </div>
            }
            <div id='menuBar' style={{ height: menuHeight }} className={`${styles.menuContainer} mt-[60px] sm:mt-0 boxShadow z-[102] sm:boxShadow overflow-x-auto sm:overflow-hidden flex flex-row sm:flex-col gap-2 items-center justify-start sm:w-14 bg-gradient-to-t from-secondaryDefaultLight to-primaryDefaultLight dark:bg-gradient-to-t dark:from-secondaryDefaultDark dark:via-primaryDefaultDark dark:to-secondaryDefaultDark dark:text-titleGrayTextLight dark:border-x dark:border-primaryBorderDark sm:pr-0`}>
                <div ref={modalRef} className={`${showMyProfile ? 'flex' : 'hidden'} sm:items-baseline items-start justify-center sm:justify-start w-full sm:w-auto absolute top-0 md:top-2 left-0 sm:left-16 z-30 flex-col  `}>
                    <div className={`${showMyProfileInternalModal ? 'opacity-1 translate-y-0' : 'translate-y-10 opacity-0 max-h-0'} flex flex-col sm:items-baseline items-start justify-center sm:justify-start w-full h-full transition-all duration-300 bg-bgPrimaryLight dark:bg-primaryDefaultDark p-6 gap-8 sm:gap-2 boxShadow border border-secondaryDefaultLight dark:border dark:border-thirdDefaultDark sm:h-48'`}>
                        <div className='flex flex-col w-full items-center lg:items-start justify-start sm:justify-center '>
                            <h2 className='text-primaryDefaultLight dark:text-titleGrayTextDark mt-2 mb-4 font-bold text-4xl sm:text-base'>{userData[0].fullname.split(' ')[0]}</h2>
                        </div>
                        <div onClick={() => { navigate(`/myProfile`); setShowMyProfile(false) }} className='cursor-pointer flex flex-row sm:gap-2 gap-8 w-full text-2xl sm:text-base items-center justify-center lg:items-start lg:justify-start hover:brightness-50 duration-200 transition-all text-primaryDefaultLight'>
                            <FaUserAlt />
                            <a className='sm:text-sm text-2xl text-primaryDefaultLight'>Meu perfil</a>
                        </div>
                        <div onClick={() => { setShowMyProfile(false); setShowExitModal(true); setShowExitModal(true) }} title='Sair do sistema' className='text-2xl sm:text-base cursor-pointer flex flex-row sm:gap-2 gap-8 w-full items-center lg:items-start lg:justify-start justify-center hove duration-200 transition-all text-primaryDefaultLight'>
                            <FaDoorOpen />
                            <a className='sm:text-sm text-2xl text-primaryDefaultLight'>Sair</a>
                        </div>
                        <button onClick={() => setShowMyProfile(!showMyProfile)} className='sm:hidden flex flex-col items-center justify-center absolute right-8 top-8 border p-2 border-primaryDefaultLight rounded-full w-10 h-10 text-white font-bold bg-primaryDefaultLight'>X</button>
                    </div>
                </div>
                <div ref={openModalRef} onClick={() => { setShowMyProfile(!showMyProfile); setShowModificationModal(false) }} key={`menu-1-profile`} id={`myProfile`} className={`${userPic?.length > 0 ? 'bg-transparent' : 'bg-primaryDefaultLight dark:bg-secondaryDefaultDark'} w-12 hover:from-secondaryDefaultLight hover:to-primaryDefaultLight p-8 h-full sm:h-auto text-titleGrayTextLight dark:text-primaryDefaultLight flex flex-col gap-2 items-center justify-center text-2xl cursor-pointer md:hover:scale-110 transition-all duration-200 py-2`}>
                    <div className={`${userPic?.length > 0 ? 'bg-transparent' : 'bg-primaryLight dark:bg-secondaryDefaultDark'} h-10 w-10 bg-primaryDefaultLight rounded-full flex flex-col items-center justify-center`}>
                        {
                            userPic?.length > 0 ?
                                <div className='flex flex-col items-center justify-center rounded-full w-10 h-10 overflow-hidden'>
                                    <img key={userPic} src={userPic}></img>
                                </div>
                                :
                                <a className='text-titleGrayTextLight'>{userData[0]?.fullname.slice(0, 1)}</a>
                        }
                    </div>
                </div>
                {
                    loading ?
                        <></>
                        :
                        filteredIconsItens.map((item, index) => {
                            console.log()
                            if (
                                (item?.permissions?.indexOf(userData[0]?.typeAccess_id) > -1) 
                                ||
                                userData[0]?.permissions?.filter((userPermission) => {
                                    if (item.permissions.indexOf(userPermission) > -1) {
                                        return userPermission
                                    }
                                }).length > 0 
                                || item.permissions.includes(0)
                            ) {
                                if (!selectedCompany) {
                                    if (item?.path == "/customersOfCustomer") {
                                        return
                                    }
                                }
                                if (![1, 2].includes(userData[0]?.typeAccess_id)) {
                                    if (item?.path == '/taxRegime') {
                                        return
                                    }
                                }
                                return screenX > 640 ?
                                    <Tippy key={`${index}-tippy`} content={<span>{item?.name}</span>}
                                        arrow={true}
                                        animation='perspective'
                                        placement='right'
                                        delay={100}>
                                        <div key={`${item.name}-${index}`} id={`${item.name}-${index}`} onClick={() => { setShowUniversalModal(false); navegate(item.path); setShowModificationModal(false) }} className='text-titleGrayTextLight dark:text-primaryDefaultLight flex flex-col items-center justify-center text-2xl cursor-pointer hover:bg-secondaryDefaultLight dark:hover:bg-thirdDefaultDark p-2 rounded-lg transition-all duration-200'>
                                            {item.icon}
                                            <a className='text-xs sm:hidden flex 2xl:text-xs font-light text-titleGrayTextLight dark:to-titleGrayTextDark'>{item.name}</a>
                                        </div>
                                    </Tippy>
                                    :
                                    <div key={`${item.name}-${index}`} id={`${item.name}-${index}`} onClick={() => { setShowUniversalModal(false); navegate(item.path); setShowModificationModal(false) }} className='text-titleGrayTextLight dark:text-primaryDefaultLight w-full flex flex-col items-center justify-center text-2xl cursor-pointer p-2 rounded-lg transition-all duration-200'>
                                        {item.icon}
                                    </div>
                            }
                        })
                }
                {
                    removedMenuItens.length > 0 ? (
                        <div onMouseOver={() => { setDisplayMenuHide('flex'); setDisplayCom('none') }} onMouseLeave={() => handleStatusMenu()} className={`${styles.subMenu} flex flex-col hover:flex-col-reverse justify-center items-center w-full h-12 '`} onClick={() => setShowSubMenu(!showSubMenu)}>
                            <span style={{ display: displayCom }} className=' text-white font-extrabold cursor-pointer' >...</span>
                            <div style={{ display: displayMenuHide }} onMouseOver={() => { setIsToHideMenu(false) }} onMouseLeave={() => { handleMenuSubListDisplay() }} id='menuHideDiv' className={`${styles.menuHideDiv} w-full h-36 flex flex-col items-center justify-center '`}>
                                <a className='text-white font-bold'>...</a>
                                <ul id='ulHide' onMouseOver={() => changeUlHide(false)} onMouseLeave={() => changeUlHide(true)} className={`${styles.menuHoverEffect} pt-4 pb-4 rounded-lg boxShadow flex flex-col gap-2 items-start justify-center`}>
                                    {
                                        removedMenuItens.map((item) => {
                                            if (item.type === 'normal') {
                                                return <li key={item.name} id={item.name} className='flex flex-row pt-2 pb-2 items-center justify-between pl-2 cursor-pointer w-full hover:bg-hoverLight transition-all duration-300' onClick={() => { setShowUniversalModal(false); navegate(item.path); setShowModificationModal(false) }}>
                                                    <a className='text-titleBlackTextLight text-base 2xl:text-sm font-light'>{item.name}</a>
                                                </li>
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                        </div>) : ''
                }
            </div>
        </>

    )
}