export async function searchCep(cep) {

    try {
        const getCep = await fetch(`https://viacep.com.br/ws/${cep.replace('-', '')}/json/`)
        const getCepData = await getCep.json()

        const getIbge = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${getCepData?.ibge}`)
        const getIbgeData = await getIbge.json()

        return getCepData

    } catch (error) {
        return error
    }
}