import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Title } from '../../../components/titlePages/title';
import { Body } from '../../../components/container/Body';
import { Container } from '../../../components/container/container';
import { LoadingPulse } from '../../../components/loaders/loadingPulse';
import { TitlePage } from "../../../components/titlePages/title.page";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { LabelInput } from "../../../components/label/label.input";
import { TableDefault } from "../../../components/table/table.default";
//contexts
import { useAuth } from '../../../contexts/useAuth';
import { useThemeContext } from '../../../contexts/themeContext';
import { useScreenSizeContext } from '../../../contexts/screenSizeContext';
import { FaCloudDownloadAlt } from "react-icons/fa";
// services
import { api } from "../../../services/api/api";
import moment from "moment";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/buttons/button.default";
import { TableManager } from "../../../components/tablesAgglutinated/table.manager";
import { BsCardChecklist } from "react-icons/bs";
import Multiselect from "../../../components/selects/mult.select";
import { validateFields } from '../../../utils/form.validator';
import tableToExcel from "../../../utils/tableToExcel";
import { TableDetails } from "../../../components/tablesAgglutinated/table.details";

export function Agglutinated() {

    const navigate = useNavigate()
    const { userData, selectedCompany } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { screenX, screenY } = useScreenSizeContext()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingMult, setLoadingMult] = useState(true)
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [showMessageNoHaveSends, setShowMessageNoHaveSends] = useState(false)
    const [selectedOption, setSelectedOption] = useState(1)
    const [overview, setOverview] = useState([])
    const [details, setDetails] = useState([])
    const [manager, setManager] = useState([])
    const [selectedStartDate, setSelectedStartDate] = useState(moment()?.add(-2, 'month').startOf('month').format('YYYY-MM'))
    const [selectedFinishDate, setSelectedFinishDate] = useState(moment().endOf('month').format('YYYY-MM'))
    const [selectedCompanies, setSelectedCompanies] = useState([selectedCompany])
    const [selectedCompetence, setSelectedCompetence] = useState({id: 2, name: 'Caixa', value: false})
    const [companies, setCompanies] = useState([])

    useEffect(() => {
        getData()
    }, [selectedOption])

    async function getData() {
        setLoading(true)

        try {
            const getCompaniesAPI = await api.get(`api/v1/companies/readCompaniesSelected`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setCompanies(getCompaniesAPI?.data?.data)
        } catch(error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }

        let params = []
        selectedStartDate && params?.push(`startDate=${selectedStartDate}`)
        selectedFinishDate && params?.push(`finishDate=${selectedFinishDate}`)
        selectedCompanies && params?.push(`companies=${selectedCompanies?.map(item => {return item?.id}).join(',')}`)
        selectedCompetence && selectedCompetence?.value && params?.push(`competence=${selectedCompetence?.value}`)
        params = params.join('&')

        if (selectedOption == 1){
            try {
                const getManagerAPI = await api.get(`api/v1/fc/sends/manager?${params ? params : ''}`, {
                    headers: {
                        authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                setManager(getManagerAPI?.data?.data)
                setLoading(false)
                setLoadingMult(false)
            } catch (error){
                if (responseError(error).length > 0) {
                    setShowModificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModalText(responseError(error))
                    setLoading(false)
                } else {
                    setShowModificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModalText(responseError(error))
                    setLoading(false)
                }
            }
        } else if (selectedOption == 2) {
            try {
                const getDataApi = await api.get(`api/v1/fc/sends/overview?${params ? params : ''}`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })

                setOverview(getDataApi?.data?.data.map((item, index) => {
                    return {
                        ...item,
                        period: moment(item?.period, 'M/YYYY').utc(false).format('MM/YYYY'),
                        previousBalance: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.previousBalance),
                        received: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.received),
                        paid: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.paid),
                        finalBalance: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.finalBalance),
                        balance30: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.balance30),
                        balance90: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.balance90),
                        badDebtCustomers: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.badDebtCustomers),
                        badDebtSuppliers: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.badDebtSuppliers)
                    }
                }))

                setLoading(false)
            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowMessageNoHaveSends(true)
                    return
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    navigate('/')
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        } else {
            const getDataApi = await api.get(`api/v1/fc/sends/details?${params ? params : ''}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setDetails(getDataApi?.data?.data)
            setLoading(false)
        }
    }

    async function validatorFields(){

        const requiredFields = [
            { name: 'startDate', value: selectedStartDate, required: true, type: 'string' },
            { name: 'finishDate', value: selectedFinishDate, required: true, type: 'string' }
        ]

        let startElement = document.getElementById('startDate')
        let finishElement = document.getElementById('finishDate')
        let companiesElement = document.getElementById('labelCompanies')

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (!selectedCompanies || selectedCompanies?.length == 0){
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            companiesElement.style.color = 'red'
            return setShowNotificationModalText('Campos obrigatórios não preenchidos')
        }

        if (!moment(selectedStartDate)?.isBefore(selectedFinishDate) || selectedStartDate == selectedFinishDate) {
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            finishElement.style.border = '1px solid red'
            startElement.style.border = '1px solid red'
            return setShowNotificationModalText('Data final não pode ser menor ou igual que a data inicial')
        }

        companiesElement.style.color = ''

        getData()
    }

    const tableHead = [
        {
            "name": "Período",
            "original_name": "period"
        },
        {
            "name": "Saldo Anterior",
            "original_name": "previousBalance"
        },
        {
            "name": "Recebidos",
            "original_name": "received"
        },
        {
            "name": "Pagos",
            "original_name": "paid"
        },
        {
            "name": "Saldo Final",
            "original_name": "finalBalance"
        },
        {
            "name": "Saldo (30 dias)",
            "original_name": "balance30"
        },
        {
            "name": "Saldo (90 dias)",
            "original_name": "balance90"
        },
        {
            "name": "Inadimp. Clientes",
            "original_name": "badDebtCustomers"
        },
        {
            "name": "Inadimp. Fornecedores",
            "original_name": "badDebtSuppliers"
        }
    ]

    const optionCompetence = [
        {
            id: 1,
            name: 'Competência',
            value: true
        },
        {
            id: 2,
            name: 'Caixa',
            value: false
        }
    ]

    return (
        <Container>
            <TitlePage displayNotification={showNotification} success={successData} setDisplayNotification={e => setShowNotication(e)}>
                <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                    <div className='flex flex-col items-start justify-center w-full md:w-auto'>
                        <Title text={'Demonstrativo'}></Title>
                    </div>
                    <div className="hidden sm:flex sm:flex-row">
                        <Button onClick={() => {tableToExcel(`Demonstrativo_${Number(new Date())}.xls`)}} height={7}>
                            <FaCloudDownloadAlt className='text-white' /> <p className='text-white pl-2'> Exportar </p>
                            <a id="link-to-download" href="!#">{}</a>
                        </Button>
                    </div>
                </div>
            </TitlePage>
            <Body>
                {
                    <>
                        {
                            loadingMult ?
                            <></>
                            :
                            <>
                                <div className="hidden sm:flex sm:flex-wrap 2xl:flex-row md:gap-2 2xl:gap-0 w-full justify-between">
                                    <div className="flex sm:flex-wrap 2xl:flex-row items-center sm:gap-2">
                                        <LabelInput text={'Data Início *'}>
                                            <Input
                                                type={'month'}
                                                value={selectedStartDate ? selectedStartDate : ''}
                                                width={'150px'}
                                                onChange={(e) => {
                                                    setSelectedStartDate(e?.target?.value)
                                                }}
                                                id={'startDate'}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Data Final *'}>
                                            <Input
                                                type={'month'}
                                                value={selectedFinishDate ? selectedFinishDate : ''}
                                                width={'150px'}
                                                onChange={(e) => {
                                                    setSelectedFinishDate(e?.target?.value)
                                                }}
                                                id={'finishDate'}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Empresas *'} id={'labelCompanies'} icon={<BsCardChecklist className='flex items-center ml-2 text-lg'/>}>
                                            <Multiselect
                                                items={companies}
                                                mult                                                 
                                                onChange={e => setSelectedCompanies(e)}
                                                selectedLabel={'businessName'}
                                                value={selectedCompanies ? selectedCompanies : []}
                                                id={`selectedCompanies`}
                                                height={8}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Tipo'}>
                                            <InputAutoComplete
                                                data={optionCompetence}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                                preSelectedValue={selectedCompetence ? optionCompetence.filter(e => e?.id == selectedCompetence?.id)[0]?.name : ''}
                                                value={selectedCompetence ? selectedCompetence?.name : ''}
                                                onChange={e => setSelectedCompetence(e)}
                                                id='optionCompetence'
                                                width={36}
                                            />
                                        </LabelInput>
                                        <div className="flex gap-3 md:flex-wrap 2xl:flex-row sm:mt-5">
                                            <Button onClick={() => {validatorFields()}}>Filtrar</Button>
                                        </div>
                                    </div>
                                    <div className="hidden sm:flex flex-row gap-5 items-center sm:mt-3 lg:mt-0">
                                        <div className="flex flex-col items-center">
                                            <LabelInput text={'Gerencial'} />
                                            <input
                                                type={'checkbox'}
                                                className={'mt-1 cursor-pointer focus:outline-none'}
                                                id={'manager'}
                                                onClick={() => {
                                                    setSelectedOption(1);
                                                    document.getElementById('manager').checked = true;
                                                    document.getElementById('overview').checked = false;
                                                    document.getElementById('details').checked = false;
                                                }}
                                                checked={selectedOption == 1 ? true : false}
                                            />
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <LabelInput text={'Geral'} />
                                            <input
                                                type={'checkbox'}
                                                className={'mt-1 cursor-pointer focus:outline-none'}
                                                id={'overview'}
                                                onClick={() => {
                                                    setSelectedOption(2);
                                                    document.getElementById('overview').checked = true;
                                                    document.getElementById('details').checked = false;
                                                    document.getElementById('manager').checked = false;
                                                }}
                                                checked={selectedOption == 2 ? true : false}
                                            />
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <LabelInput text={'Detalhada'} />
                                            <input
                                                type={'checkbox'}
                                                className={'mt-1 cursor-pointer focus:outline-none'}
                                                id={'details'}
                                                onClick={() => {
                                                    setSelectedOption(3);
                                                    document.getElementById('details').checked = true;
                                                    document.getElementById('overview').checked = false;
                                                    document.getElementById('manager').checked = false;
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex text-center sm:hidden">
                                    <p>Essa funcionalidade está disponivel apenas para computador</p>
                                </div>
                            </>
                        }
                        {
                            loading ? 
                            <LoadingPulse/>
                            : selectedOption == 1 ?
                                <TableManager data={manager}/>
                            : selectedOption == 2 ?
                                overview?.length > 0 ?
                                <div className="mt-3 w-full hidden sm:flex sm:flex-col shadow-xl rounded-md">
                                    <TableDefault
                                        id={'TableToExport'}
                                        data={overview}
                                        title={tableHead}
                                        onClick={() => {}}
                                        cursorPointer={true}
                                        collumns={['period', 'previousBalance', 'received', 'paid', 'finalBalance', 'balance30', 'balance90', 'badDebtCustomers', 'badDebtSuppliers']}
                                    />
                                </div>
                                :
                                <div className="flex flex-col justify-center items-center gap-5 mt-6">
                                    <h1 className="text-md dark:text-gray-300">Verifique se há bancos ou lançamentos registrados.</h1>
                                    <a className="underline text-primaryDefaultLight text-md cursor-pointer hover:brightness-75" onClick={() => navigate('/cashFlow')}>
                                        Registrar lançamento.
                                    </a>
                                </div>
                            : selectedOption == 3 &&
                                details?.length > 0 ?
                                <div className="md:mt-0 xl:mt-3 2xl:mt-6 w-full hidden sm:flex sm:flex-col shadow-xl rounded-md">
                                    <TableDetails data={details}/>
                                </div>
                                :   
                                <div className="flex flex-col justify-center items-center mt-6">
                                    <h1 className="text-md">Não há plano de contas registrados.</h1>
                                    <a onClick={() => navigate('/accountsPlans')} className="underline text-primaryDefaultLight text-md cursor-pointer hover:brightness-75 text-center mt-5">Registrar plano de contas.</a>
                                </div>
                        }
                    </>
                }
            </Body>
        </Container>
    )
}