//hooks
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
//utils
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Body } from "../../../components/container/Body";
import { Input } from "../../../components/input/input";
import { LabelInput } from "../../../components/label/label.input";
import { Button } from "../../../components/buttons/button.default";
import { api } from "../../../services/api/api";

export function SellerCreate() {

    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [seller, setSeller] = useState('')

    async function handleSubmit() {
        setLoading(true)

        const requiredFields = [
            { name: 'seller', value: seller, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post(`/api/v1/lm/seller`, {
                name: seller
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/seller')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Cadastro de Vendedor'} />
                </div>
            </TitlePage>
            {
                loading ?
                    <DefaultLoader />
                    :
                    <Body>
                        <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                            <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                <LabelInput 
                                    text={'Vendedor'} 
                                    required={true}
                                    showIconInfo={true}
                                    messageIconInfo={'Definir nome para o vendedor.'}
                                >
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={seller ? seller : ''}
                                        id='seller'
                                        onChange={(e) => setSeller(e.target.value)}
                                    />
                                </LabelInput>
                            </form>
                            <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                                <Button
                                    onClick={() => navigate('/seller')}
                                    shadow={true}
                                    approval={false}
                                >Cancelar
                                </Button>
                                <Button
                                    shadow={true}
                                    onClick={() => handleSubmit()}
                                >Cadastrar
                                </Button>
                            </div>
                        </div>
                    </Body>
            }
        </Container>
    )
}