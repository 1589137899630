import styles from './styles/loadingPulse.module.css'

export function LoadingPulse() {
    return (
        <div class={`${styles.lds_ring} translate-y-5`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div> 
    )
}