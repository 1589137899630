// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/useAuth'
import { useParams } from 'react-router-dom'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
// components 
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { TitlePage } from '../../../components/titlePages/title.page'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { validateFields } from '../../../utils/form.validator'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
// utils 
import { validateEmail } from '../../../utils/validators/email.validator'
import { cnpjMask } from '../../../utils/validators/cnpjMask'
import { formatSizeCnpj } from '../../../utils/validators/formatSizeCnpj'
import { mCPF } from '../../../utils/validators/cpfMask'
import { maskCep } from '../../../utils/validators/cepMask'
import { searchCep } from '../../../utils/validators/searchCep'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { justNumber } from '../../../utils/validators/justNumber'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//services
import { api } from '../../../services/api/api'
import { ScreenModal } from '../../../components/modals/notification/screenModal'

export function CustomersOfCustomersEdit() {

    const navigate = useNavigate()
    const { id } = useParams()
    const { userData, selectedCompany } = useAuth()
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    // Fields
    const [nameSupplier, setNameSupplier] = useState('')
    const [customerData, setCustomerData] = useState({})
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [addressState, setAddressState] = useState('')
    const [addressStateList, setAddressStateList] = useState([])
    const [addressCityList, setAddressCityList] = useState([])

    useEffect(() => {

        async function getStates() {

            setLoading(true)

            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()

                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        async function getCustomer() {
            try {

                const getCustomersAPI = await api.get(`/api/v1/general/supplierAndCustomer/${id}`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setCustomerData(getCustomersAPI?.data?.data[0])
                setNameSupplier(getCustomersAPI?.data?.data[0]?.businessName)
                setLoading(false)
            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getStates()
        getCustomer()

    }, [])

    async function getCityApi(data) {

        setLoading(true)

        try {

            const getCity = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data?.id}/distritos`)
            const getCityData = await getCity.json()

            setAddressCityList(getCityData)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleCep() {

        const cepData = await searchCep(customerData?.cep)

        setCustomerData((prev) => ({
            ...prev,
            addressCity: cepData?.localidade,
            addressDistrict: cepData?.bairro,
            addressRoad: cepData?.logradouro,
            addressState: cepData?.uf
        }))

    }

    async function handleSubmit() {

        setLoading(true)

        const requiredFields = [
            { name: 'businessName', value: customerData?.businessName, required: true, type: 'string' },
            { name: 'commission', value: customerData?.commission, required: true, type: 'string' },
            { name: 'optingSimple', value: customerData?.optingSimple, required: true, type: 'string' },
            { name: 'cpf', value: customerData?.cpf, required: customerData?.typePerson == 'PF' ? true : false, type: 'string' },
            { name: 'cnpj', value: !customerData?.cnpj ? '' : customerData?.cnpj, required: customerData?.typePerson == 'PJ' ? true : false, type: 'string' },
            { name: 'isMaster', value: customerData?.isMaster, required: true, type: 'string' },
            { name: 'isSupplier', value: customerData?.isSupplier, required: true, type: 'string' },
            { name: 'isCustomer', value: customerData?.isCustomer, required: true, type: 'string' },
            { name: 'addressState', value: customerData?.addressState, required: true, type: 'string' },
        ]

        if (customerData?.isCustomer) {
            requiredFields.push({ name: 'finalCostumer', value: customerData?.finalCostumer, required: true, type: 'string' })
        }

        if (customerData?.isCustomer == undefined) {
            requiredFields.push({ name: 'isCustomer', value: customerData?.isCustomer, required: true, type: 'object' })
        }
        if (customerData?.isCustomer && customerData?.finalCostumer == undefined) {
            requiredFields.push({ name: 'finalCostumer', value: customerData?.finalCostumer, required: true, type: 'object' })
        }
        if (customerData?.isSupplier == undefined) {
            requiredFields.push({ name: 'isSupplier', value: customerData?.isSupplier, required: true, type: 'object' })
        }
        if (customerData?.isMaster == undefined) {
            requiredFields.push({ name: 'isMaster', value: customerData?.isMaster, required: true, type: 'object' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (customerData?.email) {
            if (!isValidEmail) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText('Email inválido')
                return setLoading(false)
            }
        }

        try {

            await api.put(`api/v1/general/supplierAndCustomer/${id}`, {
                businessName: customerData?.businessName,
                fantasyName: customerData?.fantasyName ? customerData.fantasyName : undefined,
                cnpj: customerData?.cnpj ? customerData.cnpj?.replace('-', '')?.replace('/', '')?.replaceAll('.', '') : undefined,
                email: customerData?.email ? customerData.email : undefined,
                dddphone: customerData?.dddphone ? customerData.dddphone : undefined,
                phone: customerData?.phone?.toString()?.indexOf('-') > -1 ? customerData?.phone?.replace('-', '') : customerData?.phone?.toString()?.indexOf('-') < 0 ? parseInt(customerData?.phone) : undefined,
                cep: customerData?.cep ? customerData.cep?.replace('-', '') : undefined,
                commission: customerData?.commission ? customerData?.commission : undefined,
                typePerson: customerData?.typePerson ? customerData?.typePerson : undefined,
                cpf: customerData?.cpf ? customerData?.cpf?.replaceAll('.', '')?.replace('-', '') : undefined,
                finalCostumer: customerData?.isCustomer && (customerData?.finalCostumer || customerData?.finalCostumer === false) ? customerData?.finalCostumer : false,
                optingSimple: customerData?.optingSimple,
                externalId: customerData?.externalId ? customerData.externalId.toString() : undefined,
                addressState: customerData?.addressState ? customerData.addressState : undefined,
                addressCity: customerData?.addressCity ? customerData.addressCity : undefined,
                addressDistrict: customerData?.addressDistrict ? customerData.addressDistrict : undefined,
                addressRoad: customerData?.addressRoad ? customerData.addressRoad : undefined,
                addressNumber: customerData?.addressNumber ? customerData.addressNumber : undefined,
                addressComplement: customerData?.addressComplement ? customerData.addressComplement : undefined,
                isMaster: customerData?.isMaster,
                isSupplier: customerData?.isSupplier,
                isCustomer: customerData?.isCustomer
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/customersOfCustomer')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    async function handleDelete() {
        setLoading(true)

        try {

            await api.delete(`/api/v1/general/supplierAndCustomer/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/customersOfCustomer')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const options = [
        {
            name: 'Sim',
            value: true
        },
        {
            name: 'Não',
            value: false
        }
    ]

    const optionsPerson = [
        {
            name: 'Pessoa Física',
            value: 'PF'
        },
        {
            name: 'Pessoa Jurídica',
            value: 'PJ'
        }
    ]

    return (
        <>
            <ScreenModal title={'Exclusão de Cliente/Fornecedor'} visible={showModal} >
                <div className='w-full h-32 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir?</p>
                    <div className='flex flex-row gap-1 mt-8'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Edição de Cliente/Fornecedor - ${nameSupplier}`} />
                    </div>
                </TitlePage>
                {
                    loading ?
                        <DefaultLoader />
                        :
                        <Body>
                            <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                                <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                    <LabelInput text={'Razão social *'}>
                                        <Input
                                            type={'text'}
                                            width={80}
                                            value={customerData?.businessName}
                                            id='businessName'
                                            onChange={(e) => setCustomerData((prev) => ({ ...prev, businessName: e.target.value }))}
                                            charLimit={200}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Tipo pessoa *'}>
                                        <InputAutoComplete
                                            width={80}
                                            data={optionsPerson}
                                            optionList={["name"]}
                                            selectedLabel={'name'}
                                            value={customerData?.typePerson ? optionsPerson.filter(op => op.value == customerData?.typePerson)[0].name : ''}
                                            id='typePerson'
                                            onChange={(e) => setCustomerData(prev => ({ ...prev, typePerson: e.value }))}
                                        />
                                    </LabelInput>
                                    {
                                        customerData?.typePerson == 'PJ' ?
                                            <LabelInput text={'CNPJ *'}>
                                                <Input type={'text'}
                                                    value={cnpjMask(customerData?.cnpj)}
                                                    id='cnpj'
                                                    width={80}
                                                    onChange={(e) => setCustomerData(prev => ({ ...prev, cnpj: e.target.value }))}
                                                    charLimit={18}
                                                />
                                            </LabelInput>
                                            : customerData?.typePerson == 'PF' ?
                                                <LabelInput text={'CPF *'}>
                                                    <Input type={'text'}
                                                        value={mCPF(customerData?.cpf)}
                                                        width={80}
                                                        id='cpf'
                                                        onChange={(e) => setCustomerData(prev => ({ ...prev, cpf: e.target.value }))}
                                                        charLimit={18}
                                                    />
                                                </LabelInput>
                                                :
                                                <></>
                                    }
                                    <LabelInput text={'Matriz *'}>
                                        <InputAutoComplete
                                            type={'text'}
                                            data={options}
                                            optionList={["name"]}
                                            selectedLabel={'name'}
                                            width={80}
                                            value={options.find(op => op.value === customerData?.isMaster)?.name}
                                            id='isMaster'
                                            onChange={(e) => setCustomerData(prev => ({ ...prev, isMaster: e?.value }))} />
                                    </LabelInput>
                                    <LabelInput text={'Nome fantasia'}>
                                        <Input
                                            type={'text'}
                                            width={80}
                                            value={customerData?.fantasyName}
                                            id='name'
                                            onChange={(e) => setCustomerData(prev => ({ ...prev, fantasyName: e.target.value }))}
                                            charLimit={200}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'E-mail'} valid={!isValidEmail ? false : true}>
                                        <Input type={'text'} width={80} value={customerData?.email} id='email' onChange={(e) => {
                                            const isValid = validateEmail(e.target.value)
                                            if (isValid === true) {
                                                setIsValidEmail(true)
                                            } else {
                                                setIsValidEmail(false)
                                            }
                                            setCustomerData(prev => ({ ...prev, email: e.target.value }))
                                        }} charLimit={255} />
                                    </LabelInput>
                                    <LabelInput text={'Fornecedor'} required={true}>
                                        <InputAutoComplete
                                            data={options}
                                            optionList={["name"]}
                                            selectedLabel={'name'}
                                            id='isSupplier'
                                            onChange={e => { setCustomerData(prev => ({ ...prev, isSupplier: e?.value })) }}
                                            preSelectedValue={options.filter(op => op.value == customerData?.isSupplier)[0]?.name}
                                            value={options.filter(op => op?.value == customerData?.isSupplier)[0]?.name}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Cliente'} required={true}>
                                        <InputAutoComplete
                                            data={options}
                                            optionList={["name"]}
                                            selectedLabel={'name'}
                                            id='isCustomer'
                                            onChange={e => { setCustomerData(prev => ({ ...prev, isCustomer: e?.value })) }}
                                            preSelectedValue={options.filter(op => op.value == customerData?.isCustomer)[0]?.name}
                                            value={options.filter(op => op?.value == customerData?.isCustomer)[0]?.name}
                                        />
                                    </LabelInput>
                                    {
                                        customerData?.isCustomer &&
                                        <LabelInput text={'Consumidor final'} required={true}>
                                            <InputAutoComplete
                                                data={options}
                                                optionList={["name"]}
                                                selectedLabel={'name'}
                                                id='finalCostumer'
                                                onChange={e => { setCustomerData(prev => ({ ...prev, finalCostumer: e?.value })) }}
                                                preSelectedValue={!customerData?.finalCostumer ? options[1]?.name : options.find(op => op.value === customerData?.finalCostumer)?.name}
                                                value={options.find(op => op?.value === customerData?.finalCostumer)?.name}
                                            />
                                        </LabelInput>
                                    }
                                    <LabelInput text={'CEP'}>
                                        <Input
                                            type={'text'}
                                            width={80}
                                            value={customerData?.cep ? maskCep(customerData?.cep?.toString()) : ''}
                                            id='cep'
                                            onChange={(e) => setCustomerData(prev => ({ ...prev, cep: e.target.value }))}
                                            charLimit={9}
                                            onBlur={(e) => {
                                                handleCep()
                                            }}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Estado'} required={true}>
                                        <InputAutoComplete
                                            preSelectedValue={customerData?.addressState ? addressStateList.filter(e => { return e?.sigla == customerData?.addressState })[0]?.nome : ''}
                                            value={addressState?.nome}
                                            selectedLabel={'nome'} id='addressState'
                                            data={addressStateList} optionList={['nome']}
                                            onChange={e => {
                                                {
                                                    setCustomerData(prev => ({ ...prev, addressState: e?.sigla }))
                                                    e?.nome !== undefined && getCityApi(e)
                                                }
                                            }}>
                                        </InputAutoComplete>
                                    </LabelInput>
                                    {
                                        !customerData?.addressState ?
                                            <LabelInput text={'Cidade'}>
                                                <Input width={80} type={'text'} value={customerData?.addressCity} id='addressCity' disabled={true} />
                                            </LabelInput>
                                            :
                                            <LabelInput text={'Cidade'}>
                                                <InputAutoComplete
                                                    preSelectedValue={customerData?.addressCity ? customerData?.addressCity : ''}
                                                    selectedLabel={'nome'} width={80} id='addressCity'
                                                    data={addressCityList} optionList={['nome']}
                                                    onChange={e => {
                                                        setCustomerData(prev => ({ ...prev, addressCity: e?.nome }))
                                                    }}>
                                                </InputAutoComplete>
                                            </LabelInput>
                                    }
                                    <LabelInput text={'Código externo'}>
                                        <Input type={'text'} width={80} value={customerData?.externalId} onChange={(e) => setCustomerData(prev => ({ ...prev, externalId: e.target.value }))} charLimit={200}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Bairro'}>
                                        <Input width={80} type={'text'} value={customerData?.addressDistrict} onChange={(e) => setCustomerData(prev => ({ ...prev, addressDistrict: e.target.value }))} charLimit={255} />
                                    </LabelInput>
                                    <LabelInput text={'Logradouro'}>
                                        <Input width={80} type={'text'} value={customerData?.addressRoad} onChange={(e) => setCustomerData(prev => ({ ...prev, addressRoad: e.target.value }))} charLimit={255} />
                                    </LabelInput>
                                    <LabelInput text={'Número'}>
                                        <Input width={80} type={'text'} id='addressNumber' value={customerData?.addressNumber} onChange={(e) => setCustomerData(prev => ({ ...prev, addressNumber: justNumber(e.target.value) }))} charLimit={8} />
                                    </LabelInput>
                                    <LabelInput 
                                        text={'Comissão (%) *'}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir comissão para ser preenchida nas vendas. Editável no momento da venda caso esse valor altere (Apenas para cliente).'}
                                    >
                                        <Input type={'text'} width={80} value={customerData?.commission} id='commission' onChange={(e) => setCustomerData(prev => ({ ...prev, commission: justNumber(e.target.value) }))} charLimit={9} />
                                    </LabelInput>
                                    <LabelInput text={'Optante do simples'} required={true}>
                                        <InputAutoComplete type={'text'} data={options} optionList={["name"]} selectedLabel={'name'} width={80} value={customerData?.optingSimple !== null ? options.find(op => op.value === customerData?.optingSimple)?.name : ''} id='optingSimple' onChange={(e) => setCustomerData(prev => ({ ...prev, optingSimple: e?.value }))} />
                                    </LabelInput>
                                    <LabelInput text={'Complemento'}>
                                        <Input width={80} type={'text'} value={customerData?.addressComplement} id='addressComplement' onChange={(e) => setCustomerData(prev => ({ ...prev, addressComplement: e.target.value }))} charLimit={100} />
                                    </LabelInput>
                                    <div className='flex flex-row gap-3'>
                                        <LabelInput text={'DDD'}>
                                            <Input charLimit={2} width={'50px'} type={'text'} onChange={(e) => setCustomerData(prev => ({ ...prev, dddphone: justNumber(e.target.value) }))} value={customerData?.dddphone}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Telefone'}>
                                            <Input charLimit={10} type={'text'} width={'256px'} onChange={(e) => setCustomerData(prev => ({ ...prev, phone: maskPhone(e.target.value) }))} value={maskPhone(customerData?.phone)}></Input>
                                        </LabelInput>
                                    </div>
                                </form>
                                <div className='mt-8 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                    <Button
                                        onClick={() => navigate('/customersOfCustomer')}
                                        shadow={true}
                                        approval={false}
                                    >Cancelar
                                    </Button>
                                    <div className='flex gap-2'>
                                        {
                                            (userData[0]?.permissions?.indexOf('customersOfCustomer:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                shadow={true}
                                                approval={false}
                                                onClick={() => setShowUniversalModal(true)}
                                            >Excluir
                                            </Button>
                                        }
                                        {
                                            (userData[0]?.permissions?.indexOf('customersOfCustomer:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                shadow={true}
                                                onClick={() => handleSubmit()}
                                            >Editar
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Body>
                }
            </Container>
        </>
    )
}