import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
//contexts
import { useAuth } from '../../contexts/useAuth'
import { useThemeContext } from '../../contexts/themeContext'
import { FaExchangeAlt, FaLock, FaUnlock } from 'react-icons/fa'
import { api } from '../../services/api/api'
import { responseError } from '../../utils/responsesFunctions/error.response'
import { Button } from '../buttons/button.default'
import { LabelInput } from '../label/label.input'
import { InputAutoComplete } from '../input/input.autocomplete'
import { validateFields } from '../../utils/form.validator'

export function Container({ children, id, bg, validPeriod_lm }) {

    const navigate = useNavigate()
    const location = useLocation()
    const { setShowNotificationModalSuccess, setShowNotificationModalText, setShowModificationModal } = useThemeContext()
    const { selectedCompany, setSelectedCompany, setSelectedModule, selectedModule, userData, setSelectedPeriod, selectedPeriod, loadingAuth, setLoadingAuth } = useAuth()
    const [textColor, setTextColor] = useState('')
    const [textModule, setTextModule] = useState('')
    const [animateThisModal, setAnimateThisModal] = useState(false)
    const [modalAlterPeriod, setModalAlterPeriod] = useState(false)
    const [periods, setPeriods] = useState([])
    const [selectedPeriodId, setSelectedPeriodId] = useState('')

    useEffect(() => {
        if (selectedCompany) {
            let r = parseInt(selectedCompany?.colorIdentifier?.substr(1, 2), 16)
            let g = parseInt(selectedCompany?.colorIdentifier?.substr(3, 2), 16)
            let b = parseInt(selectedCompany?.colorIdentifier?.substr(5, 2), 16)

            setTextColor(((r * 299) + (g * 587) + (b * 114)) / 1000)
        }

        if (selectedModule) {
            if (selectedModule == 'fc') {
                setTextModule("Fluxo de caixa")
            } else if (selectedModule == 'lm') {
                setTextModule("Margens de contribuição")
            }
        } else {
            setTextModule('')
        }

        if(validPeriod_lm && selectedModule == 'lm' && !selectedPeriod){
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Para realizar essa ação é necessário selecionar o período de apuração.')
            navigate('/')
        }

    }, [selectedCompany, selectedModule])

    useEffect(() => {

        async function animateModal(status) {

            await new Promise(resolve => setTimeout(resolve, 60))
            modalAlterPeriod ? setAnimateThisModal(true) : setAnimateThisModal(false)
        }

        if (modalAlterPeriod) {
            animateModal(true)
        } else {
            animateModal(false)
        }
    }, [modalAlterPeriod])

    async function getPeriodsAPI(){
        try {
            const getPeriods = await api.get(`/api/v1/general/periods`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setPeriods(getPeriods?.data?.data?.map(item => {
                return {
                    ...item,
                    valueToShowSelected: (item.month.toString().length == 1 ? '0' + item.month : item.month) + '/' + item.year,
                    value: (item.month.toString().length == 1 ? '0' + item.month : item.month) + '/' + item.year,
                    success: item.isOpen
                }
            }))
            setModalAlterPeriod(true)
        }
        catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmitPeriod() {

        const requiredFields = [
            { name: 'period', value: selectedPeriodId, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campo obrigatório não preenchido, preencha o campo em vermelho')
        }
        try {
            setLoadingAuth(true)
            await api.post(`/api/v1/setting/selectedPeriod`, {
                periods_id: selectedPeriodId?.id
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setSelectedPeriod(selectedPeriodId)
            navigate(0)
            setLoadingAuth(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            {   
                modalAlterPeriod &&
                <div className='flex flex-col'>
                    <div className={`${modalAlterPeriod ? 'flex' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[80]`}>
                        <div className={`${animateThisModal ? 'translate-y-0' : '-translate-y-96'} w-80 sm:w-96 transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow`}>
                            <div className='flex flex-row justify-between items-center p-2'>
                                <h1 className='text-base text-titleBlackTextLight dark:text-titleGrayTextLight'>Alteração de período</h1>
                                <button onClick={() => setModalAlterPeriod(false)} className='bg-gray-300 w-6 h-6 rounded-full'>x</button>
                            </div>
                            <div className='flex flex-col justify-center items-center gap-10 h-48 p-2'>
                            <LabelInput text={'Períodos *'}>
                                <InputAutoComplete
                                    height={96}
                                    icon={{
                                        icon: <FaUnlock />,
                                        compareIcon: <FaLock />
                                    }}
                                    colorIcon={'dark:text-green-500 text-green-800'}
                                    colorCompareIcon={'dark:text-green-500 text-red-800'}
                                    data={periods}
                                    type={'text'}
                                    id='period'
                                    selectedLabel={['valueToShowSelected']}
                                    optionList={['value']}
                                    width={80}
                                    value={periods ? periods?.value : ''}
                                    preSelectedValue={periods ? periods?.valueToShowSelected : ''}
                                    onChange={(e) => setSelectedPeriodId(e)}
                                />
                            </LabelInput>
                            <div className='flex justify-center items-center gap-4'>
                                <Button shadow={true} approval={false} onClick={() => setModalAlterPeriod(false)}>Cancelar</Button>
                                <Button shadow={true} onClick={() => handleSubmitPeriod()}>Continuar</Button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className={`${modalAlterPeriod ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[1000vh] sm:h-full z-50`}></div>
                </div>
            }
            <div id={id} className={`p-0 flex flex-col w-full h-full ${bg ? bg : 'bg-zinc-200'} md:gap-1 items-center dark:border-primaryBorderDark dark:bg-primaryDefaultDark`}>
                {children}
                {/* Footer */}
                <div className='w-full h-10 z-10 bg-white fixed shadow-sm border-t border-zinc-200 sm:bottom-0 bottom-[60px] flex flex-col items-center justify-center dark:border-primaryBorderDark dark:bg-primaryDefaultDark'>
                    <div className='max-w-[1950px] pl-12 pr-12 w-full'>
                        {
                            selectedCompany.businessName?.length > 0 ?
                                <div className='flex justify-between items-center'>
                                    <div className='flex gap-5 items-center'>
                                        <div className={`w-5 h-5 bg-companyColor border hidden sm:flex items-center`}></div>
                                        <a className='text-[9px] hidden sm:flex sm:text-sm dark:text-gray-300'>
                                            {`Empresa: ${selectedCompany.businessName}`}
                                        </a>
                                        <a className='text-[9px] sm:hidden flex sm:text-sm dark:text-gray-300'>
                                            {`${selectedCompany.businessName?.slice(0,12)}...`}
                                        </a>
                                        <span>|</span>
                                        <a className='text-[9px] hidden sm:flex sm:text-sm dark:text-gray-300'> 
                                            {`Módulo: ${textModule ? textModule : 'Nenhum módulo selecionado'}`}
                                        </a>
                                        <a className='text-[9px] flex sm:hidden sm:text-sm dark:text-gray-300'> 
                                            {`${textModule ? textModule : 'Nenhum módulo selecionado'}`}
                                        </a>
                                        {
                                            selectedModule == 'lm' &&
                                            <>
                                                <span>|</span>
                                                <div className='flex items-center justify-center gap-2'>
                                                    <a className='text-[9px] sm:text-sm dark:text-gray-300'>{`Período: ${selectedPeriod ? selectedPeriod.month?.toString().length == 1 ? '0' + selectedPeriod?.month + '/' + selectedPeriod?.year : selectedPeriod?.month + '/' + selectedPeriod?.year : '-'}`}</a>
                                                    <p 
                                                        onClick={() => getPeriodsAPI() } 
                                                        className={`${location.pathname === '/' && 'hidden'} text-sm text-blue-600 hover:cursor-pointer`}
                                                    >
                                                        <FaExchangeAlt />
                                                    </p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                :
                                <a className='text-sm dark:text-gray-300'>Nenhuma empresa selecionada</a>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
