// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// components 
import { FaArrowDown, FaEdit, FaFilter } from 'react-icons/fa'
import { FiSlash } from 'react-icons/fi'
import { TablePaginationDefault } from '../../../components/table/tablePagination.default'
import { TitlePage } from '../../../components/titlePages/title.page'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import { Tooltip } from '../../../components/toolTip/toolTip'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
//services
import { api } from '../../../services/api/api'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// utils
import { justNumber } from '../../../utils/validators/justNumber'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'

export function UsersClientsTable() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { setShowUniversalModal } = useThemeContext()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const [loading, setLoading] = useState(false)
    const { screenY, screenX } = useScreenSizeContext()
    const screenSize = screenY - 200
    const [quantity, setQuantity] = useState(0)
    const [typeAccessList, setTypeAccessList] = useState([])
    const [filterConstructor, setFilterConstructor] = useState({ typeAccess_id: '3' })
    const [filter, setFilter] = useState({ typeAccess_id: '3', active: 1 })

    useEffect(() => {

        async function getAccess() {

            const getAccess = await api.get('/api/v1/typeAccess', {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            setTypeAccessList(getAccess.data.data.filter(access => {
                if (access?.id > 2){
                    return access
                }
            }))
        }

        getAccess()

    }, [])

    function handleEdit(data) {
        navigate(`/usersClientsEdit/${data.id}`)
    }

    async function exportUsers(){
        try {

        const urlContructor = ['']

        Object.keys(filterConstructor).map(key => {
            if(![null, undefined, '', NaN].includes(filterConstructor[key])){
                urlContructor.push(`${key}=${filterConstructor[key]}`)
            }
        })
        
        const dataExports = await api.get('/api/v1/users/exports' + '?' + urlContructor.join('&'), {
            headers: {
                authorization: `Bearer ${userData[0].token}`
            },
            responseType: 'blob',
        })

        const url = window.URL.createObjectURL(dataExports?.data)
        const downloadElement = document.createElement('a')
        downloadElement.setAttribute('href', url)
        downloadElement.setAttribute('download', `Usuarios_${new Date().toISOString().split('T')[0]}`)
        downloadElement.click()

        } catch(error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Filtros'}>
            {
                loading
                    ? <DefaultLoader />
                    : (
                        <div className='flex flex-col pt-2 pb-5 px-0 gap-2 items-center'>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                <Input
                                    charLimit={255} id='id' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                    value={filterConstructor?.id} autoFocus={true}
                                    width={80}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                <Input
                                    charLimit={255} id='fullname' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, fullname: e.target.value })}
                                    value={filterConstructor?.fullname} autoFocus={true}
                                    width={80}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                <Input
                                    charLimit={255} id='email' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, email: e.target.value })}
                                    value={filterConstructor?.email} autoFocus={true}
                                    width={80}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>CPF *</a>
                                <Input
                                    charLimit={255} id='cpf' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, cpf: e.target.value })}
                                    value={filterConstructor?.cpf} autoFocus={true}
                                    width={80}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Tipos de Acesso *</a>
                                <InputAutoComplete
                                    data={typeAccessList}
                                    disabled={true}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                    onChange={e => setFilterConstructor({ ...filterConstructor, typeAccess_id: e.id })}
                                    preSelectedValue={typeAccessList ? typeAccessList?.filter(type => type.id == filter.typeAccess_id)[0]?.description : ''}
                                    id='typeAccess'
                                    width={80}
                                />
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Status *</a>
                                <InputAutoComplete
                                    data={[{ id: 0, description: 'Inativo' }, { id: 1, description: 'Ativo' }]}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                    onChange={e => setFilterConstructor({ ...filterConstructor, active: e.id })}
                                    preSelectedValue={[{ id: 0, description: 'Inativo' }, { id: 1, description: 'Ativo' }]?.filter(filter => filter == filterConstructor.active)[0]?.description}
                                    id='active'
                                    width={80}
                                />
                            </label>
                            <div className='mt-5'>
                                <Button onClick={() => {
                                    setFilter(filterConstructor)
                                    setShowUniversalModal(false)
                                }}>Filtrar</Button>
                            </div>
                        </div>
                    )
            }
            </ScreenModal>
            <Container>
                <TitlePage displayNotification={showNotification} success={successData} setDisplayNotification={e => setShowNotication(e)}>
                    <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Usuários Clientes'}></Title>
                            <div className='flex flex-row gap-2 md:w-48 items-center'>
                                <h3 className='hidden sm:flex text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity} Usuarios</h3>
                                <div className='hidden md:flex flex-row justify-start items-center gap-1 cursor-pointer' onClick={() => exportUsers()}>
                                    <Tooltip
                                        component={<a className='dark:text-titleGrayTextDark text-xs 2xl:text-sm ml-3'>Exportar</a>}
                                        title="Exportar lista em excel"
                                    />
                                    <FaArrowDown className='dark:text-titleGrayTextDark text-xs 2xl:text-sm' />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/userClientsCreate')}>
                            {
                                (userData[0]?.permissions?.indexOf('usersClient:create') > -1 || userData[0]?.typeAccess_id < 2) &&
                                <Button shadow={true} onClick={() => navigate('/userClientsCreate')}>+ Novo usuário</Button>
                            }
                        </div>
                    </div>
                </TitlePage>
                <Body>
                    <div className='w-full sm:mt-0 mt-2 items-center flex gap-4'>
                        <Button onClick={() => setShowUniversalModal(true)}>
                            <FaFilter className='text-white' /> 
                            <p className='text-white'> Filtrar </p>
                        </Button>
                        {filter && JSON.stringify(filter) != JSON.stringify({ typeAccess_id: '3', active: 1 })
                            ? <Tippy
                                key={`clearFilter`}
                                content={<span>Limpar filtro</span>}
                                arrow={true}
                                animation='perspective'
                                placement='right'
                                delay={100}>
                                <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                    <FiSlash className='cursor-pointer' onClick={async () => {
                                        setLoading(true)
                                        setFilter({ typeAccess_id: '3', active: 1 })
                                        setFilterConstructor({ typeAccess_id: '3' })
                                        await new Promise(resolve => setTimeout(resolve, 500))
                                        setLoading(false)
                                    }} />
                                </div>
                            </Tippy>
                            : <></>
                        }
                    </div>
                    <TablePaginationDefault
                        onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                        quantity={(e) => setQuantity(e)}
                        apiUrl={'/api/v1/usersClient'}
                        filters={filter}
                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                        collumns={[
                            {
                                name: 'id',
                                type: 'number',
                                description: 'Código'
                            },
                            {
                                name: 'fullname',
                                type: 'string',
                                description: 'Nome'
                            },
                            {
                                name: 'email',
                                type: 'string',
                                description: 'E-mail'
                            },
                            {
                                name: 'typeAccess_description',
                                type: 'string',
                                description: 'Tipo de acesso'
                            },
                            {
                                name: 'created_at',
                                type: 'date',
                                description: 'Criado em'
                            }
                        ]}
                    />
                </Body>
            </Container>
        </>
    )


}