import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { FaArrowDown, FaCloudUploadAlt, FaEdit, FaFilter } from 'react-icons/fa';
import { BsCardChecklist } from 'react-icons/bs';
import { FiSlash } from 'react-icons/fi'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { Button } from '../../../components/buttons/button.default';
import { Body } from '../../../components/container/Body';
import { TablePaginationDefault } from '../../../components/table/tablePagination.default';
import FileDropZone from '../../../components/dropZone/dropZone';
import { ScreenModal } from '../../../components/modals/notification/screenModal';
import { DefaultLoader } from '../../../components/loaders/defaultLoader';
import { Input } from '../../../components/input/input';
import Multiselect from '../../../components/selects/mult.select';
import { InputAutoComplete } from '../../../components/input/input.autocomplete';
import { LabelInput } from '../../../components/label/label.input';
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//utils
import { typeProducts } from "../../../utils/typeProduct/typeProduct";
import { unitMeasures } from "../../../utils/unitMeasure/unitMeasureList";
import { justNumber } from '../../../utils/validators/justNumber'
//api
import { api } from "../../../services/api/api";

export function ProductsTable() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const [filter, setFilter] = useState({active: true})
    const [filterConstructor, setFilterConstructor] = useState({})
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { setShowUniversalModal } = useThemeContext()
    const typeProductsList = typeProducts()
    const unitMeasureList = unitMeasures()
    const [loading, setLoading] = useState(false)
    const [productsGroupList, setProductsGroupList] = useState([])

    const [companies, setCompanies] = useState([])
    const [selectedCompanies] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [showImports, setShowImports] = useState(false)
    const [sendFile, setSendFile] = useState('')
    const [detectModal, setDetectModal] = useState('')

    useEffect(() => {

        setLoading(true)

        async function getCompanie() {
            try {
                const getCompaniesAPI = await api.get(`api/v1/companies/readCompaniesSelected`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                setCompanies(getCompaniesAPI?.data?.data)
    
                const getGroupsAPI = await api.get(`api/v1/lm/productsGroup`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                setProductsGroupList(getGroupsAPI?.data?.data)
            } catch(error){
                if (responseError(error).length > 0) {
                    setShowModificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModalText(responseError(error))
                    setLoading(false)
                } else {
                    setShowModificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModalText(responseError(error))
                    setLoading(false)
                }
            }
        }

        getCompanie()
        setLoading(false)

    }, [])

    async function handleEdit(customer) {
        navigate(`/productsEdit/${customer?.id}`)
    }

    async function downloadCSV() {
        setLoading(true)

        const dataExportsModel = await api.get(`/api/v1/lm/imports/exportModelProducts`, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                authorization: `Bearer ${userData[0].token}`
            }
        })

        let fileObj = URL.createObjectURL(dataExportsModel?.data)
        let myElement = document.createElement('a')
        myElement.setAttribute('href', fileObj)
        myElement.setAttribute("download", `Produtos_${Number((new Date))}`)
        myElement.click()

        setLoading(false)
    }

    async function handleImports() {

        if (!sendFile) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowUniversalModal(false)
            return setShowNotificationModalText("Selecione um arquivo para realizar a importação.")
        }

        setLoading(true)
        setShowUniversalModal(false)
        
        try {

            const formData = new FormData();
            formData.append('files', sendFile);

            await api.post(`/api/v1/lm/imports/importProducts`, formData, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })
            setSendFile('')
            setShowModificationModal(true)
            setShowNotificationModalText('Importação realizada com sucesso')            
            setShowNotificationModalSuccess(true)
            setShowImports(false)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function exportProducts(){
        try {

        const urlContructor = ['']

        Object.keys(filterConstructor).map(key => {
            if(![null, undefined, '', NaN].includes(filterConstructor[key])){                
                urlContructor.push(`${key}=${filterConstructor[key]}`)
            }
        })
        
        const dataExports = await api.get('/api/v1/lm/products/exports' + '?' + urlContructor.join('&'), {
            headers: {
                authorization: `Bearer ${userData[0].token}`
            },
            responseType: 'blob',
        })

        const url = window.URL.createObjectURL(dataExports?.data)
        const downloadElement = document.createElement('a')
        downloadElement.setAttribute('href', url)
        downloadElement.setAttribute('download', `Produtos_${new Date().toISOString().split('T')[0]}`)
        downloadElement.click()

        } catch(error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }
    }

    return (
        <>
            {
                detectModal == '1' &&
                <ScreenModal title={'Importar Produtos'}>
                    <div className='w-full h-full flex flex-col items-center justify-center mt-5 pb-5'>
                        <p className='text-base text-titleBlackTextLight'>Deseja realmente importar os produtos?</p>
                        <div className='flex flex-row gap-1 mt-5'>
                            <Button onClick={() => handleImports()}>Sim </Button>
                            <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            {
                detectModal == '2' &&
                <ScreenModal title={'Filtros'}>
                    {
                        loading
                            ? <DefaultLoader />
                            : (
                                <div className='flex flex-col pt-2 pb-5 px-12 gap-2 items-center'>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Tipo do produto *</a>
                                        <InputAutoComplete
                                            data={typeProductsList}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, typeProduct: typeProductsList.filter(item => item.typeProduct == e.typeProduct)[0]?.typeProduct })}
                                            optionList={['typeProduct']}
                                            selectedLabel={'typeProduct'}
                                            width={80}
                                        />
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Código (produto) *</a>
                                        <Input
                                            charLimit={255} id='code' type='text'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, code: justNumber(e.target.value) })}
                                            value={filterConstructor?.code}
                                            width={80}
                                        ></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Descrição *</a>
                                        <Input
                                            charLimit={255} id='description' type='text'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, description: e.target.value })}
                                            value={filterConstructor?.description}
                                            width={80}
                                        ></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Unidade de medida *</a>
                                        <InputAutoComplete
                                            data={unitMeasureList}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, unitMeasure: unitMeasureList.filter(item => item.id == e.id)[0]?.id })}
                                            optionList={['id']}
                                            selectedLabel={'id'}
                                            width={80}
                                        />
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Grupo do Produto *</a>
                                        <InputAutoComplete
                                            data={productsGroupList}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, productsGroup_id: e?.id })}
                                            optionList={['id', 'description']}
                                            selectedLabel={'description'}
                                            width={80}
                                        />
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Código antigo</a>
                                        <Input
                                            charLimit={255} id='externalId' type='text'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, externalId: e.target.value })}
                                            value={filterConstructor?.externalId}
                                            width={80}
                                        ></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Status *</a>
                                        <InputAutoComplete
                                            data={[{ value: false, description: 'Inativo' }, { value: true, description: 'Ativo' }]}
                                            selectedLabel={'description'}
                                            optionList={['description']}
                                            onChange={e => setFilterConstructor({ ...filterConstructor, active: e.value })}
                                            preSelectedValue={[{ value: false, description: 'Inativo' }, { value: true, description: 'Ativo' }]?.filter(filter => filter == filterConstructor.active)[0]?.description}
                                            id='active'
                                            width={80}
                                            height={60}
                                        />
                                    </label>
                                    <div className='mt-5'>
                                        <Button onClick={() => {
                                            let formatCompanies = []
                                            if (filterConstructor?.companies_id) {
                                                formatCompanies = filterConstructor?.companies_id?.map(item => { return item?.id })
                                            }
                                            setFilter({ ...filterConstructor, companies_id: formatCompanies?.toString() })
                                            setShowUniversalModal(false)
                                        }}>Filtrar</Button>
                                    </div>
                                </div>
                            )
                    }
                </ScreenModal>
            }
            <Container validPeriod_lm={true}>
                <TitlePage>
                    <div className='flex flex-row gap-2 items-center justify-between  pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Produtos'}></Title>
                            <div className='flex flex-row gap-2 md:w-48 items-center'>
                                <h3 className='text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity} Produtos</h3>
                                <div className='hidden md:flex flex-row justify-start items-center gap-1 cursor-pointer ml-3' onClick={() => exportProducts()}>
                                    <Tippy
                                        content={<span>Exportar lista em excel</span>}
                                        arrow={true}
                                        animation='perspective'
                                        placement='bottom'
                                        delay={80}
                                    >
                                        <p className='flex flex-row items-center justify-center gap-1 text-xs 2xl:text-sm dark:text-gray-300'>Exportar <FaArrowDown className='dark:text-titleGrayTextDark text-xs 2xl:text-sm' /></p>
                                    </Tippy>
                                </div>
                            </div>
                        </div>
                        {
                            userData.map(isAdmin => {
                                return isAdmin.typeAccess_id == 1
                                    && <div className='w-full flex justify-end items-end mr-[15px] mt-[10px]'>
                                        <Tippy
                                            content={<span>Importar Produtos</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={80}
                                        >
                                            <div onClick={() => setShowImports(true)} className="text-xl text-blue-600 cursor-pointer">
                                                <FaCloudUploadAlt />
                                            </div>
                                        </Tippy>
                                    </div>
                            })
                        }
                        {
                            (userData[0]?.permissions?.indexOf('lm_products:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                            <>
                                <div className='hidden sm:flex flex-row gap-5 items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/productsCreate')}>
                                    <Button shadow={true} width={56} onClick={() => navigate('/productsCreate')}>+ Novo Produto</Button>
                                </div>
                                <div className='sm:hidden flex flex-col items-center justify-end' onClick={() => navigate('/productsCreate')}>
                                    <Button shadow={true} onClick={() => navigate('/productsCreate')}>Novo</Button>
                                </div>
                            </>
                        }
                    </div>
                </TitlePage>
                <Body>
                    {showImports ?
                        <>
                            {
                                loading ?
                                <DefaultLoader/>
                                :
                                <>
                                    <div className="flex flex-col justify-start items-start gap-1 w-full">
                                        <h1 className="text-md text-red-500">Atenção às regras de importação.</h1>
                                        <p className='text-sm'>
                                            <span className="font-semibold">Regras de importação:</span> Utilizar o arquivo base para importação, o arquivo poderá ser baixado no botão abaixo ou clicando <button className="underline" onClick={() => downloadCSV()}>aqui</button>
                                        </p>
                                        <p className='text-sm'>
                                            <span className="font-semibold">Campos obrigatórios:</span> "Tipo de produto", "Descrição" e "Unidade de medida"
                                        </p>
                                        <p className='text-sm flex flex-col gap-1 mb-1'>
                                            <i>Tipo de produto: PA, PI, PR, MP, E, S</i>
                                            <i>Unidade de medida: Un, Lt, Kg, Cx, M3, M2, M, Hr</i>
                                            <i>Obs: Valores monetários informar com vírgula, exemplo: 1000,90</i>
                                        </p>
                                        <Button onClick={() => downloadCSV()}>Baixar</Button>
                                    </div>
                                    <div className='w-full'>
                                        <FileDropZone setSendFile={setSendFile} />
                                    </div>
                                    <div className="flex gap-10">
                                        <Button onClick={() => setShowImports(false)} approval={false} width={20}>Voltar</Button>
                                        <Button onClick={() => { setDetectModal("1"); setShowUniversalModal(true) }}  >Importar</Button>
                                    </div>
                                </>
                            }
                        </>
                        :
                        loading ?
                            <DefaultLoader />
                            :
                            <>
                                <div className='w-full sm:mt-0 mt-2 items-center flex gap-4'>
                                    <Button onClick={() => { setDetectModal("2"); setShowUniversalModal(true) }}>
                                        <FaFilter className='text-white' />
                                        <p className='text-white'> Filtrar </p>
                                    </Button>
                                    {filter && JSON.stringify(filter) != JSON.stringify({active: true})
                                        ? <Tippy
                                            key={`clearFilter`}
                                            content={<span>Limpar filtro</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='right'
                                            delay={100}>
                                            <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                                <FiSlash className='cursor-pointer' onClick={async () => {
                                                    setLoading(true)
                                                    setFilter({active: true})
                                                    setFilterConstructor({})
                                                    await new Promise(resolve => setTimeout(resolve, 500))
                                                    setLoading(false)
                                                }} />
                                            </div>
                                        </Tippy>
                                        : <></>
                                    }
                                </div>
                                <TablePaginationDefault
                                    onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                                    quantity={(e) => setQuantity(e)}
                                    apiUrl={'/api/v1/lm/products'}
                                    filters={filter}
                                    mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                    collumns={[
                                        {
                                            name: 'typeProduct',
                                            type: 'string',
                                            description: 'Tipo'
                                        },
                                        {
                                            name: 'code',
                                            type: 'number',
                                            description: 'Código'
                                        },
                                        {
                                            name: 'description',
                                            type: 'string',
                                            description: 'Descrição'
                                        },
                                        {
                                            name: 'unitMeasure',
                                            type: 'string',
                                            description: 'Un. Medida',
                                            func: (item) => {
                                                return unitMeasureList?.filter(unit => unit?.id == item?.unitMeasure)[0]?.unitMeasure
                                            }
                                        },
                                        {
                                            name: 'cost',
                                            type: 'number',
                                            description: 'Custo',
                                            func: (item) => {
                                                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(item?.cost)
                                            }
                                        },
                                        {
                                            name: 'productsGroup',
                                            type: 'string',
                                            description: "Grupo",
                                            func: (item) => {
                                                if (item?.productsGroup){
                                                    return item?.productsGroup
                                                } else {
                                                    return ' - '
                                                }
                                            }
                                        },
                                        {
                                            name: 'active',
                                            type: 'boolean',
                                            description: 'Status',
                                            func: (item) => {
                                                if (item?.active){
                                                    return 'Ativo'
                                                } else {
                                                    return 'Inativo'
                                                }
                                            }
                                        }
                                    ]}
                                />
                            </>
                    }
                </Body>
            </Container >
        </>
    )
}