import React, { useEffect } from 'react'
import { Route, Routes, HashRouter, Navigate } from 'react-router-dom'
import { CalendarMain } from '../components/calendar/calendar.main'
import { ProtectedRoute } from '../pages/privateRoute'
import { useAuth } from '../contexts/useAuth'
//components
import { Menu } from '../components/menu/menu'
import { ClientsCreate } from '../pages/configuration/clients/clients.create'
import { ClientsEdit } from '../pages/configuration/clients/clients.edit'
import { ClientsTable } from '../pages/configuration/clients/clients.table'

import { CompaniesTable } from '../pages/configuration/companies/companies.table'
import { CompaniesCreate } from '../pages/configuration/companies/companies.create'
import { CompaniesEdit } from '../pages/configuration/companies/companies.edit'

import { Configuration } from '../pages/configuration/configurationHome/configuration.home'
import { Customer } from '../pages/configuration/customer/customer.profile'
import { ThemesCenter } from '../pages/configuration/themes/themes.center'
import { UserEdit } from '../pages/configuration/users/user.edit'

import { PermissionGroupsTable } from '../pages/configuration/permissionGroups/permissionGroups.table'
import { PermissionGroupsCreate } from '../pages/configuration/permissionGroups/permissionGroups.create'
import { PermissionGroupsEdit } from '../pages/configuration/permissionGroups/permissionGroups.edit'

import { ProductsTable } from '../pages/configuration/products/products.tables'
import { ProductsCreate } from '../pages/configuration/products/products.create'
import { ProductsEdit } from '../pages/configuration/products/products.edit'

import { UserCreate } from '../pages/configuration/users/users.creation'
import { UsersTable } from '../pages/configuration/users/users.table'

import { UsersClientsTable } from '../pages/configuration/usersClients/users.clients.table'
import { UserClientsCreate } from '../pages/configuration/usersClients/users.clients.creation'
import { UserClientsEdit } from '../pages/configuration/usersClients/user.clients.edit'

import { CustomersOfCustomersTable } from '../pages/configuration/customersOfCustomer/customersOfCustomer.table'
import { CustomersOfCustomersCreate } from '../pages/configuration/customersOfCustomer/customersOfCustomer.create'
import { CustomersOfCustomersEdit } from '../pages/configuration/customersOfCustomer/customersOfCustomer.edit'

import { BanksTable } from '../pages/configuration/fc_banks/banks.table'
import { BanksCreate } from '../pages/configuration/fc_banks/banks.create'
import { BanksEdit } from '../pages/configuration/fc_banks/banks.edit'

import { CashFlow } from '../pages/configuration/cashFlow/cash.Flow'
import { CashFlowMobileEdit } from '../pages/configuration/cashFlow/cash.Flow.Mobile.Edit'

import { DeterminationPeriodCreate } from '../pages/configuration/determinationPeriod/determination.Period.create'
import { DeterminationPeriod } from '../pages/configuration/determinationPeriod/determination.Period'
import { DeterminationPeriodEdit } from '../pages/configuration/determinationPeriod/determination.Period.edit'

import { Home } from '../pages/home'
import { ThemesDefaultCenter } from '../pages/configuration/themes/themes.default.center'
import { CompanySelect } from '../pages/security/companySelect'
import { Dashboard } from '../pages/configuration/dashboard/dashboard'
import { MyProfile } from '../pages/configuration/myProfile/myProfile'
import { AccountsPlans } from '../pages/configuration/accountsPlans/accountsPlans'
import { TemplateAccountsPlans } from '../pages/configuration/templateAccountsPlans/templateAccountsPlans'
import { Agglutinated } from '../pages/configuration/agglutinated/agglutinated'
import { Imports } from '../pages/configuration/imports/imports'
import { ManagerCustomer } from '../pages/configuration/managerCustomer/managerCustomer'

import { Shopping } from '../pages/configuration/shopping/shopping'
import { ShoppingCreate } from '../pages/configuration/shopping/shopping.create'
import { ShoppingEdit } from '../pages/configuration/shopping/shopping.edit'

import { TaxRegime } from '../pages/configuration/taxRegime/taxRegime'
import { TaxRegimeCreate } from '../pages/configuration/taxRegime/taxRegime.create'
import { TaxRegimeEdit } from '../pages/configuration/taxRegime/taxRegime.edit'

import { PaymentCondition } from '../pages/configuration/paymentCondition/paymentCondition'
import { PaymentConditionCreate } from '../pages/configuration/paymentCondition/paymentCondition.create'
import { PaymentConditionEdit } from '../pages/configuration/paymentCondition/paymentCondition.edit'

import { Seller } from '../pages/configuration/seller/seller'
import { SellerCreate } from '../pages/configuration/seller/seller.create'
import { SellerEdit } from '../pages/configuration/seller/seller.edit'

import { ComissionSale } from '../pages/configuration/comissionSale/comissionSale'
import { ComissionSaleCreate } from '../pages/configuration/comissionSale/comissionSaleCreate'
import { ComissionSaleEdit } from '../pages/configuration/comissionSale/comissionSaleEdit'

import { DataSheet } from '../pages/configuration/dataSheet/dataSheet'
import { DataSheetCreate } from '../pages/configuration/dataSheet/dataSheetCreate'
import { DataSheetEdit } from '../pages/configuration/dataSheet/dataSheetEdit'

import { Sales } from '../pages/configuration/sales/sales'
import { SalesCreate } from '../pages/configuration/sales/salesCreate'
import { SalesEdit } from '../pages/configuration/sales/salesEdit'

import { InflatorFinancial } from '../pages/configuration/inflatorFinancial/inflatorFinancial'
import { InflatorFinancialCreate } from '../pages/configuration/inflatorFinancial/inflatorFinancial.create'
import { InflatorFinancialEdit } from '../pages/configuration/inflatorFinancial/inflatorFinancial.edit'

import { ICMSTable } from '../pages/configuration/icmsTable/icmsTable'
import { ICMSTableCreate } from '../pages/configuration/icmsTable/icmsTable.create'

import { ProductsGroup } from '../pages/configuration/productsGroup/productsGroup'
import { ProductsGroupCreate } from '../pages/configuration/productsGroup/productsGroupCreate'
import { ProductsGroupEdit } from '../pages/configuration/productsGroup/productsGroupEdit'

import { ContributionMargin } from '../pages/configuration/contributionMargin/contributionMargin'

export function AppRoutes() {
    const { userData, selectedCompany, selectedModule } = useAuth()

    return (
        <>
            <HashRouter>
                <div className="flex flex-col-reverse sm:flex-row md:h-auto justify-between md:justify-items-start overflow-hidden">
                    <Menu />
                    <Routes>
                        {selectedCompany !== '' ? (
                            <Route path="/" element={<Home />} />
                        ) : (

                            <Route path="/" element={<CompanySelect />} />
                        )}
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/configuration" element={<Configuration />} />
                        <Route path="/myProfile" element={<MyProfile />} />
                        <Route path="/users" element={<UsersTable />} />
                        <Route path="/userCreate" element={<UserCreate />} />
                        <Route path="/usersEdit/:id" element={<UserEdit />} />
                        <Route path="/usersClients" element={<UsersClientsTable />} />
                        <Route path="/userClientsCreate" element={<UserClientsCreate />} />
                        <Route path="/usersClientsEdit/:id" element={<UserClientsEdit />} />
                        <Route path="/permissionGroups" element={<PermissionGroupsTable />} />
                        <Route
                            path="/permissionGroupsCreate"
                            element={<PermissionGroupsCreate />}
                        />
                        <Route
                            path="/permissionGroupsEdit/:id"
                            element={<PermissionGroupsEdit />}
                        />
                        <Route path="/clients" element={<ClientsTable />} />
                        <Route path="/clientsCreate" element={<ClientsCreate />} />
                        <Route path="/clientsEdit/:id" element={<ClientsEdit />} />

                        <Route path="/companies" element={<CompaniesTable />} />
                        <Route path="/companiesCreate" element={<CompaniesCreate />} />
                        <Route path="/companiesEdit/:id" element={<CompaniesEdit />} />

                        {/* Ambos os Modulos */}
                        <Route
                            path="/customersOfCustomer"
                            element={<CustomersOfCustomersTable />}
                        />
                        <Route
                            path="/customersOfCustomerCreate"
                            element={<CustomersOfCustomersCreate />}
                        />
                        <Route
                            path="/customersOfCustomerEdit/:id"
                            element={<CustomersOfCustomersEdit />}
                        />
                        <Route
                            path="/accountsPlans"
                            element={<AccountsPlans />}
                        />
                        <Route
                            path="/templateAccountsPlans"
                            element={<TemplateAccountsPlans />}
                        />

                        {/* Fluxo de Caixa */}
                        <Route
                            path="/banks"
                            element={<BanksTable />}
                        />
                        <Route
                            path="/banksCreate"
                            element={<BanksCreate />}
                        />
                        <Route
                            path="/banksEdit/:id"
                            element={<BanksEdit />}
                        />
                        <Route
                            path="/agglutinated"
                            element={<Agglutinated />}
                        />
                        <Route
                            path="/managerCustomer"
                            element={<ManagerCustomer />}
                        />
                        <Route
                            path="/imports"
                            element={<Imports />}
                        />
                        {/* Na rota abaixo já está incluso a criação e vizualização da tabela*/}
                        <Route
                            path="/cashFlow"
                            element={<CashFlow />}
                        />
                        <Route
                            path="/cashFlowMobileEdit/:id"
                            element={<CashFlowMobileEdit />}
                        />
                        <Route
                            path="/determinationPeriod"
                            element={<DeterminationPeriod />}
                        />
                        <Route
                            path="/determinationPeriodCreate"
                            element={<DeterminationPeriodCreate />}
                        />
                        <Route
                            path="/determinationPeriodEdit/:id"
                            element={<DeterminationPeriodEdit />}
                        />
                        {/* Leitura de Margem */}
                        <Route
                            path="/products"
                            element={<ProductsTable />}
                        />
                        <Route
                            path="/productsCreate"
                            element={<ProductsCreate />}
                        />
                        <Route
                            path="/productsEdit/:id"
                            element={<ProductsEdit />}
                        />
                        <Route
                            path="/shopping"
                            element={<Shopping />}
                        />
                        <Route
                            path="/shoppingCreate"
                            element={<ShoppingCreate />}
                        />
                        <Route
                            path="/shoppingEdit/:id"
                            element={<ShoppingEdit />}
                        />
                        <Route
                            path="/taxRegime"
                            element={<TaxRegime />}
                        />
                        <Route
                            path="/taxRegimeCreate"
                            element={<TaxRegimeCreate />}
                        />
                        <Route
                            path="/taxRegimeEdit/:id"
                            element={<TaxRegimeEdit />}
                        />
                        <Route
                            path="/paymentCondition"
                            element={<PaymentCondition />}
                        />
                        <Route
                            path="/paymentConditionCreate"
                            element={<PaymentConditionCreate />}
                        />
                        <Route
                            path="/paymentConditionEdit/:id"
                            element={<PaymentConditionEdit />}
                        />
                        <Route
                            path="/seller"
                            element={<Seller />}
                        />
                        <Route
                            path="/sellerCreate"
                            element={<SellerCreate />}
                        />
                        <Route
                            path="/sellerEdit/:id"
                            element={<SellerEdit />}
                        />
                        <Route
                            path="/comissionSale"
                            element={<ComissionSale />}
                        />
                        <Route
                            path="/comissionSaleCreate"
                            element={<ComissionSaleCreate />}
                        />
                        <Route
                            path="/comissionSaleEdit/:id"
                            element={<ComissionSaleEdit />}
                        />
                        <Route
                            path="/inflatorFinancial"
                            element={<InflatorFinancial />}
                        />
                        <Route
                            path="/inflatorFinancialCreate"
                            element={<InflatorFinancialCreate />}
                        />
                        <Route
                            path="/inflatorFinancialEdit/:id"
                            element={<InflatorFinancialEdit />}
                        />
                        <Route
                            path="/datasheet"
                            element={<DataSheet />}
                        />
                        <Route
                            path="/datasheetCreate"
                            element={<DataSheetCreate />}
                        />
                        <Route
                            path="/datasheetEdit/:id/:reference?"
                            element={<DataSheetEdit />}
                        />
                        <Route
                            path="/sales"
                            element={<Sales />}
                        />
                        <Route
                            path="/salesCreate"
                            element={<SalesCreate />}
                        />
                        <Route
                            path="/salesEdit/:id"
                            element={<SalesEdit />}
                        />
                        <Route
                            path="/icmsTable"
                            element={<ICMSTable />}
                        />
                        <Route
                            path="/icmsTableCreate"
                            element={<ICMSTableCreate />}
                        />
                        <Route
                            path="/contributionMargin"
                            element={<ContributionMargin />}
                        />
                        <Route
                            path="/productsGroup"
                            element={<ProductsGroup />}
                        />
                        <Route
                            path="/productsGroupCreate"
                            element={<ProductsGroupCreate />}
                        />
                        <Route
                            path="/productsGroupEdit/:id"
                            element={<ProductsGroupEdit />}
                        />
                        {/* exemplo de rota privada  */}
                        {/* <Route path='/customer' element={
                                <ProtectedRoute user={userData} func={["customers:read", "customers:update", "customers:delete"]}>
                                    <Customer />
                                </ProtectedRoute>
                            }
                            /> */}
                        <Route path="/theme" element={<ThemesCenter />} />
                        <Route
                            path="/defaultTheme"
                            element={
                                <ProtectedRoute user={userData} func={''}>
                                    <ThemesDefaultCenter />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                </div>
            </HashRouter>
        </>
    )
}
