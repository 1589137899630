//hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from "../../../contexts/useAuth";
//services
import { api } from "../../../services/api/api";
//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { FaArrowDown, FaEdit, FaFilter } from "react-icons/fa";
import { Button } from "../../../components/buttons/button.default";
import { Body } from "../../../components/container/Body";
import { TablePaginationDefault } from "../../../components/table/tablePagination.default";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { Input } from "../../../components/input/input";
import { FiSlash } from "react-icons/fi";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
//utils
import { justNumber } from '../../../utils/validators/justNumber'

export function ProductsGroup(){

    const navigate = useNavigate()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({ })
    const [filterConstructor, setFilterConstructor] = useState({})
    const { setShowUniversalModal } = useThemeContext()
    const [quantity, setQuantity] = useState(0)

    async function handleEdit(group) {
        navigate(`/productsGroupEdit/${group?.id}`)
    }

    return (
        <>
            {
                <ScreenModal title={'Filtros'}>
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            <div className='flex flex-col pt-2 pb-5 px-0 gap-2 items-center'>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Código do Grupo *</a>
                                    <Input
                                        charLimit={255} id='id' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                        value={filterConstructor?.id}
                                        width={80}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Descrição do Grupo *</a>
                                    <Input
                                        charLimit={255} id='id' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, description: e.target.value })}
                                        value={filterConstructor?.description}
                                        width={80}
                                    ></Input>
                                </label>
                                <div className='mt-5'>
                                    <Button onClick={() => {
                                        setFilter(filterConstructor)
                                        setShowUniversalModal(false)
                                    }}>Filtrar</Button>
                                </div>
                            </div>
                    }
                </ScreenModal>
            }
            <Container>
                <TitlePage>
                    <div className='flex flex-row gap-2 items-center justify-between  pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Grupos de Produtos'}></Title>
                            <div className='hidden sm:flex flex-row gap-2 md:w-48 justify-between items-center'>
                                <h3 className='text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity} Grupos</h3>
                            </div>
                        </div>
                        {
                            (userData[0]?.permissions?.indexOf('lm_productsGroup:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                            <>
                                <div className='hidden sm:flex flex-row gap-5 items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/productsGroupCreate')}>
                                    <Button shadow={true} width={40} onClick={() => navigate('/productsGroupCreate')}>+ Novo Grupo</Button>
                                </div>
                                <div className='sm:hidden flex flex-col items-center justify-end' onClick={() => navigate('/productsGroupCreate')}>
                                    <Button shadow={true} onClick={() => navigate('/productsGroupCreate')}>Novo</Button>
                                </div>
                            </>
                        }
                    </div>
                </TitlePage>
                <Body>
                    <div className='w-full sm:mt-0 mt-2 items-center flex gap-4'>
                        <Button onClick={() => { setShowUniversalModal(true) }}>
                            <FaFilter className='text-white' />
                            <p className='text-white'> Filtrar </p>
                        </Button>
                        {filter && JSON.stringify(filter) != JSON.stringify({ })
                            ? <Tippy
                                key={`clearFilter`}
                                content={<span>Limpar filtro</span>}
                                arrow={true}
                                animation='perspective'
                                placement='right'
                                delay={100}>
                                <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                    <FiSlash className='cursor-pointer' onClick={async () => {
                                        setLoading(true)
                                        setFilter({ })
                                        setFilterConstructor({})
                                        await new Promise(resolve => setTimeout(resolve, 500))
                                        setLoading(false)
                                    }} />
                                </div>
                            </Tippy>
                            : <></>
                        }
                    </div>
                    <TablePaginationDefault
                        onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                        quantity={(e) => setQuantity(e)}
                        apiUrl={'/api/v1/lm/productsGroup'}
                        filters={filter}
                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                        colSpan={9}
                        collumns={[
                            {
                                name: 'id',
                                type: 'number',
                                description: 'Código do Grupo'
                            },
                            {
                                name: 'description',
                                type: 'string',
                                description: 'Descrição do Grupo',
                            },
                            {
                                name: 'created_at',
                                type: 'date',
                                description: 'Criado em'
                            },
                        ]}
                    />
                </Body>
            </Container>
        </>
    )
}