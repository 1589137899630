export function unitMeasures() {
    const unitMeasureList = [
        {
            unitMeasure: 'Unidade',
            id: 'Un'
        },
        {
            unitMeasure: 'Litros',
            id: 'Lt'
        },
        {
            unitMeasure: 'Quilograma',
            id: 'Kg'
        },
        {
            unitMeasure: 'Caixa',
            id: 'Cx'
        },
        {
            unitMeasure: 'Metro cúbico',
            id: 'M3'
        },
        {
            unitMeasure: 'Metro quadrado',
            id: 'M2'
        },
        {
            unitMeasure: 'Metro',
            id: 'M'
        },
        {
            name: 'Hora',
            id: 'Hr'
        }
    ]

    return unitMeasureList
}