//hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaExchangeAlt, FaCheck, FaUnlock, FaLock } from 'react-icons/fa'
import { FaCalendarDays, FaUpRightFromSquare } from 'react-icons/fa6'
//components
import { Body } from '../components/container/Body'
import { Container } from '../components/container/container'
import { Title } from '../components/titlePages/title'
import { TitlePage } from '../components/titlePages/title.page'
import { DefaultLoader } from '../components/loaders/defaultLoader'
import { ScreenModal } from '../components/modals/notification/screenModal'
import { Button } from '../components/buttons/button.default'
import { LabelInput } from '../components/label/label.input'
import { InputAutoComplete } from '../components/input/input.autocomplete'
//contexts
import { useAuth } from '../contexts/useAuth'
import { useThemeContext } from '../contexts/themeContext'
//services
import { api } from '../services/api/api'
//utils
import { responseError } from '../utils/responsesFunctions/error.response'
import { validateFields } from '../utils/form.validator'
//others
import ImgFC from '../assets/logos/img_fc.jpg'
import ImgLM from '../assets/logos/img_lm.jpg'
import { LoadingPulse } from '../components/loaders/loadingPulse'

export function Home() {

    const navigate = useNavigate()
    const { selectedCompany, setSelectedCompany, setSelectedModule, selectedModule, userData, setSelectedPeriod } = useAuth()
    const { setCompanyColor, companyColor, setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [detectModal, setDetectModal] = useState('')
    const [loading, setLoading] = useState(false)
    const [periods, setPeriods] = useState([])
    const [selectedPeriodId, setSelectedPeriodId] = useState('')
    const [loadingPeriod, setLoadingPeriod] = useState(false)

    useEffect(() => {
        if(selectedModule ==  'lm'){
            getPeriodDataAPI()
        }
    }, [selectedModule])

    async function getPeriodDataAPI() {
        setLoadingPeriod(true)
        try {
            const getPeriods = await api.get(`/api/v1/general/periods`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setPeriods(getPeriods?.data?.data?.map(item => {
                return {
                    ...item,
                    valueToShowSelected: (item.month.toString().length == 1 ? '0' + item.month : item.month) + '/' + item.year,
                    value: (item.month.toString().length == 1 ? '0' + item.month : item.month) + '/' + item.year,
                    success: item.isOpen
                }
            }))
            setLoadingPeriod(false)
        } catch (error) {
            setLoadingPeriod(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function alterBusiness() {
        localStorage.removeItem('@company')
        localStorage.removeItem('@module')

        localStorage.removeItem('companyColor')
        setCompanyColor('')
        document.documentElement.style.setProperty('--companyColor', companyColor)

        setSelectedCompany('')
        setSelectedModule('')
        setSelectedPeriod('')
        setShowUniversalModal(false)
    }

    async function handleModule(module) {
        localStorage.setItem('@module', module)
        setSelectedModule(module)
    }

    async function handleSubmitPeriod() {

        const requiredFields = [
            { name: 'period', value: selectedPeriodId, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campo obrigatório não preenchido, preencha o campo em vermelho')
        }
        try {

            await api.post(`/api/v1/setting/selectedPeriod`, {
                periods_id: selectedPeriodId?.id
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Período selecionado com sucesso!')
            setShowUniversalModal(false)
            setSelectedPeriod(selectedPeriodId)

        } catch (error) {
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            {   detectModal == '1' &&
                <ScreenModal title={'Trocar empresa'} width={400} height={200} >
                    <div className='w-full h-96 flex flex-col items-center justify-center'>
                        <p className='text-base text-titleBlackTextLight'>Deseja realmente trocar de empresa?</p>
                        <div className='flex flex-row gap-1 mt-10'>
                            <Button shadow={true} onClick={() => alterBusiness() }>Sim</Button>
                            <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            {
                detectModal == '2' &&
                    <ScreenModal title={'Selecionar período'} width={400} height={250}>
                        <div className='flex flex-col justify-center items-center gap-10 h-96'>
                            <LabelInput text={'Períodos *'}>
                                <InputAutoComplete
                                    height={96}
                                    icon={{
                                        icon: <FaUnlock />,
                                        compareIcon: <FaLock />
                                    }}
                                    colorIcon={'dark:text-green-500 text-green-800'}
                                    colorCompareIcon={'dark:text-green-500 text-red-800'}
                                    data={periods}
                                    type={'text'}
                                    id='period'
                                    selectedLabel={['valueToShowSelected']}
                                    optionList={['value']}
                                    width={80}
                                    value={periods ? periods?.value : ''}
                                    preSelectedValue={periods ? periods?.valueToShowSelected : ''}
                                    onChange={(e) => setSelectedPeriodId(e)}
                                />
                            </LabelInput>
                            <div className='flex justify-center items-center gap-3'>
                                <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Cancelar</Button>
                                <Button shadow={true} onClick={() => handleSubmitPeriod()}>Continuar</Button>
                            </div>
                        </div>
                    </ScreenModal>
            }
            <Container bg={`bg-gray-100`}>
                <TitlePage>
                    <Title text={'Home'}></Title>
                </TitlePage>
                {
                    loading ?
                        <DefaultLoader />
                        :
                        <Body padding={false} shadow={false} background={false}>
                            <div className='flex flex-col items-center justify-center'>
                                <div>
                                    <Title text={'Selecione um módulo'} />
                                </div>
                                <div className={`flex lg:flex-row gap-16 mt-10 flex-col `}>
                                    {

                                        selectedCompany?.modules.map(module => {
                                            if (module == 'fc') {
                                                return <div style={{ backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), rgba(27, 27, 27, 0.9)), url(${ImgFC})` }} className={`Left_To_Center bg-cover w-80 md:w-92 md:h-[12rem] lg:h-[24rem] 2xl:h-[27rem] h-[26rem] mb-10 bg-bgSecondaryLight sm:hover:scale-110 duration-200 cursor-pointer rounded-lg ${selectedModule == 'fc' ? 'shadow-3xl shadow-primaryDefaultLight' : ''}`} onClick={() => handleModule('fc')}>
                                                    <div className='flex flex-col items-center justify-center rounded-lg h-full gap-4'>
                                                        <p className='text-white text-lg md:translate-y-0 lg:translate-y-44 translate-y-44 flex flex-row items-center'>
                                                            Fluxo de caixa
                                                            {
                                                                selectedModule === 'fc' &&
                                                                <FaCheck className='ml-2 text-blue-500' />
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            if (module == 'lm') {
                                                return <div style={{ backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), rgba(27, 27, 27, 0.9)), url(${ImgLM})` }} className={`Right_To_Center bg-cover w-80 md:w-92 md:h-[12rem] lg:h-[24rem] 2xl:h-[27rem] h-[26rem] mb-10 bg-bgSecondaryLight sm:hover:scale-110 duration-200 cursor-pointer rounded-lg ${selectedModule == 'lm' ? 'shadow-3xl shadow-primaryDefaultLight' : ''}`} onClick={() => handleModule('lm')}>
                                                    <div className='flex flex-col items-center justify-center rounded-lg h-full gap-4'>
                                                        <p id='pLM' className='text-white text-lg md:translate-y-0 lg:translate-y-44 translate-y-44 flex flex-row items-center'>
                                                            Margens de contribuição
                                                            {
                                                                selectedModule === 'lm' &&
                                                                <FaCheck className='ml-2 text-blue-500' />
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                                {
                                    selectedModule == 'fc' ?
                                    <div className={`rounded-lg flex items-center justify-center w-full`}>
                                        <span className='hover:scale-110 duration-200 cursor-pointer  flex flex-col items-center justify-center h-full gap-1' onClick={() => {setDetectModal('1'); setShowUniversalModal(true) }}>
                                            <FaExchangeAlt className='flex text-lg text-primaryDefaultLight' />
                                            <p className='text-sm text-primaryDefaultLight'>Trocar Empresa</p>
                                        </span>
                                    </div>
                                    :
                                    loadingPeriod ? 
                                        <span className='flex items-center justify-center ml-8'>
                                            <LoadingPulse/>
                                        </span>
                                    : <div className={`rounded-lg flex flex-col gap-5 md:gap-0 md:flex md:flex-row items-center justify-around w-full`}>
                                        <span className='hover:scale-110 duration-200 cursor-pointer  flex flex-col items-center justify-center h-full gap-1' onClick={() => {setDetectModal('1'); setShowUniversalModal(true) }}>
                                            <FaExchangeAlt className='flex text-lg text-primaryDefaultLight' />
                                            <p className='text-sm text-primaryDefaultLight'>Trocar Empresa</p>
                                        </span>
                                        { 
                                            selectedModule == 'lm' && periods.length > 0 ?
                                                <span className='hover:scale-110 duration-200 cursor-pointer  flex flex-col items-center justify-center h-full gap-1' onClick={() => {setDetectModal('2'); setShowUniversalModal(true) }}>
                                                    <FaUpRightFromSquare className='flex text-lg text-primaryDefaultLight' />
                                                    <p className='text-sm text-primaryDefaultLight'>Selecionar Período</p>
                                                </span>
                                            : selectedModule == 'lm' &&
                                                <span className='hover:scale-110 duration-200 cursor-pointer  flex flex-col items-center justify-center h-full gap-1' onClick={() => navigate('/determinationPeriod')}>
                                                    <FaCalendarDays className='flex text-lg text-primaryDefaultLight' />
                                                    <p className='text-sm text-primaryDefaultLight'>Criar Período</p>
                                                </span>
                                        }
                                    </div>
                                }
                            </div>
                        </Body>
                }
            </Container>
        </>
    )
}