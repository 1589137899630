export const formatRulesList = [
    {
        id: 1,
        description: 'R.B. - RECEITA BRUTA'
    },
    {
        id: 2,
        description: 'IMPOSTOS (+IR+CS)'
    },
    {
        id: 3,
        description: 'ESTORNOS'
    },
    {
        id: 4,
        description: 'R.L. - RECEITA LÍQUIDA'
    },
    {
        id: 5,
        description: 'C.V. - CUSTO VARIÁVEL'
    },
    {
        id: 6,
        description: 'D.V. - DESPESA VARIÁVEL'
    },
    {
        id: 7,
        description: 'M.C. - MARGEM DE CONTRIBUIÇÃO'
    },
    {
        id: 8,
        description: 'G.F.  - GASTO FIXO'
    },
    {
        id: 9,
        description: 'D.F. - DESPESA FINANC. OPERACIONAL'
    },
    {
        id: 10,
        description: 'RETORNO'
    },
    {
        id: 11,
        description: 'M.C. - MARGEM DE CONTRIBUIÇÃO'
    },
    {
        id: 12,
        description: 'G.F. - GASTO FIXO'
    },
    {
        id: 13,
        description: 'D.F. - DESPESA FINANCEIRA'
    },
    {
        id: 14,
        description: 'RETORNO'
    },
    {
        id: 15,
        description: '( - ) PARCELAMENTOS / ACORDOS / MUTUOS'
    },
    {
        id: 16,
        description: '( - ) EMPRÉSTIMOS E CAPITAL DE GIRO'
    },
    {
        id: 17,
        description: 'PONTO DE EQUILÍBRIO (RB)'
    },
]