//hooks
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FaArrowDown, FaCloudUploadAlt, FaEdit, FaFilter } from "react-icons/fa";
import Tippy from '@tippyjs/react';
import { FiSlash } from 'react-icons/fi';
import moment from 'moment';
//utils
import { justNumber } from '../../../utils/validators/justNumber';
import { responseError } from '../../../utils/responsesFunctions/error.response';
//services
import { api } from '../../../services/api/api';
//contexts
import { useAuth } from '../../../contexts/useAuth';
import { useThemeContext } from '../../../contexts/themeContext';
//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from '../../../components/buttons/button.default';
import { Body } from '../../../components/container/Body';
import { TablePaginationDefault } from '../../../components/table/tablePagination.default';
import { ScreenModal } from '../../../components/modals/notification/screenModal';
import { Input } from '../../../components/input/input';
import { DefaultLoader } from '../../../components/loaders/defaultLoader';
import { InputAutoComplete } from '../../../components/input/input.autocomplete';
import FileDropZone from '../../../components/dropZone/dropZone';

export function Sales() {

    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState()

    const [supplierAndCustomerData, setSupplierAndCustomerData] = useState([])
    const [paymentConditionData, setPaymentConditionData] = useState([])
    const [sellerData, setSellerData] = useState([])

    const [quantity, setQuantity] = useState(0)
    const [showImports, setShowImports] = useState(false)
    const [sendFile, setSendFile] = useState('')
    const [detectModal, setDetectModal] = useState('')

    useEffect(() => {
        async function getData(){
            setLoading(true)
            try {
                const supplierAndCustomer = await api.get(`/api/v1/general/supplierAndCustomer?isCustomer=true`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setSupplierAndCustomerData(supplierAndCustomer?.data?.data)
    
                const paymentCondition = await api.get(`/api/v1/lm/paymentCondition`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setPaymentConditionData(paymentCondition?.data?.data.filter(item => item.textDays = 'Dias'))
    
                const seller = await api.get(`/api/v1/lm/seller?active=true`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setSellerData(seller?.data?.data)
                setLoading(false)
            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }
        getData()
    }, [])

    async function handleEdit(sales) {
        navigate(`/salesEdit/${sales?.id}`)
    }

    async function downloadCSV() {
        setLoading(true)

        const dataExportsModel = await api.get(`/api/v1/lm/imports/exportModelSales`, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                authorization: `Bearer ${userData[0].token}`
            }
        })

        let fileObj = URL.createObjectURL(dataExportsModel?.data)
        let myElement = document.createElement('a')
        myElement.setAttribute('href', fileObj)
        myElement.setAttribute("download", `Vendas_${Number((new Date))}`)
        myElement.click()

        setLoading(false)
    }

    async function handleImports() {

        if (!sendFile) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowUniversalModal(false)
            return setShowNotificationModalText("Selecione um arquivo para realizar a importação.")
        }

        setLoading(true)
        setShowUniversalModal(false)        
        
        try {

            const formData = new FormData();
            formData.append('files', sendFile);

            await api.post(`/api/v1/lm/imports/importSales`, formData, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })
            setSendFile('')
            setShowModificationModal(true)
            setShowNotificationModalText('Importação realizada com sucesso')            
            setShowNotificationModalSuccess(true)
            setShowImports(false)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function exportSales(){
        try {

        const urlContructor = ['']

        Object.keys(filterConstructor).map(key => {
            if(![null, undefined, '', NaN].includes(filterConstructor[key])){
                urlContructor.push(`${key}=${filterConstructor[key]}`)
            }
        })
        
        const dataExports = await api.get('/api/v1/lm/sales/exports' + '?' + urlContructor.join('&'), {
            headers: {
                authorization: `Bearer ${userData[0].token}`
            },
            responseType: 'blob',
        })

        const url = window.URL.createObjectURL(dataExports?.data)
        const downloadElement = document.createElement('a')
        downloadElement.setAttribute('href', url)
        downloadElement.setAttribute('download', `Vendas_${new Date().toISOString().split('T')[0]}`)
        downloadElement.click()

        } catch(error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }
    }

    return (
        <>
            {
                detectModal == '1' &&
                <ScreenModal title={'Importar Produtos'} width={screenX > 400 ? '400px' : '150px'} height={160} >
                    <div className='w-full h-full flex flex-col items-center justify-center mt-5'>
                        <p className='text-base text-titleBlackTextLight'>Deseja realmente importar os produtos?</p>
                        <div className='flex flex-row gap-1 mt-5'>
                            <Button onClick={() => handleImports()}>Sim </Button>
                            <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            {
                detectModal == '2' &&
                <ScreenModal title={'Filtros'} >
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            <div className='flex flex-col pt-2 pb-5 px-10 gap-2 items-center'>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                    <Input
                                        charLimit={255} id='id' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                        value={filterConstructor?.id}
                                        width={80}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>De *</a>
                                    <Input
                                        id='startDate'
                                        type='date'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, startDate: e.target.value })}
                                        value={filterConstructor?.startDate}
                                        width={80}
                                    />
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Até *</a>
                                    <Input
                                        id='finishDate'
                                        type='date'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, finishDate: e.target.value })}
                                        value={filterConstructor?.finishDate}
                                        width={80}
                                    />
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Cliente *</a>
                                    <InputAutoComplete
                                        data={supplierAndCustomerData}
                                        selectedLabel={'businessName'}
                                        optionList={['businessName']}
                                        onChange={e => setFilterConstructor({ ...filterConstructor, supplierAndCustomer_id: e.id })}
                                        id='supplierAndCustomer_id'
                                        width={80}
                                    />
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Condição Pag. *</a>
                                    <InputAutoComplete
                                        data={paymentConditionData}
                                        selectedLabel={'days'}
                                        optionList={['days', 'textDays']}
                                        onChange={e => setFilterConstructor({ ...filterConstructor, paymentCondition_id: e.id })}
                                        preSelectedValue={filterConstructor?.paymentCondition_id ? paymentConditionData.filter(item => item.id == filterConstructor?.paymentCondition_id)[0]?.days : ''}
                                        id='paymentCondition_id'
                                        width={80}
                                    />
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Vendedor</a>
                                    <InputAutoComplete
                                        data={sellerData}
                                        selectedLabel={'name'}
                                        optionList={['name']}
                                        onChange={e => setFilterConstructor({ ...filterConstructor, seller_id: e.id })}
                                        preSelectedValue={filterConstructor?.seller_id ? sellerData.filter(item => item.id == filterConstructor?.seller_id)[0]?.name : ''}
                                        id='seller_id'
                                        width={80}
                                    />
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>NF *</a>
                                    <Input
                                        charLimit={255} id='nf' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, nf: justNumber(e.target.value) })}
                                        value={filterConstructor?.nf}
                                        width={80}
                                    ></Input>
                                </label>
                                <div className='mt-5'>
                                    <Button onClick={() => {
                                        setFilter(filterConstructor)
                                        setShowUniversalModal(false)
                                    }}>Filtrar</Button>
                                </div>
                            </div>
                    }
                </ScreenModal>
            }
            <Container validPeriod_lm={true}>
                <TitlePage>
                    <div className='flex flex-row gap-2 items-center justify-between  pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Vendas'}></Title>
                            <div className='flex flex-row gap-2 md:w-48 items-center'>
                                <h3 className='text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity} Vendas</h3>
                                <div className='hidden md:flex flex-row justify-start items-center gap-1 cursor-pointer' onClick={() => exportSales()}>
                                    <Tippy
                                        content={<span>Exportar lista em excel</span>}
                                        arrow={true}
                                        animation='perspective'
                                        placement='bottom'
                                        delay={80}
                                    >
                                        <p className='flex flex-row items-center justify-center gap-1 text-xs 2xl:text-sm dark:text-gray-300 ml-3'>Exportar <FaArrowDown className='dark:text-titleGrayTextDark text-sm' /></p>
                                    </Tippy>
                                </div>
                            </div>
                        </div>
                        {
                            userData.map(isAdmin => {
                                return isAdmin.typeAccess_id == 1
                                    && <div className='w-full flex justify-end items-end mr-[15px] mt-[10px]'>
                                        <Tippy
                                            content={<span>Importar Vendas</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={80}
                                        >
                                            <div onClick={() => setShowImports(true)} className="text-xl text-blue-600 cursor-pointer">
                                                <FaCloudUploadAlt />
                                            </div>
                                        </Tippy>
                                    </div>
                            })
                        }
                        {
                            (userData[0]?.permissions?.indexOf('lm_sales:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                            <>
                                <div className='hidden sm:flex flex-row gap-5 items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/salesCreate')}>
                                    <Button shadow={true} width={40} onClick={() => navigate('/salesCreate')}>+ Nova Venda</Button>
                                </div>
                                <div className='sm:hidden flex flex-col items-center justify-end' onClick={() => navigate('/salesCreate')}>
                                    <Button shadow={true} onClick={() => navigate('/salesCreate')}>Nova</Button>
                                </div>
                            </>
                        }
                    </div>
                </TitlePage>
                <Body>
                    {showImports ?
                        <>
                            {
                                loading ?
                                <DefaultLoader/>
                                :
                                <>
                                    <div className="flex flex-col justify-start items-start gap-2 w-full">
                                        <h1 className="text-md text-red-500">Atenção às regras de importação.</h1>
                                        <p className='text-sm'>
                                            <span className="font-semibold text-sm">Regras de importação:</span> Utilizar o arquivo base para importação, o arquivo poderá ser baixado no botão abaixo ou clicando <button className="underline" onClick={() => downloadCSV()}>aqui</button>
                                        </p>
                                        <p className='text-sm'>
                                            <span className="font-semibold text-sm">Campos obrigatórios:</span> "Data da Venda", "Código do Cliente", "Código do Produto", "Preço Unitário", "Quantidade", "Código (condição de pagamento)", "Total da Venda" e "Imposto Padrão".
                                        </p>
                                        <p className='text-sm'>
                                            <i>Obs: Valores monetários informar com vírgula, exemplo: 1000,90</i>
                                        </p>
                                        <Button onClick={() => downloadCSV()}>Baixar</Button>
                                    </div>
                                    <div className='w-full'>
                                        <FileDropZone setSendFile={setSendFile} />
                                    </div>
                                    <div className="flex gap-10">
                                        <Button onClick={() => setShowImports(false)} approval={false} width={20}>Voltar</Button>
                                        <Button onClick={() => { setDetectModal("1"); setShowUniversalModal(true) }}  >Importar</Button>
                                    </div>
                                </>
                            }
                        </>
                        :
                        <>
                            <div className='w-full sm:mt-0 mt-2 items-center flex gap-4'>
                                <Button onClick={() => { setDetectModal("2"); setShowUniversalModal(true) }}>
                                    <FaFilter className='text-white' />
                                    <p className='text-white'> Filtrar </p>
                                </Button>
                                {filter && JSON.stringify(filter) != JSON.stringify()
                                    ? <Tippy
                                        key={`clearFilter`}
                                        content={<span>Limpar filtro</span>}
                                        arrow={true}
                                        animation='perspective'
                                        placement='right'
                                        delay={100}>
                                        <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                            <FiSlash className='cursor-pointer' onClick={async () => {
                                                setLoading(true)
                                                setFilter()
                                                setFilterConstructor({})
                                                await new Promise(resolve => setTimeout(resolve, 500))
                                                setLoading(false)
                                            }} />
                                        </div>
                                    </Tippy>
                                    : <></>
                                }
                            </div>
                            <TablePaginationDefault
                                onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                                quantity={(e) => setQuantity(e)}
                                apiUrl={'/api/v1/lm/sales'}
                                filters={filter}
                                mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                colSpan={9}
                                collumns={[
                                    {
                                        name: 'id',
                                        type: 'number',
                                        description: 'Código'
                                    },
                                    {
                                        name: 'saleDate',
                                        type: 'string',
                                        description: 'Data de Venda',
                                        func: (item) => {
                                            if (item.saleDate) {
                                                return moment(item?.saleDate)?.utc(false).format('DD/MM/YYYY')
                                            }
                                        }
                                    },
                                    {
                                        name: 'supplierAndCustomer_id',
                                        type: 'string',
                                        description: 'Cliente',
                                        func: (item) => {
                                            if (item.supplierAndCustomer_id) {
                                                return supplierAndCustomerData.filter(id => id.id == item.supplierAndCustomer_id)[0]?.businessName
                                            }
                                        }
                                    },
                                    {
                                        name: 'paymentCondition_id',
                                        type: 'string',
                                        description: 'Condição de Pag.',
                                        func: (item) => {
                                            if (item.paymentCondition_id) {
                                                return paymentConditionData.filter(id => id.id == item.paymentCondition_id)[0]?.days + ' - Dias'
                                            }
                                        }
                                    },
                                    {
                                        name: 'seller_id',
                                        type: 'string',
                                        description: 'Vendedor',
                                        func: (item) => {
                                            if (item.seller_id) {
                                                return sellerData.filter(id => id.id == item.seller_id)[0]?.name
                                            } else {
                                                return  '-'
                                            }
                                        }
                                    },
                                    {
                                        name: 'nf',
                                        type: 'string',
                                        description: "NF"
                                    },
                                    {
                                        name: 'totalSale',
                                        type: 'string',
                                        description: 'Total',
                                        func: (item) => {
                                            if (item.totalSale) {
                                                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(item?.totalSale)
                                            }
                                        }
                                    },
                                    {
                                        name: 'created_at',
                                        type: 'date',
                                        description: 'Criado em'
                                    },
                                ]}
                            />
                        </>
                    }
                </Body>
            </Container>
        </>
    )
}