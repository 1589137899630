import React, { useState } from 'react'
import { NotificationModal } from '../modals/notification/notification.modal'

export function TitlePage({ children, setDisplayNotification }) {

    return (

        <div className='shadow-sm mb-2 bg-bgPrimaryLight border-t border-zinc-200 dark:bg-primaryDefaultDark dark:border-b dark:border-primaryBorderDark flex flex-col items-center justify-center w-full pl-8 pr-8 h-14'>
            <NotificationModal>

            </NotificationModal>
            <div className='flex flex-col w-full justify-center items-start max-w-[1950px]'>
                {children}
            </div>
        </div>
    )
}