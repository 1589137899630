import React, { useEffect, useState } from "react";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Input } from "../../../components/input/input";
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";
import { Button } from "../../../components/buttons/button.default";

//api
import { api } from "../../../services/api/api";

//context
import { useAuth } from "../../../contexts/useAuth";

//utils
import { validateFields } from "../../../utils/form.validator";
import { responseError } from "../../../utils/responsesFunctions/error.response";

//utils
import { justNumber } from '../../../utils/validators/justNumber'
import { useThemeContext } from "../../../contexts/themeContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";

export function CashFlowMobileEdit({ id, setEditMobile }) {

    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(false)
    //edit 
    const [sendDateEdit, setSendDateEdit] = useState('')
    const [dueDateEdit, setDueDateEdit] = useState('')
    const [docNumberEdit, setDocNumberEdit] = useState('')
    const [originalValueEdit, setOriginalValueEdit] = useState('')
    const [interestValueEdit, setInterestValueEdit] = useState('')
    const [fineValueEdit, setFineValueEdit] = useState('')
    const [observationEdit, setObservationEdit] = useState('')
    const [paymentDateEdit, setPaymentDateEdit] = useState('')
    const [supplierAndCustomerEdit, setSupplierAndCustomerEdit] = useState('')
    const [banksAccountsEdit, setBanksAccountsEdit] = useState('')
    const [accountPlansEdit, setAccountPlansEdit] = useState('')
    const [period, setPeriod] = useState('')

    const [accountPlansData, setAccountPlansData] = useState([])
    const [supplierAndCustomerData, setSupplierAndCustomerData] = useState([])
    const [accountsBanksList, setAccountBanksList] = useState([])

    useEffect(() => {
        async function getBanksAccounts() {
            const banksAccountsApi = await api.get(`/api/v1/fc/banksAccounts`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setAccountBanksList(banksAccountsApi?.data?.data)
        }

        async function getSupplierAndCostumer() {
            const supplierAndCustomerApi = await api.get(`/api/v1/general/supplierAndCustomer/`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setSupplierAndCustomerData(supplierAndCustomerApi?.data?.data)
        }

        async function getAccountPlans() {
            const accountPlansApi = await api.get(`/api/v1/general/accountPlans`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setAccountPlansData(accountPlansApi?.data?.data)

        }

        getAccountPlans()
        getBanksAccounts()
        getSupplierAndCostumer()

    }, [])

    useEffect(() => {
        async function getDataCashFlow() {
            const cashFlowApi = await api.get(`api/v1/fc/sends/${id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            setPaymentDateEdit(cashFlowApi?.data?.data[0]?.paymentDate?.split('T')[0])
            setSendDateEdit(cashFlowApi?.data?.data[0]?.sendDate?.split('T')[0])
            setDueDateEdit(cashFlowApi?.data?.data[0]?.dueDate?.split('T')[0])
            setDocNumberEdit(cashFlowApi?.data?.data[0]?.docNumber)
            setOriginalValueEdit(cashFlowApi?.data?.data[0]?.originalValue)
            setInterestValueEdit(cashFlowApi?.data?.data[0]?.interestValue)
            setFineValueEdit(cashFlowApi?.data?.data[0]?.fineValue)
            setObservationEdit(cashFlowApi?.data?.data[0]?.observation)
            setAccountPlansEdit(cashFlowApi?.data?.data[0]?.g_accountPlans_code)
            setSupplierAndCustomerEdit(cashFlowApi?.data?.data[0]?.g_supplierAndCustomer_id)
            setBanksAccountsEdit(cashFlowApi?.data?.data[0]?.fc_banksAccounts_id)
            setPeriod(cashFlowApi?.data?.data[0]?.g_periods_id)
        }

        getDataCashFlow()

    }, [])

    async function handleEditCashflow() {

        const requiredFields = [
            { name: 'sendDateEdit', value: sendDateEdit, required: true, type: 'string' },
            { name: 'dueDateEdit', value: dueDateEdit, required: true, type: 'string' },
            { name: 'docNumberEdit', value: docNumberEdit, required: true, type: 'string' },
            { name: 'originalValueEdit', value: originalValueEdit, required: true, type: 'string' },
            { name: 'interestValueEdit', value: interestValueEdit, required: true, type: 'string' },
            { name: 'fineValueEdit', value: fineValueEdit, required: true, type: 'string' },
            { name: 'accountPlansEdit', value: accountPlansEdit, required: true, type: 'string' },
            { name: 'supplierAndCustomerEdit', value: supplierAndCustomerEdit, required: true, type: 'string' },
        ]
        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }
        setLoading(true)
        try {
            await api.put(`api/v1/fc/sends/${id}`, {
                sendDate: sendDateEdit,
                dueDate: dueDateEdit,
                docNumber: docNumberEdit,
                originalValue: originalValueEdit,
                interestValue: interestValueEdit,
                fineValue: fineValueEdit,
                observation: observationEdit,
                paymentDate: paymentDateEdit ? paymentDateEdit : undefined,
                g_accountPlans_code: accountPlansEdit,
                g_supplierAndCustomer_id: supplierAndCustomerEdit,
                fc_banksAccounts_id: banksAccountsEdit,
                periods_id: period
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            setEditMobile(false)
            setLoading(false)

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

    }

    return (
        <>
            <Container>
                {
                    loading
                        ?
                        <DefaultLoader />
                        :
                        <>
                            <TitlePage>
                                <div className="flex flex-row justify-between items-center w-full">
                                    <Title text={'Editar Lançamento'} />
                                    <Button approval={false} onClick={() => { setEditMobile(false) }}>Cancelar</Button>
                                </div>
                            </TitlePage>
                            <Body>
                                <div className="flex flex-col justify-center items-center gap-5 p-5 max-w-[1200px] overflow-y-auto overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400">
                                    <LabelInput text={'Data de competência *'}>
                                        <Input
                                            id='sendDateEdit'
                                            type={'date'}
                                            value={sendDateEdit ? sendDateEdit : ''}
                                            onChange={(e) => setSendDateEdit(e.target.value)}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Vencimento *'}>
                                        <Input
                                            id='dueDateEdit'
                                            type={'date'}
                                            value={dueDateEdit ? dueDateEdit : ''}
                                            onChange={(e) => setDueDateEdit(e.target.value)}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Data de pagamento '}>
                                        <Input
                                            id='paymentDateEdit'
                                            type={'date'}
                                            value={paymentDateEdit ? paymentDateEdit : ''}
                                            onChange={(e) => setPaymentDateEdit(e.target.value)}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Número Documento *'}>
                                        <Input
                                            id='docNumberEdit'
                                            type={'text'}
                                            value={docNumberEdit ? docNumberEdit : ''}
                                            onChange={(e) => setDocNumberEdit(justNumber(e.target.value))}
                                            charLimit={200}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Banco *'}>
                                        <InputAutoComplete
                                            id='banksAccountsEdit'
                                            data={accountsBanksList}
                                            selectedLabel={['description']}
                                            optionList={['description']}
                                            type={'text'}
                                            preSelectedValue={accountsBanksList.filter(item => item?.id === banksAccountsEdit)[0]?.description}
                                            value={banksAccountsEdit ? accountsBanksList.filter(item => item?.id === banksAccountsEdit)[0]?.description : ''}
                                            onChange={(e) => setBanksAccountsEdit(e?.id)}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Fornecedor/Cliente *'}>
                                        <InputAutoComplete
                                            id='supplierAndCustomerEdit'
                                            type={'text'}
                                            selectedLabel={['businessName']}
                                            optionList={['businessName']}
                                            data={supplierAndCustomerData}
                                            preSelectedValue={supplierAndCustomerData.filter(item => item.id === supplierAndCustomerEdit)[0]?.businessName}
                                            value={supplierAndCustomerEdit ? supplierAndCustomerData.businessName : ''}
                                            onChange={(e) => setSupplierAndCustomerEdit(e?.id)} />
                                    </LabelInput>
                                    <LabelInput text={'Plano de contas *'}>
                                        <InputAutoComplete
                                            id='accountPlansEdit'
                                            data={accountPlansData}
                                            selectedLabel={'description'}
                                            optionList={['code', 'description']}
                                            preSelectedValue={accountPlansData.filter(item => item.code === accountPlansEdit)[0]?.description}
                                            value={accountPlansEdit ? accountPlansEdit?.description : ''}
                                            onChange={(e) => setAccountPlansEdit(e?.code)} />

                                    </LabelInput>
                                    <LabelInput text={'Valor Principal *'}>
                                        <CurrencyInput id='originalValueEdit'
                                            value={originalValueEdit ? originalValueEdit : ''}
                                            placeHolder={originalValueEdit ? "R$" + originalValueEdit : 'R$ -'} onChange={(e) => {
                                                setOriginalValueEdit(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                            }}></CurrencyInput>
                                    </LabelInput >
                                    <LabelInput text={'Juros *'}>
                                        <CurrencyInput id='interestValueEdit'
                                            value={interestValueEdit ? interestValueEdit : ''}
                                            placeHolder={interestValueEdit ? "R$" + interestValueEdit : 'R$ -'} onChange={(e) => {
                                                setInterestValueEdit(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                            }}></CurrencyInput>
                                    </LabelInput>
                                    <LabelInput text={'Multa *'}>
                                        <CurrencyInput id='fineValueEdit'
                                            value={fineValueEdit ? fineValueEdit : ''}

                                            placeHolder={fineValueEdit ? "R$" + fineValueEdit : 'R$ -'} onChange={(e) => {
                                                setFineValueEdit(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                            }}></CurrencyInput>
                                    </LabelInput>
                                    <LabelInput text={'Anotações'}>
                                        <Input type={'text'}
                                            id='observationEdit'
                                            value={observationEdit ? observationEdit : ''}
                                            onChange={(e) => setObservationEdit(e.target.value)}
                                            charLimit={200}
                                        />
                                    </LabelInput>
                                    <div className="flex items-center justify-center w-full">
                                        <Button onClick={() => handleEditCashflow()}>Editar</Button>
                                    </div>
                                </div>
                            </Body>
                        </>
                }
            </Container>
        </>
    )
}