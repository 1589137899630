import { Routes, Route, Link, Navigate } from 'react-router-dom';

export function ProtectedRoute({ user, func, children }) {
    if (
        (user[0]?.typeAccess_id !== 2 && user[0]?.typeAccess_id !== 1)
        &&
        (
            user[0]?.permissions.filter((userPermission) => {
                return func.includes(userPermission)
            }).lenght > 0
        )
    ) {
        return <Navigate to="/" replace />;
    }

    return children;
}
