// hooks 
import React, { useEffect, useState } from 'react'
// contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { ScreenSizeProvider, useScreenSizeContext } from '../../../contexts/screenSizeContext'
// components 
import { TitlePage } from '../../../components/titlePages/title.page'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
// utils
import { colors } from '../../theme/colorsSchema';
import { changeThemeColor } from '../../theme/changeColors';
import { menuItems } from '../../../components/menu/menuItems'
import { Button } from '../../../components/buttons/button.default'
//contexts
import { useAuth } from '../../../contexts/useAuth'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { api } from '../../../services/api/api'

export function ThemesDefaultCenter() {


    const { userData, setUserData } = useAuth()
    const [primaryColor, setPrimaryColor] = useState(!userData[0]?.customersData?.primaryColor ? undefined : userData[0]?.customersData?.primaryColor)
    const [secundaryColor, setSecundaryColor] = useState(!userData[0]?.customersData?.secundaryColor ? undefined : userData[0]?.customersData?.secundaryColor)
    const { darkMode,
        setDarkMode,
        changeTheme,
        primaryClientDefault,
        setPrimaryDefaultLight,
        setCompanyColor,
        secondaryClientDefault
    } = useThemeContext()
    const { screenY } = useScreenSizeContext()
    const [blockColor, setBlockColor] = useState(userData[0]?.customersData?.blockColor ? userData[0]?.customersData?.blockColor : false)

    useEffect(() => {
        console.log(primaryColor)
    }, [primaryColor])


    async function handleChangeDefaultColor() {

        if (window.confirm('Deseja aplicar esta cor como padrão para seu sistema?')) {

            try {
                const updateTheme = await api.put(`/api/v1/customers`, {
                    blockColor,
                    primaryColor,
                    secundaryColor
                },
                    {
                        headers: {
                            Authorization: `Bearer ${userData[0]?.token}`
                        }
                    })
                userData[0].customersData.blockColor = blockColor
                userData[0].customersData.primaryColor = primaryColor
                userData[0].customersData.secundaryColor = secundaryColor

                setUserData([...userData])

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }
    }

    return (

        <Container>
            <TitlePage>
                <Title text={'Temas'}></Title>
            </TitlePage>
            <Body>
                <h2 className='text-center mt-4  hidden md:flex text-base font-bold text-secondaryDefaultLight dark:text-titleGrayTextDark'>Selecione o esquema de cores <br />Padrão do sistema que será apresentado para os clientes </h2>
                <div className=' mb-20 rounded-2xl flex flex-col sm:w-[980px] 2xl:w-[1100px] boxShadow items-center justify-between gap-10 dark:border dark:border-primaryBorderDark'>
                    <div className='flex flex-col xl:flex-row gap-12 w-full items-center justify-center rounded-2xl mt-16'>
                        <div onClick={() => changeTheme(false)} className={`cursor-pointer border-2 ${darkMode ? 'border-transparent' : 'border-primaryDefaultLight'}  rounded-2xl flex flex-col boxShadow w-80 2xl:w-96 h-64 2xl:h-80 bg-bgPrimaryColor dark:bg-thirdDefaultDark dark:border dark:border-secondaryBorderDark items-center justify-center gap-4`}>
                            <dvi className='flex flex-row shadow-lg shadow-titleBlackTextLight/60 dark:shadow-titleGrayTextDark/50'>
                                <div className='flex flex-col w-4 2xl:w-8 bg-primaryDefaultLight h-36 2xl:h-48'>
                                    <div className='flex flex-col items-center gap-2 mt-2'>
                                        {menuItems.map((item) => {
                                            return <div className='flex flex-col items-center text-xs text-titleGrayTextLight'>
                                                {item.icon}
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className='flex flex-col bg-bgSecondaryLight w-72 gap-2 pl-1 pr-1'>
                                    <div className='w-full bg-bgPrimaryLight h-8 pl-1 pr-1 flex flex-row justify-between items-center'>
                                        <h2 className='text-xs text-titleBlackTextLight'>Exemplo</h2>
                                        <button className='flex flex-col items-center justify-center bg-primaryDefaultLight w-10 h-4 text-xs text-titleGrayTextLight p-px rounded-md hover:bg-secondaryDefaultLight' >Botão</button>
                                    </div>
                                    <div className='w-full bg-bgPrimaryLight h-full pl-1'>
                                        <h2 className='text-xs text-titleBlackTextDark'>Exemplo</h2>
                                    </div>
                                </div>
                            </dvi>
                            <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>Claro</a>
                        </div>
                        <div onClick={() => { changeTheme(true); }} className={`cursor-pointer border-2 ${darkMode ? 'border-primaryDefaultLight' : 'border-transparent'}  rounded-2xl flex flex-col boxShadow w-80 2xl:w-96 h-64 2xl:h-80 bg-bgPrimaryColor dark:bg-thirdDefaultDark items-center justify-center gap-4`}>
                            <div className='flex flex-row shadow-lg shadow-titleBlackTextLight/60 dark:shadow-titleGrayTextDark/50'>
                                <div className='flex flex-col w-6 2xl:w-8 bg-secondaryDefaultDark h-36 2xl:h-48 border border-primaryBorderDark'>
                                    <div className='flex flex-col items-center gap-2 mt-2'>
                                        {menuItems.map((item) => {
                                            return <div className='flex flex-col items-center text-xs text-titleGrayTextLight'>
                                                {item.icon}
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className='flex flex-col bg-primaryDefaultDark w-72 gap-2 pl-1 pr-1'>
                                    <div className='w-full bg-secondaryDefaultDark border border-primaryBorderDark h-8 pl-1 pr-1 flex flex-row justify-between items-center'>
                                        <h2 className='text-xs text-titleGrayTextDark'>Exemplo</h2>
                                        <button className='flex flex-col items-center justify-center bg-primaryDefaultLight w-10 h-4 text-xs text-titleGrayTextLight p-px rounded-md hover:bg-secondaryDefaultLight' >Botão</button>
                                    </div>
                                    <div className='w-full bg-secondaryDefaultDark border border-secondaryBorderDark h-full pl-1'>
                                        <h2 className='text-xs text-titleGrayTextDark'>Exemplo</h2>
                                    </div>
                                </div>
                            </div>
                            <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>Escuro</a>
                        </div>

                    </div>
                    <div className=' w-full flex flex-col max-w-[700px] sm:max-h-full'>
                        <div className='relative flex overflow-scroll sm:overflow-hidden flex-row flex-wrap border-t border-titleGrayTextDark dark:border-thirdDefaultDark sm:h-20 w-full gap-4 items-center justify-center'>
                            {colors.map((colorSchema) => {
                                return <Tippy content={<span>{colorSchema?.name}</span>}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='bottom'
                                    delay={50}>
                                    <button style={{ background: colorSchema.primaryColor }} onClick={() => {
                                        changeThemeColor(colorSchema.name, colorSchema.primaryColor); setPrimaryColor(colorSchema.primaryColor);
                                        setPrimaryDefaultLight(colorSchema.primaryColor);                                        
                                        setSecundaryColor(colorSchema.secundaryColor)
                                    }} className={`hover:brightness-150 transition-all duration-200 hover:scale-105  flex flex-col items-center cursor-pointer justify-center w-16 h-16 sm:w-10 sm:h-10 rounded-full`}></button>
                                </Tippy>
                            })}


                        </div>
                        <div className='w-full items-center justify-center flex flex-row gap-2 mt-4'>
                            <h2 className='text-secondaryDefaultLight mb-6 mt-6 font-bold'>Cor personalizada</h2>
                            <Tippy content={<span>{'Cor primária'}</span>}
                                arrow={true}
                                animation='shift-away'
                                placement='bottom'
                                delay={50}>
                                <input value={primaryClientDefault} onChange={(e) => { changeThemeColor('primaryClientDefault', e.target.value); setPrimaryColor(e.target.value) }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-16 h-16 sm:w-10 sm:h-10 outline-none dark:bg-secondaryDefaultDark' type='color'></input>
                            </Tippy>
                            <Tippy content={<span>{'Cor secundária'}</span>}
                                arrow={true}
                                animation='shift-away'
                                placement='bottom'
                                delay={50}>
                                <input value={secondaryClientDefault} onChange={(e) => { changeThemeColor('secondaryClientDefault', e.target.value); setSecundaryColor(e.target.value); secondaryClientDefault(e.target.value) }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-16 h-16  sm:w-10 sm:h-10 outline-none dark:bg-secondaryDefaultDark' type='color'></input>
                            </Tippy>
                        </div>
                        <div className='w-full border-t border-t-gray-300 dark:border-t-primaryBorderDark flex flex-col items-center justify-center p-5'>
                            <div className='flex flex-row gap-2 w-full items-center justify-center mb-4' >
                                <p className='text-titleBlackTextLight dark:text-titleGrayTextDark'>Travar troca de cores</p>
                                <Tippy content={<span>{'Marque para que não seja possível seus colaboradores alterarem a cor para eles'}</span>}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='bottom'
                                    delay={50}>
                                    <input checked={blockColor} onChange={(e) => setBlockColor(e.target.checked)} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-6 h-6 outline-none' type='checkbox'></input>
                                </Tippy>
                            </div>
                            <Button shadow={true} onClick={() => handleChangeDefaultColor()}>Aplicar</Button>
                        </div>
                    </div>
                </div>
            </Body>
        </Container>
    )
}