//hooks
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tippy from "@tippyjs/react";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
//utils
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//services
import { api } from "../../../services/api/api";
//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Body } from "../../../components/container/Body";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/buttons/button.default";
import { Toggle } from "../../../components/toogle/Toogle";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";

export function SellerEdit() {

    const { id } = useParams()
    const { userData } = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [activeSeller, setActiveSeller] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    const [sellerData, setSellerData] = useState('')

    useEffect(() => {
        setLoading(true)

        async function getSellerDataAPI() {
            const getSellerData = await api.get(`/api/v1/lm/seller/${id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setSellerData(getSellerData?.data?.data)
            setActiveSeller(getSellerData?.data?.data?.active)
        }

        getSellerDataAPI()
        setLoading(false)
    }, [])

    async function sellerStatus() {

        const status = !activeSeller
        setActiveSeller(status)

        try {
            await api.put(`/api/v1/lm/seller/alterStatus/${id}`, {
                status: status
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            setShowNotificationModalText(status === false ? `Vendedor desativado com sucesso.` : `Vendedor ativado com sucesso.`)

        } catch (error) {
            setLoading(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            }
        }

    }

    async function handleSubmit() {
        setLoading(true)

        const requiredFields = [
            { name: 'name', value: sellerData?.name, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.put(`/api/v1/lm/seller/${id}`, {
                name: sellerData?.name
            }, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/seller')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }
    }

    async function handleDelete() {
        setLoading(true)

        try {
            await api.delete(`/api/v1/lm/seller/${id}`, {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/seller')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setLoading(false)
    }

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={`Edição de Vendedor`} />
                </div>
            </TitlePage>
            {
                loading ?
                    <DefaultLoader />
                    :
                    <Body>
                        <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                            <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                <LabelInput 
                                    text={'Vendedor'} 
                                    required={true}
                                    showIconInfo={true}
                                    messageIconInfo={'Definir nome para o vendedor.'}
                                >
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={sellerData?.name}
                                        id='name'
                                        onChange={(e) => setSellerData((prev) => ({ ...prev, name: e.target.value }))}
                                    />
                                </LabelInput>
                            </form>
                            <div className='mt-8 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                <div className="flex justify-start gap-3">
                                    <Button
                                        onClick={() => navigate('/seller')}
                                        shadow={true}
                                        approval={false}
                                    >Cancelar
                                    </Button>
                                    {
                                        (userData[0]?.permissions?.indexOf('lm_seller:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            shadow={true}
                                            approval={false}
                                            onClick={() => handleDelete()}
                                        >Excluir
                                        </Button>
                                    }
                                </div>
                                <div className='flex gap-3'>
                                    {
                                        (userData[0]?.permissions?.indexOf('lm_seller:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <>
                                            <div className='mt-[8px]'>
                                                <Tippy content={<span>{activeSeller ? 'Desativar' : 'Ativar'}</span>}
                                                    arrow={true}
                                                    animation='perspective'
                                                    placement='top'
                                                    delay={100}>
                                                    <div>
                                                        <Toggle status={activeSeller ? true : false} onClick={(e) => sellerStatus()} />
                                                    </div>
                                                </Tippy>
                                            </div>
                                            <Button
                                                shadow={true}
                                                onClick={() => handleSubmit()}
                                            >Editar
                                            </Button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </Body>
            }
        </Container>
    )
}