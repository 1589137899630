import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
// components 
import { FaArrowDown, FaCloudUploadAlt, FaEdit, FaFilter, FaTrash } from 'react-icons/fa'
import { FiSlash } from 'react-icons/fi'
import { TablePaginationDefault } from '../../../components/table/tablePagination.default'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// utils
import FileDropZone from "../../../components/dropZone/dropZone";
import { justNumber } from '../../../utils/validators/justNumber'
import { cnpjMask } from '../../../utils/validators/cnpjMask'
import { mCPF } from '../../../utils/validators/cpfMask'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
// api
import { api } from "../../../services/api/api";
import { responseError } from '../../../utils/responsesFunctions/error.response'

export function CustomersOfCustomersTable() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { setShowUniversalModal } = useThemeContext()
    const [showNotification, setShowNotication] = useState('')
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({})
    const { screenX, screenY } = useScreenSizeContext()

    const [showImports, setShowImports] = useState(false)
    const [detectModal, setDetectModal] = useState('')
    const [sendFile, setSendFile] = useState('')

    async function handleEdit(customer) {
        navigate(`/customersOfCustomerEdit/${customer?.id}`)
    }

    async function handleImports() {

        if (!sendFile) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowUniversalModal(false)
            return setShowNotificationModalText("Selecione um arquivo para realizar a importação.")
        }

        setLoading(true)
        try {

            const formData = new FormData();
            formData.append('files', sendFile);

            await api.post(`/api/v1/fc/imports/importSupplierAndCustomer`, formData, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })
            setSendFile('')
            setShowModificationModal(true)
            setShowNotificationModalText('Importação realizada com sucesso')
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setShowImports(false)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function downloadCSV() {
        setLoading(true)

        const dataExportsModel = await api.get(`/api/v1/fc/imports/exportModel/supplierAndCustomer`, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                authorization: `Bearer ${userData[0].token}`
            }
        })

        let fileObj = URL.createObjectURL(dataExportsModel?.data)
        let myElement = document.createElement('a')
        myElement.setAttribute('href', fileObj)
        myElement.setAttribute("download", `Clientes_Fornecedores_${Number((new Date))}`)
        myElement.click()

        setLoading(false)
    }

    const options = [
        {
            name: 'Sim',
            value: true
        },
        {
            name: 'Não',
            value: false
        }
    ]

    async function exportSupplierAndCustomer(){
        try {

        const urlContructor = ['']

        Object.keys(filterConstructor).map(key => {
            if(![null, undefined, '', NaN].includes(filterConstructor[key])){
                urlContructor.push(`${key}=${filterConstructor[key]}`)
            }
        })
        
        const dataExports = await api.get('/api/v1/general/supplierAndCustomer/exports' + '?' + urlContructor.join('&'), {
            headers: {
                authorization: `Bearer ${userData[0].token}`
            },
            responseType: 'blob',
        })

        const url = window.URL.createObjectURL(dataExports?.data)
        const downloadElement = document.createElement('a')
        downloadElement.setAttribute('href', url)
        downloadElement.setAttribute('download', `Cliente_Fornce_${new Date().toISOString().split('T')[0]}`)
        downloadElement.click()

        } catch(error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }
    }

    return (
        <>
            {
                detectModal == '1' &&
                <ScreenModal title={'Importar Lançamentos'}>
                    <div className='w-full h-full flex flex-col items-center justify-center mt-5'>
                        <p className='text-base text-titleBlackTextLight'>Deseja realmente importar os lançamentos?</p>
                        <div className='flex flex-row gap-1 mt-5 pb-5'>
                            <Button onClick={() => handleImports()}>Sim </Button>
                            <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            {
                detectModal == '2' &&
                <ScreenModal title={'Filtros'}>
                    {
                        loading
                            ? <DefaultLoader />
                            : (
                                <div className='flex flex-col pt-2 pb-5 px-8 gap-2 items-center'>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                        <Input
                                            charLimit={255} id='id' type='text'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                            value={filterConstructor?.id}
                                            width={80}
                                        ></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Nome da empresa *</a>
                                        <Input
                                            charLimit={255} id='businessName' type='text'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, businessName: e.target.value })}
                                            value={filterConstructor?.businessName}
                                            width={80}
                                        ></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Nome fantasia *</a>
                                        <Input
                                            charLimit={255} id='fantasyName' type='text'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, fantasyName: e.target.value })}
                                            value={filterConstructor?.fantasyName}
                                            width={80}
                                        ></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                        <Input
                                            charLimit={255} id='email' type='text'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, email: e.target.value })}
                                            value={filterConstructor?.email}
                                            width={80}
                                        ></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>CNPJ *</a>
                                        <Input
                                            charLimit={255} id='cnpj' type='text'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, cnpj: e.target.value })}
                                            value={filterConstructor?.cnpj}
                                            width={80}
                                        ></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>CPF *</a>
                                        <Input
                                            charLimit={255} id='cpf' type='text'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, cpf: e.target.value })}
                                            value={filterConstructor?.cpf}
                                            width={80}
                                        ></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Fornecedor *</a>
                                        <InputAutoComplete
                                            data={options}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, isSupplier: e.value })}
                                            optionList={['name']}
                                            selectedLabel={'name'}
                                        />
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Cliente *</a>
                                        <InputAutoComplete
                                            data={options}
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, isCustomer: e.value })}
                                            optionList={['name']}
                                            selectedLabel={'name'}
                                        />
                                    </label>
                                    <div className='mt-5'>
                                        <Button onClick={() => {
                                            setFilter(filterConstructor)
                                            setShowUniversalModal(false)
                                        }}>Filtrar</Button>
                                    </div>
                                </div>
                            )
                    }
                </ScreenModal>
            }
            <Container validPeriod_lm={true}>
                <TitlePage displayNotification={showNotification} setDisplayNotification={e => setShowNotication(e)}>
                    <div className='flex flex-row gap-2 items-center justify-between  pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Clientes/Fornecedores'}></Title>
                            <div className='flex flex-row gap-5 md:w-44 items-center'>
                                <h3 className='text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity} Clientes</h3>
                                <div className='hidden md:flex flex-row justify-start items-center gap-1 cursor-pointer' onClick={() => exportSupplierAndCustomer()}>
                                    <Tippy
                                        content={<span>Exportar lista em excel</span>}
                                        arrow={true}
                                        animation='perspective'
                                        placement='bottom'
                                        delay={80}
                                    >
                                        <p className='flex flex-row items-center justify-center gap-1 text-xs 2xl:text-sm dark:text-gray-300'>Exportar <FaArrowDown className='dark:text-titleGrayTextDark text-xs 2xltext-sm' /></p>
                                    </Tippy>
                                </div>
                            </div>
                        </div>
                        {
                            userData.map(isAdmin => {
                                return isAdmin.typeAccess_id == 1 && 
                                    <div className='w-full flex justify-end items-end mr-[15px] mt-6 sm:mt-[10px]'>
                                        <Tippy
                                            content={<span>Importar clientes/fornecedores</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={80}
                                        >
                                            <div onClick={() => setShowImports(true)} className="text-xl text-blue-600 cursor-pointer">
                                                <FaCloudUploadAlt />
                                            </div>
                                        </Tippy>
                                    </div>
                            })
                        }
                        {
                            (userData[0]?.permissions?.indexOf('customersOfCustomer:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                            <>
                                <div className='hidden sm:flex flex-row gap-5 items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/customersOfCustomerCreate')}>
                                    <Button shadow={true} width={56} onClick={() => navigate('/customersOfCustomerCreate')}>+ Novo Cliente/Fornecedor</Button>
                                </div>
                                <div className='sm:hidden flex flex-col items-center justify-end' onClick={() => navigate('/customersOfCustomerCreate')}>
                                    <Button shadow={true} onClick={() => navigate('/customersOfCustomerCreate')}>Novo</Button>
                                </div>
                            </>
                        }
                    </div>
                </TitlePage>
                <Body>
                    {
                        showImports ?
                            <>
                                <div className="flex flex-col justify-start items-start gap-1 w-full">
                                    <h1 className="text-md text-red-500">Atenção às regras de importação.</h1>
                                    <p className='text-sm'>
                                        <span className="font-semibold">Regras de importação:</span> Utilizar o arquivo base para importação, o arquivo poderá ser baixado no botão abaixo ou clicando <button className="underline" onClick={() => downloadCSV()}>aqui</button>
                                    </p>
                                    <p className='text-sm'>
                                        <span className="font-semibold">Campos obrigatórios:</span> "Razão Social", "CNPJ (PJ)", "CPF (PF)", "É Cliente", "É Fornecedor" e "Tipo Pessoa".
                                    </p>
                                    <p className='text-sm flex flex-col gap-1 mb-1'>
                                        <i>Cliente/Fornecedor: Sim/1 ou Não/0</i>
                                        <i>Tipo Pessoa: PJ ou PF</i>
                                    </p>
                                    <Button onClick={() => downloadCSV()}>Baixar</Button>
                                </div>
                                <div className='w-full'>
                                    <FileDropZone setSendFile={setSendFile} />
                                </div>
                                <div className="flex gap-10">
                                    <Button onClick={() => setShowImports(false)} approval={false} width={20}>Voltar</Button>
                                    <Button onClick={() => { setDetectModal("1"); setShowUniversalModal(true) }}  >Importar</Button>
                                </div>
                            </>
                            :
                            <>
                                <div className='w-full sm:mt-0 mt-2 items-center flex gap-4'>
                                    <Button onClick={() => { setDetectModal("2"); setShowUniversalModal(true) }}>
                                        <FaFilter className='text-white' />
                                        <p className='text-white'> Filtrar </p>
                                    </Button>
                                    {filter && JSON.stringify(filter) != JSON.stringify({})
                                        ? <Tippy
                                            key={`clearFilter`}
                                            content={<span>Limpar filtro</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='right'
                                            delay={100}>
                                            <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                                <FiSlash className='cursor-pointer' onClick={async () => {
                                                    setLoading(true)
                                                    setFilter({})
                                                    setFilterConstructor({})
                                                    await new Promise(resolve => setTimeout(resolve, 500))
                                                    setLoading(false)
                                                }} />
                                            </div>
                                        </Tippy>
                                        : <></>
                                    }
                                </div>
                                <TablePaginationDefault
                                    onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                                    quantity={(e) => setQuantity(e)}
                                    apiUrl={'/api/v1/general/supplierAndCustomer'}
                                    filters={filter}
                                    mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                    collumns={[
                                        {
                                            name: 'id',
                                            type: 'number',
                                            description: 'Código'
                                        },
                                        {
                                            name: 'businessName',
                                            type: 'string',
                                            description: 'Razão Social'
                                        },
                                        {
                                            name: 'fantasyName',
                                            type: 'string',
                                            description: 'Nome Fantasia',
                                            func: (item) => {
                                                if (item?.fantasyName) {
                                                    return item?.fantasyName
                                                } else {
                                                    return ' - '
                                                }
                                            }
                                        },
                                        {
                                            name: 'isSupplier',
                                            type: 'boolean',
                                            description: 'Fornecedor',
                                            func: (item) => {
                                                if (item?.isSupplier) {
                                                    return 'Fornecedor'
                                                } else {
                                                    return ' - '
                                                }
                                            }
                                        },
                                        {
                                            name: 'isCustomer',
                                            type: 'boolean',
                                            description: 'Cliente',
                                            func: (item) => {
                                                if (item?.isCustomer) {
                                                    return 'Cliente'
                                                } else {
                                                    return ' - '
                                                }
                                            }
                                        },
                                        {
                                            name: 'cnpj',
                                            type: 'number',
                                            description: 'CNPJ / CPF',
                                            func: (item) => {
                                                if (item?.typePerson == 'PJ') {
                                                    return cnpjMask(item?.cnpj?.toString())
                                                } else {
                                                    return mCPF(item?.cpf?.toString())
                                                }
                                            }
                                        },
                                        {
                                            name: 'email',
                                            type: 'string',
                                            description: 'E-mail',
                                            func: (item) => {
                                                if (!item?.email) {
                                                    return ' - '
                                                } else {
                                                    return item?.email
                                                }
                                            }
                                        },
                                        {
                                            name: 'created_at',
                                            type: 'date',
                                            description: 'Criado em'
                                        }
                                    ]}
                                />
                            </>
                    }
                </Body>
            </Container>
        </>
    )
}
