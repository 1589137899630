import {
    FaCalendarAlt,
    FaChartPie,
    FaCog,
    FaHome,
    FaUsers
} from 'react-icons/fa'

export const menuItems = [
    {
        "icon": <FaHome />,
        "name": "Home",
        "type": "normal",
        "path": "/",
        "permissions": [1, 2, 3]
    },
    {
        icon: <FaUsers />,
        name: 'Clientes/Fornecedores',
        type: 'normal',
        path: '/customersOfCustomer',
        permissions: ['customersOfCustomer:create', 'customersOfCustomer:read', 1],
    },   
    {
        "icon": <FaCog />,
        "name": "Ajustes",
        "type": "normal",
        "path": "/configuration",
        "permissions": [1, 2, 3]
    },
]