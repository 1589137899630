import moment from "moment"

export function formattTimeToShow(apiTime) {
    try {
        if(apiTime.indexOf('T') == -1){
            return moment(apiTime).format('DD/MM/YYYY')
        }
        let zPos = apiTime.indexOf('Z')
        let offset = moment().utcOffset()
        let time = apiTime
        time = apiTime.slice(0, zPos)
        time = time.replace('T', ' ')
        let localTime = moment.utc(time).utcOffset(offset).format("L LT");
        return localTime
    } catch (error) {
        return apiTime
    }
}