import React, { useContext, createContext, useState, useEffect } from 'react'

//services
import { getPermissionGroupEspecific, isAuth } from '../services/api/callAPIsFunctions/defaultCalls.api'

const AuthContext = createContext()

export function AuthContextProvider({ children }) {


    const [signed, setSigned] = useState(false)
    const [userData, setUserData] = useState([])
    const [selectedCompany, setSelectedCompany] = useState('')
    const [selectedModule, setSelectedModule] = useState('')
    const [selectedPeriod, setSelectedPeriod] = useState('')
    const [requestAuth, setRequestAuth] = useState(false)
    const userToken = localStorage.getItem('@auth:token')
    const [loadingAuth, setLoadingAuth] = useState(false)

    //funcao para verificar se esta logado ajustar para o sistema corrente
    async function verifytoken(userToken) {

        try {
            setLoadingAuth(true)
            const isAuthUser = await isAuth(userToken)

            if (isAuthUser.statusCode === 200) {
                const apiUserData = []
                apiUserData.push(isAuthUser.data.userData)
                apiUserData[0].token = isAuthUser.data.token
                setUserData(apiUserData)
                if (isAuthUser?.data?.userData?.period){
                    const period = isAuthUser?.data?.userData?.period
                    period.valueToShowSelected = (period?.month.toString().length == 1 ? '0' + period?.month : period?.month) + '/' + period?.year,
                    period.value = (period?.month.toString().length == 1 ? '0' + period?.month : period?.month) + '/' + period?.year,
                    period.success = period?.isOpen
                }
                setSelectedPeriod(isAuthUser?.data?.userData?.period ? isAuthUser?.data?.userData?.period : '')
                setSigned(true)
                setLoadingAuth(false)
            }
                        
            if (isAuthUser.statusCode === 401) {
                console.log('deslogando')
                localStorage.removeItem('@auth:token')
                localStorage.removeItem('@module')
                localStorage.removeItem('@company')
                localStorage.removeItem('companyColor')
                setLoadingAuth(false)
                setSigned(false)
                return
            }
        } catch (error) {
            alert('Erro no login, contate o administrador')
            localStorage.removeItem('@auth:token')
            localStorage.removeItem('@module')
            localStorage.removeItem('@company')
            localStorage.removeItem('companyColor')
            setLoadingAuth(false)
            setSigned(false)
            console.log(error)
        }
    }

    // Função para verificar company
    async function verifyCompany() {
        const company = localStorage.getItem('@company')
        if (company?.length > 0) {
            setSelectedCompany(JSON.parse(company))
        }

    }

    async function verifyModule() {
        const module = localStorage.getItem('@module')
        setSelectedModule(module)
    }

    //funcao para pegar os dados de autenticacao a cada 5 minutos
    useEffect(() => {

        async function sleep(time) {
            await new Promise(resolve => setTimeout(resolve, time))
            setRequestAuth(!requestAuth)
        }
        verifytoken(userToken)
        verifyCompany()
        verifyModule()
        sleep(500000)

    }, [requestAuth])

    return (
        <AuthContext.Provider value={{
            signed,
            setSigned,
            userData,
            setUserData,
            selectedCompany,
            setSelectedCompany,
            verifytoken,
            selectedModule,
            setSelectedModule,
            selectedPeriod,
            setSelectedPeriod,
            loadingAuth, setLoadingAuth
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {

    return useContext(AuthContext)
}