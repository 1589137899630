export function validatorAccounts(array) {

    const arrayKeys = Object.keys(array)

    const errors = arrayKeys.filter((key) => {

        switch (array[key].account) {
            case null:
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            case '':
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            case undefined:
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            default:
                break;
        }

        switch (array[key].agency) {
            case null:
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            case '':
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            case undefined:
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            default:
                break;
        }

        switch (array[key].description) {
            case null:
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            case '':
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            case undefined:
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            default:
                break;
        }

        switch (array[key].balance) {
            case null:
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            case '':
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            case undefined:
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            default:
                break;
        }

        switch (array[key].precedenceDate) {
            case null:
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            case '':
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            case undefined:
                document.getElementById(`${key} - account`).style.border = 'solid red 1px'
                document.getElementById(`${key} - agency`).style.border = 'solid red 1px'
                document.getElementById(`${key} - description`).style.border = 'solid red 1px'
                document.getElementById(`${key} - balance`).style.border = 'solid red 1px'
                document.getElementById(`${key} - precedenceDate`).style.border = 'solid red 1px'
                return key

            default:
                break;
        }

    })

    return errors

}