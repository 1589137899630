//hooks
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
//services
import { api } from "../../../services/api/api";
//utils
import { validateFields } from "../../../utils/form.validator";
import { responseError } from "../../../utils/responsesFunctions/error.response";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/buttons/button.default";
import { ScreenModal } from "../../../components/modals/notification/screenModal";

export function ProductsGroupEdit(){

    const { userData } = useAuth()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [selectedDescription, setSelectedDescription] = useState('')

    useEffect(() => {
        async function getData(){
            setLoading(true)
            try {
                const getDataApi = await api.get(`api/v1/lm/productsGroup/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                setSelectedDescription(getDataApi?.data?.data[0]?.description)
                setLoading(false)

            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
                }
            }
        }

        getData()
    }, [])

    async function handleSubmit() {

        setLoading(true)

        const requiredFields = [
            { name: 'description', value: selectedDescription, required: true, type: 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.put(`api/v1/lm/productsGroup/${id}`, {
                description: selectedDescription,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Grupo editado com sucesso')
            setShowNotificationModalSuccess(true)
            navigate('/productsGroup')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    async function handleDelete(){
        setLoading(true)
        try {
            await api.delete(`/api/v1/lm/productsGroup/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso')
            setShowNotificationModalSuccess(true)
            navigate('/productsGroup')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Exclusão de Grupo'} width={400}>
                <div className='w-full h-32 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir o grupo?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div>
                        <Title text={'Edição de Grupo de Produtos'}></Title>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <DefaultLoader/>
                        :
                        <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                            <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                <LabelInput text={'Descrição do Grupo'} required={true}>
                                    <Input
                                        type={'text'}
                                        width={80}
                                        value={selectedDescription ? selectedDescription : ''}
                                        id='description'
                                        onChange={(e) => setSelectedDescription(e.target.value)}
                                    />
                                </LabelInput>
                            </form>
                            <div className='mt-8 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                <Button
                                    onClick={() => navigate('/productsGroup')}
                                    shadow={true}
                                    approval={false}
                                >Cancelar
                                </Button>
                                <div className='flex gap-2'>
                                    {
                                        (userData[0]?.permissions?.indexOf('lm_productsGroup:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            shadow={true}
                                            approval={false}
                                            onClick={() => setShowUniversalModal(true)}
                                        >Excluir
                                        </Button>
                                    }
                                    {
                                        (userData[0]?.permissions?.indexOf('lm_productsGroup:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            shadow={true}
                                            onClick={() => handleSubmit()}
                                        >Editar grupo
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </Body>
            </Container>
        </>
    )
}