// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/useAuth'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
// components 
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { validateFields } from '../../../utils/form.validator'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
// utils 
import { validateEmail } from '../../../utils/validators/email.validator'
import { cnpjMask } from '../../../utils/validators/cnpjMask'
import { justNumber } from '../../../utils/validators/justNumber'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//services
import { api } from '../../../services/api/api'

export function ClientsCreate({hasCustomer, notHasCustomers}) {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    
    // Fields
    const [companiesList, setCompaniesList] = useState([])
    const [businessName, setBusinessName] = useState('')
    const [fantasyName, setFantasyName] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [emailMaster, setEmailMaster] = useState('')
    const [phone, setPhone ] = useState('')
    const [selectedModule, setSelectedModule] = useState('')
    
    useEffect(() => {

        async function getCustomers(){
            try {
                
                const getCustomersAPI = await api.get('/api/v1/customers', {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })

                setCompaniesList(getCustomersAPI?.data?.data.filter(customer => {
                    if (customer?.isMaster){
                        return customer
                    }
                }))
                
                setLoading(false)

            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getCustomers()

    }, [])

    async function handleSubmit(){
        
        setLoading(true)

        const requiredFields = [
            { name: 'businessName', value: businessName, required: true, type: 'string' },
            { name: 'module', value: selectedModule, required: true, type: 'object' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (emailMaster){
            if (!isValidEmail) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText('Email inválido')
                return setLoading(false)
            }
        }

        try {
            
            await api.post('api/v1/customers', {
                businessName: businessName,
                fantasyName: fantasyName ? fantasyName : undefined,
                cnpj: cnpj ? cnpj?.replace('-', '')?.replace('/', '')?.replaceAll('.', '') : undefined,
                email: emailMaster ? emailMaster : undefined,
                modules: selectedModule && selectedModule?.id !== 9999 ? [selectedModule?.id] : selectedModule?.id === 9999 ? ['fc', 'lm'] : undefined,
                phone: phone ? phone : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            if (hasCustomer){
                notHasCustomers(false)
                return navigate('/companiesCreate')
            }
            navigate('/clients')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    const modulesList = [
        {
            id: 9999,
            description: 'Ambos'
        },
        {
            id: 'fc',
            description: 'Fluxo de caixa'
        },
        {
            id: 'lm',
            description: 'Margens de contribuição'
        }
    ]

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Cadastro de Cliente'} />
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                    <DefaultLoader/>
                :
                    <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                        <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                            <LabelInput text={'Razão social *'}>
                                <Input 
                                    type={'text'} 
                                    width={80}
                                    autoFocus={true} 
                                    value={businessName} 
                                    id='businessName' 
                                    onChange={(e) => setBusinessName(e.target.value)} 
                                    charLimit={200} 
                                />
                            </LabelInput>
                            <LabelInput text={'Nome fantasia'}>
                                <Input 
                                    type={'text'} 
                                    width={80}
                                    value={fantasyName} 
                                    id='name' 
                                    onChange={(e) => setFantasyName(e.target.value)} 
                                    charLimit={200} 
                                />
                            </LabelInput>
                            <LabelInput text={'E-mail'} valid={!isValidEmail ? false : true}>
                                <Input type={'text'} width={80} value={emailMaster} id='email' onChange={(e) => {
                                    const isValid = validateEmail(e.target.value)
                                    if (isValid === true) {
                                        setIsValidEmail(true)
                                    } else {
                                        setIsValidEmail(false)
                                    }
                                    setEmailMaster(e.target.value)
                                }} charLimit={255} />
                            </LabelInput>
                            <LabelInput text={'CNPJ'}>
                                <Input type={'text'} 
                                    value={cnpjMask(cnpj)} 
                                    width={80}
                                    id='cnpj' onChange={(e) => setCnpj(cnpjMask(e.target.value))} 
                                    charLimit={18} 
                                />
                            </LabelInput>                            
                            <LabelInput 
                                text={'Módulo *'}
                                showIconInfo={true}
                                messageIconInfo={'Definir qual módulo o cliente vai poder acessar.'}
                            >
                                <InputAutoComplete
                                    value={selectedModule ? modulesList.filter(e => (e?.id == selectedModule?.id))[0]?.description : ''}
                                    preSelectedValue={selectedModule?.description ? selectedModule?.description : ''}
                                    selectedLabel={'description'} id='module'
                                    placeHolder={' - '}
                                    data={modulesList} optionList={['description']}
                                    onChange={e => {
                                        {
                                            setSelectedModule(e)
                                        }
                                    }}>
                                </InputAutoComplete>
                            </LabelInput>
                            <LabelInput text={'Telefone com DDD'}>
                                <Input charLimit={12} type={'text'} width={80} onChange={(e) => setPhone(justNumber(e.target.value))} value={phone}></Input>
                            </LabelInput>
                        </form>
                        <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                            {
                                !hasCustomer &&
                                <Button
                                    onClick={() => navigate('/clients')}
                                    shadow={true}
                                    approval={false}
                                >Cancelar
                                </Button>
                            }
                            <Button
                                shadow={true}
                                onClick={() => handleSubmit()}
                            >+ Novo cliente
                            </Button>
                        </div>
                    </div>
                }            
            </Body>
        </Container>
    )
}