// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/useAuth'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
// components 
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { validateFields } from '../../../utils/form.validator'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Body } from '../../../components/container/Body'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import { LabelInput } from '../../../components/label/label.input'
// utils 
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { justNumber } from '../../../utils/validators/justNumber'
import { validatorAccounts } from '../../../utils/validators/validatorAccounts'
//services
import { api } from '../../../services/api/api'
import { FiMinus, FiPlus } from 'react-icons/fi'
import { v4 as uuid } from 'uuid'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';

export function BanksCreate() {

    const { screenX } = useScreenSizeContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [selectedBank, setSelectedBank] = useState('')
    const [banksList, setBanksList] = useState([])
    const [accountsList, setAccountsList] = useState([])
    const [accountsIdList, setAccountsIdList] = useState([])

    // useEffect(() => {

    //     async function getBanks() {
    //         try {

    //             const banksListAPI = await fetch('https://brasilapi.com.br/api/banks/v1', {
    //                 method: "GET"
    //             })
    //             const banksListData = await banksListAPI.json()

    //             setBanksList(banksListData)
    //             setLoading(false)

    //         } catch (error) {
    //             if (responseError(error).length > 0) {
    //                 setLoading(false)
    //                 return setShowNotificationModalText(responseError(error))
    //             } else {
    //                 setLoading(false)
    //                 return setShowNotificationModalText('Erro inesperado')
    //             }
    //         }
    //     }

    //     getBanks()

    // }, [])

    async function handleSubmit() {
        setLoading(true)

        const fields =
            [
                { "name": 'banks', "value": selectedBank, "required": true, "type": 'string' }
            ]

        if (Object.keys(accountsList).length > 0) {
            if (validatorAccounts(accountsList).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha os campos em vermelho')

            }
        }

        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {

            await api.post('api/v1/fc/banks', {
                name: selectedBank,
                accounts: Object.keys(accountsList).length > 0 ? Object.keys(accountsList).map(acc => accountsList[acc]) : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Banco cadastrado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/banks')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setLoading(false)
    }

    function addAccount() {

        const id = uuid()
        let add

        add = Object.assign(accountsList, {
            [id]: {
                account: null,
                agency: null,
                description: null,
                balance: null,
                precedenceDate: null
            }
        })

        setAccountsList(add)
        setAccountsIdList([...accountsIdList, id])
    }

    async function removeAccount(id) {
        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 50))

        const replicaAccounts = structuredClone(accountsList)
        const replicaAccountsList = structuredClone(accountsIdList)

        const position = accountsIdList.indexOf(id)
        replicaAccountsList.splice(position, 1)

        delete replicaAccounts[id]

        setAccountsList(replicaAccounts)
        setAccountsIdList([...replicaAccountsList])
        setLoading(false)
    }

    return (
        <Container>
            <TitlePage>
                <Title text={`Novo banco`} />
            </TitlePage>
            <Body>
                {
                    loading ?
                        <div className='flex flex-col items-center justify-center w-full h-full'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='flex flex-col items-center lg:items-start justify-start w-full'>
                                <form className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                    <LabelInput 
                                        text={'Banco'}
                                        required={true}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir banco para as contas. Os valores de saldo só irão contar com base nas contas cadastradas. Banco apenas para controle.'}
                                    >
                                        <Input
                                            value={selectedBank ? selectedBank : ''}
                                            onChange={e => setSelectedBank(e?.target?.value)}
                                            id='banks'
                                            width={96}
                                        />
                                    </LabelInput>
                                </form>
                            </div>
                            {
                                accountsIdList.length > 0 ? (
                                    <>
                                        {
                                            accountsIdList.map((item) => {
                                                return (

                                                    <div className='w-full mt-5'>

                                                        <div className='hidden lg:flex flex-col lg:flex-row gap-2 rounded-t-2xl items-center justify-around pt-1 lg:w-[55rem] 2xl:w-[71rem] h-8 dark:shadow-secondaryDefaultDark bg-primaryDefaultLight'>
                                                            <label className='text-white'>Conta</label>
                                                            <label className='text-white'>Agência</label>
                                                            <label className='text-white'>Descrição</label>
                                                            <label className='text-white'>Saldo</label>
                                                            <label className='text-white'>Data do saldo</label>
                                                        </div>
                                                        <div className='lg:pl-10 mb-10 flex flex-col lg:flex-row rounded-b-2xl gap-2 items-center justify-start lg:w-[55rem] 2xl:w-[71rem] dark:shadow-secondaryDefaultDark dark:bg-primaryDefaultDark lg:bg-white lg:h-20 lg:shadow-xl'>
                                                            <LabelInput text={'Nº da conta'}>
                                                                <Input
                                                                    value={accountsList[item]?.account}
                                                                    id={`${item} - account`}
                                                                    onChange={(e) => {
                                                                        accountsList[item].account = justNumber(e.target.value)
                                                                        setAccountsList(accountsList)
                                                                        setAccountsIdList([...accountsIdList])
                                                                    }} charLimit={100} width={screenX >= 1024 && screenX < 1536 ? '150px' : '210px'}
                                                                />
                                                            </LabelInput>
                                                            <LabelInput text={'Agência'}>
                                                                <Input
                                                                    value={accountsList[item]?.agency}
                                                                    id={`${item} - agency`}
                                                                    onChange={(e) => {
                                                                        accountsList[item].agency = justNumber(e.target.value)
                                                                        setAccountsList(accountsList)
                                                                        setAccountsIdList([...accountsIdList])
                                                                    }} charLimit={100} width={screenX >= 1024 && screenX < 1536 ? '155px' : '210px'}
                                                                />
                                                            </LabelInput>
                                                            <LabelInput text={'Descrição'}>
                                                                <Input
                                                                    id={`${item} - description`}
                                                                    value={accountsList[item]?.description}
                                                                    onChange={(e) => {
                                                                        accountsList[item].description = e?.target.value
                                                                        setAccountsList(accountsList)
                                                                        setAccountsIdList([...accountsIdList])
                                                                    }} charLimit={255} width={screenX >= 1024 && screenX < 1536 ? '155px' : '210px'}
                                                                />
                                                            </LabelInput>
                                                            <LabelInput 
                                                                text={'Saldo'}
                                                                showIconInfo={true}
                                                                messageIconInfo={'Definir saldo inicial/existente da conta.'}
                                                            >
                                                                <CurrencyInput className='w-[210px] lg:w-[155px] 2xl:w-[210px] px-1 border border-gray-400 border-opacity-75 
                                                                     rounded-sm outline-none transition-all duration-200
                                                                     dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark 
                                                                     dark:border-secondaryBorderDark focus:shadow-borderShadow h-8'
                                                                    id={`${item} - balance`} placeHolder='R$ 0,00' onChange={(e) => {
                                                                        accountsList[item].balance = parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.'))
                                                                        setAccountsList(accountsList)
                                                                        setAccountsIdList([...accountsIdList])
                                                                    }}></CurrencyInput>
                                                            </LabelInput >

                                                            <LabelInput 
                                                                text={'Data do saldo'}
                                                                showIconInfo={true}
                                                                messageIconInfo={'Definir data que irá contar o saldo para o fluxo de caixa.'}
                                                            >
                                                                <Input
                                                                    id={`${item} - precedenceDate`}
                                                                    value={accountsList[item]?.precedenceDate}
                                                                    type={'date'}
                                                                    onChange={(e) => {
                                                                        accountsList[item].precedenceDate = e?.target.value
                                                                        setAccountsList(accountsList)
                                                                        setAccountsIdList([...accountsIdList])
                                                                    }} charLimit={255} width={screenX >= 1024 && screenX < 1536 ? '155px' : '210px'}
                                                                />
                                                            </LabelInput>
                                                            <div className='flex flex-row gap-3 mt-4 lg:translate-x-16 lg:-translate-y-2'>
                                                                <Tippy content={<span>Adicionar</span>}
                                                                    arrow={true}
                                                                    animation='perspective'
                                                                    placement='top'
                                                                    delay={100}>
                                                                    <div>
                                                                        <FiPlus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-lg 2xl:text-xl cursor-pointer' onClick={() => addAccount()} />
                                                                    </div>
                                                                </Tippy>
                                                                <Tippy content={<span>Remover</span>}
                                                                    arrow={true}
                                                                    animation='perspective'
                                                                    placement='top'
                                                                    delay={100}>
                                                                    <div>
                                                                        <FiMinus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-lg 2xl:text-xl cursor-pointer' approval={true} shadow={true} onClick={() => removeAccount(item)} />
                                                                    </div>
                                                                </Tippy>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </>
                                ) : (
                                    <div className='flex flex-col justify-start items-center w-full gap-1 mt-8'>
                                        <div className='flex flex-col text-white gap-1 items-center  mt-8 left-28'>
                                            <LabelInput>
                                                <Title text={'Adicionar Conta'} />
                                            </LabelInput>
                                            <FiPlus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-lg 2xl:text-xl cursor-pointer' onClick={() => addAccount()} />
                                        </div>
                                    </div>
                                )

                            }
                            <div className='mt-5 pb-5 lg:pb-0 flex justify-center flex-row gap-6 lg:gap-1 w-full items-center lg:justify-start'>
                                <Button
                                    approval={false}
                                    shadow={true}
                                    onClick={() => { navigate('/banks'); setShowModificationModal(false) }}
                                >Cancelar
                                </Button>
                                <Button
                                    approval={true}
                                    shadow={true}
                                    onClick={() => handleSubmit()}
                                >Cadastrar
                                </Button>
                            </div>
                        </>
                }
            </Body>
        </Container>
    )
}