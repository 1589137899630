import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { formatRulesList } from "../../utils/rulesList";
import { monthsList } from "./monthsList";

export function TableManager({data}) {

    const [tableHead, setTableHead] = useState([])
    const [newData, setNewData] = useState([])

    useEffect(() => {
        async function getData(){
            const arrayMonthsData = []
            const arrayMonthsDataWithValue = []

            data?.map(item => {
                item?.values?.map(value => {
                    if (arrayMonthsData.includes(`${monthsList.filter(item => item?.id == value?.month)[0]?.name}/${value?.year}`)){
                        return
                    }
                    arrayMonthsData.push(`${monthsList.filter(item => item?.id == value?.month)[0]?.name}/${value?.year}`, 'A.V.', 'A.H.')
                    arrayMonthsDataWithValue.push({...value, value: 0, av: '0,0', ah: '0,0'})
                })
            })
            arrayMonthsData.push('Acumulado', 'A.V.')

            // Formatando com base no arquivo "formatRulesList"
            const formatValues = formatRulesList?.map((filter, indexManager) => {
                const valuesManager = data?.filter(item => item?.description == filter?.description)[0]
                if (!valuesManager){
                    return {
                        ...filter,
                        values: arrayMonthsDataWithValue
                    }
                } else {
                    return {
                        ...valuesManager,
                        id: indexManager + 1
                    }
                }
            })

            // Somar os items: "R.B. - RECEITA BRUTA(1)","IMPOSTOS (+IR+CS)(2)" e "ESTORNOS(3)" para "R.L. - RECEITA LÍQUIDA(4)"
            let arrayRLValues = []
            const newFormatValuesRL = formatValues?.map(manager => {
                manager?.values?.map(value => {
                    if ([1,2,3]?.includes(manager?.id)){
                        arrayRLValues.push(value)
                    }
                })

                if (manager?.id == 4){
                    return {
                        ...manager,
                        values: reduceAndJoinValuesByMonthAndYear(arrayRLValues)
                    }
                } else {
                    return manager
                }

            })

            // Somar os items: "R.L. - RECEITA LÍQUIDA(4)","C.V. - CUSTO VARIÁVEL(5)" e "D.V. - DESPESA VARIÁVEL(6)" para "M.C. - MARGEM DE CONTRIBUIÇÃO(7)"
            let arrayMCValues = []
            const newFormatValuesMC =  newFormatValuesRL?.map(manager => {
                manager?.values?.map(value => {
                    if ([4,5,6]?.includes(manager?.id)){
                        arrayMCValues.push(value)
                    }
                })

                if (manager?.id == 7){
                    return {
                        ...manager,
                        values: reduceAndJoinValuesByMonthAndYear(arrayMCValues)
                    }
                } else {
                    return manager
                }
            })

            // Somar os items: "M.C. - MARGEM DE CONTRIBUIÇÃO(7)","G.F. - GASTO FIXO(8)" e "D.F. - DESPESA FINANC. OPERACIONAL(9)" para "RETORNO(10)"
            let arrayReturnValues = []
            const newFormatValuesReturn = newFormatValuesMC?.map(manager => {
                manager?.values?.map(value => {
                    if ([7,8,9]?.includes(manager?.id)){
                        arrayReturnValues.push(value)
                    }
                })

                if (manager?.id == 10){
                    return {
                        ...manager,
                        values: reduceAndJoinValuesByMonthAndYear(arrayReturnValues)
                    }
                } else {
                    return manager
                }

            })

            let arraySecondMC = []
            let arraySecondGF = []
            let arraySecondDF = []
            let arraySecondReturn = []
            let arrayPE = []

            let arrayImDivisionRB = []
            let arrayEstorDivisionRB = []
            let arrayRLDivisionRB = []
            let arrayCVDivisionRL = []
            let arrayDVDivisionRL = []
            let arrayMCDivisionRL = []
            let arrayGFDivisionRL = []
            let arrayDFDivisionRL = []
            let arrayReturnDivisionRL = []
            let arrayParceDivisionRL = []
            let arrayEmpreDivisionRL = []

            // Arrays para o acumulado de todos os meses
            // Seguindo os itens do arquivo formatRulesList - sequência
            let accumulatedValuesRB = 0
            let accumulatedValuesIm = 0
            let accumulatedValuesEst = 0
            let accumulatedValuesRL = 0
            let accumulatedValuesCV = 0
            let accumulatedValuesDV = 0
            let accumulatedValuesMC = 0
            let accumulatedValuesGF = 0
            let accumulatedValuesDF = 0
            let accumulatedValuesFirstReturn = 0
            let accumulatedValuesSecondMC = 0
            let accumulatedValuesSecondGF = 0
            let accumulatedValuesSecondDF = 0
            let accumulatedValuesSecondReturn = 0
            let accumulatedValuesPar = 0
            let accumulatedValuesEmp = 0
            let accumulatedValuesPE = 0

            const newSecondForms = newFormatValuesReturn.map(manager => {
                manager?.values?.map((value, indexMonth) => {
                    if ([1,2,3,5,6,8,9,15,16]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayPE.push(value)
                    }
                    if ([1,2]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayImDivisionRB.push(value)
                    }
                    if ([1,3]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayEstorDivisionRB.push(value)
                    }
                    if ([1,4]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayRLDivisionRB.push(value)
                    }
                    if ([4,5]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayCVDivisionRL.push(value)
                    }
                    if ([4,6]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayDVDivisionRL.push(value)
                    }
                    if ([4,7]?.includes(manager?.id)){
                        value.id = manager.id
                        arraySecondMC.push(value)
                        arrayMCDivisionRL.push(value)
                    } 
                    if ([4,8]?.includes(manager?.id)){
                        value.id = manager.id
                        arraySecondGF.push(value)
                        arrayGFDivisionRL.push(value)
                    }
                    if ([4,9]?.includes(manager?.id)){
                        value.id = manager.id
                        arraySecondDF.push(value)
                        arrayDFDivisionRL.push(value)
                    }
                    if ([4,10]?.includes(manager?.id)){
                        value.id = manager.id
                        arraySecondReturn.push(value)
                        arrayReturnDivisionRL.push(value)
                    }
                    if ([4,15]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayParceDivisionRL.push(value)
                    }
                    if ([4,16]?.includes(manager?.id)){
                        value.id = manager.id
                        arrayEmpreDivisionRL.push(value)
                    }
                })

                if (manager?.id == 2){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arrayImDivisionRB, '1', '2')

                    let arrayAH = []
                    let arrayDivisionValues = []  
                    
                    valuesReturn.map((month, index2) => {
                        const id1 = month?.value[1]
                        const id2 = month?.value[2]
                        
                        if (id1 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: '0,0'})
                        } else {
                            const division = ((id2 / id1) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})

                            const divisionAH = ((valuesReturn[index2]?.value[2]/valuesReturn[index2 - 1]?.value[2] - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: !valuesReturn[index2 - 1]?.value[2] ? '0,0' : replaceDivisionAH})
                        }
                    })
                    accumulatedValuesIm = manager?.values?.reduce((accumulator, currentObject) => {return accumulator + currentObject.value}, 0)

                    let avAccumulatedValuesIm
                    if (accumulatedValuesIm != 0){
                        avAccumulatedValuesIm = (accumulatedValuesIm/accumulatedValuesRB * 100)?.toFixed(1)
                    } else {
                        avAccumulatedValuesIm = '0,0'
                    }
                    return {
                        ...manager,
                        av: arrayDivisionValues, 
                        ah: arrayAH,
                        accumulatedValuesIm,
                        avAccumulatedValuesIm
                    }
                } else if (manager?.id == 3){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arrayEstorDivisionRB, '1', '3')

                    let arrayDivisionValues = []
                    let arrayAH = []           
                    
                    valuesReturn.map((month, index2) => {
                        const id1 = month?.value[1]
                        const id3 = month?.value[3]
                        
                        if (id1 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: '0,0'})
                        } else {
                            const division = ((id3 / id1) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})

                            const divisionAH = ((valuesReturn[index2]?.value[3]/valuesReturn[index2 - 1]?.value[3] - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: !valuesReturn[index2 - 1]?.value[3] ? '0,0' : replaceDivisionAH})
                        }
                    })
                    accumulatedValuesEst = manager?.values?.reduce((accumulator, currentObject) => {return accumulator + currentObject.value}, 0)
                    let avAccumulatedValuesEst
                    if (accumulatedValuesEst != 0){
                        avAccumulatedValuesEst = ((accumulatedValuesEst/accumulatedValuesRB) * 100)?.toFixed(1)
                    } else {
                        avAccumulatedValuesEst = '0,0'
                    }
                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        accumulatedValuesEst,
                        avAccumulatedValuesEst
                    }
                } else if (manager?.id == 4){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arrayRLDivisionRB, '1', '4')

                    let arrayDivisionValues = []  
                    let arrayAH = [] 

                    valuesReturn.map((month, index2) => {
                        const id1 = month?.value[1]
                        const id4 = month?.value[4]
                        
                        if (id1 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: '0,0'})
                        } else {
                            const division = ((id4 / id1) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})

                            const divisionAH = ((valuesReturn[index2]?.value[4]/valuesReturn[index2 - 1]?.value[4] - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: !valuesReturn[index2 - 1]?.value[4] ? '0,0' : replaceDivisionAH})
                        }
                    })
                    accumulatedValuesRL = Number(Number(accumulatedValuesRB) + Number(accumulatedValuesIm) + Number(accumulatedValuesEst)).toFixed(2)
                    let avAccumulatedValuesRL
                    if (accumulatedValuesRL != 0){
                        avAccumulatedValuesRL = ((accumulatedValuesRL/accumulatedValuesRB) * 100)?.toFixed(1)
                    } else {
                        avAccumulatedValuesRL = '0,0'
                    }
                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        accumulatedValuesRL,
                        avAccumulatedValuesRL
                    }
                } else if (manager?.id == 5){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arrayCVDivisionRL, '4', '5')

                    let arrayDivisionValues = []     
                    let arrayAH = []  
                                        
                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id5 = month?.value[5]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: '0,0'})
                        } else {
                            const division = ((id5 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})

                            const divisionAH = ((valuesReturn[index2]?.value[5]/valuesReturn[index2 - 1]?.value[5] - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: !valuesReturn[index2 - 1]?.value[5] ? '0,0' : replaceDivisionAH})
                        }
                    })
                    accumulatedValuesCV = manager?.values?.reduce((accumulator, currentObject) => {return accumulator + currentObject.value}, 0)
                    let avAccumulatedValuesCV
                    if (accumulatedValuesCV != 0){
                        avAccumulatedValuesCV = ((accumulatedValuesCV/accumulatedValuesRL) * 100)?.toFixed(1)
                    } else {
                        avAccumulatedValuesCV = '0,0'
                    }
                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        accumulatedValuesCV,
                        avAccumulatedValuesCV
                    }
                } else if (manager?.id == 6){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arrayDVDivisionRL, '4', '6')

                    let arrayDivisionValues = []    
                    let arrayAH = []

                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id6 = month?.value[6]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: '0,0'})
                        } else {
                            const division = ((id6 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})

                            const divisionAH = ((valuesReturn[index2]?.value[6]/valuesReturn[index2 - 1]?.value[6] - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: !valuesReturn[index2 - 1]?.value[6] ? '0,0' : replaceDivisionAH})
                        }
                    })
                    accumulatedValuesDV = manager?.values?.reduce((accumulator, currentObject) => {return accumulator + currentObject.value}, 0)
                    let avAccumulatedValuesDV
                    if (accumulatedValuesDV != 0){
                        avAccumulatedValuesDV = ((accumulatedValuesDV/accumulatedValuesRL) * 100)?.toFixed(1)
                    } else {
                        avAccumulatedValuesDV = '0,0'
                    }
                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        accumulatedValuesDV,
                        avAccumulatedValuesDV
                    }
                } else if (manager?.id == 7){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arrayMCDivisionRL, '4', '7')

                    let arrayDivisionValues = []     
                    let arrayAH = []          
                    
                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id7 = month?.value[7]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: '0,0'})
                        } else {
                            const division = ((id7 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})

                            const divisionAH = ((valuesReturn[index2]?.value[7]/valuesReturn[index2 - 1]?.value[7] - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: !valuesReturn[index2 - 1]?.value[7] ? '0,0' : replaceDivisionAH})
                        }
                    })
                    accumulatedValuesMC = manager?.values?.reduce((accumulator, currentObject) => {return accumulator + currentObject.value}, 0)
                    let avAccumulatedValuesMC
                    if (accumulatedValuesMC != 0) {
                        avAccumulatedValuesMC = ((accumulatedValuesMC/accumulatedValuesRL) * 100)?.toFixed(1)
                    } else {
                        avAccumulatedValuesMC = '0,0'
                    }
                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        accumulatedValuesMC,
                        avAccumulatedValuesMC
                    }
                } else if (manager?.id == 8){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arrayGFDivisionRL, '4', '8')

                    let arrayDivisionValues = []    
                    let arrayAH = []   

                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id8 = month?.value[8]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: '0,0'})
                        } else {
                            const division = ((id8 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})

                            const divisionAH = ((valuesReturn[index2]?.value[8]/valuesReturn[index2 - 1]?.value[8] - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: !valuesReturn[index2 - 1]?.value[8] ? '0,0' : replaceDivisionAH})
                        }
                    })
                    accumulatedValuesGF = manager?.values?.reduce((accumulator, currentObject) => {return accumulator + currentObject.value}, 0)
                    let avAccumulatedValuesGF
                    if (accumulatedValuesGF != 0){
                        if (accumulatedValuesGF < 0){
                            avAccumulatedValuesGF = '0,0'
                        } else {
                            avAccumulatedValuesGF = ((accumulatedValuesGF/accumulatedValuesRL) * 100)?.toFixed(1)   
                        }
                    } else {
                        avAccumulatedValuesGF = '0,0'
                    }
                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        accumulatedValuesGF,
                        avAccumulatedValuesGF
                    }
                } else if (manager?.id == 9){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arrayDFDivisionRL, '4', '9')

                    let arrayDivisionValues = []        
                    let arrayAH = []   

                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id9 = month?.value[9]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: '0,0'})
                        } else {
                            const division = ((id9 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})

                            const divisionAH = ((valuesReturn[index2]?.value[9]/valuesReturn[index2 - 1]?.value[9] - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: !valuesReturn[index2 - 1]?.value[9] ? '0,0' : replaceDivisionAH})
                        }
                    })
                    accumulatedValuesDF = manager?.values?.reduce((accumulator, currentObject) => {return accumulator + currentObject.value}, 0)
                    let avAccumulatedValuesDF
                    if (accumulatedValuesDF != 0){
                        avAccumulatedValuesDF = ((accumulatedValuesDF/accumulatedValuesRL) * 100)?.toFixed(1)
                    } else {
                        avAccumulatedValuesDF = '0,0'
                    }
                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        accumulatedValuesDF,
                        avAccumulatedValuesDF
                    }
                } else if (manager?.id == 10){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arrayReturnDivisionRL, '4', '10')

                    let arrayDivisionValues = []   
                    let arrayAH = []      
                    
                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id10 = month?.value[10]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: '0,0'})
                        } else {
                            const division = ((id10 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})

                            const divisionAH = ((valuesReturn[index2]?.value[10]/valuesReturn[index2 - 1]?.value[10] - 1) * 100)?.toFixed(1)
                            const replaceDivisionAH = divisionAH?.replace('.', ',')
                            arrayAH.push({month: Number(month?.month), year: Number(month?.year), ah: !valuesReturn[index2 - 1]?.value[10] ? '0,0' : replaceDivisionAH})
                        }
                    })
                    accumulatedValuesFirstReturn = manager?.values?.reduce((accumulator, currentObject) => {return accumulator + currentObject.value}, 0)
                    let avAccumulatedValuesFirstReturn
                    if (accumulatedValuesFirstReturn != 0){
                        if (accumulatedValuesFirstReturn < 0){
                            avAccumulatedValuesFirstReturn = '0,0'
                        } else {
                            avAccumulatedValuesFirstReturn = ((accumulatedValuesFirstReturn/accumulatedValuesRL) * 100)?.toFixed(1)
                        }
                    } else {
                        avAccumulatedValuesFirstReturn = '0,0'
                    }
                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: arrayAH,
                        accumulatedValuesFirstReturn,
                        avAccumulatedValuesFirstReturn
                    }
                } else if (manager?.id == 11){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arraySecondMC, '4', '7')

                    let arrayDivisionValues = []   

                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id7 = month?.value[7]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                        } else {
                            const division = ((id7 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})
                        }
                    })
                    if (accumulatedValuesMC != 0){
                        accumulatedValuesSecondMC = Number((Number(accumulatedValuesMC)/Number(accumulatedValuesRL)) * 100)?.toFixed(1)
                    }
                    return {
                        ...manager,
                        av: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                ah: '0,0'
                            }
                        }),
                        values: arrayDivisionValues,
                        accumulatedValuesSecondMC
                    }
                } else if (manager?.id == 12){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arraySecondGF, '4', '8')

                    let arrayDivisionValues = []

                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id8 = month?.value[8]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                        } else {
                            const division = ((id8 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})
                        }
                    })
                    if (accumulatedValuesGF != 0){
                        if (accumulatedValuesGF < 0){
                            accumulatedValuesSecondGF = '0'
                        } else {
                            accumulatedValuesSecondGF = Number((accumulatedValuesGF/accumulatedValuesRL) * 100).toFixed(1)
                        }
                    }
                    return {
                        ...manager,
                        av: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                ah: '0,0'
                            }
                        }),
                        values: arrayDivisionValues,
                        accumulatedValuesSecondGF
                    }
                } else if (manager?.id == 13){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arraySecondDF, '4', '9')
                    let arrayDivisionValues = []
                    
                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id9 = month?.value[9]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                        } else {
                            const division = ((id9 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})
                        }
                    })
                    if (accumulatedValuesDF != 0){
                        accumulatedValuesSecondDF = Number((accumulatedValuesDF/accumulatedValuesRL) * 100).toFixed(1)
                    }
                    return {
                        ...manager,
                        av: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                ah: '0,0'
                            }
                        }),
                        values: arrayDivisionValues,
                        accumulatedValuesSecondDF
                    }
                } else if (manager?.id == 14){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arraySecondReturn, '4', '10')
                    let arrayDivisionValues = []

                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id10 = month?.value[10]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                        } else {
                            const division = ((id10 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})
                        }
                    })
                    if (accumulatedValuesFirstReturn != 0){
                        if (accumulatedValuesFirstReturn < 0){
                            accumulatedValuesSecondReturn = 0
                        } else {
                            accumulatedValuesSecondReturn = Number((accumulatedValuesFirstReturn/accumulatedValuesRL) * 100).toFixed(1)
                        }
                    }
                    return {
                        ...manager,
                        av: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                ah: '0,0'
                            }
                        }),
                        values: arrayDivisionValues,
                        accumulatedValuesSecondReturn
                    }
                } else if (manager?.id == 15){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arrayParceDivisionRL, '4', '15')
                    let arrayDivisionValues = [] 
                    
                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id15 = month?.value[15]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                        } else {
                            const division = ((id15 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})
                        }
                    })
                    accumulatedValuesPar = manager?.values?.reduce((accumulator, currentObject) => {return accumulator + currentObject.value}, 0)
                    let avAccumulatedValuesPar
                    if (accumulatedValuesPar != 0){
                        avAccumulatedValuesPar = ((accumulatedValuesPar/accumulatedValuesRL) * 100)?.toFixed(1)
                    } else {
                        avAccumulatedValuesPar = '0,0'
                    }
                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                ah: '0,0'
                            }
                        }),
                        accumulatedValuesPar,
                        avAccumulatedValuesPar
                    }
                } else if (manager?.id == 16){
                    const valuesReturn = reduceAndJoinValuesByMonthAndYearWithOfRule(arrayEmpreDivisionRL, '4', '16')
                    let arrayDivisionValues = []   
                    
                    valuesReturn.map((month, index2) => {
                        const id4 = month?.value[4]
                        const id16 = month?.value[16]
                        
                        if (id4 == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: '0,0'})
                        } else {
                            const division = ((id16 / id4) * 100)?.toFixed(1)
                            const replaceDivision = division?.replace('.', ',')
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), av: replaceDivision})
                        }
                    })
                    accumulatedValuesEmp = manager?.values?.reduce((accumulator, currentObject) => {return accumulator + currentObject.value}, 0)
                    let avAccumulatedValuesEmp
                    if (accumulatedValuesEmp != 0){
                        avAccumulatedValuesEmp = ((accumulatedValuesEmp/accumulatedValuesRL) * 100)?.toFixed(1)
                    } else {
                        avAccumulatedValuesEmp = '0,0'
                    }
                    return {
                        ...manager,
                        av: arrayDivisionValues,
                        ah: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                ah: '0,0'
                            }
                        }),
                        accumulatedValuesEmp,
                        avAccumulatedValuesEmp
                    }
                } else if (manager?.id == 17){
                    const accumulatedValues = {};

                    const valuesReturn = arrayPE.reduce((accumulator, month) => {
                        const { month: currentMonth, year: currentYear } = month;
                        const key = `${currentMonth}_${currentYear}`;
                        if (!accumulator[key]) {
                            accumulator[key] = {};
                            accumulator[key][1] = 0;
                            accumulator[key][2] = 0;
                            accumulator[key][3] = 0;
                            accumulator[key][5] = 0;
                            accumulator[key][6] = 0;
                            accumulator[key][8] = 0;
                            accumulator[key][9] = 0;
                            accumulator[key][15] = 0;
                            accumulator[key][16] = 0;
                        }
                    
                        if (month.id == 1) { 
                            accumulator[key][1] += month.value
                        } else if (month.id == 2) {
                            accumulator[key][2] += month.value
                        } else if (month.id == 3) {
                            accumulator[key][3] += month.value
                        } else if (month.id == 5) {
                            accumulator[key][5] += month.value
                        } else if (month.id == 6) {
                            accumulator[key][6] += month.value
                        } else if (month.id == 8) {
                            accumulator[key][8] += month.value
                        } else if (month.id == 9) {
                            accumulator[key][9] += month.value
                        } else if (month.id == 15) {
                            accumulator[key][15] += month.value
                        } else if (month.id == 16) {
                            accumulator[key][16] += month.value
                        }
                    
                        return accumulator;
                    }, accumulatedValues);
                
                    // Convertendo o objeto de acumulados em um array de objetos
                    const resultArray = Object.keys(accumulatedValues).map((key) => ({
                        month: parseInt(key.split('_')[0]),
                        year: parseInt(key.split('_')[1]),
                        value: accumulatedValues[key]
                    }))
                    
                    let arrayDivisionValues = []    
                    
                    resultArray.map((month, index2) => {
                        const id1 = month?.value[1]
                        const id2 = month?.value[2]
                        const id3 = month?.value[3]
                        const id5 = month?.value[5]
                        const id6 = month?.value[6]
                        const id8 = month?.value[8]
                        const id9 = month?.value[9]
                        const id15 = month?.value[15]
                        const id16 = month?.value[16]
                        
                        const result = (-id8-id15-id16)/(1-(-id2-id3-id5-id6-id9)/id1)
                        if (isNaN(result) || result == 0) {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), value: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(0), porcent: '0,0'})
                        } else {
                            arrayDivisionValues.push({month: Number(month?.month), year: Number(month?.year), value: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(result?.toFixed(2)), porcent: ((id1/result) * 100)?.toFixed(1)})         
                        }
                    })
                    accumulatedValuesPE = ((-accumulatedValuesGF-accumulatedValuesPar-accumulatedValuesEmp)/(1-(-accumulatedValuesIm-accumulatedValuesEst-accumulatedValuesCV-accumulatedValuesDV-accumulatedValuesDF)/accumulatedValuesRB)).toFixed(2)
                    if (isNaN(accumulatedValuesPE)){
                        accumulatedValuesPE = 0
                    }
                    let avAccumulatedValuesPE
                    if (accumulatedValuesRB != 0) {
                        avAccumulatedValuesPE = ((accumulatedValuesRB/accumulatedValuesPE) * 100)?.toFixed(1)
                        if (avAccumulatedValuesPE == Infinity){
                            avAccumulatedValuesPE = '0,0'
                        }
                    } else {
                        avAccumulatedValuesPE = '0,0'
                    }
                    return {
                        ...manager,
                        av: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                ah: '0,0'
                            }
                        }),
                        values: arrayDivisionValues,
                        accumulatedValuesPE,
                        avAccumulatedValuesPE
                    }
                } else {
                    accumulatedValuesRB = manager?.values?.reduce((accumulator, currentObject) => {return accumulator + currentObject.value}, 0)
                    return {
                        ...manager,
                        av: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                av: '0,0'
                            }
                        }),
                        ah: Array.from({length: arrayMonthsDataWithValue?.length}, (_,index) => {
                            return {
                                month: index,
                                ah: '0,0'
                            }
                        }),
                        accumulatedValuesRB
                    }
                }
            })
            setTableHead(arrayMonthsData)
            setNewData(structuredClone(newSecondForms))
        }
        getData()

    }, [])

    function reduceAndJoinValuesByMonthAndYear(array) {
        const accumulatedValues = [];
      
        array.forEach((month) => {
          const existingMonth = accumulatedValues.find(
            (item) => item.month === month.month && item.year === month.year
          );
      
          if (!existingMonth) {
            accumulatedValues.push({
              month: month.month,
              year: month.year,
              value: month.value,
            });
          } else {
            existingMonth.value += month.value;
          }
        });
      
        return accumulatedValues;
    }

    function reduceAndJoinValuesByMonthAndYearWithOfRule(array, cod1, cod2) {
        const accumulatedValues = {};

        array.reduce((accumulator, month) => {
            const { month: currentMonth, year: currentYear } = month;
            const key = `${currentMonth}_${currentYear}`;
            if (!accumulator[key]) {
            accumulator[key] = {};
            accumulator[key][cod1] = 0;
            accumulator[key][cod2] = 0;
            }
        
            if (month.id == cod1) { 
                accumulator[key][cod1] += month.value
            } else if (month.id == cod2) {
                accumulator[key][cod2] += month.value
            }
        
            return accumulator;
        }, accumulatedValues);
      
        // Convertendo o objeto de acumulados em um array de objetos
        const resultArray = Object.keys(accumulatedValues).map((key) => ({
            month: parseInt(key.split('_')[0]),
            year: parseInt(key.split('_')[1]),
            value: accumulatedValues[key]
        }));
      
        return resultArray;
    }

    return (
        <div className={`hidden sm:flex sm:flex-col md:h-[42vh] lg:h-[53vh] xl:h-[60vh] 2xl:h-[63vh] relative mt-5 w-full`}>                                  
            <div className="flex flex-col pb-2 shadow-xl pr-0 overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 relative rounded-md">
                <table className='border-collapse' id='TableToExport'>
                    <thead className='m-0 p-0 bg-primaryDefaultLight text-white font-bold relative'>
                        <tr className='sticky top-0 z-20'>
                            <th className='sticky left-0 top-0 bg-primaryDefaultLight '>
                                <div className='min-w-[20rem] z-5 p-1 border-0 border-r-2  border-r-gray-300 text-sm'>
                                    Painel Gerencial Operacional
                                </div>
                            </th>
                            {
                                tableHead.map((month, index) => {
                                    if (![(tableHead?.length - 2), (tableHead?.length - 1)].includes(index)){
                                        return (
                                            <th className='bg-primaryDefaultLight text-sm'>{month}</th>
                                        )
                                    } 
                                    if (index == (tableHead?.length - 2)){
                                        return (
                                            <th className={`bg-primaryDefaultLight text-sm sticky top-0 right-24`}>
                                                <div className='z-5 p-1 text-sm border-l-2 border-l-gray-300'>
                                                    {month}
                                                </div>
                                            </th>
                                        )
                                    }

                                    if (index == (tableHead?.length - 1)){
                                        return (
                                            <th className={`bg-primaryDefaultLight text-sm sticky right-0 border-r-2`}>
                                                <div className='z-5 p-1 text-sm'>
                                                    {month}
                                                </div>
                                            </th>
                                        )
                                    }
                                })
                            }
                        </tr>
                    </thead>
                    <tbody className='pb-2 m-0'>
                        {
                            newData.map((manager, index) => {
                                // vem com base no arquivo ../../../utils/rulesList.js
                                if (![11,12,13,14,15,16,17].includes(manager?.id)){
                                    return (
                                        <>
                                            <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'} ${manager?.id == 10 && 'border-b border-b-gray-700'}`}>
                                                <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                    <div className='min-w-[10rem] py-1 px-1 items-center dark:text-white text-xs'>{manager?.description}</div>
                                                </td>
                                                {
                                                    manager?.values?.map((value, indexValue) => {
                                                        return (
                                                            <>
                                                                <td className={`m-0 p-0 sticky left-0 z-3 border-l border-l-gray-600`}>
                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value?.value)}</div>
                                                                </td>
                                                                <td className={`m-0 p-0 sticky left-0 z-3`}>
                                                                    <div className='min-w-[4rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{manager?.id == 1 ? '' : `${manager?.av[indexValue]?.av}%`}</div>
                                                                </td>
                                                                <td className={`m-0 p-0 sticky left-0 z-3`}>
                                                                    <div className='min-w-[4rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{manager?.id == 1 ? '' : indexValue + 1 == 1 ? '0,0%' : `${manager?.ah[indexValue]?.ah}%`}</div>
                                                                </td>
                                                            </>
                                                        )
                                                    })
                                                }
                                                {/* Acumulados dos meses, valores e porcentagem */}
                                                <td className={`m-0 p-0 sticky right-24 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                    {
                                                        manager?.id == 1 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesRB)}
                                                        </div>    
                                                        : manager?.id == 2 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesIm)}
                                                        </div>
                                                        : manager?.id == 3 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesEst)}
                                                        </div>
                                                        : manager?.id == 4 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesRL)}
                                                        </div>
                                                        : manager?.id == 5 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesCV)}
                                                        </div> 
                                                        : manager?.id == 6 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesDV)}
                                                        </div>
                                                        : manager?.id == 7 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesMC)}
                                                        </div>
                                                        : manager?.id == 8 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesGF)}
                                                        </div>
                                                        : manager?.id == 9 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesDF)}
                                                        </div> 
                                                        :
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesFirstReturn)}
                                                        </div>   
                                                    }
                                                </td>
                                                <td className={`m-0 p-0 sticky right-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                    {
                                                        manager?.id == 1 ?
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '> - </div>     
                                                        : manager?.id == 2 ?
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.avAccumulatedValuesIm}%
                                                        </div>
                                                        : manager?.id == 3 ?
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.avAccumulatedValuesEst}%
                                                        </div>
                                                        : manager?.id == 4 ?
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.avAccumulatedValuesRL}%
                                                        </div>
                                                        : manager?.id == 5 ?
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.avAccumulatedValuesCV}%
                                                        </div> 
                                                        : manager?.id == 6 ?
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.avAccumulatedValuesDV}%
                                                        </div>
                                                        : manager?.id == 7 ?
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.avAccumulatedValuesMC}%
                                                        </div>
                                                        : manager?.id == 8 ?
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.avAccumulatedValuesGF}%
                                                        </div>
                                                        : manager?.id == 9 ?
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.avAccumulatedValuesDF}%
                                                        </div> 
                                                        :
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.avAccumulatedValuesFirstReturn}%
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                            })
                        }
                        {
                            newData.map((manager, index) => {
                                if ([11,12,13,14].includes(manager?.id)){
                                    return (
                                        <>
                                            <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'} ${manager?.id == 14 && 'border-b border-b-gray-700'}`}>
                                                <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                    <div className='min-w-[10rem] py-1 px-1 flex items-center dark:text-white text-xs'>{manager?.description}</div>
                                                </td>
                                                {
                                                    manager?.values?.map((value, indexValue) => {
                                                        return (
                                                            <>
                                                                <td className={`m-0 p-0 sticky left-0 z-3 border-l border-l-gray-700`} colSpan={3}>
                                                                    <div className='min-w-[4rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{value?.av}%</div>
                                                                </td>
                                                            </>
                                                        )
                                                    })
                                                }
                                                {/* Acumulados com suas porcentagens */}
                                                <td className={`m-0 p-0 sticky right-0 border-r-gray-700 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`} colSpan={2}>
                                                    {
                                                        manager?.id == 11 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.accumulatedValuesSecondMC}%
                                                        </div>
                                                        : manager?.id == 12 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.accumulatedValuesSecondGF}%
                                                        </div>
                                                        : manager?.id == 13 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.accumulatedValuesSecondDF}%
                                                        </div>
                                                        :
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.accumulatedValuesSecondReturn}%
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                            })
                        }
                        {
                            newData.map((manager, index) => {
                                if ([15,16].includes(manager?.id)){
                                    return (
                                        <>
                                            <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'} ${manager?.id == 16 && 'border-b border-b-gray-700'}`}>
                                                <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                    <div className='min-w-[10rem] py-1 px-1 flex items-center dark:text-white text-xs'>{manager?.description}</div>
                                                </td>
                                                {
                                                    manager?.values?.map((value, indexValue) => {
                                                        return (
                                                            <>
                                                                <td className={`m-0 p-0 sticky left-0 z-3 border-l border-l-gray-700`}>
                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value?.value)}</div>
                                                                </td>
                                                                <td className={`m-0 p-0 sticky left-0 z-3`}>
                                                                    <div className='min-w-[4rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>{manager?.id == 1 ? '' : `${manager?.av[indexValue]?.av}%`}</div>
                                                                </td>
                                                                <td className={`m-0 p-0 sticky left-0 z-3`}>
                                                                    <div className='min-w-[4rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white '>0,0%</div>
                                                                </td>
                                                            </>
                                                        )
                                                    })
                                                }
                                                {/* Acumulados */}
                                                <td className={`m-0 p-0 sticky right-24 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                    {
                                                        manager?.id == 15 ?
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesPar)}
                                                        </div>  
                                                        :
                                                        <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesEmp)}
                                                        </div>  
                                                    }
                                                </td>
                                                <td className={`m-0 p-0 sticky right-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                    {
                                                        manager?.id == 15 ?
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.avAccumulatedValuesPar}%
                                                        </div>  
                                                        :
                                                        <div className='min-w-[6rem] py-1 px-1 border-0 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white '>
                                                            {manager?.avAccumulatedValuesEmp}%
                                                        </div>  
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                            })
                        }
                        {
                            newData.map((manager, index) => {
                                if ([17].includes(manager?.id)){
                                    return (
                                        <>
                                            <tr className={`${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'} border-b border-b-gray-700`}>
                                                <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                                                    <div className='min-w-[10rem] py-1 px-1 flex items-center dark:text-white text-xs'>{manager?.description}</div>
                                                </td>
                                                {
                                                    manager?.values?.map((value, indexValue) => {
                                                        return (
                                                            <>
                                                                <td className={`m-0 p-0 sticky left-0 z-3 border-l border-l-gray-700`} colSpan={3}>
                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white'>{value?.value}</div>
                                                                    <div className='min-w-[10rem] py-1 px-1 border-0 flex items-center text-sm justify-center dark:text-white'>{value?.porcent}%</div>
                                                                </td>
                                                            </>
                                                        )
                                                    })
                                                }
                                                <td className={`m-0 p-0 sticky right-0 ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-[#ececec] dark:bg-gray-600'} z-3`} colSpan={2}>
                                                    <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white'>
                                                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(manager?.accumulatedValuesPE)}
                                                    </div>
                                                    <div className='min-w-[10rem] py-1 px-1 border-0 border-l border-l-gray-700 border-r border-r-gray-700 flex items-center text-sm justify-center dark:text-white'>
                                                        {manager?.avAccumulatedValuesPE}%
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}