import React, { useEffect, useState } from 'react'

export function Input({ children, height, width, placeholder, onChange, value, autoFocus, id, type, disabled, charLimit, onBlur, onKeyDown, minLength }) {

    const [widthInput, setWidthInput] = useState(width?.length > 0 ? `` : width > 0 ? `w-${width}` : 'w-96')
    const [widthPx, setWidthPx] = useState(width)

    const heightPixel = height > 0 ? `h-[${height}px]` : 'h-12 sm:h-8'
    const heightDefault = height > 0 ? `h-${height}` : 'h-12 sm:h-8'

    useEffect(() => {
        setWidthPx(width)
        width && setWidthInput(typeof (width) === 'string' ? `w-[${width}]` : width > 0 ? `w-${width}` : 'w-96')
    }, [width])

    return (
        <input
            style={{ width: typeof (width) === 'string' ? width?.indexOf('px') > -1 ? width : '' : '' }}
            type={type}
            onBlur={(e) => onBlur !== undefined && onBlur()}
            onKeyDown={(e) => onKeyDown ? onKeyDown(e) : ''}
            disabled={disabled ? true : false}
            id={id}
            autocomplete="off"
            min={minLength ? minLength : ''}
            value={value ? value : ''}
            autoFocus={autoFocus ? true : false}
            onChange={(e) => e.target.value.length > charLimit ? '' : onChange(e)}
            placeholder={placeholder}
            className={`w-80 sm:${widthInput} ${height > 96 ? `${heightPixel}` : `${heightDefault}`} 
            px-1 border border-gray-400 placeholder-white
            border-opacity-75 rounded-sm  
            outline-none
        

        transition-all duration-200
        dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark 
        focus:shadow-borderShadow 
        ${disabled ? 'bg-gray-300 dark:bg-thirdDefaultDark' : ''}'`}></input>
    )
}