// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/useAuth'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
// components 
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { TitlePage } from '../../../components/titlePages/title.page'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { validateFields } from '../../../utils/form.validator'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
import Multiselect from '../../../components/selects/mult.select'
// utils 
import { validateEmail } from '../../../utils/validators/email.validator'
import { mCPF } from '../../../utils/validators/cpfMask'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { justNumber } from '../../../utils/validators/justNumber'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//services
import { api } from '../../../services/api/api'
import { BsCardChecklist } from 'react-icons/bs'

export function UserCreate() {

    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [permissionsData, setPermissionsData] = useState([])
    const { screenY } = useScreenSizeContext()

    const [loading, setLoading] = useState(true)
    const navegate = useNavigate()
    const [typeAccessList, setTypeAccessList] = useState([])
    const [companiesList, setCompaniesList] = useState([])
    const [selectedLink, setSelectedLink] = useState([])
    //selectedVariables
    const [selectedTypeAccesss, setSelectedTypeAccess] = useState('')
    const [selectedPermissionGroup, setSelectedPermissionGroup] = useState('')
    const [selectedFullname, setSelectedFullname] = useState('')
    const [selectedUserEmail, setSelectedUserEmail] = useState('')
    const [selectedBirthDay, setSelectedBirthDay,] = useState('')
    const [selectedDDDPhone, setSelectedDDDPhone] = useState('')
    const [selectedUserGender, setSelectedUserGender] = useState('')
    const [selectedPhone, setSelectedPhone,] = useState('')
    const [selecteduserCpf, setSelectedUserCpf] = useState('')

    function cleanState() {

        setSelectedTypeAccess('')
        setSelectedFullname('')
        setSelectedUserEmail('')
        setSelectedUserCpf('')
        setSelectedBirthDay('')
    }

    useEffect(() => {

        async function getCustomersAPI() {
            const getCustomersAPI = await api.get('/api/v1/customers', {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setCompaniesList(getCustomersAPI?.data?.data)
        }

        async function getTypeAccess() {

            try {

                const typeAccessData = await api.get(`/api/v1/typeAccess`, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                setTypeAccessList(typeAccessData?.data?.data.filter(access => {
                    if (access?.id < 3) {
                        return access
                    }
                }))

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        async function getPermissionsGroup() {


            try {
                const permissionsData = await api.get(`/api/v1/permissionGroups`, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                setPermissionsData(permissionsData.data.data)
                setLoading(false)
            } catch (error) {

                if (responseError(error).length > 0) {
                    setLoading(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getTypeAccess()
        getPermissionsGroup()
        getCustomersAPI()

    }, [])

    async function handleSubmit() {

        const fields =
            [
                { "name": 'selectedFullname', "value": selectedFullname, "required": true, "type": 'string' },
                { "name": 'selectedUserEmail', "value": selectedUserEmail, "required": true, "type": 'string' },
                { "name": 'selectedTypeAccesss', "value": selectedTypeAccesss, "required": true, "type": 'string' },
                { "name": 'selectedPermissionGroup', "value": selectedPermissionGroup, "required": [2, 3].includes(selectedTypeAccesss?.id) ? true : false, "type": 'string' },
            ]


        setLoading(true)

        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (!validateEmail(selectedUserEmail)) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('E-mail inválido')
        }
        if (!selectedTypeAccesss) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Selecione um tipo de acesso')
        }

        const idsCustomerBlocked = []

        selectedLink.map(customer => {
            idsCustomerBlocked.push(customer?.id)
        })

        try {

            const userCreate = await api.post('api/v1/users', {
                email: selectedUserEmail,
                fullname: selectedFullname,
                birthday: selectedBirthDay ? selectedBirthDay : undefined,
                cpf: selecteduserCpf ? selecteduserCpf.replace('-', '').replaceAll('.', '') : undefined,
                dddphone: selectedDDDPhone !== '' ? selectedDDDPhone : undefined,
                phone: selectedPhone ? selectedPhone.replace('-', '') : undefined,
                gender: selectedUserGender !== '' ? selectedUserGender.id : undefined,
                typeAccess_id: parseInt(selectedTypeAccesss.id),
                permissionGroups_id: selectedPermissionGroup !== undefined ? selectedPermissionGroup.id : undefined,
                blockedCustomers: selectedLink ? idsCustomerBlocked : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navegate('/users')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        cleanState()
        setLoading(false)

    }

    //temporary API
    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    return (
        <Container>
            <TitlePage>
                <Title text={`Novo usuário - Interno`} />
            </TitlePage>
            <Body>
                {
                    loading ?
                        <div className='flex flex-col items-center justify-center w-full h-full'>
                            <DefaultLoader />
                        </div>
                        :
                        <div className='flex flex-col items-center lg:items-start justify-start w-full'>
                            <form className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Nome Completo *</a>
                                    <Input width={80} charLimit={255} id='selectedFullname' type='text' value={selectedFullname} onChange={(e) => setSelectedFullname(e.target.value)} autoFocus={true}></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                    <Input charLimit={255} id='selectedUserEmail' width={80} type='e-mail' value={selectedUserEmail} onChange={(e) => { validateEmail(e.target.value); setSelectedUserEmail(e.target.value) }}></Input>
                                </label>
                                <label className='relative flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Tipo de acesso *</a>
                                    <InputAutoComplete
                                        data={typeAccessList}
                                        selectedLabel={'description'}
                                        optionList={['id', 'description']}
                                        value={selectedTypeAccesss?.description}
                                        onChange={selectedTypeAccess => setSelectedTypeAccess(selectedTypeAccess)}
                                        id='selectedTypeAccesss'
                                        width={80}
                                    />
                                </label>
                                {
                                    [2, 3].includes(selectedTypeAccesss.id) &&
                                    <LabelInput 
                                        text={'Permissão *'}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir grupo de permissão que o usuário melhor se encaixa.'}
                                    >
                                        <InputAutoComplete
                                            data={permissionsData}
                                            selectedLabel={'name'}
                                            optionList={['name']}
                                            value={selectedPermissionGroup?.name}
                                            onChange={value => setSelectedPermissionGroup(value)}
                                            id='selectedPermissionGroup'
                                            width={80}
                                        />
                                    </LabelInput>
                                }
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>CPF</a>
                                    <Input
                                        width={80}
                                        charLimit={14}
                                        id='selecteduserCpf'
                                        type='text'
                                        value={selecteduserCpf}
                                        onChange={(e) => setSelectedUserCpf(mCPF(e.target.value))}
                                    />
                                </label>
                                <div className='flex flex-row gap-3'>
                                    <LabelInput text={'DDD'}>
                                        <Input charLimit={2} width={'50px'} type={'text'} onChange={(e) => setSelectedDDDPhone(justNumber(e.target.value))} value={selectedDDDPhone}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Telefone'}>
                                        <Input charLimit={10} type={'text'} width={'256px'} onChange={(e) => setSelectedPhone(maskPhone(e.target.value))} value={selectedPhone}></Input>
                                    </LabelInput>
                                </div>
                                {
                                    [2].includes(selectedTypeAccesss.id) &&
                                    <LabelInput 
                                        text={'Clientes para usuário não visualizar'} 
                                        icon={<BsCardChecklist className='flex items-center ml-2 text-lg' />} 
                                        showIconInfo={true}
                                        messageIconInfo={'Definir clientes que não irão aparecer para o usuário.'}
                                    >
                                        <Multiselect
                                            items={companiesList}
                                            mult
                                            onChange={e => {setSelectedLink(e)}}
                                            selectedLabel={'businessName'}
                                            value={selectedLink ? selectedLink : []}
                                            id={`selectedLink`}
                                        />
                                    </LabelInput>
                                }
                            </form>
                            <div className='mt-10 pb-5 lg:pb-0 flex justify-center flex-row gap-6 lg:gap-1 w-full items-center lg:justify-start'>
                                <Button
                                    approval={false}
                                    shadow={true}
                                    onClick={() => { navegate('/users'); setShowModificationModal(false) }}
                                >Cancelar
                                </Button>
                                <Button
                                    approval={true}
                                    shadow={true}
                                    onClick={() => handleSubmit()}
                                >+ Novo usuário
                                </Button>
                            </div>
                        </div>
                }
            </Body>
        </Container>
    )
}