import React, { useEffect, useState } from 'react';
import { FaFile } from "react-icons/fa";
import ButtonFile from '../buttons/buttonFile';

const FileDropZone = ({ setSendFile }) => {
    const [file, setFile] = useState(null);
    const [fileButton, setFileButton] = useState('')
    const [fileButtonName, setFileButtonName] = useState('')

    useEffect(() => {
        setSendFile(fileButton)
    }, [fileButton])

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        { setFile(droppedFile); setSendFile(droppedFile) }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    return (
        <div
            className='w-full border border-gray-300 h-80 flex flex-col justify-center items-center'
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            {
                file ?
                    (
                        <p>Arquivo selecionado: {file.name}</p>
                    )
                    : fileButton ?
                        (
                            <p>Arquivo selecionado: {fileButtonName}</p>
                        )
                        :
                        (
                            <div className='flex flex-col justify-center items-center gap-5'>
                                <p className='text-xl text-blue-500'><FaFile /></p>
                                <p>Arraste um arquivo aqui</p>
                                <div><ButtonFile setFileButton={setFileButton} setFileButtonName={setFileButtonName} /></div>
                                <input type="file" id="inputArquivo" className='hidden'></input>
                            </div>
                        )
            }
        </div>
    );
};

export default FileDropZone;