//hooks
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/buttons/button.default";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
//utils
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//services
import { api } from '../../../services/api/api'

export function TaxRegimeCreate() {

    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [startDate, setStartDate] = useState('')
    const [taxation, setTaxation] = useState('')
    const [simplePercentage, setSimplePercentage] = useState('')
    const [pis, setPis] = useState('')
    const [cofins, setCofins] = useState('')
    const [ircs, setIrcs] = useState('')
    const [iva, setIva] = useState('')

    const optionsTaxation = [
        {
            id: 1,
            name: 'Simples Nacional'
        },
        {
            id: 2,
            name: 'Lucro Presumido'
        },
        {
            id: 3,
            name: 'Lucro Real'
        }
    ]

    async function handleSubmit() {

        setLoading(true)

        let requiredFields = []
        if (taxation == 1) {
            requiredFields = [
                { name: 'startDate', value: startDate, required: true, type: 'string' },
                { name: 'taxation', value: taxation, required: true, type: 'string' },
                { name: 'simplePercentage', value: simplePercentage, required: true, type: 'string' }
            ]
        } else {
            requiredFields = [
                { name: 'startDate', value: startDate, required: true, type: 'string' },
                { name: 'taxation', value: taxation, required: true, type: 'object' },
                { name: 'pis', value: pis, required: true, type: 'string' },
                { name: 'cofins', value: cofins, required: true, type: 'string' },
                { name: 'ircs', value: ircs, required: true, type: 'string' },
                { name: 'iva', value: iva, required: true, type: 'string' },
            ]
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post('api/v1/lm/regimeTributary', {
                startDate: startDate,
                taxation: taxation?.id,
                simplePercentage: simplePercentage ? simplePercentage : undefined,
                pis: taxation == 1 ? 0 : pis,
                cofins: taxation == 1 ? 0 : cofins,
                ircs: taxation == 1 ? 0 : ircs,
                iva: taxation == 1 ? 0 : iva
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/taxRegime')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <div>
                        <Title text={'Cadastro de Regime Tributário'}></Title>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <DefaultLoader />
                        :
                        <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                            <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                <LabelInput 
                                    text={'Tributação'} 
                                    required={true}
                                    showIconInfo={true}
                                    messageIconInfo={'Definir tributação para o regime tributário.'}
                                >
                                    <InputAutoComplete
                                        width={80}
                                        data={optionsTaxation}
                                        optionList={["id", "name"]}
                                        selectedLabel={'name'}
                                        value={taxation ? optionsTaxation.filter(item => item.id == taxation?.id)[0]?.name : ''}
                                        preSelectedValue={taxation ? optionsTaxation.filter(item => item.id == taxation?.id)[0]?.name : ''}
                                        id='taxation'
                                        onChange={(e) => { setTaxation(e) }}
                                    />
                                </LabelInput>
                                {
                                    taxation?.id == 1 ?
                                    <>
                                        <LabelInput text={'Simples Nacional %'} required={true}>
                                            <Input
                                                type={'number'}
                                                width={80}
                                                value={simplePercentage ? simplePercentage : ''}
                                                id='simplePercentage'
                                                onChange={(e) => setSimplePercentage(e.target.value)}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Data Inicial'} required={true}>
                                            <Input
                                                type={'month'}
                                                width={80}
                                                value={startDate ? startDate : ''}
                                                id='startDate'
                                                onChange={(e) => {setStartDate(e.target.value)}}
                                            />
                                        </LabelInput>
                                    </>
                                    :
                                    <>
                                        <LabelInput text={'Data Inicial'} required={true}>
                                            <Input
                                                type={'month'}
                                                width={80}
                                                value={startDate ? startDate : ''}
                                                id='startDate'
                                                onChange={(e) => {setStartDate(e.target.value)}}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'PIS %'} required={true}>
                                            <Input
                                                type={'number'}
                                                width={80}
                                                value={pis ? pis : ''}
                                                id='pis'
                                                onChange={(e) => setPis(e.target.value)}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'COFINS %'} required={true}>
                                            <Input
                                                type={'number'}
                                                width={80}
                                                value={cofins ? cofins : ''}
                                                id='cofins'
                                                onChange={(e) => setCofins(e.target.value)}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'IR/CS %'} required={true}>
                                            <Input
                                                type={'number'}
                                                width={80}
                                                value={ircs ? ircs : ''}
                                                id='ircs'
                                                onChange={(e) => setIrcs(e.target.value)}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'IVA %'} required={true}>
                                            <Input
                                                type={'number'}
                                                width={80}
                                                value={iva ? iva : ''}
                                                id='iva'
                                                onChange={(e) => setIva(e.target.value)}
                                            />
                                        </LabelInput>
                                    </>                                    
                                }
                            </form>
                            <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 lg:gap-2 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                                <Button
                                    onClick={() => navigate('/taxRegime')}
                                    shadow={true}
                                    approval={false}
                                >Cancelar
                                </Button>
                                <Button
                                    shadow={true}
                                    onClick={() => handleSubmit()}
                                >Cadastrar
                                </Button>
                            </div>
                        </div>
                    }
                </Body>
            </Container>
        </>
    )
}