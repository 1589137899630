import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "../../../components/container/container";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Body } from "../../../components/container/Body";
import { Title } from "../../../components/titlePages/title";
import { Button } from "../../../components/buttons/button.default";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import FileDropZone from "../../../components/dropZone/dropZone";
import { useAuth } from "../../../contexts/useAuth";
import { api } from "../../../services/api/api";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { useThemeContext } from "../../../contexts/themeContext";
import { ScreenModal } from "../../../components/modals/notification/screenModal";

export function Imports() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const [sendFile, setSendFile] = useState('')
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()

    async function downloadCSV() {

        setLoading(true)

        const dataExportsModel = await api.get(`/api/v1/fc/imports/exportModel`, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                authorization: `Bearer ${userData[0].token}`
            }
        })

        let fileObj = URL.createObjectURL(dataExportsModel?.data)
        let myElement = document.createElement('a')
        myElement.setAttribute('href', fileObj)
        myElement.setAttribute("download", `PlanoDeContas_${Number((new Date))}`)
        myElement.click()

        setLoading(false)

    }


    async function importPlansAccounts() {

        if (!sendFile) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowUniversalModal(false)
            return setShowNotificationModalText("Selecione um arquivo, não é possivel importar.")
        }

        setLoading(true)
        try {

            const formData = new FormData();
            formData.append('files', sendFile);

            await api.post(`/api/v1/fc/imports/importAccounts`, formData, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })
            setSendFile('')
            setShowModificationModal(true)
            setShowNotificationModalText('Importação realizada com sucesso')
            navigate('/accountsPlans')
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(true)
            setLoading(false)

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }


    return (
        <>
            <ScreenModal title={"Confirmar importação."} visible={showUniversalModal} width={400} height={200}>
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente realizar a importação ?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => importPlansAccounts()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div>
                        <Title text={'Importações'}></Title>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            <>
                                {/*importar plano de contas*/}
                                <div className="flex items-center justify-start gap-10 w-full">
                                    <div className="flex">
                                        <p className="text-lg text-blue-500">Importação de plano de contas</p>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-start items-start gap-[10px] w-full">
                                    <h1 className="text-lg text-red-500">Atenção às regras de importação.</h1>
                                    <p>
                                        <span className="font-semibold">Regras de importação:</span> Utilizar o arquivo base para importação, o arquivo poderá ser baixado no botão abaixo ou clicando <button className="underline" onClick={() => downloadCSV()}>aqui</button>
                                    </p>
                                    <p>
                                        <span className="font-semibold">Campos obrigatórios:</span> "SubGrupo", "Conta", "Nome", "Descrição", "Origem", "Compõe Saldo Fluxo" e "Compõe Saldo Individamento"
                                    </p>
                                    <p className='text-sm flex flex-col gap-1'>
                                        <i>Origem: C - Credora ou D - Devedora</i>
                                        <i>Compõe: Sim ou Não/Vazio</i>
                                    </p>
                                    <Button onClick={() => downloadCSV()}>Baixar</Button>
                                </div>
                                <div className="w-full flex">
                                    <FileDropZone setSendFile={setSendFile} />
                                </div>
                                <div className="flex gap-10">
                                    <Button onClick={() => navigate(-1)} approval={false} width={20}>Voltar</Button>
                                    <Button onClick={() => setShowUniversalModal(true)}  >Importar</Button>
                                </div>
                            </>
                    }
                </Body>
            </Container >
        </>
    )
}