import React, { useEffect, useState } from 'react'

export function Toggle({ status,onClick, disable, greenAndRed}){

    const [thisStatus, setThisStatus] = useState(status)

    useEffect(()=>{

        setThisStatus(status)

    },[status])


    return (
        <div onClick={()=> {setThisStatus(!thisStatus);onClick(thisStatus)}} className={`${status && !greenAndRed ? 'bg-primaryDefaultLight' : status && greenAndRed ? 'bg-green-800' :'bg-zinc-300 '} cursor-pointer flex flex-col items-start h-6 w-12 rounded-full `}>
            <div className={`${status ? 'translate-x-6 ' : 'translate-x-0'} transition-all duration-300 bg-zinc-300 border ${greenAndRed ? `${status ? 'border-green-800': ''}` : 'border-primaryDefaultLight'} h-6 w-6 rounded-full`}></div>
        </div>
    //    <button onClick={ () => onClick(action)} className={`text-sm ${approval === false ?'bg-white rounded-full' : 'bg-primaryDefaultLight hover:bg-secondaryDefaultLight'} transition-all duration-300 text-titleGrayTextLight ${shadow ? 'shadow-md shadow-gray-500/50 dark:shadow-md dark:shadow-secondaryDefaultDark' : ''} ${width > 96 ? widthPixel : widthDefault} ${height > 96 ? heightDefault : heightDefault}`}>{children}</button>
    )
}