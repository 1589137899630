export function validatorSales(array) {

    const arrayKeys = Object.keys(array)

    const errors = arrayKeys.filter((key) => {

        switch (array[key].products_id) {
            case null:
                document.getElementById(`${key} - products_id`).style.border = 'solid red 1px'
                document.getElementById(`${key} - quantity`).style.border = 'solid red 1px'
                document.getElementById(`${key} - unitPrice`).style.border = 'solid red 1px'
                return key

            case '':
                document.getElementById(`${key} - products_id`).style.border = 'solid red 1px'
                document.getElementById(`${key} - quantity`).style.border = 'solid red 1px'
                document.getElementById(`${key} - unitPrice`).style.border = 'solid red 1px'
                return key

            case undefined:
                document.getElementById(`${key} - products_id`).style.border = 'solid red 1px'
                document.getElementById(`${key} - quantity`).style.border = 'solid red 1px'
                document.getElementById(`${key} - unitPrice`).style.border = 'solid red 1px'
                return key

            default:
                break;
        }

        switch (array[key].quantity) {
            case null:
                document.getElementById(`${key} - products_id`).style.border = 'solid red 1px'
                document.getElementById(`${key} - quantity`).style.border = 'solid red 1px'
                document.getElementById(`${key} - unitPrice`).style.border = 'solid red 1px'
                return key

            case '':
                document.getElementById(`${key} - products_id`).style.border = 'solid red 1px'
                document.getElementById(`${key} - quantity`).style.border = 'solid red 1px'
                document.getElementById(`${key} - unitPrice`).style.border = 'solid red 1px'
                return key

            case undefined:
                document.getElementById(`${key} - products_id`).style.border = 'solid red 1px'
                document.getElementById(`${key} - quantity`).style.border = 'solid red 1px'
                document.getElementById(`${key} - unitPrice`).style.border = 'solid red 1px'
                return key

            default:
                break;
        }

        switch (array[key].unitPrice) {
            case null:
                document.getElementById(`${key} - products_id`).style.border = 'solid red 1px'
                document.getElementById(`${key} - quantity`).style.border = 'solid red 1px'
                document.getElementById(`${key} - unitPrice`).style.border = 'solid red 1px'
                return key

            case '':
                document.getElementById(`${key} - products_id`).style.border = 'solid red 1px'
                document.getElementById(`${key} - quantity`).style.border = 'solid red 1px'
                document.getElementById(`${key} - unitPrice`).style.border = 'solid red 1px'
                return key

            case undefined:
                document.getElementById(`${key} - products_id`).style.border = 'solid red 1px'
                document.getElementById(`${key} - quantity`).style.border = 'solid red 1px'
                document.getElementById(`${key} - unitPrice`).style.border = 'solid red 1px'
                return key

            default:
                break;
        }
    })
    return errors

}