import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// components 
import { FaArrowDown, FaEdit, FaFilter } from 'react-icons/fa'
import { FiSlash } from 'react-icons/fi'
import { TablePaginationDefault } from '../../../components/table/tablePagination.default'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Tooltip } from '../../../components/toolTip/toolTip'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// utils
import { justNumber } from '../../../utils/validators/justNumber'
import { cnpjMask } from '../../../utils/validators/cnpjMask'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { api } from '../../../services/api/api'

export function CompaniesTable() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { setShowUniversalModal } = useThemeContext()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({})


    function handleEdit(data) {
        navigate(`/companiesEdit/${data.id}`)
    }

    async function exportCompanies(){
        try {

        const urlContructor = ['']

        Object.keys(filterConstructor).map(key => {
            if(![null, undefined, '', NaN].includes(filterConstructor[key])){
                urlContructor.push(`${key}=${filterConstructor[key]}`)
            }
        })
        
        const dataExports = await api.get('/api/v1/companies/exports' + '?' + urlContructor.join('&'), {
            headers: {
                authorization: `Bearer ${userData[0].token}`
            },
            responseType: 'blob',
        })

        const url = window.URL.createObjectURL(dataExports?.data)
        const downloadElement = document.createElement('a')
        downloadElement.setAttribute('href', url)
        downloadElement.setAttribute('download', `Empresas_${new Date().toISOString().split('T')[0]}`)
        downloadElement.click()

        } catch(error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Filtros'}>
            {
                loading
                    ? <DefaultLoader />
                    : (
                        <div className='flex flex-col pt-2 pb-5 px-0 gap-2 items-center'>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                <Input
                                    charLimit={255} id='id' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                    value={filterConstructor?.id}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Nome da empresa *</a>
                                <Input
                                    charLimit={255} id='businessName' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, businessName: e.target.value })}
                                    value={filterConstructor?.businessName}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Nome fantasia *</a>
                                <Input
                                    charLimit={255} id='fantasyName' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, fantasyName: e.target.value })}
                                    value={filterConstructor?.fantasyName}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                <Input
                                    charLimit={255} id='email' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, email: e.target.value })}
                                    value={filterConstructor?.email}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>CNPJ *</a>
                                <Input
                                    charLimit={255} id='cnpj' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, cnpj: e.target.value })}
                                    value={filterConstructor?.cnpj}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>UF *</a>
                                <Input
                                    charLimit={255} id='addressState' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, addressState: e.target.value })}
                                    value={filterConstructor?.addressState}
                                ></Input>
                            </label>                       
                            <div className='mt-5'>
                                <Button onClick={() => {
                                    setFilter(filterConstructor)
                                    setShowUniversalModal(false)
                                }}>Filtrar</Button>
                            </div>
                        </div>
                    )
            }
            </ScreenModal>
            <Container>
                <TitlePage displayNotification={showNotification} success={successData} setDisplayNotification={e => setShowNotication(e)}>
                <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                    <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                        <Title text={'Empresas de Cliente'}></Title>
                        <div className='flex flex-row gap-2 md:w-48 items-center'>
                            <h3 className='hidden sm:flex text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity} Empresas</h3>
                            <div className='hidden md:flex flex-row justify-start items-center gap-1 cursor-pointer ml-3' onClick={() => exportCompanies()}>
                                <Tooltip
                                    component={<a className='dark:text-titleGrayTextDark text-xs 2xl:text-sm'>Exportar</a>}
                                    title="Exportar lista em excel"
                                />
                                <FaArrowDown className='dark:text-titleGrayTextDark text-xs 2xl:text-sm' />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/companiesCreate')}>
                        {
                            (userData[0]?.permissions?.indexOf('companies:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                            <Button shadow={true} onClick={() => navigate('/companiesCreate')}>+ Nova empresa</Button>
                        }
                    </div>
                </div>
                </TitlePage>
                <Body>
                    <div className='w-full sm:mt-0 mt-2 items-center flex gap-4'>
                        <Button onClick={() => setShowUniversalModal(true)}>
                            <FaFilter className='text-white' /> 
                            <p className='text-white'> Filtrar </p>
                        </Button>
                        {filter && JSON.stringify(filter) != JSON.stringify({ })
                            ? <Tippy
                                key={`clearFilter`}
                                content={<span>Limpar filtro</span>}
                                arrow={true}
                                animation='perspective'
                                placement='right'
                                delay={100}>
                                <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                    <FiSlash className='cursor-pointer' onClick={async () => {
                                        setLoading(true)
                                        setFilter({ })
                                        setFilterConstructor({ })
                                        await new Promise(resolve => setTimeout(resolve, 500))
                                        setLoading(false)
                                    }} />
                                </div>
                            </Tippy>
                            : <></>
                        }
                    </div>
                    <TablePaginationDefault
                        onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                        quantity={(e) => setQuantity(e)}
                        apiUrl={'/api/v1/companies/readAll'}
                        filters={filter}
                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                        collumns={[
                            {
                                name: 'id',
                                type: 'number',
                                description: 'Código'
                            },
                            {
                                name: 'businessName',
                                type: 'string',
                                description: 'Razão Social'
                            },
                            {
                                name: 'fantasyName',
                                type: 'string',
                                description: 'Nome Fantasia',
                                func: (item) => {
                                    if (item?.fantasyName){
                                        return item?.fantasyName
                                    } else {
                                        return ' - '
                                    }
                                }
                            },
                            {
                                name: 'customer_name',
                                type: 'string',
                                description: 'Cliente'
                            },
                            {
                                name: 'cnpj',
                                type: 'number',
                                description: 'CNPJ',
                                func: (item) => {
                                    return item?.cnpj ? cnpjMask(item?.cnpj?.toString()) : ' - '
                                }
                            },
                            {
                                name: 'email',
                                type: 'string',
                                description: 'E-mail',
                                func: (item) => {
                                    if (!item?.email){
                                        return ' - '
                                    } else {
                                        return item?.email
                                    }
                                }
                            },
                            {
                                name: 'created_at',
                                type: 'date',
                                description: 'Criado em'
                            }
                        ]}
                    />
                </Body>
            </Container>
        </>
    )
}