import React from 'react'
import { useAuth } from '../contexts/useAuth'
import { AppRoutes } from './app.routes'
import { LoginRoutes } from './login.routes'
import { PreLoader } from '../pages/preLoader'

export function RedirectRoutes() {

    const { signed, loadingAuth } = useAuth()

    return (
        <div>
            {
                signed ?
                <AppRoutes />
                : signed && loadingAuth ?
                <PreLoader/>
                :
                <LoginRoutes />
            }
        </div>
    )
}