import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// hooks
import { useAuth } from '../../contexts/useAuth'
// components
import { Button } from '../../components/buttons/button.default'
import { Body } from '../../components/container/Body'
import { Container } from '../../components/container/container'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
import { LabelInput } from '../../components/label/label.input'
import { Title } from '../../components/titlePages/title'
import { TitlePage } from '../../components/titlePages/title.page'
import { DefaultLoader } from '../../components/loaders/defaultLoader'
import { ClientsCreate } from '../configuration/clients/clients.create'
// utils
import { responseError } from '../../utils/responsesFunctions/error.response'
// service
import { api } from '../../services/api/api'
// context
import { useThemeContext } from '../../contexts/themeContext'
import { ScreenModal } from '../../components/modals/notification/screenModal'

export function CompanySelect() {
    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setPrimaryDefaultLight } = useThemeContext()
    const { selectedCompany, setSelectedCompany } = useAuth()
    const {
        setShowModificationModal,
        setShowNotificationModalSuccess,
        setShowNotificationModalText,
        setShowUniversalModal
    } = useThemeContext()
    const [company, setCompany] = useState('')
    const [companiesList, setCompaniesList] = useState([])
    const [loading, setLoading] = useState(false)

    const [notHasCustomers, setNotHasCustomers] = useState(false)

    async function getCustomers() {
        try {
            const getCustomersAPI = await api.get('/api/v1/companies/readAll', {
                headers: {
                    authorization: `Bearer ${userData[0].token}`,
                },
            })

            setCompaniesList(getCustomersAPI?.data?.data)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    useEffect(() => {
        getCustomers()
    }, [])

    const handleCompany = async () => {
        try {
            await api.post(
                '/api/v1/setting/customerAccessed',
                {
                    companies_id: company?.id,
                },
                {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`,
                    },
                }
            )

            //function para selecionar a empresa
            setShowUniversalModal(false)
            setSelectedCompany(company)
            document.documentElement.style.setProperty(
                '--companyColor',
                company.colorIdentifier ? company.colorIdentifier : '#3162be'
            )
            localStorage.setItem(
                'companyColor',
                company.colorIdentifier ? company.colorIdentifier : '#3162be'
            )

            localStorage.setItem('@company', JSON.stringify(company))
        } catch (error) {
            return
        }
    }

    return (
        <>
            <ScreenModal title={'Acesso a empresa'} width={400} height={200} >
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <p className='text-sm text-titleBlackTextLight text-center'>Empresa {company.businessName}, deseja continuar?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={handleCompany}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            {notHasCustomers ? (
                <ClientsCreate
                    hasCustomer={true}
                    notHasCustomers={e => {
                        getCustomers()
                        setNotHasCustomers(e)
                    }}
                />
            ) : (
                <Container>
                    <TitlePage>
                        <Title text={`Seleção de empresa`} />
                    </TitlePage>
                    <Body>
                        {loading ? (
                            <DefaultLoader />
                        ) : (
                            <>
                                {companiesList?.length == 0 &&
                                    [1, 2].includes(userData[0].typeAccess_id) ? (
                                    <p>
                                        Não foi encontrado nenhuma empresa cadastrada. Clique{' '}
                                        <a
                                            className="hover:cursor-pointer border-b-2 hover:text-gray-300"
                                            onClick={() => setNotHasCustomers(true)}>
                                            aqui
                                        </a>{' '}
                                        para cadastrar.
                                    </p>
                                ) : (
                                    <>
                                        <h1 className="text-primaryDefaultLight">
                                            Selecione a empresa
                                        </h1>
                                        <LabelInput text={'Empresa'}>
                                            <InputAutoComplete
                                                data={companiesList}
                                                onChange={e => setCompany(e)}
                                                optionList={['id', 'businessName']}
                                                placeHolder={' - '}
                                                selectedLabel={'businessName'}
                                            />
                                        </LabelInput>
                                        <Button shadow={true} onClick={() => setShowUniversalModal(true)}>
                                            Confirmar
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    </Body>
                </Container>
            )}
        </>
    )
}
