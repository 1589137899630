//hooks
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//services
import { api } from "../../../services/api/api";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
//utils
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Body } from "../../../components/container/Body";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Button } from "../../../components/buttons/button.default";
import { ScreenModal } from "../../../components/modals/notification/screenModal";

export function ComissionSaleEdit() {

    const { id } = useParams()
    const { userData } = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    const [commissionData, setCommissionData] = useState('')
    const [sellerData, setSellerData] = useState([])

    useEffect(() => {
        setLoading(true)

        async function getComissionDataAPI() {
            const getComissionData = await api.get(`/api/v1/lm/commissionSale/${id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setCommissionData(getComissionData?.data?.data)
        }

        async function getSellerDataAPI() {
            const getSellerData = await api.get('/api/v1/lm/seller', {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setSellerData(getSellerData?.data?.data)
        }

        getComissionDataAPI()
        getSellerDataAPI()
        setLoading(false)
    }, [])

    async function handleDelete() {
        setLoading(true)

        try {
            await api.delete(`/api/v1/lm/commissionSale/${id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/comissionSale')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setLoading(false)
    }

    async function handleSubmit() {
        setLoading(true)

        const requiredFields = [
            { name: 'seller_id', value: commissionData?.seller_id, required: true, type: 'number' },
            { name: 'percentage', value: commissionData?.percentage, required: true, type: 'string' },
            { name: 'startDate', value: commissionData?.startDate, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post(`/api/v1/lm/commissionSale`, {
                seller_id: commissionData?.seller_id,
                percentage: commissionData?.percentage,
                startDate: commissionData?.startDate
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/comissionSale')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    return (
        <>
            <ScreenModal title={'Exclusão de Comissão'} width={400} height={200} >
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Edição de Comissão`} />
                    </div>
                </TitlePage>
                {
                    loading ?
                        <DefaultLoader />
                        :
                        <Body>
                            <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                                <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                    <LabelInput 
                                        text={'Vendedor'} 
                                        required={true}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir o vendedor para aplicar a porcentagem da comissão.'}
                                    >
                                        <InputAutoComplete
                                            data={sellerData}
                                            optionList={['name']}
                                            selectedLabel={'name'}
                                            type={'string'}
                                            width={80}
                                            preSelectedValue={sellerData.filter(seller => seller.id == commissionData?.seller_id)[0]?.name}
                                            value={sellerData.filter(seller => seller.id == commissionData?.seller_id)[0]?.name}
                                            id='seller_id'
                                            onChange={(e) => setCommissionData((prev) => ({ ...prev, seller_id: e.id ? e.id : null }))}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Porcentagem'} required={true}>
                                        <Input
                                            type={'number'}
                                            width={80}
                                            value={commissionData?.percentage}
                                            id='percentage'
                                            onChange={(e) => setCommissionData((prev) => ({ ...prev, percentage: e.target.value }))}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Data inicial'} required={true}>
                                        <Input
                                            type={'date'}
                                            width={'150px'}
                                            value={commissionData?.startDate}
                                            id='startDate'
                                            onChange={(e) => setCommissionData((prev) => ({ ...prev, startDate: e.target.value }))}
                                        />
                                    </LabelInput>
                                </form>
                                <div className='mt-8 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                    <div className="flex justify-start gap-3">
                                        <Button
                                            onClick={() => navigate('/comissionSale')}
                                            shadow={true}
                                            approval={false}
                                        >Cancelar
                                        </Button>
                                    </div>
                                    <div className="flex gap-3">
                                        {
                                            (userData[0]?.permissions?.indexOf('lm_commissionSale:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                shadow={true}
                                                approval={false}
                                                onClick={() => setShowUniversalModal(true)}
                                            >Excluir
                                            </Button>
                                        }
                                        {
                                            (userData[0]?.permissions?.indexOf('lm_commissionSale:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                shadow={true}
                                                onClick={() => handleSubmit()}
                                            >Editar
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Body>
                }
            </Container>
        </>
    )
}